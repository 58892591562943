import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import MainProductCategorySub from './comp/mainProductCategorySub';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mainSearch extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),
			mainTab: cookies.get('mainTab') == undefined ? 0 : cookies.get('mainTab'),
			porder: cookies.get('porder') == undefined ? "recent_registeration" : cookies.get('porder'),
			oorder: cookies.get('oorder') == undefined ? "recent_registeration" : cookies.get('oorder'),
			stxt: props.match.params.word,

			productCnt:0,
			onlineCnt:0,
			plists: [],
			olists: [],
			wh : 0
		};
	}

	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$("body").addClass("gbg");

		var _this = this;
		_this.setState({wh : $(window).height()});
		setTimeout(function(){
			_this.setState({wh : $(window).height()});
		}, 700);

		if(this.state.mainTab === "2"){
			this.onlineMallFold();
		}
		if(this.state.mainTab === "3"){
			this.productFold();
		}

		var url = this.state.apiurl+"/product/search_keyword.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("target", "product");
		formData.append("keyword", this.state.stxt);
		formData.append("sort", this.state.porder);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				productCnt : res.data.data.products_count,
				plists : res.data.data.products
			});
			url = this.state.apiurl+"/product/search_keyword.json";
			formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("offset", 1);
			formData.append("target", "online");
			formData.append("keyword", this.state.stxt);
			formData.append("sort", this.state.oorder);
			let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res2 => {
				if( ~~res2.data.result === -1){
					if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
					if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
				}
				clearInterval(spinnerInterval2);
				//console.log("search_keyword");
				//console.log(res2.data.data.onlines);
				this.setState({
					onlineCnt : res2.data.data.onlines_count,
					olists : res2.data.data.onlines
				});
				if(res.data.data.products_count + res2.data.data.onlines_count == 0){
					this.alertPop("검색 결과가 없습니다.");
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
		$("#stxt").val(this.state.stxt);
	}
	handleKeyPress = (e) => {
		var stxt = $("#stxt").val();
		if(stxt.length>=2){
			if(e.key === "Enter"){
				document.location.href="/main/mainSearch/"+stxt;
			}
		}
	}
	cancelClick = (e) => {
		$("#stxt").val('');
		$("#stxt").blur();
		if(global.variable.osType == 0){
			$('body').removeClass('notouch').off('scroll touchmove mousewheel');
		}
		$(".btm_btn").find("a").removeClass("on");
	}
	orderChg = (mode, type, e) => {

		const { cookies } = this.props;
		var originPage = this.state.originPage;

		if(type === "p"){
		cookies.set('porder', mode, { path: '/' });
		this.setState({porder : mode});
		}
		if(type === "o"){
		cookies.set('oorder', mode, { path: '/' });
		this.setState({oorder : mode});
		}

		var url = this.state.apiurl+"/product/search_keyword.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("keyword", this.state.stxt);
		if(type === "p"){
			$(".btns_p").find("a").removeClass("on");
			if(mode == "recent_registeration") $(".btns_p").find("a").eq(0).addClass("on");
			if(mode == "most_purchased") $(".btns_p").find("a").eq(1).addClass("on");
			formData.append("target", "product");
			formData.append("sort", mode);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				this.setState({
					productCnt : res.data.data.products_count,
					plists : res.data.data.products
				});
				if(res.data.data.products_count + this.state.onlineCnt == 0){
					this.alertPop("검색 결과가 없습니다.");
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
		if(type === "o"){
			$(".btns_o").find("a").removeClass("on");
			if(mode == "recent_registeration") $(".btns_o").find("a").eq(0).addClass("on");
			if(mode == "most_purchased") $(".btns_o").find("a").eq(1).addClass("on");
			formData.append("target", "online");
			formData.append("sort", mode);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				this.setState({
					onlineCnt : res.data.data.onlines_count,
					olists : res.data.data.onlines
				});
				if(res.data.data.onlines_count + this.state.productCnt == 0){
					this.alertPop("검색 결과가 없습니다.");
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		//$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + posY) + "px");
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	goBack = (page, e) => {
		const { cookies } = this.props;
		var originPage = this.state.originPage;
		cookies.set('originPage', '', { path: '/' });
		cookies.set('porder', 'recent_registeration', { path: '/' });
		cookies.set('oorder', 'recent_registeration', { path: '/' });

		document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? page : originPage);
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/main/mainSearch/'+this.state.stxt, { path: '/' });
		
		document.location.href= page;
	}
	productFold(){
		if($(".main_cont_inner").eq(0).css("display") === "none"){
			$(".main_cont_inner").eq(0).show();
			$(".key_prod_fold:eq(0) > a").removeClass("on");
		}else{
			$(".main_cont_inner").eq(0).hide();
			$(".key_prod_fold:eq(0) > a").addClass("on");
		}
	}
	onlineMallFold(){
		if($(".main_cont_inner").eq(1).css("display") === "none"){
			$(".main_cont_inner").eq(1).show();
			$(".key_prod_fold:eq(1) > a").removeClass("on");
		}else{
			$(".main_cont_inner").eq(1).hide();
			$(".key_prod_fold:eq(1) > a").addClass("on");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPage('/main/mainSearchBlank');
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goBack.bind(this, '/main/mainSearchBlank')}>앞으로</Link></p>
			<h2>통합 키워드 검색</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop noResultPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			{/*<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goBack.bind(this, '/main/mainSearchBlank')}>앞으로</Link></p>
				<h2>통합 키워드 검색</h2>
			</header>*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="total_search_top">
				<div className="total_search_input">
					<input id="stxt" name="stxt" placeholder="검색어" type="text" onKeyPress={this.handleKeyPress.bind(this)}/>
					<Link className="btn_input_del" to="#" onClick={this.cancelClick.bind(this)}>삭제</Link>
				</div>
				<Link className="btn_cancel" to="#" onClick={this.cancelClick.bind(this)}>취소</Link>
			</div>
			<div className="prod_cate_search_result">
				<div className="prod_cate_search_top">
					<p className="num">“{this.state.stxt}” 검색  결과 총 <span>{this.state.productCnt + this.state.onlineCnt}</span>건</p>
				</div>
				<div className="prod_cate_search_top">
					<p className="num">상품 <span>{this.state.productCnt}</span>건</p>
					{
					this.state.porder === "recent_registeration" &&
					<div className="btns btns_p">
						<Link className="on" to="#" onClick={this.orderChg.bind(this, 'recent_registeration', 'p')}>최근 등록순</Link>
						<Link to="#" onClick={this.orderChg.bind(this, 'most_purchased', 'p')}>최다 구매순</Link>
					</div>
					}
					{
					this.state.porder === "most_purchased" &&
					<div className="btns btns_p">
						<Link to="#" onClick={this.orderChg.bind(this, 'recent_registeration', 'p')}>최근 등록순</Link>
						<Link className="on" to="#" onClick={this.orderChg.bind(this, 'most_purchased', 'p')}>최다 구매순</Link>
					</div>
					}
					<p className="key_prod_fold"><Link to="#" onClick={this.productFold.bind(this)}></Link></p>
				</div>
				<div className="main_cont_inner">
					<div className="serv_prod_list">
						<ul>
							{this.state.plists.map((plist, index) =>
							<li key={index}>
								<Link to="#" onClick={this.goPage.bind(this, "/main/productView/"+plist.ccidx)}>
									<div className="serv_prod_info">
										<figure><img alt="" src={plist.image} /></figure>
										<div className="txt">
											{plist.reward_method === "F" && <strong>{plist.reward}P</strong>}
											{plist.reward_method === "R" && <strong>{plist.reward}%</strong>}
											<p>{plist.name}</p>
										</div>
									</div>
									<div className="serv_prod_tags">
										<ul>
											{plist.limit_type === 0 && <li><span>무제한</span></li>}
											{plist.limit_type === "none" && <li><span>무제한</span></li>}
											{plist.limit_type === "day" && <li><span>하루 {plist.daily_limit - plist.remain_trial}/{plist.daily_limit}</span></li>}
											{plist.limit_type === "season" &&<li><span>기간 {plist.seasonal_limit - plist.remain_trial}/{plist.seasonal_limit}</span></li>}
										</ul>
										{
											plist.condition_count > 0 && 
											<MainProductCategorySub list={plist.condition_list}/>
										}
									</div>
								</Link>
							</li>
							)}
						</ul>
					</div>
				</div>
				<div className="prod_cate_search_top">
					<p className="num">온라인몰 <span>{this.state.onlineCnt}</span>건</p>
					{
					this.state.oorder === "recent_registeration" &&
					<div className="btns btns_o">
						<Link className="on" to="#" onClick={this.orderChg.bind(this, 'recent_registeration', 'o')}>최근 등록순</Link>
						<Link to="#" onClick={this.orderChg.bind(this, 'most_purchased', 'o')}>최다 구매순</Link>
					</div>
					}
					{
					this.state.oorder === "most_purchased" &&
					<div className="btns btns_o">
						<Link to="#" onClick={this.orderChg.bind(this, 'recent_registeration', 'o')}>최근 등록순</Link>
						<Link className="on" to="#" onClick={this.orderChg.bind(this, 'most_purchased', 'o')}>최다 구매순</Link>
					</div>
					}
					<p className="key_prod_fold"><Link to="#" onClick={this.onlineMallFold.bind(this)}></Link></p>
				</div>
				<div className="main_cont_inner">
					<div className="lp_prod_list">
						<ul>
							{this.state.olists.map((olist, index) =>
							<li key={index}>
								<Link to="#" onClick={this.goPage.bind(this, "/main/onlineMall/"+olist.omidx)}>
									<figure><img alt="" src={olist.opimage} /></figure>
									<div className="txt_wrap">
										<strong className="prod_name">{olist.opname}</strong>
										{
										olist.reward_method === "F" &&
										<span className="prod_info">전상품 +{olist.reward}P적립</span>
										}
										{
										olist.reward_method === "R" &&
										<span className="prod_info">전상품 +{olist.reward}%적립</span>
										}
										<div className="lp_cate_icon">
											<p className="yellow"><span>{(olist.ocname).substr(0, 5)}</span></p>
											{olist.guide_01 !== "" && <p><span>{(olist.guide_01).substr(0, 5)}</span></p>}
											{olist.guide_02 !== "" && <p><span>{(olist.guide_02).substr(0, 5)}</span></p>}
											{olist.guide_03 !== "" && <p><span>{(olist.guide_03).substr(0, 5)}</span></p>}
											{/*olist.guide_04 !== "" && <p>{(olist.guide_04).substr(0, 5)}</p>*/}
										</div>
									</div>
								</Link>
							</li>
							)}
						</ul>
					</div>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainSearch);