import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import ReceiptStoreSub from '../receipt/comp/receiptStoreSub';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import star_on from '../img/receipt/star_on.png';

class receiptStore extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};


	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),
			receiptStoreST: cookies.get('receiptStoreST') == undefined ? 0 : ~~cookies.get('receiptStoreST'),

			fcount: 0,
			flists: [],
			bcategorys: [],
			blists: [],
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/receipt/receiptStore");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/receipt/receiptStore"});}
		}catch(e){}
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/receipt/receiptStore");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/receipt/receiptStore"});}
		}catch(e){}
		fnc.event_log("reward_store", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		const { cookies } = this.props;
		if(this.state.cidx === 0){
			cookies.set('loginAfterPath', '/receipt/receiptStore', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		if(this.state.receiptStoreST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
		}

		$('html').scrollTop(0);
		$("body").addClass("gbg");

		$('html, body').css({
			'overflow':'visible'
		});

		var url = this.state.apiurl+"/user/favorite_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "brand");
		formData.append("block", 20);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("favorite_list");
			//console.log(res);
			this.setState({
				fcount : res.data.data.count,
				flists : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		url = this.state.apiurl+"/reward/brand_list.json";
		formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			//console.log("info");
			//console.log(res);
			//console.log(res.data.data.category_count);
			if(res.data.data.category_count > 0){
				this.setState({
					bcategorys : res.data.data.category_list
				});
			}
			if(res.data.data.brand_count > 0){
				this.setState({
					blists : res.data.data.brand_list
				});
			}
			if(this.state.receiptStoreST !== 0){
				$(window).scrollTop(this.state.receiptStoreST);
				this.setState({receiptStoreST : 0});
				cookies.set('receiptStoreST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		var _this = this;
		$(document).on("click", ".cancel", function(){

			if(global.variable.osType === 0){
				$(window).scrollTop(_this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");

			$(".fav_dimm").fadeOut();
			$(".prod_detail_pop").fadeOut();
		});
	}
	componentDidUpdate(){
		$(".fav_store_slide").find(".swiper-slide:gt(19)").remove();
	}
	favSet(idx, name, mode){
		/*
		var bidx = new Array();
		bidx[0] = idx;
		var jsonString = JSON.stringify(bidx);
		*/
		if(mode === "on"){
			mode = "del";
			$(".prod_detail_pop_cont").html("<p>“"+name+"”<br />즐겨찾는 매장에서 제외하시겠습니까?</p>");
		}else{
			mode = "set";
			$(".prod_detail_pop_cont").html("<p>“"+name+"”<br />즐겨찾는 매장에 추가하시겠습니까?</p>");
		}

		$(".fav_dimm").fadeIn();

		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".prod_detail_pop").css("top", Math.max(0, (($(window).height() - $(".prod_detail_pop").outerHeight()) / 2) + posY) + "px");
		$(".prod_detail_pop").fadeIn();

		$(".dgrey").data("mode", mode);
		$(".dgrey").data("idx", idx);

	}
	popCls(){
		var _this = this;

		if(global.variable.osType === 0){
			$(window).scrollTop(_this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		$(".layer_dimm").fadeOut();
		$(".prod_detail_pop").fadeOut();

		var mode = $(".dgrey").data("mode");
		var idx = $(".dgrey").data("idx");

		if(mode == "set"){
			$('.star[data-idx='+idx+']').addClass("on");
		}else{
			$('.star[data-idx='+idx+']').removeClass("on");
		}
		var arr = '[{"idx":'+idx+'}]';
		var url = this.state.apiurl+"/user/favorite_set.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "brand");
		formData.append("action", mode);
		formData.append("list", arr);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			url = this.state.apiurl+"/user/favorite_list.json";
			formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("type", "brand");
			formData.append("block", 20);
			formData.append("offset", 1);
			let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval2);
				_this.setState({
					fcount : res.data.data.count
				});
				_this.setState({
					flists : res.data.data.list
				});
				//console.log("111");

				url = _this.state.apiurl+"/reward/brand_list.json";
				formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", _this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", _this.state.cidx);
				formData.append("block", 99);
				formData.append("offset", 1);
				let spinnerInterval3 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, _this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval3);
					//console.log("info");
					//console.log(res);

					//console.log("c : " + res.data.data.category_count);
					//console.log(res.data.data.category_list);

					//console.log("b : " + res.data.data.brand_count);
					//console.log(res.data.data.brand_list);

					_this.setState({
						bcategorys : res.data.data.category_list,
						blists : res.data.data.brand_list
					});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goBack(){
		const { cookies } = this.props;
		var originPage = this.state.originPage;
		cookies.set('receiptStoreST', 0, { path: '/' });

		if(originPage != "" && originPage != null && originPage != undefined){
			cookies.set('originPage', '', { path: '/' });
			document.location.href= originPage;
		}else{
			document.location.href= "/main";
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pulldown"){
		}
		if(resObj.key == "pback"){
			this.goBack();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		const params = {
			slidesPerView: 'auto',
			spaceBetween: 10,
			grabCursor: true,
			rebuildOnUpdate: true
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 즐겨찾기 추가 팝업 -->*/}
			<div className="layer_dimm fav_dimm"></div>
			<div className="prod_detail_pop fixed" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>“이마트”<br />즐겨찾는 매장에 추가하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#">취소</Link>
					<Link className="dgrey" to="#" onClick={this.popCls.bind(this)}>확인</Link>
				</div>
			</div>
			{/*<!-- 즐겨찾기 추가 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goBack.bind(this)}>앞으로</Link></p>
				<h2>구매매장 선택</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="fav_store_slide_wrap">
				<h3 className="sub_cont_ttl">즐겨찾는 매장</h3>
				<div className="fav_store_slide">
					<Swiper {...params} className="swiper-wrapper">
						{this.state.flists.map((flist, idx) =>
						<div className="swiper-slide" key={idx}><Link to={"/receipt/receiptGuide/"+flist.idx}><img alt={flist.brand_name} src={flist.thumbnail}/></Link></div>
						)}
					</Swiper>
					{
					this.state.fcount === 0 &&
					<p className="no_fav_store">
						아직 즐겨찾는 매장이 설정되지 않았습니다.<br />
						해당 매장의 <img alt="" src={star_on} /> 아이콘을 터치해서 설정하세요.
					</p>
					}
				</div>
			</div>
			<div className="receipt_store_sel">
				<h3 className="sub_cont_ttl">영수증 발행 매장을 선택해주세요.</h3>
				{this.state.bcategorys.map((bcategory, index) =>
				<div key={index}>
					<h4 className="store_sep_ttl mart" key={bcategory.bcidx}><img src={bcategory.image} style={{width:'42px'}}/>{bcategory.name}</h4>
					<ReceiptStoreSub list={this.state.blists} bcidx={bcategory.bcidx} onClick={this.favSet.bind(this)}/>
				</div>
				)}
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(receiptStore);