import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import MainProductProductSub from './mainProductProductSub';

import * as fnc from '../../js/fnc.js';

import btn_fold_d from '../../img/main/btn_fold_d.png';
import btn_fold_u from '../../img/main/btn_fold_u.png';

class mainProductProduct extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),

			prodprods: [],
			pageNo: 1,
			moreProc: false,
			init: true
		};
	}
	componentDidMount (){
		this.init();
		window.addEventListener('scroll', this.showMore.bind(this), true);
	}
	init(){
		const url = this.state.apiurl+"/product/home_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 5);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.data.category_count > 0){
				this.setState({
					init : true,
					pageNo : 1,
					prodprods : res.data.data.category_list
				});
				$(".allShow").show();
				$(".allHide").hide();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	showMore(){
		const { cookies } = this.props;
		let mainTab = cookies.get('mainTab');
		if(mainTab !== "2") return;

		if(this.state.moreProc) return;

		let scrollHeight = Math.floor(document.documentElement.scrollHeight, document.body.scrollHeight);
		let scrollTop = Math.ceil(document.documentElement.scrollTop, document.body.scrollTop);
		let clientHeight = Math.ceil(document.documentElement.clientHeight);
		var pageNo = this.state.pageNo;
		if(scrollTop + clientHeight + 150 >= scrollHeight){
			pageNo = pageNo+1;
			this.setState({
				pageNo : pageNo,
				moreProc : true
			});

			const url = this.state.apiurl+"/product/home_list.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("block", 5);
			formData.append("offset", pageNo);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var prodprods  = this.state.prodprods ;
				for (var i in res.data.data.category_list) {
					//console.log(res.data.data.category_list[i]);
					prodprods.push(res.data.data.category_list[i]);
				}
				this.setState({
					prodprods  : prodprods
				});
				$('nav.common_nav div p:eq(2) a').trigger('click');
				this.setState({
					moreProc:false
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	componentDidUpdate(){
		var init = this.state.init;
		if(init === true){
			$(".prodprodsubcust").each(function(){
				$(this).find("li:gt(2)").hide();
			});
			$(".prodprodrecocust").find(".itemList:gt(1)").hide();

			//$(".serv_prod_list").each(function(){
			//	$(this).find(".itemList:gt(2)").hide();
			//});
			$(".serv_prod_list").find(".itemList:gt(3)").hide();

			$(".main_cont_inner").each(function(idx){
				if($(this).html() == ""){
					//console.log(idx);
//					$(this).remove();
				}
			});
			this.setState({
				init:false
			});

		}

	}
	prodOpen = (pcidx, i, cnt, ad_product_count, page, e) => {
		fnc.event_log("product_main", "product_main_click", "prod_category_more");
		e.preventDefault();
		const url = this.state.apiurl+"/product/list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("category", pcidx);
		formData.append("block", 4);
		formData.append("offset", page);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var prodprod = this.state.prodprods;
			var nowItemCnt = 0;
			for (var j in prodprod) {
				if( i == j ){
					for (var k in res.data.data.list) {
						prodprod[j].product_list.push(res.data.data.list[k]);
						nowItemCnt = prodprod[j].product_list.length;
					}
					
				}
			}
			this.setState({
				prodprods : prodprod
			});

			if(ad_product_count != nowItemCnt){
				this.prodOpen(pcidx, i, cnt, ad_product_count, page+1);
				return;
			}

			$(".itemList"+i).stop().show();
			$(".btn_prod_more"+i).find("a").first().hide();
			$(".btn_prod_more"+i).find("a").last().show();
			var _atvHeight = $('.main_cont_slide > .swiper-container > .swiper-wrapper > .swiper-slide-active').height();
			$('.main_cont_slide > .swiper-container > .swiper-wrapper').css({
				'height':_atvHeight
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	prodCls = (i, ad_product_count, e) => {
		e.preventDefault();

		var prodprod = this.state.prodprods;
		for (var j in prodprod) {
			if( i == j ){
				prodprod[j].product_list.splice(4, ad_product_count-4);
			}
		}
		this.setState({
			prodprods : prodprod
		});

		$(".itemList"+i+":gt(3)").hide();
		$(".itemAddList"+i).remove();
		$(".btn_prod_more"+i).find("a").first().show();
		$(".btn_prod_more"+i).find("a").last().hide();
		var _atvHeight = $('.main_cont_slide > .swiper-container > .swiper-wrapper > .swiper-slide-active').height();
		$('.main_cont_slide > .swiper-container > .swiper-wrapper').css({
			'height':_atvHeight
		});
	}
	render(){
		return (
							<section className="main_cont_wrap sect_prod">
								{this.state.prodprods.map((prodprod, i) =>
								<div className="main_cont_inner" key={i}>
									{
									prodprod.product_count != "undefined" && prodprod.product_count > 0 &&
									<h2 className="main_cont_ttl">{prodprod.name}</h2>
									}
									{
									prodprod.product_count != "undefined" && prodprod.product_count > 0 &&
									<div className="serv_prod_list">
										<MainProductProductSub list={prodprod.product_list} index={i}/>
										{/*
										prodprod.product_count > 4 &&
										*/}
										<p className={"btn_prod_more btn_prod_more"+i}>
											<Link className="allShow" to="#" onClick={this.prodOpen.bind(this, prodprod.pcidx, i, prodprod.product_count, prodprod.ad_product_count, 2)}>{prodprod.name} 전체 보기<img alt="" src={btn_fold_d} /></Link>
											<Link className="allHide" to="#" onClick={this.prodCls.bind(this, i, prodprod.ad_product_count)} style={{display:"none"}}>전체 보기 닫기<img alt="" src={btn_fold_u} /></Link>
										</p>
										{/*
										*/}
									</div>
									}
								</div>
								)}
								<button name="productProduct" id="productProduct" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
							</section>
		)
	}
}

export default withCookies(mainProductProduct);