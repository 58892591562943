import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowManual extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),

			mlists: []
		};
	}
	componentDidMount(){
		this.init();
		fnc.event_log("mycow_dir", "", "");
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowManual', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/guide/manual_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		this.setState({
			pageNo : 1
		});
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({mlists : res.data.data.list});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycow";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
			<h2>캐시카우 사용법</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="dona_list_wrap">
				<div className="noti_list_cont">
					<ul>
						{this.state.mlists.map((mlist, i) =>
						<li key={i}>
							<Link to={"/mycow/mycowManualView/"+mlist.umidx}>
								<p>{mlist.title}</p>
								<span>{mlist.register_date}</span>
							</Link>
						</li>
						)}
					</ul>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowManual);