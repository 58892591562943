import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import * as fnc from '../../js/fnc.js';

import btn_fold_d from '../../img/main/btn_fold_d.png';
import btn_fold_u from '../../img/main/btn_fold_u.png';
import iconTime from "../../img/main/icon_time.png";
import accum_done from "../../img/main/accum_done.png";

class mainProductRecommend extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			adult: cookies.get('adult'),

			prodrecos: [],
			prodrecoc: 0,
			init: true
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
		var init = this.state.init;
		if(init === true){
			if($(".prodprodrecocust").find(".itemListReco").length > 4){
				this.setState({init:false});
				$(".prodprodrecocust").find(".itemListReco:gt(1)").hide();
			}
		}
	}
	init(){
		const url = this.state.apiurl+"/init/reco_product.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("reco");
			//console.log(res.data.data);
			//console.log(res.data.data.list);
			if(res.data.data.count === 0){
				$(".main_cont_wrap_prod_reco").hide();
			}
			this.setState({
				init : true,
				prodrecos : res.data.data.list,
				prodrecoc : res.data.data.count
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	recoOpen(e){
		fnc.event_log("product_main", "product_main_click", "prod_recommend_more");
		e.preventDefault();
		var prodrecoc = this.state.prodrecoc;
		if(prodrecoc <= 2)	return;
		$(".itemListReco").show();
		$(".btn_prod_more").find("a").first().hide("fast");

		var _atvHeight = $('.main_cont_slide > .swiper-container > .swiper-wrapper > .swiper-slide-active').height();
		$('.main_cont_slide > .swiper-container > .swiper-wrapper').css({
			'height':_atvHeight
		});
	}
	recoCls(e){
		e.preventDefault();
		$(".itemListReco:gt(1)").hide();
		$(".btn_prod_more").find("a").first().show();

		var _atvHeight = $('.main_cont_slide > .swiper-container > .swiper-wrapper > .swiper-slide-active').height();
		$('.main_cont_slide > .swiper-container > .swiper-wrapper').css({
			'height':_atvHeight
		});
	}
	goProductView = (idx, e) =>{
		fnc.event_log("product_main", "product_main_click", "prod_recommend_store");
		const { cookies } = this.props;
		cookies.set('mainTab', '2', { path: '/' });
		cookies.set('originPage', '/main', { path: '/' });
		cookies.set('mainST', $(window).scrollTop(), { path: '/' });

		if( $(".btn_prod_more_reco").css("display") === "none"){
		cookies.set('mainST', $(window).scrollTop(), { path: '/' });
		}else{
		cookies.set('mainST', $(window).scrollTop(), { path: '/' });
		}

		document.location.href = "/main/productView/"+idx;
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	render(){
		return (
		<section className="main_cont_wrap main_cont_wrap_prod_reco">
			<h2 className="main_cont_ttl">추천 상품</h2>
			<div className="main_cont_inner">
				<div className="serv_prod_list">
					<ul className="prodprodrecocust">
						{this.state.prodrecos.map((prodreco, i) =>
						<li className="itemList itemListReco" key={i}>
							{
							prodreco.check_adult && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}

							{
							prodreco.limit_type === "day" && prodreco.remain_trial === 0 &&
							<div className="accum_done_cover">
								<img alt="" src={accum_done} />
								<div className="accum_done_dimm"></div>
							</div>
							}
							{
							prodreco.limit_type === "season" && prodreco.remain_trial === 0 &&
							<div className="accum_done_cover">
								<img alt="" src={accum_done} />
								<div className="accum_done_dimm"></div>
							</div>
							}

							<Link to="#" onClick={this.goProductView.bind(this, prodreco.ccidx)}>
								<div className="serv_prod_info">
									<figure><img alt="" src={prodreco.image} /></figure>
									<div className="txt">
										{prodreco.reward_method == "F" && <strong>{prodreco.reward}P</strong>}
										{prodreco.reward_method == "R" && <strong>{prodreco.reward}%</strong>}
										<p>{prodreco.name}</p>
									</div>
								</div>
								<div className="serv_prod_tags">
									<ul>
										{prodreco.limit_type === 0 && <li><span>무제한</span></li>}
										{prodreco.limit_type === "none" && <li><span>무제한</span></li>}
										{prodreco.limit_type === "day" && <li><span>하루 {prodreco.daily_limit - prodreco.remain_trial}/{prodreco.daily_limit}</span></li>}
										{prodreco.limit_type === "season" &&<li><span>기간 {prodreco.seasonal_limit - prodreco.remain_trial}/{prodreco.seasonal_limit}</span></li>}
										{prodreco.cond_keyword_1 != "" && <li><span>{(prodreco.cond_keyword_1).substr(0, 5)}</span></li>}
										{prodreco.cond_keyword_2 != "" && <li><span>{(prodreco.cond_keyword_2).substr(0, 5)}</span></li>}
									</ul>
									{
									!(prodreco.check_adult && this.state.adult !== "Y") && prodreco.near_end &&
									<div className="period_warn">
										<img alt="" src={iconTime} />
										적립 기한이 곧 종료됩니다.
										<div className="period_warn_bg"></div>
									</div>
									}
								</div>
							</Link>
						</li>
						)}
					</ul>
					<p className="btn_prod_more">
						<Link to="#" className="btn_prod_more_1" onClick={this.recoOpen.bind(this)}>추천 상품 전체 보기<img alt="" src={btn_fold_d} /></Link>
						<Link to="#" className="btn_prod_more_2" onClick={this.recoCls.bind(this)}>전체 보기 닫기<img alt="" src={btn_fold_u} /></Link>
					</p>
				</div>
			</div>
			<button name="productRecommend" id="productRecommend" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
		</section>
		)
	}
}

export default withCookies(mainProductRecommend);