import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import axios from 'axios';

import MainOnlineMallCategory2nd from './comp/mainOnlineMallCategory2nd';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mainOnlineMallCategory extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			onlineOrderIdx: cookies.get('onlineOrderIdx') == undefined ? "" : cookies.get('onlineOrderIdx'),

			ocategorys: [],
			oproducts: [],
			oproductCnt: 0
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
		const { cookies } = this.props;
		var onlineOrderIdx = this.state.onlineOrderIdx;
		if(onlineOrderIdx !== ""){
			$(".oc1dpt").eq(onlineOrderIdx).next().show();
			$(".oc1dpt").eq(onlineOrderIdx).addClass("on");
			this.setState({onlineOrderIdx : ""});
			cookies.set('onlineOrderIdx', "", { path: '/' });
		}
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/online/category_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("online_category");
			//console.log(res.data.data.list);
			this.setState({
				ocategorys : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	oc1dptToggle = (idx, e) => {
		//alert("oc1dptToggle : " + idx);
		$(".oc1dptall").find("ul").slideUp();
		$(".oc1dpt").removeClass("on");
		if($(".oc1dpt").eq(idx).next().css("display") === "none"){
			$(".oc1dpt").eq(idx).next().stop().slideDown();
			$(".oc1dpt").eq(idx).addClass("on");
		}
	}
	oCateChange(ocidx, idx){
		const { cookies } = this.props;
		cookies.set('onlineOrderIdx', idx, { path: '/' });
		document.location.href="/main/mainOnlineMallCategoryView/"+ocidx;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/main";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="leftTransition"
		transitionAppear={true}
		transitionEnter={false}
		transitionLeave={false}
		transitionAppearTimeout={500}
		transitionEnterTimeout={500}
		transitionLeaveTimeout={500}>

		<div className="white_dimm" style={{display:"none"}}></div>
		<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
		<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
		{/*<!-- 알림 설정 팝업 -->*/}
		<NetworkDisconnected/>
		{/*<!-- 알림 설정 팝업 end -->*/}

		{/*<!-- header 영역 -->*/}
			<header className="mem_header cate">
				<p className="btn_prev"><Link className="prod_cate_close" to="/main">앞으로</Link></p>
				<h2>온라인몰 카테고리</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			<div className="prod_cate_pop_wrap prod_cate_pop_wrap2">
				<div className="prod_cate_pop_bg"></div>
				<div className="prod_cate_pop_cont">
					{/*<!-- 내용 영역 -->*/}
					<div className="grey_gap"></div>
					<div className="prod_cate_list fold">
						<div className="prod_cate_list_ttl">
							<strong>온라인몰 카테고리별 보기</strong>
						</div>
						<ul className="oc1dptall">
							{this.state.ocategorys.map((ocategory, index) =>
							<li key={index}>
								<Link to="#" className="oc1dpt" onClick={this.oc1dptToggle.bind(this, index)}><img alt="" src={ocategory.image}/>{ocategory.ocgname}</Link>
								<MainOnlineMallCategory2nd list={ocategory.categorys} idx={index} onClick={this.oCateChange.bind(this)}/>
							</li>
							)}
						</ul>
					</div>
					{/*<!-- 내용 영역 end -->*/}
				</div>
			</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(mainOnlineMallCategory);