import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import * as fnc from '../js/fnc.js';

class gnb extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version')
		};
	}

	componentDidMount(){
		if((window.location.href).indexOf("mycow") > -1){
			$(".gnb04").addClass("on");
		}else{
			$(".gnb01").addClass("on");
		}
		$(window).scroll(function() { 
		$(".prod_detail_pop").css("top", Math.max(0, (($(window).height() - $(".prod_detail_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		});
	}

	goLoginPop = (mode, e) => {
		const { cookies } = this.props;

		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		if(this.state.cidx == 0){
			var posY = $(window).scrollTop();
			$("#posy").val(posY);
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				$('html, body').css("overflow", "hidden");
			}else{
				$('body').css("overflow", "hidden");
			}
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}else{
			if(mode == "1"){
				fnc.event_log("home_main", "home_main_click", "navi_reward");
				const url = this.state.apiurl+"/user/info.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					if(res.data.data.push === false){

						var posY = $(window).scrollTop();
							this.setState({
							posY : posY
						});
						if(global.variable.osType === 0){
							$("#wrap").css("top",-($(window).scrollTop()));
							$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
						}
						$('body').css("overflow", "hidden");

						$(".push_on_plz").css("top", Math.max(0, (($(window).height() - $(".push_on_plz").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".push_on_plz, .push_on_plz_dimm").fadeIn("fast");
					}else{
						cookies.set('originPage', document.location.pathname, { path: '/' });
						document.location.href="/receipt/receiptStore";
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}else if(mode == "2"){
				fnc.event_log("home_main", "home_main_click", "navi_point");
				cookies.set('originPage', document.location.pathname, { path: '/' });
				document.location.href="/point/point";
			}else if(mode == "3"){
				fnc.event_log("home_main", "home_main_click", "navi_mycow");
				cookies.set('originPage', document.location.pathname, { path: '/' });
				document.location.href="/mycow/mycow";
			}
		}
	}
	render(){
		return (
			<nav id="gnb">
				<ul>
					<li><Link className="gnb01" to="/main">홈</Link></li>
					<li><Link className="gnb02" to="#" onClick={this.goLoginPop.bind(this, "1")}>영수증등록</Link></li>
					<li><Link className="gnb03" to="#" onClick={this.goLoginPop.bind(this, "2")} data-page="point">포인트내역</Link></li>
					<li><Link className="gnb04" to="#" onClick={this.goLoginPop.bind(this, "3")}>마이카우</Link></li>
				</ul>
			</nav>
		)
	}
}

export default withCookies(gnb);