import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class joinMemInfo extends React.Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			uuid: cookies.get('uuid'),
			model: cookies.get('model'),

			token: "",
			focusChk : "",
			osversion : ""
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
			if(global.variable.osType == 1){window.android.getDeviceInfo("", "", "init", "", "", "");}
			if(global.variable.osType == 0){window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "init", param2 : "value", param3 : "value" , param4 : "value" } );}
		}catch(e){
			console.log("on pc");
		}
		$("#email_id").focus();
		if(global.variable.osType == 1){
			window.android.setWebviewFocus();
		}
		this.setState({focusChk : "email_id"});
	}
	emailSel(){
		$("#email_suffix").blur();
		$(".email_sel").fadeIn("fast");
		$(".email_sel_pop").fadeIn("fast");
	}
	emailSeled = (mail, e) => {
		if(mail === ""){
			$(".email_domain").focus();
		}
		$(".email_sel").fadeOut("fast");
		$(".email_sel_pop").fadeOut("fast");
		$("#email_suffix").val(mail);

		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();
		var pw = $("#pw").val();
		var repw = $("#repw").val();
		var warn = $(".email_warn").html();
		
		if(email_id != "" && email_suffix != "" && pw != "" && repw != "" && !$(".btn_login").hasClass("on") && warn == ""){
			$(".btn_login").addClass("on");
		}
		if(email_id == "" || email_suffix == "" || pw == "" || repw == "" || warn != ""){
			$(".btn_login").removeClass("on");
		}

		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();
		var email_full = email_id+'@'+email_suffix;
		var pw = $("#pw").val();
		var repw = $("#repw").val();

		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		if(email_full == "@"){
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_id != "" && email_suffix == "") {
			$(".email_warn").html("이메일을 선택하거나 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_id == "" && email_suffix != "") {
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_full.match(emailRegExp) == null) {
			$(".email_warn").html("잘못된 이메일 형식입니다.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else{
			$(".email_warn").html("");
			$(".memjoin_input_email").addClass("ok")
			$(".memjoin_input_email").removeClass("wrong")
		}
	}
	layerCls = (e) => {
		$(".email_sel").fadeOut("fast");
		$(".email_sel_pop").fadeOut("fast");
	}
	valChg1(){
		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();
		var email_full = email_id+'@'+email_suffix;
		var pw = $("#pw").val();
		var repw = $("#repw").val();
		var warn = $(".email_warn").html();
		
		if(email_id != "" && email_suffix != "" && pw != "" && repw != "" && !$(".btn_login").hasClass("on") && warn == ""){
			$(".btn_login").addClass("on");
		}
		if(email_id == "" || email_suffix == "" || pw == "" || repw == "" || warn != ""){
			$(".btn_login").removeClass("on");
		}

		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		if(email_full == "@"){
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_id != "" && email_suffix == "") {
			$(".email_warn").html("이메일을 선택하지 않으셨습니다.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_id == "" && email_suffix != "") {
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else if(email_full.match(emailRegExp) == null) {
			$(".email_warn").html("잘못된 이메일 형식입니다.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
		}else{
			$(".email_warn").html("");
			$(".memjoin_input_email").addClass("ok")
			$(".memjoin_input_email").removeClass("wrong")
		}
	}
	valChg2(){
		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();
		var pw = $("#pw").val();
		var repw = $("#repw").val();
		var warn = $(".email_warn").html();

//		if(email_id != "" && email_suffix != "" && pw != "" && repw != "" && !$(".btn_login").hasClass("on") ){
//			$(".btn_login").addClass("on");
//		}
//		if(email_id == "" || email_suffix == "" || pw == "" || repw == ""){
//			$(".btn_login").removeClass("on");
//		}

		var passChk = 0;
		var passRegExp = /^[A-Za-z0-9!@#$%^*-_=]{6,12}$/;
		if(email_id == ""){
			passChk++;
		}
		if(email_suffix == ""){
			passChk++;
		}
		if(pw.match(passRegExp) == null) {
			$(".pw_warn").eq(0).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
			$("#pw").removeClass("ok");
			$("#pw").addClass("wrong");
			passChk++;
		}else{
			$("#pw").addClass("ok");
			$("#pw").removeClass("wrong");
			$(".pw_warn").eq(0).html("");
		}
		if(repw != ""){
			if(pw != repw){
				$(".pw_warn").eq(1).html("비밀번호가 일치하지 않습니다.");
				$("#repw").removeClass("ok");
				$("#repw").addClass("wrong");
				passChk++;
			}else if(repw.match(passRegExp) == null) {
				$(".pw_warn").eq(1).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
				$("#repw").removeClass("ok");
				$("#repw").addClass("wrong");
				passChk++;
			}else{
				$("#repw").addClass("ok");
				$("#repw").removeClass("wrong");
				$(".pw_warn").eq(1).html("");
			}
		}else{
			passChk++;
		}
		if(warn != ""){
			passChk++;
		}

		if(passChk == 0){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	valChk3(){
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:"pw"});
		}, 10);
		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();

		if(email_id !== "" && email_suffix !== ""){
			var email_full = email_id+'@'+email_suffix;
			if(global.variable.osType == 1){
				this.emailChk($.trim(window.android.encryptBase64(email_full)));
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : email_full, param1 : "email_chk", param2 : "", param3 : "" , param4 : "" } )
				return;
			}
		}
	}
	valChk4(){
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:"repw"});
		}, 10);
		var pw = $("#pw").val();

		if(global.variable.osType == 1){
			this.pwChk($.trim(window.android.encryptBase64(pw)));
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.encryptBase64.postMessage({ str : pw, param1 : "pw_chk", param2 : "", param3 : "" , param4 : "" } )
			return;
		}
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	emailChk = (email, e) => {
		const url = this.state.apiurl+"/signup/signup_check.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "email");
		formData.append("value", email);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code1 = res.data.error_code;
			if(error_code1 == undefined){
			}else{
				$(".email_warn").html(res.data.error);
				return;
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	pwChk = (pw, e) => {
		const url = this.state.apiurl+"/signup/signup_check.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "password");
		formData.append("value", pw);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code1 = res.data.error_code;
			if(error_code1 == undefined){
			}else{
				$(".pw_warn").eq(0).html(res.data.error);
				return;
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	validChk(){
		if(!$(".btn_login").hasClass("on")){
			var focusChk = this.state.focusChk;
			if(focusChk !== ""){
				$("#"+focusChk).focus();
			}
			return;
		}

		var email_id = $("#email_id").val();
		var email_suffix = $("#email_suffix").val();
		var email_full = email_id+'@'+email_suffix;
		var pw = $("#pw").val();
		var repw = $("#repw").val();
		var pass = 0;

		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		if(email_full == "@"){
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
			pass++;
		}else if(email_id != "" && email_suffix == "") {
			$(".email_warn").html("이메일을 선택하지 않으셨습니다.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
			pass++;
		}else if(email_id == "" && email_suffix != "") {
			$(".email_warn").html("이메일을 입력해주세요.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
			pass++;
		}else if(email_full.match(emailRegExp) == null) {
			$(".email_warn").html("잘못된 이메일 형식입니다.");
			$(".memjoin_input_email").removeClass("ok")
			$(".memjoin_input_email").addClass("wrong")
			pass++;
		}else{
			$(".email_warn").html("");
			$(".memjoin_input_email").addClass("ok")
			$(".memjoin_input_email").removeClass("wrong")
		}
	
		var passRegExp = /^[A-Za-z0-9!@#$%^*-_=]{6,12}$/;
		if(pw.match(passRegExp) == null) {
			$(".pw_warn").eq(0).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
			$("#pw").removeClass("ok");
			$("#pw").addClass("wrong");
			pass++;
		}else{
			$("#pw").addClass("ok");
			$("#pw").removeClass("wrong");
			$(".pw_warn").eq(0).html("");
		}
		if(pw != repw){
			$(".pw_warn").eq(1).html("비밀번호가 일치하지 않습니다.");
			$("#repw").removeClass("ok");
			$("#repw").addClass("wrong");
			pass++;
		}else if(repw.match(passRegExp) == null) {
			$(".pw_warn").eq(1).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
			$("#repw").removeClass("ok");
			$("#repw").addClass("wrong");
			pass++;
		}else{
			$("#repw").addClass("ok");
			$("#repw").removeClass("wrong");
			$(".pw_warn").eq(1).html("");
		}

		if(global.variable.osType === 1){
			var userid = $.trim(window.android.encryptBase64(email_full));
			var userpw = $.trim(window.android.encryptBase64(pw));
			this.setState({
				userid : userid,
				userpw : userpw
			});
			this.procIdentity(pass);
		}
		if(global.variable.osType === 0){
			//alert("validChk : " + email_full);
			//alert("validChk : " + pass);
			window.webkit.messageHandlers.encryptBase64.postMessage({ str : email_full, param1 : "email", param2 : pass, param3 : "" , param4 : "" } )
			return;
		}
	}
	procIdentity(pass){
		fnc.event_log("signup_auth", "", "");
		const url = this.state.apiurl+"/signup/signup_check.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "email");
		formData.append("value", this.state.userid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code1 = res.data.error_code;
			if(error_code1 == undefined){
			}else{
				$(".email_warn").html(res.data.error);
				pass++;
			}
			formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", "0");
			formData.append("type", "password");
			formData.append("value", this.state.userpw);
			let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res2 => {
				if( ~~res2.data.result === -1){
					if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
					if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
				}
				clearInterval(spinnerInterval2);
				var error_code2 = res2.data.error_code;
				if(error_code2 == undefined){
				}else{
					$(".pw_warn").html(res2.data.error);
					pass++;
				}
				if(pass == 0){
					var email_id = $("#email_id").val();
					var email_suffix = $("#email_suffix").val();
					var email_full = email_id+'@'+email_suffix;

					if(global.variable.osType === 1){
						window.android.getIdentity("0", "url", "param1", "param2", "param3", "param4", "signup", email_full);
					}
					if(global.variable.osType === 0){
						window.webkit.messageHandlers.getIdentity.postMessage({ cidx : "0", url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : "signup", userid : email_full } );
					}
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;
		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/\n/gi, "<br/>");

		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version,
				osversion : resObj.os_version
			});
		}
		if(resObj.key == "encryptBase64"){
			if( resObj.param1 == "email_chk" ){
				this.emailChk(resObj.returnStr);
			}
			if( resObj.param1 == "pw_chk" ){
				this.pwChk(resObj.returnStr);
			}
			if( resObj.param1 == "email" ){
				this.setState({
					userid : resObj.returnStr
				});
				var pw = $("#pw").val();
				//alert("email_enc : " + resObj.returnStr);
				//alert("email_enc : " + resObj.param2);
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : pw, param1 : "pw", param2 : resObj.param2, param3 : "" , param4 : "" } )
			}
			if( resObj.param1 == "pw" ){
				var pass = ~~(resObj.param2);

				this.setState({
					userpw : resObj.returnStr
				});
				this.procIdentity(pass);
			}
		}
		if(resObj.key == "getIdentity"){
			var email_id = $("#email_id").val();
			var email_suffix = $("#email_suffix").val();
			var email_full = email_id+'@'+email_suffix;
			var pw = $("#pw").val();

			var error_code = resObj.result_message.error_code;
			if(error_code == undefined){
				var enc_userid = "";
				var enc_userpw = "";

				if(global.variable.osType == 1){
					enc_userid = $.trim(window.android.encryptBase64(email_full));
					enc_userpw = $.trim(window.android.encryptBase64(pw));
				}
				if(global.variable.osType == 0){
					enc_userid = this.state.userid;
					enc_userpw = this.state.userpw;
				}

				var url = this.state.apiurl+"/signup/signup.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("email", enc_userid);
				formData.append("password", enc_userpw);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					error_code = res.data.error_code;
					if(error_code == undefined){
						var cidx = res.data.data.cidx;
						url= this.state.apiurl+"/v7.0/report/token.json";
						formData = new FormData();
						formData.append("os", global.variable.osType);
						formData.append("appver", this.state.version);
						formData.append("applocale", "kr");
						formData.append("bypass_emergency", 0);
						formData.append("cidx", cidx);
						formData.append("token", this.state.token);
						axios.post(url, formData, this.state.config).then(res => {
							url = this.state.apiurl+"/user/info.json";
							formData = new FormData();
							formData.append("os", global.variable.osType);
							formData.append("appver", this.state.version);
							formData.append("applocale", "kr");
							formData.append("bypass_emergency", 0);
							formData.append("cidx", cidx);
							let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
							axios.post(url, formData, this.state.config).then(res => {
								if( ~~res.data.result === -1){
									if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
									if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
								}
								clearInterval(spinnerInterval2);
								cookies.set('cidx', cidx, { path: '/' });
								cookies.set('uemail', email_full, { path: '/' });
								cookies.set('adult', res.data.data.adult, { path: '/' });
								cookies.set('uuid', this.state.uuid, { path: '/' });
								cookies.set('model', this.state.model, { path: '/' });
								cookies.set('version', this.state.version, { path: '/' });
								cookies.set('autoNoLogin', this.state.autoNoLogin, { path: '/' });

								if(global.variable.osType == 1){
									window.android.login(cidx, email_full, global.variable.osType, pw);
								}
								if(global.variable.osType == 0){
									window.webkit.messageHandlers.login.postMessage({ cidx : cidx , userid : email_full, os : global.variable.osType, password : pw } );
								}

								document.location.href='/member/recommend';
							}).catch(res => {
								var msg = res.message;
								if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
							});
						});
					}else{
						this.alertPop(res.data.error);
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}else{
				var error = resObj.result_message.error;
				this.alertPop(error.replace(/\n/g, "<br/>"));
			}
		}
		if(resObj.key == "pback"){
			document.location.href="/member/join";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	alertPop = (msg, e) => {
		$(".alertPop").find("p").html(msg);
		$(".alertPop").show();
	}
	alertCls = (e) => {
		$(".alertPop").hide("fast");
		//document.location.href="/member/login";
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm alertPop" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop noResultPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>

			<div className="layer_dimm email_sel" style={{display:"none"}}></div>
			<div className="email_sel_pop" style={{display:"none"}}>
				<div className="email_sel_popttl">
					이메일을 선택해주세요.
					<Link className="close" to="#" onClick={this.layerCls.bind(this)}>닫기</Link>
				</div>
				<div className="email_sel_pop_cont">
					<div className="email_sel_list">
						<ul>
							<li className="naver">
								<input id="mail1" name="mail" type="radio" onChange={this.emailSeled.bind(this,"naver.com")}/>
								<label htmlFor="mail1">naver.com</label>
							</li>
							<li className="hanmail">
								<input id="mail2" name="mail" type="radio" onChange={this.emailSeled.bind(this,"hanmail.net")}/>
								<label htmlFor="mail2">hanmail.net</label>
							</li>
							<li className="nate">
								<input id="mail3" name="mail" type="radio" onChange={this.emailSeled.bind(this,"nate.com")}/>
								<label htmlFor="mail3">nate.com</label>
							</li>
							<li className="google">
								<input id="mail4" name="mail" type="radio" onChange={this.emailSeled.bind(this,"gmail.com")}/>
								<label htmlFor="mail4">gmail.com</label>
							</li>
							<li className="hanmail">
								<input id="mail5" name="mail" type="radio" onChange={this.emailSeled.bind(this,"daum.net")}/>
								<label htmlFor="mail5">daum.net</label>
							</li>
							<li className="private">
								<input id="mail6" name="mail" type="radio" onChange={this.emailSeled.bind(this,"")}/>
								<label htmlFor="mail6">직접 입력</label>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<header className="mem_header">
				<p className="btn_prev"><Link to="/member/join">앞으로</Link></p>
				<h2>회원가입</h2>
			</header>
			<div id="content">
				<div className="memjoin_id_input">
					<h3 className="memjoin_input_ttl">정확히 입력해주세요.</h3>
					<p className="memjoin_input_subttl">아이디(이메일)는 변경이 불가합니다.</p>
					<fieldset>
						<div className="memjoin_input_email {/*ok*/}">
							<input className="email_id" id="email_id" name="email_id" placeholder="아이디" type="text" onChange={this.valChg1.bind(this)} onFocus={this.onFocusHandle.bind(this,'email_id')} onBlur={this.onBlurHandle.bind(this,'email_id')}/>
							<p className="email_at">@</p>
							<input className="email_domain" id="email_suffix" name="email_suffix" placeholder="선택하기" type="text" onClick={this.emailSel.bind(this)} onChange={this.valChg1.bind(this)} onFocus={this.onFocusHandle.bind(this,'email_suffix')} onBlur={this.onBlurHandle.bind(this,'email_suffix')}/>
						</div>
						<p className="pw_wran"></p>
						{/*
						<p className="pw_wran">이미 가입된 이메일 주소입니다.</p>
						<p className="pw_wran">이메일을 선택하지 않으셨습니다.</p>
						*/}
						<p className="pw_wran email_warn"></p>
						<input className="memjoin_input_pw" id="pw" name="pw" placeholder="비밀번호(6~12자 영문 대/소문자,숫자,특수문자)" type="password" onChange={this.valChg2.bind(this)} onFocus={this.valChk3.bind(this)} onBlur={this.onBlurHandle.bind(this,'pw')}/>
						<p className="pw_wran pw_warn"></p>
						{/*
						<p className="pw_wran">비밀번호는 6자 이상을 입력해주세요.</p>
						*/}
						<input className="memjoin_input_pw" id="repw" name="repw" placeholder="비밀번호 확인" type="password" onChange={this.valChg2.bind(this)} onFocus={this.valChk4.bind(this)} onBlur={this.onBlurHandle.bind(this,'repw')}/>
						<p className="pw_wran pw_warn"></p>
						{/*<p className="pw_wran">비밀번호가 일치하지 않습니다.</p>
						*/}
					</fieldset>
				</div>
			</div>
			<p className="btn_login"><Link to="#" onClick={this.validChk.bind(this)}>다음</Link></p>
			<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(joinMemInfo);