import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_use_point_w from '../img/mycow/btn_use_point_w.png';

class mycowInquiryView extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			idx: props.match.params.id,

			iinfo: [],
			iimgs: [],
			qTypeIdx: ""
		};
	}
	componentDidMount(){
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInquiryView', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/guide/inquiry_detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("idx", this.state.idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				iinfo : res.data.data,
				iimgs : res.data.data.images
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	typeSel(e){
		e.preventDefault();
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".inq_type_pop").css("top", Math.max(0, (($(window).height() - $(".inq_type_pop").outerHeight()) / 2) + posY) + "px");
		$(".layer_dimm, .inq_type_pop").fadeIn("fast");
	}
	submit(idx, e){
		var uicidx = $("#qTypeIdx").val();
		var qTitle = $("#qTitle").val();
		var contents = $("#contents").val();
		const url = this.state.apiurl+"/guide/inquiry_set.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("action", "cancel");
		formData.append("uiidx", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			document.location.href="/mycow/mycowInquiry";
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	popOpen = (cName, e) => {
		if(cName === "inquiry_cancel"){
			var posY = $(window).scrollTop();
				this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
			$(".common_pop").css({"margin-top":"100px","opacity" : "0","display" : "block"});
			$(".common_pop").animate({"margin-top":"0px","opacity" : "1"}, {
				"duration" : 400
			});
			$(".layer_dimm").fadeIn();
		}
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		if(cName === "inquiry_cancel"){
			$(".common_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".common_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
	}
	imgShow = (imgsrc, e) => {
		$(".bigImg").attr("src", imgsrc);
		$(".inq_img_zoom_wrap_dimm").fadeIn();
		$(".inq_img_zoom_wrap").fadeIn();
	}
	imgCls = (e) => {
		$(".inq_img_zoom_wrap_dimm").fadeOut();
		$(".inq_img_zoom_wrap").fadeOut();
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycowInquiry";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_close"><Link to="/mycow/mycowInquiry">닫기</Link></p>
			<h2>나의 문의 보기</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- 확대 이미지 -->*/}
			<div className="layer_dimm inq_img_zoom_wrap_dimm" style={{display:"none"}}></div>
			<div className="inq_img_zoom_wrap">
				<button className="close" onClick={this.imgCls.bind(this)}></button>
				<img className="bigImg" alt="" src=""/>
			</div>
			{/*<!-- 확대 이미지 end -->*/}

			{/*<!-- 로그인 필요 팝업 -->*/}
			<div className="layer_dimm inquiry_cancel_dimm" style={{display:"none"}}></div>
			<div className="common_pop inquiry_cancel" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>문의를 취소하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'inquiry_cancel')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.submit.bind(this, this.state.iinfo.uiidx)}>네</Link>
				</div>
			</div>
			{/*<!-- 로그인 필요 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			{/*<!-- 문의 내용 -->*/}
			<div className="my_inquiry_wrap">
				<div className="my_inquiry_ttl">
					<strong>{this.state.iinfo.title}
						{this.state.iinfo.status === 0 && <span className="stat wait">답변대기</span>}
						{this.state.iinfo.status === 1 && <span className="stat done">답변완료</span>}
						{this.state.iinfo.status === 2 && <span className="stat wait">회원취소</span>}
					</strong>
					<p><span>{this.state.iinfo.uicname}</span><span>등록일<i>{ this.state.iinfo.insert_dt }</i></span></p>
				</div>
				<div className="my_inquiry_cont">
					<div className="my_inquiry_ques">
						<p>{this.state.iinfo.inquiry}</p>
						<figure>
							{
							this.state.iimgs.map((img, i) =>
							<img alt="" src={img.url} key={i} onClick={this.imgShow.bind(this, img.url)}/>
							)}
						</figure>
					</div>
					{this.state.iinfo.status === 1 && 
					<div className="my_inquiry_answ">
						<p>{this.state.iinfo.answer}</p>
						<span className="answ_date">답변일 {this.state.iinfo.answer_date}</span>
					</div>
					}
				</div>
			</div>
			{/*<!-- 문의 내용 end -->*/}
			<div className="grey_gap"></div>
			{this.state.iinfo.status === 0 && 
			<div className="faq_btm_btn">
				{/*<Link className="cancel" to="#" onClick={this.popOpen.bind(this, 'inquiry_cancel')}>문의 취소<img alt="" src={btn_use_point_w}/></Link>*/}
				<Link className="cancel" to="#" onClick={this.popOpen.bind(this, 'inquiry_cancel')}><i>문의 취소</i></Link>
			</div>
			}
			{this.state.iinfo.status === 1 && 
			<div className="faq_btm_btn">
				{/*<Link to="/mycow/mycowInquiryWrite">1:1 문의하기<img alt="" src={btn_use_point_w}/></Link>*/}
				<Link to="/mycow/mycowInquiryWrite"><i>1:1 문의하기</i></Link>
			</div>
			}
			{this.state.iinfo.status === 2 && 
			<div className="faq_btm_btn">
				{/*<Link to="/mycow/mycowInquiryWrite">1:1 문의하기<img alt="" src={btn_use_point_w}/></Link>*/}
				<Link to="/mycow/mycowInquiryWrite"><i>1:1 문의하기</i></Link>
			</div>
			}
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInquiryView);