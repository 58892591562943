import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

class bridge extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const param_type = props.match.params.type;
		const param_id = props.match.params.id;

		const { cookies } = props;
		this.state = {
			param_type: param_type,
			param_id: param_id
		};
	}

	componentDidMount(){

		const { cookies } = this.props;

		var visitedAt = (new Date()).getTime(); // 방문 시간
		if (global.variable.osType == 1) {
//			setTimeout(function() {
//				if ((new Date()).getTime() - visitedAt < 2000) {
//					alert("안드로이드 설치 페이지로 이동");
//				}
//			}, 1000);
//			var iframe = document.createElement('iframe');
//			iframe.style.visibility = 'hidden';
//			if(this.state.param_type === "productView"){
//				//iframe.src = "intent://redirecturl?url=https:%2F%2Fcashcow-test.mgine.co.kr%2Fgate%2FproductView%2F"+ this.state.param_id +"#Intent;scheme=cashcowdev;S.browser_fallback_url=https:%2F%2Fcash-cow-test.mgine.co.kr";
//				//iframe.src = "cashcowdev://redirecturl?url=https:%2F%2Fcashcow-test.mgine.co.kr%2Fgate%2FproductView%2F"+ this.state.param_id;
//				iframe.src = "intent://campaign_product?ccidx="+ this.state.param_id +"&type=&name=#Intent;scheme=cashcowdev;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.minhouse.cashcowdev7;end";
//			}
//			document.body.appendChild(iframe);
		}
		if(global.variable.osType == 0) {
			setTimeout(function() {
				if ((new Date()).getTime() - visitedAt < 2000) {
					document.location.href=global.variable.appStoreUrl;
				}
			}, 500);
			//setTimeout(function() { 
			//	document.location.href = 
			//}, 0);
			var iframe = document.createElement('iframe');
			iframe.style.visibility = 'hidden';
			//if(this.state.param_type === "productView"){
				//iframe.src = "cashcowdev://cashcowdev/?ccidx="+ this.state.param_id +"&type="+ this.state.param_type +"&name=";
				iframe.src = "cashcowdev://campaign_product/?ccidx="+ this.state.param_id +"&type="+ this.state.param_type +"&name=";
			//}
			document.body.appendChild(iframe);
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;
		var osType = global.variable.osType;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version
			});

			//let d = new Date();
			//d.setTime(d.getTime() + (60*60*1000));
			//cookies.set('uuid', resObj.uuid, { path: '/', expires: d });
			//cookies.set('model', resObj.model, { path: '/', expires: d });
			//cookies.set('version', resObj.version, { path: '/', expires: d });
			cookies.set('uuid', resObj.uuid, { path: '/' });
			cookies.set('model', resObj.model, { path: '/' });
			cookies.set('version', resObj.version, { path: '/' });
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

		{
		global.variable.osType === 1 &&
		<a href={"intent://campaign_product?ccidx="+ this.state.param_id +"&type="+ this.state.param_type +"&name=#Intent;scheme=cashcowdev;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.minhouse.cashcowdev7;end"}>test</a>
		}

		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(bridge);