import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_home from "../img/mycow/btn_home.png";

class site extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			originPage: cookies.get('originPage'),
			adid: props.match.params.id,
			adlogid: props.match.params.lid,

			site: [],
			first: true
		};
	}
	componentDidMount(){
		fnc.event_log("push_mul_site", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$(".mem_header, .adv_accum_bnr, .grey_gap, .adv_accum_prod, .btm_btn").hide();

		const url = this.state.apiurl+"/ad/visit_detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(error_code === undefined){
				this.setState({
					wh : $(window).height()
				});
				$(".mem_header, .adv_accum_bnr, .grey_gap, .adv_accum_prod, .btm_btn").show();
				this.setState({
					qCnt : res.data.data.data.question_count,
					site : res.data.data.data,
				});
			}else{
				this.setState({
					wh : $(window).height(),
					mode : "no"
				});
				this.alertPop( res.data.error.replace(/\n/gi, '<br/>') );
				$(".layer_dimm").fadeIn();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

//		$(window).scroll(function() { 
//		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		});
	}
	popCls = (cName, e) => {
		if(cName === "complete"){
			if(global.variable.osType === 0){
				$(window).scrollTop(this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");
			$(".saving, .interrupt").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".saving, .interrupt").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}else{
			const { cookies } = this.props;
			var originPage = this.state.originPage;
			cookies.set('originPage', '', { path: '/' });
			document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? "/main" : originPage);
		}
	}
	goSite(){
		if(global.variable.osType === 1){
			window.android.openUrl(this.state.cidx, this.state.site.url, "", "", "", "");
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.openUrl.postMessage({ cidx : this.state.cidx, url : this.state.site.url, param1 : "", param2 : "", param3 : "", param4 : "" });
			return;
		}
		setTimeout(function(){
			($(".btm_btn").find("a")).addClass("on");
		}, 1000);
	}
	goCompleteShow(){
		if( !($(".btm_btn").find("a")).hasClass("on") ) return;

		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".saving").css({
			"top" : Math.max(0, (($(window).height() - $(".saving").outerHeight()) / 2) + $(window).scrollTop()) + "px",
			"margin-top" : "100px",
			"opacity" : "0",
			"display" : "block"
		});
		$(".saving").animate({"margin-top":"0px","opacity" : "1"}, 300);
		$(".layer_dimm").fadeIn();
	}
	goComplete(){
		const { cookies } = this.props;
		const url = this.state.apiurl+"/ad/visit_report.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			$(".saving").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".saving").hide();
				}
			});
			$(".certi_ok_pop").css({
				//"top" : Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".certi_ok_pop").animate({"margin-top":"0px","opacity" : "1"}, {
				"duration" : 300,
				"complete" : function(){
					cookies.set('pointSubTab', '2', { path: '/' });
					setTimeout(function(){
						document.location.href="/point/point";
					}, 2000);
				}
			});
			$(".layer_dimm").fadeIn();
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goBack = (e) => {
		e.preventDefault();

		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".interrupt").css({
			"top" : Math.max(0, (($(window).height() - $(".interrupt").outerHeight()) / 2) + $(window).scrollTop()) + "px",
			"margin-top" : "100px",
			"opacity" : "0",
			"display" : "block"
		});
		$(".interrupt").animate({"margin-top":"0px","opacity" : "1"}, 300);
		$(".layer_dimm").fadeIn();
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop").fadeOut("fast");
		$(".layer_dimm").fadeOut("fast");

		var mode = this.state.mode;
		if(mode === "no"){
			this.popCls('interrupt');
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "pback"){
			this.goBack();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm"></div>
			<div className="common_pop saving">
				<div className="common_pop_txt">
					<p>적립하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goComplete.bind(this)}>네</Link>
				</div>
			</div>
			<div className="common_pop interrupt">
				<div className="common_pop_txt">
					<p>광고 적립을 종료합니다.<br/>종료 후 다시 진행하실 수 없습니다.</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.popCls.bind(this, 'interrupt')}>확인</Link>
				</div>
			</div>

			<div className="certi_ok_pop" style={{display:"none"}}>{this.state.site.plus_point}P<br/>적립 완료</div>

			{/*<!-- alert 팝업 -->*/}
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_close"><Link to="#" onClick={this.goBack.bind(this)}>닫기</Link></p>
				<h2>광고적립</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="adv_accum_bnr" onClick={this.goSite.bind(this)}><img alt={this.state.site.name} src={"https://devimage.cash-cow.co.kr"+this.state.site.image}/></div>
			<div className="grey_gap"></div>
			<div className="adv_accum_prod">
				<strong>{this.state.site.name}</strong>
				<div className="adv_accum_prod_cont">
					<p className="adv_accum_prod_txt">{this.state.site.desc}</p>
					<Link className="adv_accum_prod_link" to="#" onClick={this.goSite.bind(this)}>
						<img alt="" src={btn_home}/>
						{this.state.site.url}
					</Link>
					<p className="adv_charge_guide">광고 사이트 이동 시 3G/4G/LTE일 경우 통화료가 발생할 수 있습니다.</p>
				</div>
			</div>
			<p className="btm_btn"><Link to="#" onClick={this.goCompleteShow.bind(this)}>{this.state.site.plus_point}P 적립하기</Link></p>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(site);