import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_use_point_w from '../img/mycow/btn_use_point_w.png';

class mycowInFaqSearch extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			mycowInFaqSearchST: cookies.get('mycowInFaqSearchST') == undefined ? 0 : ~~cookies.get('mycowInFaqSearchST'),
			stxt: props.match.params.word,

			fcount: 0,
			flists: []

		};
	}
	componentDidMount(){
		const { cookies } = this.props;
		if(this.state.mycowInFaqSearchST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowInFaqSearchST);
				_this.setState({mycowInFaqSearchST : 0});
				cookies.set('mycowInFaqSearchST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			});
		}
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInFaqSearch', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");
		$(".btn_login").css("bottom", "-100px");

		if( this.state.stxt == undefined || this.state.stxt == ""){
			document.getElementById("stxt").focus();
		}

		const url = this.state.apiurl+"/guide/faq_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		formData.append("keyword", this.state.stxt);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				fcount: res.data.data.count,
				flists: res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		$("#stxt").val(this.state.stxt);

		if(global.variable.osType == 0){
			if ($('#stxt').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}
	}
	btnShow(){
		$(".btn_login").stop().animate({"bottom":"0"}, 300);
		$(".search_result_count").hide();
		$(".faq_reward_wrap").hide();
		$(".faq_btm_btn").hide();
		$(".grey_gap").hide();
		$(".no_search_result").hide();

		var stxt = $("#stxt").val();
		if(stxt.length >= 2){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	btnHide(){
		$(".btn_login").stop().animate({"bottom":"-100px"}, 300);
		$(".search_result_count").show();
		$(".faq_reward_wrap").show();
		$(".faq_btm_btn").show();
		$(".grey_gap").show();
		$(".no_search_result").show();
	}
	btnChk(){
		var stxt = $("#stxt").val();
		if(stxt.length >= 2){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	handleKeyPress = (e) => {
		if(e.key === "Enter"){
			var stxt = $("#stxt").val();
			if(stxt.length >= 2){
				document.location.href="/mycow/mycowInFaqSearch/"+stxt;
			}
		}
	}
	cancelClick = (e) => {
		$("#stxt").val('');
		$("#stxt").blur();
		this.btnShow();
	}
	componentDidUpdate(){
	}
	faqFoldToggle = (idx, e) =>{
		if($(".fTitle").eq(idx).hasClass("fold")){
			$(".fTitle").eq(idx).removeClass("fold");
			$(".answer").eq(idx).hide();
		}else{
			$(".fTitle").removeClass("fold");
			$(".answer").hide();
			$(".fTitle").eq(idx).addClass("fold");
			$(".answer").eq(idx).show();
		}
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowInFaqSearchST', 0, { path: '/' });

		document.location.href="/mycow/mycowInFaq";
	}
	goSearch(){
		this.btnShow();
		$(".btn_login").stop().css({"bottom":"0"});
		if(!$(".btn_login").hasClass("on")){
			$("#stxt").focus();
			return;
		}
		var stxt = $("#stxt").val();
		document.location.href="/mycow/mycowInFaqSearch/"+stxt;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>

				<h2>문의하기&FAQ</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="faq_search_wrap">
				<div className="faq_search_inner searching">
					<input id="stxt" name="stxt" placeholder="검색어" type="text" onFocus={this.btnShow.bind(this)} onBlur={this.btnHide.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.btnChk.bind(this)}/>
					<Link className="btn_search_del" to="#" onClick={this.cancelClick.bind(this)}>삭제</Link>
				</div>
				<Link className="btn_search_cancel" to="#" onClick={this.cancelClick.bind(this)}>취소</Link>
			</div>
			<div className="grey_gap"></div>
			{/*<!-- 검색 결과 건수 -->*/}
			<p className="search_result_count">“{this.state.stxt}” 검색  결과 총 <strong>{this.state.fcount}</strong>건</p>
			{/*<!-- 검색 결과 건수 end -->*/}
			<div className="grey_gap"></div>
			{/*<!-- 검색 결과 건수 -->*/}
			<p className="search_result_count">FAQ <strong>{this.state.fcount}</strong>건</p>
			{/*<!-- 검색 결과 건수 end -->*/}
			<div className="grey_gap"></div>
			{/*<!-- 리워드 -->*/}
			<div className="faq_reward_wrap">
				<ul>
					{
					this.state.flists.map((flist, i) =>
					<li>
						<Link to="#" className="fTitle" onClick={this.faqFoldToggle.bind(this, i)}><span dangerouslySetInnerHTML={{__html : (flist.title).replace(this.state.stxt, "<strong style='font-weight:800'>"+this.state.stxt+"</strong>")}}></span></Link>
						<p className="answer">{flist.contents}</p>
					</li>
					)}
				</ul>
			</div>
			{/*<!-- 리워드 end -->*/}
			{/*<!-- 검색결과 없는 경우 -->*/}
			{
			this.state.fcount === 0 &&
			<p className="no_search_result">검색 결과가 없습니다.</p>
			}
			{/*<!-- 검색결과 없는 경우 end -->*/}
			<div className="grey_gap"></div>
			<div className="faq_btm_btn">
				{/*<Link to="/mycow/mycowInquiry">문의 내역/문의하기<img alt="" src={btn_use_point_w}/></Link>*/}
				<Link to="/mycow/mycowInquiry"><i>문의 내역/문의하기</i></Link>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			<p className="btn_login"><Link to="#" className="goLogin" onClick={this.goSearch.bind(this)}>확인</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInFaqSearch);