import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import buddy_invi_top from '../img/mycow/buddy_invi_top.jpg'
import icon_sns01 from '../img/mycow/icon_sns01.png'
import icon_sns02 from '../img/mycow/icon_sns02.png'
import icon_sns03 from '../img/mycow/icon_sns03.png'
import icon_sns04 from '../img/mycow/icon_sns04.png'
import icon_sns05 from '../img/mycow/icon_sns05.png'
import btn_use_point from '../img/mycow/btn_use_point.png'

class mycowMyInfo extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			uuid: cookies.get('uuid'),
			model: cookies.get('model'),
			adult: cookies.get('adult'),
			autoNoLogin: cookies.get('autoNoLogin'),

			uinfo: [],
			ccnt: 0
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_account", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowMyInfo', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");
		$('body').removeClass('extend');
		$('html, body').css({
			'overflow':'hidden'
		});

		const url = this.state.apiurl+"/user/info.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				uinfo : res.data.data
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		//$(window).scroll(function() {
		//	$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		//	$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		//});

	}
	componentDidUpdate(){
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	hpChange(e){
		e.preventDefault();

		if(global.variable.osType == 1){
			window.android.getIdentity(this.state.cidx, "url", "param1", "param2", "param3", "param4", "changehp", this.state.uemail);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.getIdentity.postMessage({ cidx : this.state.cidx, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : "changehp", userid : this.state.uemail } );
			return;
		}

	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;

			if(error == undefined){
				var _this = this;
				var posY = $(window).scrollTop();
					this.setState({
					posY : posY
				});
				if(global.variable.osType === 0){
					$("#wrap").css("top",-($(window).scrollTop()));
					$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				}
				$('body').css("overflow", "hidden");
				$(".certi_ok_pop1").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".certi_ok_pop1").html("본인 인증 완료");
				$(".certi_ok_pop1").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".alertPopDimm").fadeIn(400);

				setTimeout(function(){
					$(".certi_ok_pop1").animate({"margin-top":"0","opacity" : "1"}, {
						"duration" : 400,
						"complete" : function(){
							$(".certi_ok_pop1").fadeOut();

							$(".certi_ok_pop2").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
							$(".certi_ok_pop2").html("휴대폰 번호<br/>변경 완료");
							$(".certi_ok_pop2").animate({"margin-top":"0","opacity" : "1"}, 400);

							setTimeout(function(){
								if(global.variable.osType === 0){
									$(window).scrollTop(_this.state.posY);
									$("#wrap").css("top", 0);
									$("html, body").off('scroll touchmove');
								}
								$('html, body').css("overflow", "visible");
								$(".certi_ok_pop2").fadeOut();
								$(".alertPopDimm").fadeOut(400);
							}, 2000);
						}
					});
				}, 2000);

				const url = this.state.apiurl+"/user/info.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					this.setState({uinfo : res.data.data});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});

				cookies.set('cidx', this.state.cidx, { path: '/' });
				cookies.set('uemail', this.state.uemail, { path: '/' });
				cookies.set('uuid', this.state.uuid, { path: '/' });
				cookies.set('model', this.state.model, { path: '/' });
				cookies.set('version', this.state.version, { path: '/' });
				cookies.set('adult', this.state.adult, { path: '/' });
				cookies.set('autoNoLogin', this.state.autoNoLogin, { path: '/' });
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					var error_msg = resObj.result_message.error;
					if( error_msg.replace(/\$\$/g, '<br/>') != "cancel"){
						this.alertPop(error_msg.replace(/\$\$/g, '<br/>') );
					}
					cookies.set('cidx', this.state.cidx, { path: '/' });
					cookies.set('uemail', this.state.uemail, { path: '/' });
					cookies.set('uuid', this.state.uuid, { path: '/' });
					cookies.set('model', this.state.model, { path: '/' });
					cookies.set('version', this.state.version, { path: '/' });
					cookies.set('adult', this.state.adult, { path: '/' });
					cookies.set('autoNoLogin', this.state.autoNoLogin, { path: '/' });
				}
			}
		}
		if(resObj.key == "pback"){
			document.location.href="/mycow/mycow";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	logout = (e) => {
		var ccnt = this.state.ccnt;
		ccnt++
		this.setState({
			ccnt : ccnt
		});
		if(ccnt>=10){
			if(window.confirm("로그아웃하시겠습니까?")){
				if(global.variable.osType == 1){
					window.android.logout(global.variable.osType);
				}
				if(global.variable.osType == 0){
					window.webkit.messageHandlers.logout.postMessage({ os : global.variable.osType });
				}
				const url = this.state.apiurl+"/login/sign_out.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					const { cookies } = this.props;
					cookies.set('cidx', '', { path: '/' });
					cookies.set('uemail', '', { path: '/' });
					cookies.set('adult', '', { path: '/' });
					document.location.href='/main';
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
			<h2>계정관리</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			{/*<!-- 인증완료 팝업 -->*/}
			<div className="certi_ok_pop certi_ok_pop1" style={{display:"none"}}>본인 인증 완료</div>
			<div className="certi_ok_pop certi_ok_pop2" style={{display:"none"}}>본인 인증 완료</div>
			{/*<!-- 인증완료 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="idpw_nomatch_pop alertPop inquiry" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="my_mycow_account">
				<p>
					계정<br />
					<strong>{this.state.uemail}</strong>
				</p>
			</div>
			<div className="grey_gap"></div>
			<div className="manage_acc_list">
				<dl>
					<dt>비밀번호</dt>
					<dd>
						<span>*******</span>
						<Link className="modify_acc" to="/mycow/mycowMyPwChk"><i>변경</i></Link>
					</dd>
				</dl>
				<dl>
					<dt>생년월일</dt>
					<dd>{this.state.uinfo.birthday}</dd>
				</dl>
				<dl>
					<dt>성별</dt>
					{this.state.uinfo.sex === "M" && <dd>남자</dd>}
					{this.state.uinfo.sex === "F" && <dd>여자</dd>}
				</dl>
				<dl>
					<dt>휴대전화</dt>
					<dd>
						<span>{this.state.uinfo.phone}</span>
						<Link className="modify_acc" to="#" onClick={this.hpChange.bind(this)}><i>변경</i></Link>
					</dd>
				</dl>
			</div>
			<p className="modify_acc_guide">휴대전화 번호 변경은 현재 등록된 번호의 명의자와 변경할<br />휴대폰 번호의 명의자가 동일한 경우에만 가능합니다.<br />(휴대폰 기기와 무관 휴대폰 번호와 연동)</p>
			{/*<!-- <p className="btn_withdraw" style={{bottom:"280px"}} onClick={this.logout.bind(this)}></p>
			<p className="btn_withdraw">
				<Link to="/mycow/mycowLeavePw">탈퇴하기</Link>
			</p> -->*/}
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<p className="btn_withdraw" style={{bottom:"280px"}} onClick={this.logout.bind(this)}></p>
			<p className="btn_withdraw">
				<Link to="/mycow/mycowLeavePw"><span>탈퇴하기</span></Link>
			</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowMyInfo);