import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import axios from 'axios';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mainProductCategory extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;

		const cidx = cookies.get('cidx') == undefined ? 0 : cookies.get('cidx');
		const apiurl = cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl');

		this.state = {
			pcategorys: [],
			pproducts: [],
			pproductConds: [],
			pproductCnt: 0,
			cidx: cidx,
			apiurl: apiurl,
			version: cookies.get('version'),
			dtlIdx: 0,
			config : global.variable.config
		};
	}
	componentDidMount (){
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/product/category_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("product_category");
			//console.log(res.data.data.list);
			this.setState({
				pcategorys : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	pCateChange = (idx, e) => {
		document.location.href="/main/mainProductCategoryView/"+idx;
		return;
		$(".prod_result_pop_wrap1").show(/*"fast"*/);
		//$(".prod_result_pop_bg").height("auto");
		const url = this.state.apiurl+"/product/list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("category", idx);
		formData.append("sort", "recent_registeration");
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("rank_detail");
			//console.log(res.data.data.count);
			//console.log(res.data.data.list);
			this.setState({
				pproducts : res.data.data.list,
				pproductCnt : res.data.data.count,
				dtlIdx : idx
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			const omidx = this.state.omidx;
			document.location.href = "/main";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="leftTransition"
		transitionAppear={true}
		transitionEnter={false}
		transitionLeave={false}
		transitionAppearTimeout={500}
		transitionEnterTimeout={500}
		transitionLeaveTimeout={500}>
		{/*<!-- header 영역 -->*/}
		<header className="mem_header cate">
			<p className="btn_prev"><Link className="prod_cate_close prod_cate_close1" to="/main">앞으로</Link></p>
			<h2>상품 카테고리</h2>
		</header>
		{/*<!-- header 영역 end -->*/}
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="prod_cate_pop_wrap prod_cate_pop_wrap1">
				<div className="prod_cate_pop_bg"></div>
				<div className="prod_cate_pop_cont">
					{/*<!-- 내용 영역 -->*/}
					<div className="grey_gap"></div>
					<div className="prod_cate_list">
						<div className="prod_cate_list_ttl">
							<strong>상품 카테고리별 보기</strong>
						</div>
						<ul>
							{this.state.pcategorys.map((pcategory, index) =>
							<li key={index} onClick={this.pCateChange.bind(this, pcategory.pcidx)}><img alt="" src={pcategory.image}/>{pcategory.name}</li>
							)}
						</ul>
					</div>
					{/*<!-- 내용 영역 end -->*/}
				</div>
			</div>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(mainProductCategory);