import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowLeavePw extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			leave_idx: cookies.get('leave_idx') == undefined ? "" : cookies.get('leave_idx'),
			leave_desc: cookies.get('leave_desc') == undefined ? "" : cookies.get('leave_desc'),

			opw: "",
			leaveReasons: [],
			leave: false,
			wh: 0,
			status : 0
		};
	}
	componentDidMount(){
		fnc.event_log("leave_agree", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		try{
			if(global.variable.osType == 1){
				window.android.getPw();
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.getPw.postMessage("");
			}
		}catch(e){
		}

		const formData = new FormData();
		var recomId = $("#recomId").val();
		formData.append('os', global.variable.osType);
		formData.append('appver', this.state.version);
		formData.append('applocale', 'kr');
		formData.append('bypass_emergency', 0);
		formData.append('cidx', this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+'/signup/sign_delete_question.json', formData, this.state.config).then( res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log( res );
			this.setState({
				leaveReasons : res.data.data.list
			});

			let leave_idx = this.state.leave_idx;
			let leave_desc = this.state.leave_desc;

			if(leave_idx !== ""){
				this.setState({
					status : 2
				});

				$(".withdraw_info_del_bg").hide();
				$(".hidden_mem_header").hide();
				$(".hidden_content_1").hide();
				$(".hidden_content_2").hide();
				$(".btn_go_leave").hide();

				$(".hidden_mem_header_3").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".hidden_content_3").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".hidden_mem_header_3").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".hidden_content_3").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".btn_go_leave_proc").fadeIn();

				$(".btn_go_next").addClass("on");
				$(".btn_go_leave").addClass("on");

				$("input[name=withdrawAgree]").prop("checked", true);
				$("input[name=WR][value='"+leave_idx+"']").prop("checked", true);
				$("#description").val(leave_desc);

				$("#pw").focus();

				if(global.variable.osType == 0){
					if ($('#pw').is(':focus')) {
						$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
							e.preventDefault();
						});
					} else {
						$('body').removeClass('notouch').off('scroll touchmove mousewheel');
					}
				}

				if(global.variable.osType == 1){
					window.android.setWebviewFocus();
				}
				this.setState({
					status : 2
				});

				const { cookies } = this.props;
				cookies.set('leave_idx', "", { path: '/' });
				cookies.set('leave_desc', "", { path: '/' });
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		this.setState({
			wh : $(window).height()
		});
	}
	agreeChk(){
		if($('#withdrawAgree').prop('checked')){
			$(".btn_go_next").addClass("on");
		}else{
			$(".btn_go_next").removeClass("on");
		}
	}
	reasonChg = (idx, e) => {
		$("#description").val('');
		if(global.variable.osType == 0){
			$("html, body").animate({
				scrollTop: 0
			}, 500);
			$('body').removeClass('extend');
		}
		if(idx == 1){
			$(".btn_go_leave").removeClass("on");
			$("#description").focus();

			if(global.variable.osType == 0){
				$("html, body").animate({
					scrollTop: 350
				}, 500);
				$('body').addClass('extend');

				if ($('#description').is(':focus')) {
					$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});
				} else {
					$('body').removeClass('notouch').off('scroll touchmove mousewheel');
				}
			}

			//if(global.variable.osType === 1){
			//	window.android.setWebviewFocus();
			//}
		}else{
			$(".btn_go_leave").addClass("on")
		}
		$(".contentsLen").html("0/200");
	}
	focusIn(){
		var idx = $("input[name=WR]:checked").val();
		if(idx != 1){
			$("#description").blur();
		}
	}
	reasonTxtChg(){
		var description = $("#description").val();
		var idx = $("input[name=WR]:checked").val();
		if(idx == 1){
			if(description.length >= 2){
				$(".btn_go_leave").addClass("on");
				if(global.variable.osType == 0){
					$("html, body").animate({
						scrollTop: 350
					}, 500);
					$('body').addClass('extend');
				}
			}else{
				$(".btn_go_leave").removeClass("on")
			}
		}
		$(".contentsLen").html(description.length + "/200");
	}
	goToLeave(){
		if($(".btn_go_leave").hasClass("on")){
			$(".hidden_mem_header").hide();
			$(".hidden_content_1").hide();
			$(".hidden_content_2").hide();
			$(".btn_go_leave").hide();

			$(".hidden_mem_header_3").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".hidden_content_3").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".hidden_mem_header_3").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".hidden_content_3").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".btn_go_leave_proc").fadeIn();

			$("#pw").focus();
			if(global.variable.osType == 0){
				$("html, body").animate({
					scrollTop: 0
				}, 500);
				$('body').removeClass('extend');
				if ($('#pw').is(':focus')) {
					$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});
				} else {
					$('body').removeClass('notouch').off('scroll touchmove mousewheel');
				}
			}
			if(global.variable.osType == 1){
				window.android.setWebviewFocus();
			}
			this.setState({
				status : 2
			});
			fnc.event_log("leave_pass", "", "");
		}
	}
	goNext(){
		if( !$(".btn_go_next").hasClass("on") ) return;

		$(".withdraw_info_del_bg").hide();

		$(".hidden_mem_header").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".hidden_content_1").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".hidden_content_2").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".hidden_mem_header").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".hidden_content_1").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".hidden_content_2").animate({"margin-top":"0","opacity" : "1"}, 400);

		fnc.event_log("leave_reason", "", "");

		this.setState({
			status : 1
		});
	}
	goPrev = (status, e) => {
		if(status === 0){
			$(".hidden_mem_header").hide();
			$(".hidden_content_1").hide();
			$(".hidden_content_2").hide();

			$(".withdraw_info_del_bg").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".withdraw_info_del_bg").animate({"margin-top":"0","opacity" : "1"}, 400);

			$('body').removeClass('notouch').off('scroll touchmove mousewheel');
		}
		if(status === 1){
			$(".hidden_mem_header_3").hide();
			$(".hidden_content_3").hide();
			$(".btn_go_leave_proc").hide();

			$(".hidden_mem_header").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".hidden_content_1").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".hidden_content_2").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".hidden_mem_header").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".hidden_content_1").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".hidden_content_2").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".btn_go_leave").fadeIn();
		}
		this.setState({
			status : status
		});
	}
	valChg(){
		var pw = $("#pw").val();

		if(pw !== ""){
			var opw = this.state.opw;
			if(pw === this.state.opw){
				$(".btn_go_leave_proc").addClass("on");
				$(".pw_wran").html("");
			}else{
				$(".btn_go_leave_proc").removeClass("on");
				$(".pw_wran").html("비밀번호가 일치하지 않습니다.");
			}
		}else{
			$(".pw_wran").html("");
		}
	}
	validChk(){
		if( $(".btn_go_leave_proc").hasClass("on") ){
			var pw = $("#pw").val();
			var pass = 0;

			if(pw.length >= 6 && pw.length != 0){
				$("#pw").addClass("ok");
				$("#pw").removeClass("wrong");
				$(".pw_warn").html("");
			}else if(pw.length < 6 && pw.length != 0){
				$(".pw_warn").html("비밀번호는 6자 이상을 입력해주세요.");
				$("#pw").removeClass("ok");
				$("#pw").addClass("wrong");
				pass++;
			}else if(pw.length == 0){
				$("#pw").removeClass("wrong");
				$(".pw_warn").html("");
			}

			var posY = $(window).scrollTop();
			this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				//$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			//$('body').css("overflow", "hidden");
			
			$(".prod_detail_pop").css("top", Math.max(0, ((this.state.wh - $(".prod_detail_pop").outerHeight()) / 2) + posY) + "px");
			$(".prod_detail_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".prod_detail_pop").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".cancel_dimm").fadeIn(400);

			this.setState({pass : pass});
			return;
		}
	}
	cancel(){
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".prod_detail_pop").animate({"margin-top":"100px","opacity" : "0"}, {
			"duration" :400,
			"complete" : function(){
				$(".prod_detail_pop").hide()
			}
		});
		$(".cancel_dimm").fadeOut(400);
	}
	leave(){
		var pw = $("#pw").val();
		var pass = this.state.pass;

		$(".prod_detail_pop").animate({"margin-top":"100px","opacity" : "0"}, {
			"duration" :400,
			"complete" : function(){
				$(".prod_detail_pop").hide()
			}
		});

		const { cookies } = this.props;
		cookies.set('autoNoLogin', 'N', { path: '/' });
		if(global.variable.osType == 1){
			this.procLeave($.trim(window.android.encryptBase64(pw)));
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.encryptBase64.postMessage({ str : pw, param1 : "pw", param2 : pass, param3 : "" , param4 : "" } )
			return;
		}
	}
	goPwFind(){
		const { cookies } = this.props;

		var idx = $("input[name=WR]:checked").val();
		var description = $("#description").val();
		
		cookies.set('leave_idx', idx, { path: '/' });
		cookies.set('leave_desc', description, { path: '/' });
		cookies.set('originPage', '/mycow/mycowLeavePw', { path: '/' });

		document.location.href="/member/findPw";
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + posY) + "px");
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		var leave = this.state.leave;
		if(leave){
			$(".alertPop, .alertPopDimm").fadeOut("fast");
			document.location.href='/main';
		}else{
			$(".alertPop, .alertPopDimm").fadeOut("fast");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getPw"){
			this.setState({
				opw : resObj.password
			});
		}
		if(resObj.key == "encryptBase64"){
			if( resObj.param1 == "pw" ){
				this.procLeave(resObj.returnStr);
			}
		}
		if(resObj.key == "pback"){
			var status = this.state.status;
			if(status === 0){
				document.location.href="/mycow/mycowMyInfo";
			}
			if(status === 1){
				$(".hidden_mem_header").hide();
				$(".hidden_content_1").hide();
				$(".hidden_content_2").hide();

				$(".withdraw_info_del_bg").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".withdraw_info_del_bg").animate({"margin-top":"0","opacity" : "1"}, 400);
			}
			if(status === 2){
				$(".hidden_mem_header_3").hide();
				$(".hidden_content_3").hide();
				$(".btn_go_leave_proc").hide();

				$(".hidden_mem_header").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".hidden_content_1").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".hidden_content_2").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".hidden_mem_header").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".hidden_content_1").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".hidden_content_2").animate({"margin-top":"0","opacity" : "1"}, 400);
				$(".btn_go_leave").fadeIn();
			}
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	procLeave = (pw, e) => {
		const formData = new FormData();
		var url = this.state.apiurl+"/signup/sign_delete.json";
		var idx = $("input[name=WR]:checked").val();
		var description = $("#description").val();

		formData.append('os', global.variable.osType);
		formData.append('appver', this.state.version);
		formData.append('applocale', 'kr');
		formData.append('bypass_emergency', 0);
		formData.append('cidx', this.state.cidx);
		formData.append('password', pw);
		formData.append('reason_idx', idx);
		formData.append('description', description);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then( res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(error_code == undefined){
				fnc.event_log("leave_complete", "", "");
				$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
				$(".alertPop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".alertPop").animate({"margin-top":"0","opacity" : "1"}, 400);
				const { cookies } = this.props;
				//let d = new Date();
				//d.setTime(d.getTime() + (60*60*1000));
				//cookies.set('cidx', '', { path: '/', expires: d });
				//cookies.set('uemail', '', { path: '/', expires: d });
				cookies.set('cidx', '', { path: '/' });
				cookies.set('uemail', '', { path: '/' });
				cookies.set('adult', '', { path: '/' });
				this.setState({
					leave : true
				});
			}else{
				this.alertPop( (res.data.error).replace(/\n/g, '<br/>') );
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p>캐시카우 탈퇴가 완료되었습니다.<br/>그동안 캐시카우를 이용해 주셔서 감사합니다.<br/>더욱 발전하는 캐시카우가 되겠습니다.<br/>감사합니다.</p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 회원탈퇴_정보삭제동의 -->*/}
			<div className="withdraw_info_del_bg">
				<Link className="withdraw_info_del_close" to="/mycow/mycowMyInfo">닫기</Link>
				<div className="withdraw_info_del">
					<strong>캐시카우 탈퇴 전<br />신중하게 결정해주세요.</strong>
					<p>
						<span>탈퇴 후 3개월 간 재가입이 불가합니다.</span><br />
						또한 회원님의 개인정보, 포인트, 상품쿠폰, 캐시아웃 신청, <br />
						기부 등의 정보가 삭제되고 다시 복구할 수 없습니다.
					</p>
					<div className="withdraw_chk">
						<input id="withdrawAgree" name="withdrawAgree" type="checkbox" value="Y" onChange={this.agreeChk.bind(this)}/>
						<label htmlFor="withdrawAgree">동의하기</label>
					</div>
				</div>
				<p className="btn_login btn_go_next"><Link to="#" onClick={this.goNext.bind(this)}>다음</Link></p>
			</div>
			{/*<!-- 회원탈퇴_정보삭제동의 end -->*/}
			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>
			{/*<!-- 완료 팝업 end -->*/}

			{/*<!-- 회원 탈퇴 컨펌 팝업 -->*/}
			<div className="layer_dimm cancel_dimm" style={{display:"none"}}></div>
			<div className="prod_detail_pop" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>정말로 캐시카우를 탈퇴하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.cancel.bind(this)}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.leave.bind(this)}>확인</Link>
				</div>
			</div>
			{/*<!-- 회원 탈퇴 컨펌 팝업 end -->*/}


			{/*<!-- header 영역 -->*/}
			<header className="mem_header hidden_mem_header" style={{display:"none"}}>
				<p className="btn_prev"><Link to="/mycow/mycowMyInfo">앞으로</Link></p>
				<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this, 0)}>앞으로</Link></p>
				<h2>회원 탈퇴</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="my_mycow_account gbg hidden_content_1">
				<p>
					탈퇴 정보 확인<br />
					<strong>{this.state.uemail}</strong>
				</p>
			</div>
			<div className="withdraw_reason_wrap hidden_content_2" style={{display:"none"}}>
				<p className="withdraw_ques">캐시카우를 탈퇴하시는 이유는 무엇인가요? <br />탈퇴 이유를 알려주시면 서비스 품질 향상을 위해 참고하겠습니다.</p>
				<ul>
					{
					this.state.leaveReasons.map((leaveReason, i) =>
					<li key={i}>
						<input id={"WR"+i} name="WR" type="radio" value={leaveReason.idx} onChange={this.reasonChg.bind(this, leaveReason.idx)}/>
						<label htmlFor={"WR"+i}><span>{leaveReason.description}</span></label>
					</li>
					)}
				</ul>
				<div className="withdraw_txtarea">
					<textarea name="description" id="description" maxLength="200" onFocus={this.focusIn.bind(this)} onChange={this.reasonTxtChg.bind(this)}></textarea>
					<span className="contentsLen">0/200</span>
				</div>
			</div>
			<p className="btn_login btn_go_leave"><Link to="#" onClick={this.goToLeave.bind(this)}>다음</Link></p>
			{/*<!-- 내용 영역 end -->*/}


			{/*<!-- header 영역 -->*/}
			<header className="mem_header hidden_mem_header_3" style={{display:"none"}}>
				<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this, 1)}>앞으로</Link></p>
				<h2>회원 탈퇴</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="memjoin_id_input hidden_content_3" style={{display:"none"}}>
				<h3 className="memjoin_input_ttl">본인 확인을 위해 비밀번호를 <br />입력해주세요.</h3>
				<fieldset>
					<input className="memjoin_input_pw" id="pw" name="pw" placeholder="비밀번호" type="password" onChange={this.valChg.bind(this)}/>
					<p className="btn_pw_find">
						<span className="pw_wran withdraw"></span>
						<Link to="#" onClick={this.goPwFind.bind(this)}>비밀번호 찾기</Link>
					</p>
				</fieldset>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			<p className="btn_login btn_go_leave_proc" style={{display:"none"}}><Link to="#" onClick={this.validChk.bind(this)}>확인</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowLeavePw);