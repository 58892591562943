import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_use_point_w from '../img/mycow/btn_use_point_w.png';

class mycowInFaq extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			mycowInFaqST: cookies.get('mycowInFaqST') == undefined ? 0 : ~~cookies.get('mycowInFaqST'),

			nlists: [],
			fcates: [],
			flists: [],
			firstfidx: 0,
			firstname: ""
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_ask_faq", "", "");
		const { cookies } = this.props;
		if(this.state.mycowInFaqST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowInFaqST);
				_this.setState({mycowInFaqST : 0});
				cookies.set('mycowInFaqST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			}, 700);
		}
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInFaq', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");
		$(".btn_login").css("bottom", "-100px");

		var url = this.state.apiurl+"/guide/notice_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 4);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("notice_list");
			//console.log(res);
			this.setState({
				nlists : res.data.data.list[0]
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		url = this.state.apiurl+"/guide/faq_list.json";
		formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			this.setState({
				fcates: res.data.data.category_list,
				firstfidx: res.data.data.category_list[0].fcidx,
				firstname: res.data.data.category_list[0].name
			});

			url = this.state.apiurl+"/guide/faq_list.json";
			formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("block", 99);
			formData.append("offset", 1);
			formData.append("category", res.data.data.category_list[0].fcidx);
			let spinnerInterval3 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res2 => {
				if( ~~res2.data.result === -1){
					if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
					if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
				}
				clearInterval(spinnerInterval3);
				//console.log("list")
				//console.log(res2)
				this.setState({
					flists: res2.data.data.list
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	icateChg = (idx, e) => {
		e.preventDefault();
		$(".fcate").removeClass("on");
		$(".fTitle").removeClass("fold");
		$(".fTitle").next().hide();
		$("[data-fidx=\""+idx+"\"]").addClass("on");
		this.setState({firstname:$("[data-fidx=\""+idx+"\"]").html()});
		var url = this.state.apiurl+"/guide/faq_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		formData.append("category", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res2 => {
			if( ~~res2.data.result === -1){
				if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
				if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("list")
			//console.log(res2)
			this.setState({
				flists: res2.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	handleKeyPress = (e) => {
		if(e.key === "Enter"){
			var stxt = $("#stxt").val();
			if(stxt.length >= 2){
				document.location.href="/mycow/mycowInFaqSearch/"+stxt;
			}
		}
	}
	componentDidUpdate(){
	}
	faqFoldToggle = (idx, e) =>{
		if($(".fTitle").eq(idx).hasClass("fold")){
			$(".fTitle").eq(idx).removeClass("fold");
			$(".answer").eq(idx).hide();
		}else{
			const url = this.state.apiurl+"/report/visit.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("type", "faq");
			formData.append("idx", idx);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				$(".fTitle").removeClass("fold");
				$(".answer").hide();
				$(".fTitle").eq(idx).addClass("fold");
				$(".answer").eq(idx).show();
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowInFaqST', 0, { path: '/' });

		document.location.href="/mycow/mycow";
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/mycow/mycowInFaq', { path: '/' });
		document.location.href=page;
	}
	goInquiry(){
		const { cookies } = this.props;
		cookies.set('mycowInFaqST', $(window).scrollTop(), { path: '/' });

		document.location.href="/mycow/mycowInquiry";
	}
	btnShow(){
		document.location.href="/mycow/mycowInFaqSearch";
		return;
		/*
		$(".btn_login").removeClass("on");
		$(".btn_login").animate({"bottom":"0"}, 300);
		$(".faq_noti_wrap").hide();
		$(".faq_reward_wrap").hide();
		$(".faq_btm_btn").hide();
		$(".grey_gap").hide();
		$(".faq_search_wrap").eq(0).hide();
		$(".faq_search_wrap").eq(1).show();
		$("#stxt").focus();
		*/
	}
	btnHide(){
		$(".btn_login").animate({"bottom":"-100px"}, 300);
		$(".faq_noti_wrap").show();
		$(".faq_reward_wrap").show();
		$(".faq_btm_btn").show();
		$(".grey_gap").show();
		$("#stxt").val('');
		$(".faq_search_wrap").eq(1).hide();
		$(".faq_search_wrap").eq(0).show();
	}
	btnChk(){
		var stxt = $("#stxt").val();
		if(stxt.length >= 2){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	goSearch(){
		$(".btn_login").stop().css({"bottom":"0"});
		if(!$(".btn_login").hasClass("on")){
			$("#stxt").focus();
			return;
		}
		var stxt = $("#stxt").val();
		document.location.href="/mycow/mycowInFaqSearch/"+stxt;
	}
	cancelClick = (e) => {
		$("#stxt").val('');
		$("#stxt").blur();
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
			<h2>문의하기&FAQ</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="faq_search_wrap">
				<div className="faq_search_inner">
					<input id="stxt1" name="stxt1" placeholder="검색어" type="text" onFocus={this.btnShow.bind(this)}/>
				</div>
			</div>
			<div className="faq_search_wrap" style={{display:"none"}}>
				<div className="faq_search_inner searching">
					<input id="stxt" name="stxt" placeholder="검색어" type="text" onBlur={this.btnHide.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.btnChk.bind(this)}/>
					<Link className="btn_search_del" to="#">삭제</Link>
				</div>
				<Link className="btn_search_cancel" to="#" onClick={this.cancelClick.bind(this)}>취소</Link>
			</div>
			<div className="grey_gap"></div>
			{/*<!-- 공지사항 -->*/}
			<div className="faq_noti_wrap">
				<div className="faq_noti_ttl">
					<strong>공지사항</strong>
					<Link to="#" onClick={this.goPage.bind(this, "/mycow/mycowNoticeView/"+this.state.nlists.idx)}>{this.state.nlists.title}</Link>
				</div>
				<div className="faq_cate_sel_wrap">
					<strong>FAQ</strong>
					<ul>
						{
						this.state.fcates.map((fcate, i) => 
						<li key={i}>
							<Link to="#" className={this.state.firstfidx === fcate.fcidx ? "fcate on" : "fcate"} data-fidx={fcate.fcidx} onClick={this.icateChg.bind(this, fcate.fcidx)}>{fcate.name}</Link>
						</li>
						)}
					</ul>
					<p>캐시아웃은 신청일로 부터 약3주(영업일 기준 15일) 전후로 입금됩니다.</p>
					<p>포인트 적립은 영업일 기준으로 48시간 전후로 완료됩니다.<br />예) 금요일 밤 영수증 등록 - >월~화요일 중 적립 완료</p>
				</div>
			</div>
			{/*<!-- 공지사항 end -->*/}
			<div className="grey_gap"></div>
			{/*<!-- 리워드 -->*/}
			<div className="faq_reward_wrap">
				<div className="faq_noti_ttl">
					<strong>{this.state.firstname}</strong>
				</div>
				<ul>
					{
					this.state.flists.map((flist, i) =>
					<li key={i}>
						<Link to="#" className="fTitle" onClick={this.faqFoldToggle.bind(this, i)}><span>{flist.title}</span></Link>
						<p className="answer" dangerouslySetInnerHTML={{__html : flist.contents}}></p>
					</li>
					)}
				</ul>
			</div>
			{/*<!-- 리워드 end -->*/}
			<div className="grey_gap"></div>
			<div className="faq_btm_btn">
				<Link to="#" onClick={this.goInquiry.bind(this)}><i>문의 내역/문의하기</i></Link>
			</div>
			{/*<!-- 내용 영역 end -->*/}

			<p className="btn_login"><Link to="#" className="goLogin" onClick={this.goSearch.bind(this)}>확인</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInFaq);