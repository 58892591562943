import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class networkDisconnected extends React.Component {
	interrupt(){
		$(".network_error_pop").css({"margin-top":"0px","opacity" : "1"}, 300);
		$(".network_error_pop").animate({
			"top" : Math.max(0, (($(window).height() - $(".network_error_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
			"margin-top" : "100px",
			"opacity" : "0",
			"display" : "block"
		});
		$(".netword_dimm").fadeOut("fast");
	}
	retry(){
		document.location.reload();
	}
	serverClosePopCls(){
		var userAgent=navigator.userAgent.toLowerCase();
		var osType = 0;
		if(userAgent.match(/android/i)){
			osType = 1;
		}
		if(osType === 1){
			window.android.quit(osType);
			
		}
		if(osType === 0){
			window.webkit.messageHandlers.quit.postMessage({ os  : osType } );
		}
	}
	updatePopCls(){
		var userAgent=navigator.userAgent.toLowerCase();
		var osType = 0;
		if(userAgent.match(/android/i)){
			osType = 1;
		}
		if(osType === 1){
			window.android.openUrl(0, global.variable.playStoreUrl, "", "", "", "");
		}
		if(osType === 0){
			window.webkit.messageHandlers.openUrl.postMessage({ cidx : 0, url : global.variable.appStoreUrl, param1 : "", param2 : "", param3 : "", param4 : "" });
		}
	}
	render(){
		return (
			<div>
				<div className="network_error_pop" style={{display:"none"}}>
					<div className="common_pop_txt">
						<p dangerouslySetInnerHTML={{__html : global.variable.networkDisconnected}}></p>
					</div>
					<div className="btm_confirm_btns">
						<Link className="white cancel" to="#" onClick={this.interrupt.bind(this)}>아니오</Link>
						<Link className="dgrey" to="#" onClick={this.retry.bind(this)}>네</Link>
					</div>
				</div>
				<div className="server_close_pop" style={{display:"none"}}>
					<div className="common_pop_txt">
						<p></p>
					</div>
					<Link className="pop_ok" to="#" onClick={this.serverClosePopCls.bind(this)}>확인</Link>
				</div>
				<div className="update_pop" style={{display:"none"}}>
					<div className="common_pop_txt">
						<p></p>
					</div>
					<Link className="pop_ok" to="#" onClick={this.updatePopCls.bind(this)}>확인</Link>
				</div>
			</div>
		)
	}
}
export default withCookies(networkDisconnected);