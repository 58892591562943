import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowNotice extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			mycowNoticeST: cookies.get('mycowNoticeST') == undefined ? 0 : ~~cookies.get('mycowNoticeST'),

			nlists: [],
			pageNo: 1,
			moreProc: false
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_notice", "", "");
		const { cookies } = this.props;
		if(this.state.mycowNoticeST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowNoticeST);
				_this.setState({mycowNoticeST : 0});
				cookies.set('mycowNoticeST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			});
		}
		this.init();
		window.addEventListener('scroll', this.showMore.bind(this), true);
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowNotice', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/guide/notice_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 10);
		formData.append("offset", 1);
		this.setState({
			pageNo : 1
		});
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({nlists : res.data.data.list});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	showMore(){
		if(this.state.moreProc) return;

		let scrollHeight = Math.floor(document.documentElement.scrollHeight, document.body.scrollHeight);
		let scrollTop = Math.ceil(document.documentElement.scrollTop, document.body.scrollTop);
		let clientHeight = Math.ceil(document.documentElement.clientHeight);
		var pageNo = this.state.pageNo;
		if(scrollTop + clientHeight + 150 >= scrollHeight){
			pageNo = pageNo+1;
			this.setState({
				pageNo : pageNo,
				moreProc : true
			});

			const url = this.state.apiurl+"/guide/notice_list.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("block", 10);
			formData.append("offset", pageNo);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var nlists  = this.state.nlists ;
				for (var i in res.data.data.list) {
					nlists.push(res.data.data.list[i]);
				}
				this.setState({
					nlists  : nlists,
					moreProc:false
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowNoticeST', 0, { path: '/' });
		document.location.href="/mycow/mycow";
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/mycow/mycowNotice', { path: '/' });
		cookies.set('mycowNoticeST', $(window).scrollTop(), { path: '/' });
		document.location.href=page;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
			<h2>공지사항</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div id="content" className="pt0">
				<div className="dona_list_wrap">
					<div className="noti_list_cont">
						<ul>
							{this.state.nlists.map((nlist, i) =>
							<li key={i}>
								<Link to="#" onClick={this.goPage.bind(this, "/mycow/mycowNoticeView/"+nlist.idx)}>
									<p>{nlist.title}</p>
									<span>{nlist.regist_date}</span>
								</Link>
							</li>
							)}
						</ul>
					</div>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowNotice);