import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowInviteInput extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			wh:0
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		this.setState({
			wh : $(window).height()
		});

		$("#recomId").focus();

		if(global.variable.osType == 0){
			if ($('#recomId').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}

		if(global.variable.osType == 1){
			window.android.setWebviewFocus();
		}
	}
	emailChk(){
		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		var recomId = $("#recomId").val();
		if(recomId.match(emailRegExp) != null) {
			if(!$(".btn_login").hasClass("on")) $(".btn_login").addClass("on")
		}else{
			$(".btn_login").removeClass("on")
		}
	}
	goNext(){
		if( !$(".btn_login").hasClass("on") ) return;
		var recomId = $("#recomId").val();

		if(global.variable.osType == 1){
			recomId = window.android.encryptBase64(recomId);
			this.inputProc(recomId);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.encryptBase64.postMessage({ str : recomId, param1 : "", param2 : "", param3 : "" , param4 : "" } )
			return;
		}
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-(posY));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + posY) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "encryptBase64"){
			var recomId = resObj.returnStr;
			this.inputProc(recomId);
		}
		if(resObj.key == "pback"){
			document.location.href="/mycow/mycowInvite";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "input_focus"){
			$("#recomId").focus();
		}
	}
	inputProc = (recomId, e) => {
		var url = this.state.apiurl+"/signup/input_recommended.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "check");
		formData.append("email", $.trim(recomId));
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(error_code == undefined){
				url = this.state.apiurl+"/signup/input_recommended.json";
				formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				formData.append("type", "input");
				formData.append("email", $.trim(recomId));
				let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res2 => {
					if( ~~res2.data.result === -1){
						if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
						if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
					}
					clearInterval(spinnerInterval2);
					var error_code2 = res2.data.error_code;
					if(error_code2 == undefined){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");

						var posY = $(window).scrollTop();
							this.setState({
							posY : posY
						});
						if(global.variable.osType === 0){
							$("#wrap").css("top",-($(window).scrollTop()));
							$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
						}
						$('body').css("overflow", "hidden");

						$(".certi_ok_pop, .certi_ok").fadeIn();
						setTimeout(function(){
							document.location.href="/mycow/mycowInvite";
						}, 2000);
					}else{
						this.alertPop( (res.data.error).replace(/\n/g, "<br/>") );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}else{
				this.alertPop( (res.data.error).replace(/\n/g, "<br/>") );
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="/mycow/mycowInvite">앞으로</Link></p>
				<h2>추천인 입력하기</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="memjoin_id_input">
				<h3 className="memjoin_input_ttl">추천인 입력은 한번만 가능합니다.</h3>
				<p className="memjoin_input_subttl">본인을 추천인으로 입력할 수 없습니다.</p>
				<fieldset>
					<input className="memjoin_input_pw" id="recomId" name="recomId" placeholder="추천인 아이디(이메일)을 입력해주세요." type="email" maxLength="30" onChange={this.emailChk.bind(this)}/>
				</fieldset>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			<p className="btn_login"><Link to="#" onClick={this.goNext.bind(this)}>확인</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInviteInput);