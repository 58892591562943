import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class gate extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			param_type : props.match.params.type == undefined ? "" : props.match.params.type,
			param_id : props.match.params.id == undefined ? "" : props.match.params.id,

			inits: [],
			initCnt: [],
			rtnJson: null
		};
	}

	componentDidMount(){
		if(global.variable.osType == 1){window.android.getDeviceInfo("", "", "init", "", "", "");}
		if(global.variable.osType == 0){window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "init", param2 : "value", param3 : "value" , param4 : "value" } );}
		//if(global.variable.osType == 1){
		//	window.android.getAppSetting();
		//}
		//if(global.variable.osType == 0){
		//	window.webkit.messageHandlers.getAppSetting.postMessage("");
		//}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;
		var originPage = this.state.originPage;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$');

		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pulldown"){
			document.location.reload();
		}
		if(resObj.key == "init"){
			document.location.href="/init";
		}
		if(resObj.key == "anything"){
			if(global.variable.osType == 1){window.android.getDeviceInfo("", "", "anything", "", "", "");}
			if(global.variable.osType == 0){window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "anything", param2 : "value", param3 : "value" , param4 : "value" } );}
		}
		if(resObj.key == "autoLogin"){
			this.setState({
				"rtnJson" : jsonStr
			});
			if(global.variable.osType == 1){
				window.android.getPw();
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.getPw.postMessage("");
			}
		}
		if(resObj.key == "getDeviceInfo"){
			if(resObj.param1 == "init"){
				const url = this.state.apiurl+"/init/version.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", resObj.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", 0);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					//if(resObj.version < res.data.data.version){
					//	fnc.update_pop("최신 버전으로 업데이트가 필요합니다.");
					//}else{
						if(global.variable.osType == 1){
							window.android.getAppSetting();
						}
						if(global.variable.osType == 0){
							window.webkit.messageHandlers.getAppSetting.postMessage("");
						}
					//}
					this.setState({ainfo : res.data.data});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg || msg === "Request aborted"){fnc.network_error_pop();}
				});
			}
			if(resObj.param1 == "anything"){
				this.setState({
					uuid : resObj.uuid,
					token : resObj.token,
					model : resObj.model,
					version : resObj.version,
					osversion : resObj.os_version
				});
				cookies.set('uuid', resObj.uuid, { path: '/' });
				cookies.set('model', resObj.model, { path: '/' });
				cookies.set('version', resObj.version, { path: '/' });

				if(this.state.param_type === ""){
					document.location.href="/main";
				}else{
					if(this.state.param_type === "productView"){
						document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
					}
					if(this.state.param_type === "onlineMall"){
						document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
					}
				}
			}
		}
		if(resObj.key == "getPw"){
			jsonStr = this.state.rtnJson;
			var resObj2 = JSON.parse(jsonStr);
			var userid		= resObj2.userid;
			var password	= resObj.password;

			var cidx		= resObj2.cidx;
			var osType		= resObj2.os;
			var uuid		= resObj2.uuid;
			var token		= resObj2.token;
			var model		= resObj2.model;
			var version		= resObj2.version;
			var os_version	= resObj2.os_version;

			if(osType == 1){
				var enc_userid = window.android.encryptBase64(userid);
				var enc_password = window.android.encryptBase64(password);

				enc_userid = $.trim(enc_userid);
				enc_password = $.trim(enc_password);

				this.loginProc(userid, password, enc_userid, enc_password);
				return;
			}
			if(osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : userid, param1 : "id", param2 : "", param3 : "" , param4 : "" } )
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : password, param1 : "pw", param2 : password, param3 : "" , param4 : "" } )
				return;
			}
		}
		if(resObj.key == "encryptBase64"){
			var mode = resObj.param1;
			if(mode === "id"){
				this.setState({
					iosid : resObj.returnStr
				});
			}
			if(mode === "pw"){
				this.setState({
					iospw : resObj.returnStr
				});

				jsonStr = this.state.rtnJson;
				var resObj2 = JSON.parse(jsonStr);
				var userid		= resObj2.userid;
				var password	= resObj.param2;

				var enc_userid = this.state.iosid;
				var enc_password = resObj.returnStr;

				this.loginProc(userid, password, enc_userid, enc_password);
			}
			if(mode === "lng"){
				this.setState({
					ioslng : resObj.returnStr
				});
			}
			if(mode === "lat"){
				this.setState({
					ioslat : resObj.returnStr
				});

				jsonStr = this.state.rtnJson;
				var resObj2 = JSON.parse(jsonStr);
				var userid		= resObj2.userid;
				var password	= resObj.param2;

				var cidx		= resObj2.cidx;
				var osType		= resObj2.os;
				var uuid		= resObj2.uuid;
				var token		= resObj2.token;
				var model		= resObj2.model;
				var version		= resObj2.version;
				var os_version	= resObj2.os_version;

				var enc_lng = this.state.ioslng;
				var enc_lat = resObj.returnStr;

				this.callLocation(enc_lng, enc_lat, osType, version, cidx, userid, password, uuid, model);
			}
		}
	}

	loginProc = (userid, password, enc_userid, enc_password, e) =>{
			var _this = this;

			var jsonStr = _this.state.rtnJson;
			var resObj2 = JSON.parse(jsonStr);
			
			var cidx		= resObj2.cidx;
			var osType		= resObj2.os;
			var uuid		= resObj2.uuid;
			var token		= resObj2.token;
			var model		= resObj2.model;
			var version		= resObj2.version;
			var os_version	= resObj2.os_version;

			var url			= "";
			var formData	= null;

			url = _this.state.apiurl+"/login/sign_in.json";
			formData = new FormData();
			formData.append("os", osType);
			formData.append("appver", version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", "0");
			formData.append("cidx", "0");
			formData.append("userid", enc_userid);
			formData.append("password", enc_password);
			formData.append("uuid", uuid);
			formData.append("osver", os_version);
			formData.append("device_model", encodeURI(model));
			//for(let [name, value] of formData) {
			//	alert(name +" = "+ value);
			//}
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, _this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var error_code = res.data.error_code;
				//alert(JSON.stringify(res.data));
				if(error_code == undefined){
					url = _this.state.apiurl+"/report/token.json";
					formData = new FormData();
					formData.append("os", osType);
					formData.append("appver", version);
					formData.append("applocale", "kr");
					formData.append("bypass_emergency", 0);
					formData.append("cidx", cidx);
					formData.append("token", token);
					//for(let [name, value] of formData) {
					//	alert(name +" = "+ value);
					//}
					let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
					axios.post(url, formData, _this.state.config).then(res => {
						if( ~~res.data.result === -1){
							if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
							if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
						}
						clearInterval(spinnerInterval2);
						var lng = "";
						var lat = "";
						try{
							navigator.geolocation.getCurrentPosition(function(position) {
								lng = position.coords.longitude;
								lat = position.coords.latitude;
								if(osType == 1){
									lng = window.android.encryptBase64(lng);
									lat = window.android.encryptBase64(lat);
									lng = $.trim(lng);
									lat = $.trim(lat);
									_this.callLocation(lng, lat, osType, version, cidx, userid, password, uuid, model);
								}
								if(osType == 0){
									window.webkit.messageHandlers.encryptBase64.postMessage({ str : lng, param1 : "lng", param2 : "", param3 : "" , param4 : "" } )
									window.webkit.messageHandlers.encryptBase64.postMessage({ str : lat, param1 : "lat", param2 : password, param3 : "" , param4 : "" } )
									return;
								}
							});
						}catch(e){
							lng = "131.86743";
							lat = "37.24074";
							if(osType == 1){
								lng = window.android.encryptBase64(lng);
								lat = window.android.encryptBase64(lat);
								lng = $.trim(lng);
								lat = $.trim(lat);
								_this.callLocation(lng, lat, osType, version, cidx, userid, password, uuid, model);
							}
							if(osType == 0){
								window.webkit.messageHandlers.encryptBase64.postMessage({ str : lng, param1 : "lng", param2 : "", param3 : "" , param4 : "" } )
								window.webkit.messageHandlers.encryptBase64.postMessage({ str : lat, param1 : "lat", param2 : password, param3 : "" , param4 : "" } )
								return;
							}
						}
					}).catch(res => {
						var msg = res.message;
						if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
					});
				}else{
					if(osType == 1){window.android.logout(osType);}
					if(osType == 0){window.webkit.messageHandlers.logout.postMessage({ os : osType });}
					fnc.event_log("login_auto_fail", "", "");

					const { cookies } = this.props;
					cookies.set('uuid', uuid, { path: '/' });
					cookies.set('model', model, { path: '/' });
					cookies.set('version', version, { path: '/' });

					if(this.state.param_type === ""){
						document.location.href="/main";
					}else{
						if(this.state.param_type === "productView"){
							document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
						}
						if(this.state.param_type === "onlineMall"){
							document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
						}
					}

				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
	}
	callLocation = (lng, lat, osType, version, cidx, userid, password, uuid, model, e) => {
		var url = this.state.apiurl+"/report/location.json";
		var formData = new FormData();
		formData.append("os", osType);
		formData.append("appver", version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", cidx);
		formData.append("latitude", lat);
		formData.append("longitude", lng);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.callUserInfo(version, cidx, userid, password, uuid, model);
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	callUserInfo = (version, cidx, userid, password, uuid, model, e) => {
		const { cookies } = this.props;

		if(global.variable.osType == 1){
			window.android.login(cidx, userid, global.variable.osType, password);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.login.postMessage({ cidx : cidx , userid : userid, os : global.variable.osType, password : password } );
		}

		var url = this.state.apiurl+"/user/info.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			cookies.set('cidx', cidx, { path: '/' });
			cookies.set('uemail', userid, { path: '/' });
			cookies.set('adult', res.data.data.adult, { path: '/' });
			cookies.set('uuid', uuid, { path: '/' });
			cookies.set('model', model, { path: '/' });
			cookies.set('version', version, { path: '/' });

			if(this.state.param_type === ""){
				fnc.event_log("login_auto_success", "", "");
				document.location.href = "/main";
			}else{
				if(this.state.param_type === "productView"){
					document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
				}
				if(this.state.param_type === "onlineMall"){
					document.location.href="/main/"+this.state.param_type+"/"+this.state.param_id;
				}
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	render(){
		return (
		<CSSTransitionGroup>
		<div id="wrap">
			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner"><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(gate);