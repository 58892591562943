import React from 'react';
import { Link } from 'react-router-dom';

class mainOnlineMallCategory2nd extends React.Component {
	oCateChange(ocidx, idx, e){
		this.props.onClick(ocidx, idx);
	}
	render(){
		return (
			<ul>
				{this.props.list.map((item, i) =>
				<li key={i} onClick={this.oCateChange.bind(this, item.ocidx, this.props.idx)}>{item.ocname}</li>
				)}
			</ul>
		)
	}
}

export default mainOnlineMallCategory2nd;