import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

function masking(email) {
	var pattern = /.{2}$/; // 정규식
	return (email.split('@')[0]).replace(pattern, "**")+'@'+email.split('@')[1];
}

class findId extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			uuid: cookies.get('uuid'),
			model: cookies.get('model'),
			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),

			mode: "",
			focusChk : "",
			wh : 0
		};
	}
	componentDidMount(){
		fnc.event_log("find_id", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}

		var _this = this;
		_this.setState({wh : $(window).height()});
		setTimeout(function(){
			_this.setState({wh : $(window).height()});
		}, 700);
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	goNext = (e) =>{
		e.preventDefault();
		if(!$(".goNext").hasClass("on")){
//			var focusChk = this.state.focusChk;
//			if(focusChk !== ""){
//				$("#"+focusChk).focus();
//			}
			return;
		}

		if(this.state.mode == "login"){
			document.location.href="/member/login";
			return;
		}

		if($("#idFind1").prop("checked") + $("#idFind2").prop("checked") === 0){
			this.alertPop("원하는 방법을 선택해주세요.");
			return;
		}
		if($("#idFind1").prop("checked")){
			var name = $("#id_name").val();
			var phone = $("#id_phone").val();
			if(name === ""){
				this.alertPop("이름을 입력해주세요.");
				$("#id_name").focus();
				return;
			}
			if(phone === ""){
				this.alertPop("휴대전화번호를을 입력해주세요.");
				$("#id_phone").focus();
				return;
			}

			var url = this.state.apiurl+"/login/find_id.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", "0");
			formData.append("name", name);
			formData.append("phone", phone);
			formData.append("mode", "info");
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				//console.log("find");
				//console.log(res);
				var error_code = res.data.error_code;
				if(error_code == undefined){

					if(global.variable.osType == 1){
						$(".decryptStr").html( masking(window.android.decryptBase64(res.data.data.userid)) );
					}
					if(global.variable.osType == 0){
						window.webkit.messageHandlers.decryptBase64.postMessage({ str : res.data.data.userid, param1 : "idfind1", param2 : "", param3 : "" , param4 : "" } )
						return;
					}

					$("#id_name").val('');
					$("#id_phone").val('');

					$(".find_idpw_ttl_head").hide();
					$(".find_idpw_radio1").hide();
					$(".find_id_info_input").hide();

					$(".find_id_result").show();
					$(".find_id_result_txt").show();

					$("#idFind1").prop("checked", false);
					$(".goNext").html("로그인하기");
					this.setState({mode : "login"});
				}else{
					var error = res.data.error;
					this.alertPop( error.replace(/\n/gi, "<br/>") );
					$("#id_name").val('');
					$("#id_name").focus();
					$("#id_phone").val('');
					$(".btn_login").removeClass("on");
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){
					fnc.network_error_pop();
					return;
				}else{
					this.alertPop( JSON.stringify(res) );
				}
			});;
		}
		if($("#idFind2").prop("checked")){
			if(global.variable.osType == 1){
				window.android.getIdentity("0", "url", "param1", "param2", "param3", "param4", "showid", "");
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.getIdentity.postMessage({ cidx : "0", url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : "showid", userid : "" } );
				return;
			}
		}
	}
	buttonOnChk = (e) => {
		if( $("input[name=idFind]:checked").val() === "1" ){
			$(".find_id_info_input").show();
			$(".goNext").removeClass("on");
			$("#id_name").focus();

			if(global.variable.osType == 0){
				if ($('#id_name').is(':focus')) {
					$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});
				} else {
					$('body').removeClass('notouch').off('scroll touchmove mousewheel');
				}
			}


			if(global.variable.osType == 1){
				window.android.setWebviewFocus();
			}
		}
		if( $("input[name=idFind]:checked").val() === "2" ){
			$("#id_name").val('');
			$("#id_phone").val('');
			$(".find_id_info_input").hide();

			$(".goNext").addClass("on");
		}
		$(".goNext").html("다음");
		this.setState({mode : ""});
	}
	valChg = (e) => {
		var id_name = $("#id_name").val();
		var id_phone = $("#id_phone").val();

		if(id_name != "" && id_phone != ""){
			if(!$(".goNext").hasClass("on")) $(".goNext").addClass("on");
		}else{
			$(".goNext").removeClass("on");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "decryptBase64"){
			if(resObj.param1 == "idfind1"){
				$(".decryptStr").html( masking(resObj.returnStr) );

				$("#id_name").val('');
				$("#id_phone").val('');

				$(".find_idpw_ttl_head").hide();
				$(".find_idpw_radio1").hide();
				$(".find_id_info_input").hide();

				$(".find_id_result").show();
				$(".find_id_result_txt").show();

				$("#idFind1").prop("checked", false);
				$(".goNext").html("로그인하기");
				this.setState({mode : "login"});
			}
			if(resObj.param1 == "idfind2"){
				$(".decryptStr").html( resObj.returnStr );

				$(".certi_ok, .certi_ok_pop").fadeOut("fast");

				$(".find_idpw_ttl_head").hide();
				$(".find_idpw_radio").hide();
				$(".find_id_info_input").hide();

				$(".find_id_result").show();
				$(".find_id_result_txt").hide();

				$(".goNext").html("로그인하기");
			}
		}
		if(resObj.key == "getIdentity"){
			var error_code = resObj.result_message.error_code;

			if(error_code == undefined){
				var url = this.state.apiurl+"/login/find_id.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("name", resObj.result_message.data.name);
				formData.append("phone", "");
				formData.append("mode", "cis");
				formData.append("PHPSESSID", resObj.phpsessid);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");
						var userid = res.data.data.userid;
						this.setState({mode : "login"});
						if(global.variable.osType === 1){
							setTimeout(function(){
								$(".decryptStr").html( window.android.decryptBase64(userid) );

								$(".certi_ok, .certi_ok_pop").fadeOut("fast");

								$(".find_idpw_ttl_head").hide();
								$(".find_idpw_radio").hide();
								$(".find_id_info_input").hide();

								$(".find_id_result").show();
								$(".find_id_result_txt").hide();

								$(".goNext").html("로그인하기");
							}, 2000);
						}else{
							setTimeout(function(){
								window.webkit.messageHandlers.decryptBase64.postMessage({ str : userid, param1 : "idfind2", param2 : "", param3 : "" , param4 : "" } )
								return;
							}, 2000);
						}
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
					}
					//let d = new Date();
					//d.setTime(d.getTime() + (60*60*1000));
					//cookies.set('uuid', this.state.uuid, { path: '/', expires: d });
					//cookies.set('model', this.state.model, { path: '/', expires: d });
					//cookies.set('version', this.state.version, { path: '/', expires: d });
					//cookies.set('autoNoLogin', this.state.autoNoLogin, { path: '/', expires: d });
					cookies.set('uuid', this.state.uuid, { path: '/' });
					cookies.set('model', this.state.model, { path: '/' });
					cookies.set('version', this.state.version, { path: '/' });
					cookies.set('autoNoLogin', this.state.autoNoLogin, { path: '/' });
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}else{
				this.alertPop( (resObj.result_message.error).replace(/\n/g, '<br/>') );
			}
		}
		if(resObj.key == "pback"){
			document.location.href = "/member/login";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	alertPop = (msg, e) => {
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/member/login">앞으로</Link></p>
			<h2>아이디(이메일)/비밀번호 찾기</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}
		
			{/*<!-- 인증완료 팝업 -->*/}
			<div className="layer_dimm certi_ok"style={{display:"none"}}></div>
			<div className="certi_ok_pop"style={{display:"none"}}>인증완료</div>
			{/*<!-- 인증완료 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			{/*<!-- <header className="mem_header">
				<p className="btn_prev"><Link to="/member/login">앞으로</Link></p>
				<h2>아이디(이메일)/비밀번호 찾기</h2>
			</header> -->*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="find_idpw_tabs">
				<p><Link className="id on" to="/member/findId">아이디(이메일) 찾기</Link></p>
				<p><Link className="pw" to="/member/findPw">비밀번호 찾기</Link></p>
			</div>
			<div className="find_idpw_wrap id">
				<p className="find_idpw_ttl find_idpw_ttl_head">원하는 방법을 선택해 <br/>아이디를 찾으세요.</p>
				<fieldset>
					<div className="find_idpw_radio find_idpw_radio1">
						<input id="idFind1" name="idFind" type="radio" value="1" onChange={this.buttonOnChk.bind(this)}/>
						<label htmlFor="idFind1">내 회원 정보로 찾기<span>(아이디 일부 ** 표시)</span></label>
					</div>
					<div className="find_id_info_input">
						<input id="id_name" name="id_name" placeholder="이름" type="text" onChange={this.valChg.bind(this)} onFocus={this.onFocusHandle.bind(this,'id_name')} onBlur={this.onBlurHandle.bind(this,'id_name')}/>
						<input id="id_phone" name="id_phone" placeholder="휴대전화번호(‘-’ 제외하고 입력해주세요.)" type="tel" onChange={this.valChg.bind(this)} onFocus={this.onFocusHandle.bind(this,'id_phone')} onBlur={this.onBlurHandle.bind(this,'id_phone')}/>
					</div>

					<div className="find_id_result" style={{display:"none"}}>
						<p>캐시카우 아이디(이메일)는 <br/><strong className="decryptStr">te**@cash-cow.com</strong>입니다.</p>
					</div>
					<p className="find_idpw_ttl find_id_result_txt" style={{display:"none"}}>아이디 전체를 찾으시려면, <br/>아래 방법을 선택해 주세요.</p>


					<div className="find_idpw_radio">
						<input id="idFind2" name="idFind" type="radio" value="2" onChange={this.buttonOnChk.bind(this)}/>
						<label htmlFor="idFind2">본인 인증으로 찾기</label>
					</div>
				</fieldset>
			</div>
			{/*<!-- <p className="btm_btn"><Link className="goNext" to="#" onClick={this.goNext.bind(this)}>다음</Link></p> -->*/}
		</div>
		<p className="btm_btn"><Link className="goNext" to="#" onClick={this.goNext.bind(this)}>다음</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(findId);