import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class surveySub2 extends React.Component {
	answerSel(id, min, max, i, e){
		if($(".option_"+id).hasClass("on")){
			$(".option_"+id).removeClass("on");
		}else{
			$(".option_"+id).addClass("on");
		}
		var lastVal = "";
		var selCnt = 0;
		$(".option_order_"+i).each(function(idx){
			if($(this).hasClass("on")){
				lastVal += $(this).data("id")+",";
				selCnt++;
			}
		});
		if(selCnt < min){
			$("input[name=answer"+i+"]").val("");
			$(".btn_next_ques"+i).find("a").removeClass("on");
			return;
		}
		if(selCnt > max){
			this.answerSel(id, min, max, i);
			return;
		}
		$("input[name=answer"+i+"]").val( lastVal.substr(0, lastVal.length-1 ) );

		var result = 0;
		for(var j=0 ; j < this.props.qCnt ; j++){
			if($("input[name=answer"+j+"]").val() == ""){
				result++;
			}
		}
		if(result == 0){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}
		
		if(lastVal === ""){
		$(".btn_next_ques"+i).find("a").removeClass("on");
		}else{
		$(".btn_next_ques"+i).find("a").addClass("on");
		}
	}
	render(){
		return (
			<ul>
				{this.props.list.map((item, i) =>
				<li key={i} className={item.option_icon}><Link to="#" className={"option_order_"+this.props.i+" option_"+item.option_id} data-id={item.option_id} onClick={this.answerSel.bind(this, item.option_id, this.props.min, this.props.max, this.props.i)}>{item.option_text}</Link></li>
				)}
			</ul>
		)
	}
}

export default surveySub2;