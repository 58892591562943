import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowMyPw extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),

			uinfo: [],
			uuid: "",
			token: "",
			model: "",
			version: "",
			oldpw: "",
			opw: "",
			wh:0,
			focusChk : ""
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		const { cookies } = this.props;
		if((this.state.cidx === 0)){
			cookies.set('loginAfterPath', '/mycow/mycowMyPw', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}

		try{
			if(global.variable.osType == 1){
				window.android.getDeviceInfo("", "", "", "", "", "");
				window.android.getPw();
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "value", param2 : "value", param3 : "value" , param4 : "value" } );
				window.webkit.messageHandlers.getPw.postMessage("");
			}
		}catch(e){
		}

		$('html').scrollTop(0);
		$("body").removeClass("gbg");

//		$(window).scroll(function() {
//			$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		});

		this.setState({
			wh : $(window).height()
		});

		$("#newpw").focus();

		if(global.variable.osType == 0){
			if ($('#newpw, #repw').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}

		if(global.variable.osType == 1){
			window.android.setWebviewFocus();
		}

		//var _this = this;
		//setTimeout(function(){
		//	_this.setState({
		//		wh : $(window).height()
		//	});
		//}, 1000);
	}
	componentDidUpdate(){
	}
	pwChgCancel(){
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".prod_detail_pop").css("top", Math.max(0, ((this.state.wh - $(".prod_detail_pop").outerHeight()) / 2) + posY) + "px");
		$(".cancel_dimm, .prod_detail_pop").fadeIn("fast");
	}
	pwValChg(){
		var newpw = $("#newpw").val();
		var repw = $("#repw").val();
		
		var passChk = 0;
		var passRegExp = /^[A-Za-z0-9!@#$%^*-_=]{6,12}$/;
		if(newpw.match(passRegExp) == null) {
			$(".pw_warn").eq(0).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
			$("#newpw").removeClass("ok");
			$("#newpw").addClass("wrong");
			passChk++;
		}else{
			$("#newpw").addClass("ok");
			$("#newpw").removeClass("wrong");
			$(".pw_warn").eq(0).html("");
		}
		if(repw != ""){
			if(newpw != repw){
				$(".pw_warn").eq(1).html("비밀번호가 일치하지 않습니다.");
				$("#repw").removeClass("ok");
				$("#repw").addClass("wrong");
				passChk++;
			}else if(repw.match(passRegExp) == null) {
				$(".pw_warn").eq(1).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
				$("#repw").removeClass("ok");
				$("#repw").addClass("wrong");
				passChk++;
			}else{
				$("#repw").addClass("ok");
				$("#repw").removeClass("wrong");
				$(".pw_warn").eq(1).html("");
			}
		}else{
			passChk++;
		}

		if(passChk == 0){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	cancel(){
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".cancel_dimm, .prod_detail_pop").fadeOut("fast");
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	pwValid = (e) =>{
		e.preventDefault();
		if(!$(".btn_login").hasClass("on")){
			var focusChk = this.state.focusChk;
			if(focusChk !== ""){
				$("#"+focusChk).focus();
			}
			return;
		}
		var oldpw = this.state.opw;
		var newpw = $("#newpw").val();
		var repw = $("#repw").val();

		if(newpw == ""){
			this.alertPop("비밀번호를 입력해주세요.");
			return;
		}
		var passRegExp = /^[A-Za-z0-9!@#$%^*-_=]{6,12}$/;
		if(newpw.match(passRegExp) == null) {
			this.alertPop("비밀번호는 6~12자 영문 대/소문자, 숫자, 특수문자를 사용해서 입력해주세요.");
			return;
		}
		if(repw == ""){
			this.alertPop("비밀번호 확인을 입력해주세요.");
			return;
		}
		if(newpw != repw){
			this.alertPop("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
			return;
		}

		try{
			if(global.variable.osType == 1){
				oldpw = window.android.encryptBase64(oldpw);
				newpw = window.android.encryptBase64(newpw);
			}
			if(global.variable.osType == 0){
				$("#iosid").val("");
				$("#iospw").val("");
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : oldpw, param1 : "old", param2 : "", param3 : "" , param4 : "" } )
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : newpw, param1 : "new", param2 : "", param3 : "" , param4 : "" } )

				return;
			}
		}catch(e){
			this.alertPop("문제가 발생되었습니다.");
			return;
		}
		this.modifyPass(oldpw, newpw);
	}
	modifyPass(oldpw, newpw){
		const url = this.state.apiurl+"/login/modify_password.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("old_password", $.trim(oldpw));
		formData.append("new_password", $.trim(newpw));
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.result == 1){
				//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
				var posY = $(window).scrollTop();
					this.setState({
					posY : posY
				});
				if(global.variable.osType === 0){
					$("#wrap").css("top",-($(window).scrollTop()));
					$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				}
				$('body').css("overflow", "hidden");
				$(".certi_ok, .certi_ok_pop").fadeIn("fast");
				setTimeout(function(){
					$(".certi_ok, .certi_ok_pop").fadeOut("fast");
					document.location.href="/mycow/mycowMyInfo";
				}, 2000);
				if(global.variable.osType == 1){
					window.android.setPw($("#newpw").val());
				}
				if(global.variable.osType == 0){
					window.webkit.messageHandlers.setPw.postMessage({ password : $("#newpw").val() } )
				}
			}else{
				this.alertPop(res.data.error.replace(/\n/g, "<br/>"));
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();
		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version
			});
		}
		if(resObj.key == "getPw"){
			this.setState({
				opw : resObj.password
			});
		}
		if(resObj.key == "encryptBase64"){
			var mode = resObj.param1;
			if(mode == "old"){
				this.setState({
					oldpw : resObj.returnStr,
				});
			}
			if(mode == "new"){
				this.modifyPass(this.state.oldpw, resObj.returnStr);
			}
		}
		if(resObj.key == "pback"){
			this.pwChgCancel();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + posY) + "px");
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 비밀번호 변경 취소 팝업 -->*/}
			<div className="layer_dimm cancel_dimm" style={{display:"none"}}></div>
			<div className="prod_detail_pop" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>비밀번호 변경을 취소하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.cancel.bind(this)}>아니오</Link>
					<Link className="dgrey" to="/mycow/mycowMyInfo">네</Link>
				</div>
			</div>
			{/*<!-- 비밀번호 변경 취소 팝업 end -->*/}
			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>
			{/*<!-- 완료 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_close"><Link to="#" onClick={this.pwChgCancel.bind(this)}>닫기</Link></p>
				<h2>비밀번호 변경하기</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="memjoin_id_input">
				<h3 className="memjoin_input_ttl">새로운 비밀번호를 입력해주세요.</h3>
				<fieldset>
					<input className="memjoin_input_pw" id="newpw" name="newpw" placeholder="비밀번호(6~12자 영문 대/소문자,숫자,특수문자)" type="password" onChange={this.pwValChg.bind(this)} onFocus={this.onFocusHandle.bind(this,'newpw')} onBlur={this.onBlurHandle.bind(this,'newpw')}/>
					<p className="pw_wran pw_warn"></p>
					<input className="memjoin_input_pw" id="repw" name="repw" placeholder="비밀번호 확인" type="password" onChange={this.pwValChg.bind(this)} onFocus={this.onFocusHandle.bind(this,'repw')} onBlur={this.onBlurHandle.bind(this,'repw')}/>
					<p className="pw_wran pw_warn"></p>
				</fieldset>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			<p className="btn_login"><Link to="#" onClick={this.pwValid.bind(this)}>확인</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowMyPw);