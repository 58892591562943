import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import * as fnc from '../js/fnc.js';
class mycowMyPwChk extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),

			autoNoLogin: cookies.get('autoNoLogin'),

			uinfo: [],
			opw: "",
			focusChk : ""
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		const { cookies } = this.props;
		if(this.state.cidx === 0){
			cookies.set('loginAfterPath', '/mycow/mycowMyPwChk', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		try{
			if(global.variable.osType == 1){
				window.android.getPw();
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.getPw.postMessage("");
			}
		}catch(e){
		}

		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		$(window).scroll(function() {
			$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		});

		$("#pw").focus();

		if(global.variable.osType == 0){
			if ($('#pw').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}

		if(global.variable.osType == 1){
			window.android.setWebviewFocus();
		}
	}
	componentDidUpdate(){
	}
	pwValChg(){
		var pw = $("#pw").val();
		var opw = this.state.opw;
		if(pw == this.state.opw){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	pwValid = (e) =>{
		e.preventDefault();
		if(!$(".btn_login").hasClass("on")){
//			var focusChk = this.state.focusChk;
//			if(focusChk !== ""){
//				$("#"+focusChk).focus();
//			}
			return;
		}
		if($(".btn_login").hasClass("on")){
			document.location.href="/mycow/mycowMyPw";
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();
		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getPw"){
			//alert(resObj.password);
			this.setState({
				opw : resObj.password
			});
		}
		if(resObj.key == "pback"){
			document.location.href="/mycow/mycowMyInfo";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	goPwFind(){
		const { cookies } = this.props;
		cookies.set('originPage', '/mycow/mycowMyPwChk', { path: '/' });
		document.location.href="/member/findPw";
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + posY) + "px");
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">


			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="/mycow/mycowMyInfo">앞으로</Link></p>
				<h2>현재 비밀번호 입력</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="memjoin_id_input">
				<h3 className="memjoin_input_ttl">현재 비밀번호를 입력해주세요.</h3>
				<fieldset>
					<input className="memjoin_input_pw" id="pw" name="pw" placeholder="현재 비밀번호" type="password" onChange={this.pwValChg.bind(this)} onFocus={this.onFocusHandle.bind(this,'pw')} onBlur={this.onBlurHandle.bind(this,'pw')}/>
					<p className="btn_pw_find">
						<Link to="#" onClick={this.goPwFind.bind(this)}>비밀번호 찾기</Link>
					</p>
				</fieldset>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			<p className="btn_login"><Link to="#" onClick={this.pwValid.bind(this)}>다음</Link></p>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowMyPwChk);