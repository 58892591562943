import React from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import * as fnc from '../../js/fnc.js';

import rank_same from '../../img/main/rank_same.png';
import arrow_rank_u from '../../img/main/arrow_rank_u.png';
import arrow_rank_d from '../../img/main/arrow_rank_d.png';

class mainRankList extends React.Component {
	goDtlRank = (type, idx, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/main', { path: '/' });

		if(type === "event"){
			fnc.event_log("ranking_main", "ranking_main_click", "ranking_event");
			document.location.href="/mycow/mycowEventView/"+idx;
		}else if (type === "online"){
			fnc.event_log("ranking_main", "ranking_main_click", "ranking_lowes_price");
			document.location.href="/main/onlineMall/"+idx;
		}else if (type === "product"){
			fnc.event_log("ranking_main", "ranking_main_click", "ranking_saved");
			document.location.href="/main/productView/"+idx;
		}
	}
	render(){
		return (
			<div>
							<section className="main_cont_wrap">
								<h2 className="main_cont_ttl">
									{this.props.rinfo.name}
									<p className="rank_stand_date">{this.props.rinfo.base_date} 기준</p>
								</h2>
								<div className="main_cont_inner">
									<div className="rank_prod_list">
										<ul>
											{this.props.list.map((rankdetail, i) =>
											<li key={i}>
												{
												rankdetail.type === "reco" &&
												<div className="rank_prod_stat">
													<p className="recom"></p>
													<span>추천</span>
												</div>
												}
												{
												rankdetail.type === "rank" &&
												<div className="rank_prod_stat">
													<p className={"badge0"+rankdetail.ranking}>{rankdetail.ranking}</p>
													{
													rankdetail.fluctuation === "new" &&
													<span className="new">NEW!</span>
													}
													{
													rankdetail.fluctuation === "same" &&
													<span className="rank_same"><img alt="" src={rank_same} /></span>
													}
													{
													rankdetail.fluctuation === "up" &&
													<span className="rank_u"><img alt="" src={arrow_rank_u} />{rankdetail.f_range}</span>
													}
													{
													rankdetail.fluctuation === "down" &&
													<span className="rank_d"><img alt="" src={arrow_rank_d} />{rankdetail.f_range}</span>
													}
												</div>
												}
												<figure><img alt="" src={rankdetail.r_thumb} /></figure>
												<div className="txt_wrap">
													<strong className="prod_name">{rankdetail.goods_name}</strong>
													<span className="prod_comp">{rankdetail.brand_name}</span>
												</div>
												{
												(rankdetail.link_type === "event") &&
												<Link className="link_btn" to="#" onClick={this.goDtlRank.bind(this, rankdetail.link_type, rankdetail.link_idx)}>이벤트<br />바로가기</Link>
												}
												{
												(rankdetail.link_type === "online") &&
												<Link className="link_btn" to="#" onClick={this.goDtlRank.bind(this, rankdetail.link_type, rankdetail.link_idx)}>최저가<br />구매하기</Link>
												}
												{
												(rankdetail.link_type === "product") &&
												<Link className="link_btn" to="#" onClick={this.goDtlRank.bind(this, rankdetail.link_type, rankdetail.link_idx)}>적립금<br />받기</Link>
												}
											</li>
											)}
										</ul>
									</div>
								</div>
							</section>
							{/*<!--
							<div className="main_hot_keyword">
								<strong>HOT KEYWORD</strong>
								<ul>
									{this.state.hotks.map((hotk, i) =>
									<li key={i} className="rankHotk" idx={hotk.rkidx} onClick={this.keywordChange.bind(this, hotk.rkidx)}><Link to="#">#{hotk.keyword}</Link></li>
									)}
								</ul>
							</div>
							-->*/}
			</div>
		)
	}
}

export default withCookies(mainRankList);