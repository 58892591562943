import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import axios from 'axios';

import MainProductCategorySub from './comp/mainProductCategorySub';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import iconTime from "../img/main/icon_time.png";
import accum_done from "../img/main/accum_done.png";

class mainProductCategoryView extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;
		const cid = props.match.params.id;

		var userAgent=navigator.userAgent.toLowerCase();

		const cidx = cookies.get('cidx') == undefined ? 0 : cookies.get('cidx');
		const apiurl = cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl');

		this.state = {
			pproducts: [],
			pproductCnt: 0,
			cidx: cidx,
			apiurl: apiurl,
			version: cookies.get('version'),
			adult: cookies.get('adult'),
			config : global.variable.config,
			cid : cid
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
		$('html, body').scrollTop(0);
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html, body').scrollTop(0);
		$("body").addClass("gbg");

		const url = this.state.apiurl+"/product/list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("category", this.state.cid);
		formData.append("sort", "recent_registeration");
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("product_category_view");
			//console.log(res.data.data.count);
			//console.log(res.data.data.list);
			this.setState({
				pproducts : res.data.data.list,
				pproductCnt : res.data.data.count
			});
			$('html, body').scrollTop(0);
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	orderChg = (sort, e) => {
		const url = this.state.apiurl+"/product/list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("category", this.state.cid);
		formData.append("sort", sort);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				pproducts : res.data.data.list,
				pproductCnt : res.data.data.count
			});

			$(".pCateOrderBtn").find("a").removeClass("on");
			if(sort === 'recent_registeration') $(".pCateOrderBtn").find("a").eq(0).addClass("on");
			if(sort === 'most_purchased') $(".pCateOrderBtn").find("a").eq(1).addClass("on");
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goProductView = (idx, e) =>{
		const { cookies } = this.props;
		cookies.set('mainTab', '2', { path: '/' });
		cookies.set('originPage', '/main/mainProductCategoryView/'+this.state.cid, { path: '/' });
		document.location.href = "/main/productView/"+idx;
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop( $("#posy").val() );
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		if(cName === "login_need"){
			$(".common_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".common_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "age_limit"){
			$(".age_limit, .age_limit_dimm").fadeOut()
		}
		if(cName === "cert_need"){
			$(".cert_need, .cert_need_dimm").fadeOut()
		}
		if(cName === "recert_need"){
			$(".recert_need, .recert_need_dimm").fadeOut()
		}
	}
	goLogin(){
		const { cookies } = this.props;
		cookies.set('loginAfterPath', "/main/mainProductCategoryView/"+this.state.cid, { path: '/' });
		document.location.href="/member/login";
	}
	goAuth = (mode, e) => {
		if(global.variable.osType == 1){
			window.android.getIdentity(this.state.cidx, "url", "param1", "param2", "param3", "param4", mode, this.state.uemail);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.getIdentity.postMessage({ cidx : this.state.cidx, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : mode, userid : this.state.uemail } );
			return;
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;
			if(error == undefined){
				cookies.set('adult', "Y", { path: '/' });
				document.location.href="/main/mainProductCategoryView/"+this.state.cid;
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					this.alertPop( (resObj.result_message.error).replace(/\$\$/g, '\n') );
				}
			}
		}
		if(resObj.key == "pback"){
			document.location.href = "/main/mainProductCategory";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionEnter={false}
		transitionLeave={false}
		transitionAppearTimeout={500}
		transitionEnterTimeout={500}
		transitionLeaveTimeout={500}>
		{/*<!-- header 영역 -->*/}
		<header className="mem_header cate">
			<p className="btn_prev btn_prev1"><Link to="/main/mainProductCategory">앞으로</Link></p>
			<h2>상품 카테고리</h2>
		</header>
		{/*<!-- header 영역 end -->*/}
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 로그인 필요 팝업 -->*/}
			<div className="layer_dimm login_need_dimm"></div>
			<div className="common_pop login_need">
				<div className="common_pop_txt">
					<p>로그인이 필요한 서비스입니다.<br/>로그인 페이지로 이동하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'login_need')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goLogin.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 로그인 필요 팝업 end -->*/}

			{/*<!-- 미성년자 사용 불가 팝업 -->*/}
			<div className="layer_dimm age_limit" style={{display:"none"}}></div>
			<div className="common_pop age_limit" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p className="emphasis">미성년자 사용 불가 서비스 입니다.</p>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'age_limit')}>확인</Link>
			</div>
			{/*<!-- 미성년자 사용 불가 팝업 end -->*/}

			{/*<!-- 본인인증 필요 팝업 -->*/}
			<div className="layer_dimm cert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop cert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						본인(성인) 인증이 필요한 서비스입니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'cert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'adult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 필요 팝업 end -->*/}

			{/*<!-- 본인인증 재인증 필요 팝업 -->*/}
			<div className="layer_dimm recert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop recert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						성인 인증 후 1년이 경과되었습니다.<br/>
						관련 법에 따라 재인증이 필요합니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'recert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'readult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 재인증 필요 팝업 end -->*/}

			<div className="prod_result_pop_wrap prod_result_pop_wrap1">
				<div className="prod_result_pop_bg"></div>
				{/*<!-- 내용 영역 -->*/}
				<div className="prod_cate_search_result prod_cate_only">
					<div className="prod_cate_search_top">
						<p className="num">상품 <span>{this.state.pproductCnt}</span>건</p>
						<div className="btns cate_page pCateOrderBtn">
							<Link className="on" to="#" onClick={this.orderChg.bind(this, 'recent_registeration')}>최근 등록순</Link>
							<Link to="#" onClick={this.orderChg.bind(this, 'most_purchased')}>최다 구매순</Link>
						</div>
					</div>
					<div className="main_cont_inner">
						<div className="serv_prod_list">
							<ul>
							{this.state.pproducts.map((pproduct, index) =>
								<li key={index}>
									{
									pproduct.check_adult === true && this.state.adult !== "Y" &&
									<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
										<div className="age_limit_dimm"></div>
										<p>미성년자 불가 상품</p>
									</div>
									}
									{
									pproduct.limit_type === "day" && pproduct.remain_trial === 0 &&
									<div className="accum_done_cover">
										<img alt="" src={accum_done} />
										<div className="accum_done_dimm"></div>
									</div>
									}
									{
									pproduct.limit_type === "season" && pproduct.remain_trial === 0 &&
									<div className="accum_done_cover">
										<img alt="" src={accum_done} />
										<div className="accum_done_dimm"></div>
									</div>
									}
									<Link to="#" onClick={this.goProductView.bind(this, pproduct.ccidx)}>
										<div className="serv_prod_info">
											<figure><img alt="" src={pproduct.image} /></figure>
											<div className="txt">
												{pproduct.reward_method === "F" && <strong>{pproduct.reward}P</strong>}
												{pproduct.reward_method === "R" && <strong>{pproduct.reward}%</strong>}
												<p>{pproduct.name}</p>
											</div>
										</div>
										<div className="serv_prod_tags">
											<ul>
												{pproduct.limit_type === 0 && <li><span>무제한</span></li>}
												{pproduct.limit_type === "none" && <li><span>무제한</span></li>}
												{pproduct.limit_type === "day" && <li><span>하루 {pproduct.daily_limit - pproduct.remain_trial}/{pproduct.daily_limit}</span></li>}
												{pproduct.limit_type === "season" &&<li><span>기간 {pproduct.seasonal_limit - pproduct.remain_trial}/{pproduct.seasonal_limit}</span></li>}
											</ul>
											{
												pproduct.condition_count > 0 && 
												<MainProductCategorySub list={pproduct.condition_list}/>
											}
											{
											!(pproduct.check_adult && this.state.adult !== "Y") && pproduct.near_end &&
											<div className="period_warn">
												<img alt="" src={iconTime} />
												적립 기한이 곧 종료됩니다.
												<div className="period_warn_bg"></div>
											</div>
											}
										</div>
									</Link>
								</li>
							)}
							</ul>
						</div>
					</div>
				</div>
				{/*<!-- 내용 영역 end -->*/}
			</div>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(mainProductCategoryView);