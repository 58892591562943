import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import * as fnc from '../../js/fnc.js';

import dc_arrow from '../../img/main/dc_arrow.png';

class mainHomeLowPrice extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			adult: cookies.get('adult'),

			onlinelows: []
		};
	}
	componentDidMount(){
		this.init();
	}
	componentDidUpdate(){
		$(".lp_prod_list").find(".lp_cate_icon").each(function(idx){
			if($(this).find("p").length > 4){
				$(this).find("p:gt(3)").remove();
			}
		});
	}
	init(){
		const url = this.state.apiurl+"/init/online_product.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				onlinelows : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);

		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}		
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	goView = (idx, e) => {
		fnc.event_log("home_main", "home_main_click", "list_online_lowest");
		document.location.href = "/main/onlineMall/"+idx;
	}
	render(){
		const paramsLoop = {
			loop: true,
			loopFillGroupWithBlank: true,
		}
		return (
		<section className="main_cont_wrap">
			<h2 className="main_cont_ttl">온라인 최저가</h2>
			<div className="main_cont_inner">
				<div className="lp_prod_list">
					<ul>
						{this.state.onlinelows.map((onlinelow, i)=>
						<li key={i}>
							{
							onlinelow.check_adult === 1 && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							{
							onlinelow.type === "S" &&
							<Link to="#" onClick={this.goView.bind(this, onlinelow.omidx)}>
								<figure><img alt="" src={onlinelow.opimage} /></figure>
								<div className="txt_wrap dc">
									<strong className="prod_name">{/*onlinelow.type*/}{onlinelow.opname}</strong>
									<span className="prod_comp">{onlinelow.omname}</span>
									{
									onlinelow.reward_method === "F" && onlinelow.dc_ratio > 0 &&
									<span className="prod_info">할인 {onlinelow.dc_ratio}% + {onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "F" && onlinelow.dc_ratio === 0 &&
									<span className="prod_info">{onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "R" && onlinelow.dc_ratio > 0 &&
									<span className="prod_info">할인 {onlinelow.dc_ratio}% + {onlinelow.reward}% 적립</span>
									}
									{
									onlinelow.reward_method === "R" && onlinelow.dc_ratio === 0 &&
									<span className="prod_info">{onlinelow.reward}% 적립</span>
									}
									<div className="dc_price">
										<p className="origin">{onlinelow.org_price}</p>
										<img alt="" src={dc_arrow} />
										<p>{onlinelow.dc_price}</p>
									</div>
								</div>
							</Link>
							}
							{
							onlinelow.type === "A" &&
							<Link to="#" onClick={this.goView.bind(this, onlinelow.omidx)}>
								<figure><img alt="" src={onlinelow.omlogo} /></figure>
								<div className="txt_wrap">
									<strong className="prod_name">{/*onlinelow.type*/}{onlinelow.opname}</strong>
									{
									onlinelow.reward_method === "F" &&
									<span className="prod_info">전품목 {onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "R" &&
									<span className="prod_info">전품목 {onlinelow.reward}% 적립</span>
									}
								<div className="lp_cate_icon">
										<p className="yellow"><span>{(onlinelow.ocgname).substr(0, 5)}</span></p>
										{onlinelow.guide_01 != "" && <p><span>{(onlinelow.guide_01).substr(0, 5)}</span></p>}
										{onlinelow.guide_02 != "" && <p><span>{(onlinelow.guide_02).substr(0, 5)}</span></p>}
										{onlinelow.guide_03 != "" && <p><span>{(onlinelow.guide_03).substr(0, 5)}</span></p>}
										{/*onlinelow.guide_04 != "" && <p><span>{(onlinelow.guide_04).substr(0, 5)}</span></p>*/}
										{/*onlinelow.guide_05 != "" && <p><span>{(onlinelow.guide_05).substr(0, 5)}</span></p>*/}
									</div>
								</div>
							</Link>
							}
							{
							onlinelow.type === "G" &&
							<Link to="#" onClick={this.goView.bind(this, onlinelow.omidx)}>
								<figure><img alt="" src={onlinelow.omlogo} /></figure>
								<div className="txt_wrap">
									<strong className="prod_name">{/*onlinelow.type*/}{onlinelow.opname}</strong>
									{
									onlinelow.reward_method === "F" &&
									<span className="prod_info">전품목 {onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "R" &&
									<span className="prod_info">전품목 {onlinelow.reward}% 적립</span>
									}
									<div className="lp_cate_icon">
										<p className="yellow"><span>{(onlinelow.ocgname).substr(0, 5)}</span></p>
										{onlinelow.guide_01 != "" && <p><span>{(onlinelow.guide_01).substr(0, 5)}</span></p>}
										{onlinelow.guide_02 != "" && <p><span>{(onlinelow.guide_02).substr(0, 5)}</span></p>}
										{onlinelow.guide_03 != "" && <p><span>{(onlinelow.guide_03).substr(0, 5)}</span></p>}
										{/*onlinelow.guide_04 != "" && <p><span>{(onlinelow.guide_04).substr(0, 5)}</span></p>*/}
										{/*onlinelow.guide_05 != "" && <p><span>{(onlinelow.guide_05).substr(0, 5)}</span></p>*/}
									</div>
								</div>
							</Link>
							}
							{
							onlinelow.type === "B" &&
							<Link to="#" onClick={this.goView.bind(this, onlinelow.omidx)}>
								<figure><img alt="" src={onlinelow.opimage} /></figure>
								<div className="txt_wrap dc">
									<strong className="prod_name">{/*onlinelow.type*/}{onlinelow.opname}</strong>
									<span className="prod_comp">{onlinelow.omname}</span>
									{
									onlinelow.reward_method === "F" && onlinelow.dc_ratio > 0 &&
									<span className="prod_info">할인 {onlinelow.dc_ratio}% + {onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "F" && onlinelow.dc_ratio === 0 &&
									<span className="prod_info">{onlinelow.reward}P 적립</span>
									}
									{
									onlinelow.reward_method === "R" && onlinelow.dc_ratio > 0 &&
									<span className="prod_info">할인 {onlinelow.dc_ratio}% + {onlinelow.reward}% 적립</span>
									}
									{
									onlinelow.reward_method === "R" && onlinelow.dc_ratio === 0 &&
									<span className="prod_info">{onlinelow.reward}% 적립</span>
									}
									<div className="dc_price">
										<p className="origin">미공개</p>
										<img alt="" src={dc_arrow} />
										<p>미공개</p>
									</div>
								</div>
							</Link>
							}
						</li>
						)}
					</ul>
				</div>
			</div>
			<button name="homeLowPrice" id="homeLowPrice" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
		</section>
		)
	}
}

export default withCookies(mainHomeLowPrice);