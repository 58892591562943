import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import '../css/style_mem.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import arrow_small_g from '../img/common/arrow_small_g.png';

class join extends React.Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uuid: cookies.get('uuid'),
			model: cookies.get('model'),

			term: [],
			token: "",
			model: "",
			uuidChkRlt: true,
			savedUserId: ""
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/member/join");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/member/join"});}
		}catch(e){}
	}

	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/member/join");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/member/join"});}
		}catch(e){}
		fnc.event_log("signup_agree", "", "");
		const { cookies } = this.props;
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		var agree1 = cookies.get("agree1");
		var agree2 = cookies.get("agree2");
		var agree3 = cookies.get("agree3");
		var agree4 = cookies.get("agree4");
		if(agree1 === "checked"){
			$("#agree1").prop("checked", true);
			cookies.set("agree1", "", "/");
		}
		if(agree2 === "checked"){
			$("#agree2").prop("checked", true);
			cookies.set("agree2", "", "/");
		}
		if(agree3 === "checked"){
			$("#agree3").prop("checked", true);
			cookies.set("agree3", "", "/");
		}
		if(agree4 === "checked"){
			$("#agree4").prop("checked", true);
			cookies.set("agree4", "", "/");
		}
		this.termChk();


		if(global.variable.osType == 1){
			try{window.android.getId();}catch(e){console.log("pc");}
		}
		if(global.variable.osType == 0){
			try{window.webkit.messageHandlers.getId.postMessage("");}catch(e){console.log("pc");}
		}

		$(document).on("click", ".btn_login > a", function(){
			if( ($(this).parent()).hasClass("on") ){
				$(".layer_dimm").eq(0).show();
				$('html, body').addClass('hidden');
			}
		});

		var url = this.state.apiurl+"/signup/uuid_check.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("uuid", this.state.uuid);
		formData.append("osver", "10.0");
		formData.append("device_model", this.state.model);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.error != undefined){
				this.setState({
					savedUserId : res.data.error,
					uuidChkRlt : false
				})
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
		url = this.state.apiurl+"/guide/tos.json";
		formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "service");
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			//console.log("agree_service");
			this.setState({term : res.data.data}); 
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		$(".existing_id").css("top", Math.max(0, (($(window).height() - 723) / 2) + $(window).scrollTop()) + "px");
	}

	layCls = (e) => {
		$(".policy_layer_bg").fadeOut("fast");
		$(".policy_layer_cont").fadeOut("fast");
	}
	
	gotoPage = (e) => {
		e.preventDefault();
		var cnt = 0;
		if( $("#agree1").prop("checked") ) cnt = cnt+1;
		if( $("#agree2").prop("checked") ) cnt = cnt+1;
		if( $("#agree3").prop("checked") ) cnt = cnt+1;
		if( $("#agree4").prop("checked") ) cnt = cnt+1;
 		if(cnt === 4){
			fnc.event_log("signup_input", "", "");
			document.location.href="/member/joinMeminfo";
		}
	}

	termAllChk = (e) => {
		const { cookies } = this.props;
		if($("#agreeAll").prop("checked") === true){
			$("#agree1").prop("checked", true);
			$("#agree2").prop("checked", true);
			$("#agree3").prop("checked", true);
			$("#agree4").prop("checked", true);
			cookies.set("agree1", "checked", "/");
			cookies.set("agree2", "checked", "/");
			cookies.set("agree3", "checked", "/");
			cookies.set("agree4", "checked", "/");
		}else{
			$("#agree1").prop("checked", false);
			$("#agree2").prop("checked", false);
			$("#agree3").prop("checked", false);
			$("#agree4").prop("checked", false);
			cookies.set("agree1", "", "/");
			cookies.set("agree2", "", "/");
			cookies.set("agree3", "", "/");
			cookies.set("agree4", "", "/");
		}
		var cnt = 0;
		if( $("#agree1").prop("checked") ) cnt = cnt+1;
		if( $("#agree2").prop("checked") ) cnt = cnt+1;
		if( $("#agree3").prop("checked") ) cnt = cnt+1;
		if( $("#agree4").prop("checked") ) cnt = cnt+1;
 		if(cnt === 4){
			$(".btn_login").addClass("on");
		}else{
			$(".btn_login").removeClass("on");
		}
	}
	termChk = (e) => {
		const { cookies } = this.props;

		var cnt = 0;
		if( $("#agree1").prop("checked") ){
			cookies.set("agree1", "checked", "/");
			cnt = cnt+1;
		}else{
			cookies.set("agree1", "", "/");
		}
		if( $("#agree2").prop("checked") ){
			cookies.set("agree2", "checked", "/");
			cnt = cnt+1;
		}else{
			cookies.set("agree2", "", "/");
		}
		if( $("#agree3").prop("checked") ){
			cookies.set("agree3", "checked", "/");
			cnt = cnt+1;
		}else{
			cookies.set("agree3", "", "/");
		}
		if( $("#agree4").prop("checked") ){
			cookies.set("agree4", "checked", "/");
			cnt = cnt+1;
		}else{
			cookies.set("agree4", "", "/");
		}
 		if(cnt === 4){
			$(".btn_login").addClass("on");
			$("#agreeAll").prop("checked", true);
		}else{
			$(".btn_login").removeClass("on");
			$("#agreeAll").prop("checked", false);
		}
	}
	alertPop = (msg, e) => {
		$(".alertPop").find("p").html(msg);
		$(".alertPop").show();
	}
	alertCls = (e) => {
		//$(".alertPop").hide("fast");
		document.location.href="/member/login";
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set("agree1", "", "/");
		cookies.set("agree2", "", "/");
		cookies.set("agree3", "", "/");
		cookies.set("agree4", "", "/");
		document.location.href="/member/login";
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "getId"){
			this.setState({
				savedUserId : resObj.userid
			});
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			{this.state.uuidChkRlt === true && <div className="layer_dimm alertPop" style={{display:"none"}}></div>}
			{this.state.uuidChkRlt === true &&
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			}
			{this.state.uuidChkRlt === false && <div className="layer_dimm alertPop" style={{display:"block"}}></div>}
			{this.state.uuidChkRlt === false &&
			<div className="common_pop h723 existing_id" style={{display:"block"}}>
				<Link className="close_pop" to="#" onClick={this.alertCls.bind(this)}>닫기</Link>
				<div className="common_pop_txt h623">
					<div className="exst_id_warn">
						기존에 사용하시던 아이디 <br/>
						(<span className="orange">{this.state.savedUserId}</span>)가 존재합니다. <br/>
						본인의 아이디가 아닌 경우 <br/>
						help@cash-cow.co.kr 로 문의 해주세요.
						<a href="mailto:help@cash-cow.co.kr" className="btn_pop_inquiry mt80">문의하기<img alt="" src={arrow_small_g}/></a>
					</div>
				</div>
				<Link className="pop_ok" to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			}
			{/*<!-- alert 팝업 end -->*/}

			<div className="policy_layer_bg" style={{display:"none"}}></div>
			<div className="policy_layer_cont" id="AP1" style={{display:"none"}} >
				<header className="mem_header">
					<p className="btn_prev"><Link to="#" onClick={this.layCls.bind(this)}>앞으로</Link></p>
					<h2>{this.state.term.title}</h2>
				</header>
 				<section dangerouslySetInnerHTML={{__html : this.state.term.contents}}></section>
			</div>

			<header className="mem_header">
				<p className="btn_prev"><Link to="#" className="backCls" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
				<h2>회원가입</h2>
			</header>
			<div id="content">
				<div className="memjoin_greet_wrap">
					<strong>환영합니다.</strong>
					<p>회원가입을 위해 약관에 동의해주세요.</p>
				</div>
				<div className="memjoin_policy_agree">
					<div className="policy_agree_all">
						<input id="agreeAll" name="" type="checkbox" onChange={this.termAllChk.bind(this)}/>
						<label htmlFor="agreeAll">모두 동의</label>
					</div>
					<div className="policy_agree_each">
						<Link className="policy_link" to="/member/terms/service">서비스 이용약관 동의<br />(혜택안내 및 광고 수신동의 포함)</Link>
						<div className="agree_each">
							<input id="agree1" name="" type="checkbox" onChange={this.termChk.bind(this)}/>
							<label htmlFor="agree1">동의</label>
						</div>
					</div>
					<div className="policy_agree_each">
						<Link className="policy_link" to="/member/terms/privacy">개인정보처리방침 동의</Link>
						<div className="agree_each">
							<input id="agree2" name="" type="checkbox" onChange={this.termChk.bind(this)}/>
							<label htmlFor="agree2">동의</label>
						</div>
					</div>
					<div className="policy_agree_each">
						<Link className="policy_link" to="/member/terms/location">위치기반서비스 이용약관 동의</Link>
						<div className="agree_each">
							<input id="agree3" name="" type="checkbox" onChange={this.termChk.bind(this)}/>
							<label htmlFor="agree3">동의</label>
						</div>
					</div>
					<div className="policy_agree_each">
						<Link className="policy_link" to="/member/terms/point">포인트 이용약관 동의</Link>
						<div className="agree_each">
							<input id="agree4" name="" type="checkbox" onChange={this.termChk.bind(this)}/>
							<label htmlFor="agree4">동의</label>
						</div>
					</div>
					<p className="policy_agree_warn">1인당 1개의 계정만 이용 가능합니다. <br />반드시 본인 명의의 휴대폰을 이용하세요. <br />타인의 개인정보를 도용하여 가입할 경우, 서비스 이용 제한이 있을 수 있습니다.</p>
				</div>
			</div>
			<p className="btn_login"><Link to="#" onClick={this.gotoPage.bind(this)}>다음</Link></p>
			<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(join);