import React from 'react';
import { Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import $ from 'jquery';

class receiptStoreSub extends React.Component {
	componentDidUpdate(){
		$(".brandLists").each(function(){
			if($(this).html() == ""){
				$(this).remove();
			}
		});
	}
	test2(idx, name, mode, e){
		this.props.onClick(idx, name, mode);
	}
	goPage = (url, e) => {
		const { cookies } = this.props;
		cookies.set('receiptStoreST', $(window).scrollTop(), { path: '/' });

		document.location.href = url;
	}
	render(){
		const bcidx = this.props.bcidx;
		return (
			<ul>
				{this.props.list.map((item, i) =>
					<li className="brandLists" key={i}>
					{
					item.bcidx === bcidx &&
					<div>
						<Link to="#" onClick={this.goPage.bind(this, "/receipt/receiptGuide/"+item.bidx)}>
							<figure><img alt="" src={item.thumb_1} style={{height:"42px"}}/></figure>
							<p className="name">{item.name}</p>
							<div className="recpt_class">
								{item.e_receipt === true && <p className="up"><span>전자영수증</span></p>}
								{item.p_receipt === true && <p className="down"><span>종이영수증</span></p>}
							</div>
						</Link>
						{item.is_favorite === true && <p className="star on" data-idx={item.bidx} onClick={this.test2.bind(this, item.bidx, item.name, 'on')}></p>}
						{item.is_favorite === false && <p className="star" data-idx={item.bidx} onClick={this.test2.bind(this, item.bidx, item.name, 'no')}></p>}
					</div>
					}
					</li>
				)}
			</ul>
		)
	}
}

export default withCookies(receiptStoreSub);