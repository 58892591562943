import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import * as fnc from '../js/fnc.js';
class mycowTerms extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),

			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin')
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_terms", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowTerms', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");
	}
	componentDidUpdate(){
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycow";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){

		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
			<h2>이용약관</h2>
		</header>
		<div id="wrap">
			{/*<!-- header 영역 -->*/}
			{/*<header className="mem_header">
				<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
				<h2>이용약관</h2>
			</header>*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="policy_list_wrap">
				<ul>
					<li>
						<Link to={"/mycow/mycowTermsView/service"}>
							<p>서비스 이용약관<span>(혜택안내 및 광고 수신동의 포함)</span></p>
						</Link>
					</li>
					<li>
						<Link to={"/mycow/mycowTermsView/privacy"}>
							<p>개인정보 처리방침</p>
						</Link>
					</li>
					<li>
						<Link to={"/mycow/mycowTermsView/location"}>
							<p>위치기반서비스 이용약관</p>
						</Link>
					</li>
					<li>
						<Link to={"/mycow/mycowTermsView/point"}>
							<p>포인트 이용약관</p>
						</Link>
					</li>
					<li>
						<Link to={"/mycow/mycowTermsView/mall"}>
							<p>카우몰 이용약관</p>
						</Link>
					</li>
					<li>
						<Link to={"/mycow/mycowTermsView/storead"}>
							<p>광고서비스 이용약관</p>
						</Link>
					</li>
				</ul>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowTerms);