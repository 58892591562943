import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Gnb from '../common/gnb';
import PushLayer from '../common/pushLayer';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import spinner from '../img/common/spinner.gif';
import btn_use_point from '../img/mycow/btn_use_point.png';
import mycow01 from '../img/mycow/icon_mycow01.png';
import mycow02 from '../img/mycow/icon_mycow02.png';
import mycow03 from '../img/mycow/icon_mycow03.png';
import mycow04 from '../img/mycow/icon_mycow04.png';
import mycow05 from '../img/mycow/icon_mycow05.png';
import mycow06 from '../img/mycow/icon_mycow06.png';
import mycow07 from '../img/mycow/icon_mycow07.png';
import mycow08 from '../img/mycow/icon_mycow08.png';
import mycow09 from '../img/mycow/icon_mycow09.png';
import mycow10 from '../img/mycow/icon_mycow10.png';

class mycow extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),
			mycowST: cookies.get('mycowST') == undefined ? 0 : ~~cookies.get('mycowST'),

			pinfo: [],
			pt1:0,
			pt2:10000,
			pt3:20000,
			pt4:30000,
			pt5:40000,
			grateNo:0,
			grate:0,
			grate2:0,
			nowPoint : 0,
			tobePoint : 0,
			expirePoint : 0
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/mycow/mycow");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/mycow/mycow"});}
		}catch(e){}
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/mycow/mycow");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/mycow/mycow"});}
		}catch(e){}
		fnc.event_log("mycow_main", "", "");
		const { cookies } = this.props;
		if(this.state.mycowST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowST);
				_this.setState({mycowST : 0});
				cookies.set('mycowST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			});
		}
		
		this.init();
	}
	componentDidUpdate(){
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		const { cookies } = this.props;
		if(this.state.cidx === 0){
			cookies.set('loginAfterPath', '/mycow/mycow', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").addClass("gbg");

		$('html, body').css({
			'overflow':'visible'
		});

		var url = this.state.apiurl+"/user/point_history.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "all");
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("point_info");
			//console.log(res.data.data);

			this.setState({
				pinfo : res.data.data,
				nowPoint : res.data.data.reward_total - res.data.data.usage_total - res.data.data.expire_total,
				tobePoint : res.data.data.reward_tobe,
				expirePoint : res.data.data.expire_tobe
			});

			var totalPt = res.data.data.reward_total - res.data.data.usage_total - res.data.data.expire_total;
			if(totalPt>40000){
				this.setState({
					pt1 : Math.floor(totalPt/10000)*10000-20000,
					pt2 : Math.floor(totalPt/10000)*10000-10000,
					pt3 : Math.floor(totalPt/10000)*10000,
					pt4 : Math.floor(totalPt/10000)*10000+10000,
					pt5 : Math.floor(totalPt/10000)*10000+20000,
					grateNo : (( totalPt - Math.floor(totalPt/10000)*10000 + 20000 )/400),
					grate : (( totalPt - Math.floor(totalPt/10000)*10000 + 20000 )/400)+"%",
					grate2 : (( totalPt - Math.floor(totalPt/10000)*10000 + 20000 )/400) * 6.15 + "px"
				});
			}else{
				this.setState({
					grateNo : (totalPt/400),
					grate : (totalPt/400)+"%",
					grate2 : (totalPt/400) * 6.15 + "px"
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		url = this.state.apiurl+"/user/request_recommend_url.json";
		formData = new FormData();
		formData.append('os', global.variable.osType);
		formData.append('appver', this.state.version);
		formData.append('applocale', 'kr');
		formData.append('bypass_emergency', 0);
		formData.append('cidx', this.state.cidx);
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			cookies.set('inviteUrl', res.data.data.shorturl, { path: '/' });
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		url = this.state.apiurl+"/user/info.json";
		formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval3 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval3);
			this.setState({
				newEvent : res.data.data.new_event,
				newNotice : res.data.data.new_notice
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/mycow/mycow', { path: '/' });
		if(page === "point"){
			document.location.href="/point/point";
		}else if(page === "usePoint"){
			cookies.set('mycowST', $(window).scrollTop(), { path: '/' });
			document.location.href= "https://devmall.v7.cash-cow.co.kr?cidx="+this.state.cidx+"&appver="+this.state.version;
		}else{
			cookies.set('mycowST', $(window).scrollTop(), { path: '/' });
			document.location.href="/mycow/"+page;
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			var originPage = this.state.originPage;
			cookies.set('originPage', '', { path: '/' });

			if(originPage === "/mycow/mycow"){
				document.location.href = "/main";
			}else{
				document.location.href = ( originPage == "" || originPage == null || originPage == undefined ? "/main" : originPage);
			}
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<h2>마이 카우</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 알림 설정 팝업 -->*/}
			<div className="layer_dimm push_on_plz_dimm" style={{display:"none"}}></div>
			<PushLayer/>
			{/*<!-- 알림 설정 팝업 end -->*/}


			{/*<!-- header 영역 -->*/}
			{/*<!-- <header className="mem_header">
				<h2>마이 카우</h2>
			</header> -->*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="pt0" id="content">
				<div className="mycow_point_top">
					<strong className="mycow_email">{this.state.uemail}</strong>
					<div className="mycow_point_ybg" onClick={this.goPage.bind(this, 'point')}>
						<div className="mycow_point_txt">
							<Link to="#">나의 포인트</Link>
							<strong>{fnc.comma(this.state.nowPoint)}P</strong>
						</div>
						<div className="expt_point_wrap">
							<dl>
								<dt>적립예정</dt>
								<dd>{fnc.comma(this.state.tobePoint)}P</dd>
							</dl>
							<dl>
								<dt>소멸예정</dt>
								<dd>{fnc.comma(this.state.expirePoint)}P</dd>
							</dl>
							<p>(이달 말일 소멸)</p>
						</div>
					</div>
					<div className="mycow_point_graph">
						{this.state.grateNo > 0 && <div className="point_chk_past past01"></div>}
						{this.state.grateNo > 25 && <div className="point_chk_past past02"></div>}
						{this.state.grateNo > 50 && <div className="point_chk_past past03"></div>}
						{this.state.grateNo > 75 && <div className="point_chk_past past04"></div>}
						{this.state.grateNo > 100 && <div className="point_chk_past past05"></div>}
						<div className="point_chk_now now03" style={{left:this.state.grate2}}></div>
						<div className="mycow_point_rail">
							<div className="mycow_point_bar" style={{width:this.state.grate}}></div>
						</div>
						<div className="point_measure">
							<p className="m01">{this.state.pt1}</p>
							<p className="m02">{this.state.pt2}</p>
							<p className="m03">{this.state.pt3}</p>
							<p className="m04">{this.state.pt4}</p>
							<p className="m05">{this.state.pt5}</p>
						</div>
					</div>
					<p className="btn_use_point" onClick={this.goPage.bind(this, 'usePoint')}><i>포인트 사용하기</i></p>
				</div>
				<div className="grey_gap"></div>
				<div className="prod_cate_list">
					<ul>
						<li onClick={this.goPage.bind(this, 'mycowMyinfo')}><img alt="" src={mycow01}/>계정관리</li>
						<li onClick={this.goPage.bind(this, 'mycowInvite')}><img alt="" src={mycow02}/>친구초대</li>
						<li onClick={this.goPage.bind(this, 'mycowDonate')}><img alt="" src={mycow03}/>기부내역</li>
						<li onClick={this.goPage.bind(this, 'mycowPset')}><img alt="" src={mycow04}/>알림설정</li>
					</ul>
				</div>
				<div className="grey_gap"></div>
				<div className="prod_cate_list">
					<ul>
						<li onClick={this.goPage.bind(this, 'mycowVersion')}><img alt="" src={mycow05}/>버전정보</li>
						<li onClick={this.goPage.bind(this, 'mycowNotice')}><img alt="" src={mycow06}/>{this.state.newNotice === true && <i></i>}공지사항</li>
						<li onClick={this.goPage.bind(this, 'mycowEvent')}><img alt="" src={mycow07}/>{this.state.newEvent === true && <i></i>}이벤트</li>
						<li onClick={this.goPage.bind(this, 'mycowTerms')}><img alt="" src={mycow08}/>이용약관</li>
					</ul>
				</div>
				<div className="grey_gap"></div>
				<div className="prod_cate_list">
					<ul>
						<li onClick={this.goPage.bind(this, 'mycowInFaq')}><img alt="" src={mycow09}/>문의하기&FAQ</li>
						<li onClick={this.goPage.bind(this, 'mycowManual')}><img alt="" src={mycow10}/>캐시카우 사용법</li>
					</ul>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
			{/*<!-- <Gnb/> -->*/}
		</div>
		<Gnb/>
		<button name="posy" id="posy" style={{display:"none"}}></button>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycow);