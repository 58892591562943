import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class pushLayer extends React.Component {
	render(){
		return (
			<div className="prod_detail_pop push_on_plz" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>
						적립/포인트 알림이 켜져있어야<br />
						적립 신청이 가능합니다.<br />
						알림 설정 페이지로 이동하시겠습니까?
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="/receipt/receiptStore">아니오</Link>
					<Link className="dgrey" to="/mycow/mycowPset">네</Link>
				</div>
			</div>
		)
	}
}

export default withCookies(pushLayer);