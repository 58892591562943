import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class notice extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') === undefined ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') === undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),

			notices: [],
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/common/notice");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/common/notice"});}
		}catch(e){}
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/common/notice");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/common/notice"});}
		}catch(e){}
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		const url = this.state.apiurl+"/user/push_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			console.log(res);
			this.setState({
				notices : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goPage = (type, url, e) => {
		e.preventDefault();
		const { cookies } = this.props;
		if(type === 4){
			if(global.variable.osType === 1){
				window.android.openinurl(url);
			}
			if(global.variable.osType === 0){
				window.webkit.messageHandlers.openinurl.postMessage({ url : url });
			}
		}else{
			cookies.set('originPage', '/common/notice', { path: '/' });
			document.location.href = url;
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key === "pback"){
			document.location.href="/main";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key === "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/main">앞으로</Link></p>
			<h2>알림</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img alt="spinner" src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			{/*<header className="mem_header">
				<p className="btn_prev"><Link to="/main">앞으로</Link></p>
				<h2>알림</h2>
			</header>*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="common_alarm_list">
				<ul>
					{this.state.notices.map(notice =>
					<li key={notice.idx} >
						{notice.type === 1  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowEventView/"+notice.target_idx)} className="icon_save">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 2  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowNotice/"+notice.target_idx)} className="icon_alarm_noti">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 3  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main")} className="icon_alarm_noti">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 4  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, notice.link_title)} className="icon_url_link">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 5  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/pointView/"+notice.target_idx)} className="icon_reward_chk">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 6  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/pointView/"+notice.target_idx)} className="icon_accum_deny">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 7  && notice.thumb === "" && <Link to="#" className="question">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 8  && notice.thumb === "" && <Link to="#" className="icon_cash_out">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 9  && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/point/")} className="icon_point">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 10 && notice.thumb === "" && <Link to="#" className="icon_coup">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 11 && notice.thumb === "" && <Link to="#" className="icon_coup">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 12 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main/productView/"+notice.target_idx)} className="icon_coup">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 13 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main/onlineMall/"+notice.target_idx)}  className="icon_coup">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 14 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowInquiryView/"+notice.target_idx)}  className="icon_coup">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 51 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/survey/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}{/*"/common/survey/"+notice.target_idx+"/"+notice.target_sub_idx*/}</p></div></Link>}
						{notice.type === 52 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/siteReward/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 53 && notice.thumb === "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/adReward/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk">{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}


						{notice.type === 1  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowEventView/"+notice.target_idx)} className="icon_save"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 2  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowNotice/"+notice.target_idx)} className="icon_alarm_noti"><img src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 3  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main")} className="icon_alarm_noti"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 4  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, notice.link_title)} className="icon_url_link"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 5  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/pointView/"+notice.target_idx)} className="icon_reward_chk"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 6  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/pointView/"+notice.target_idx)} className="icon_accum_deny"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 7  && notice.thumb !== "" && <Link to="#" className="question"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 8  && notice.thumb !== "" && <Link to="#" className="icon_cash_out"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 9  && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/point/point/")} className="icon_point"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 10 && notice.thumb !== "" && <Link to="#" className="icon_coup"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 11 && notice.thumb !== "" && <Link to="#" className="icon_coup"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 12 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main/productView/"+notice.target_idx)} className="icon_coup"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 13 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/main/onlineMall/"+notice.target_idx)}  className="icon_coup"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 14 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/mycow/mycowInquiryView/"+notice.target_idx)}  className="icon_coup"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 51 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/survey/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}{/*"/common/survey/"+notice.target_idx+"/"+notice.target_sub_idx*/}</p></div></Link>}
						{notice.type === 52 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/siteReward/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}
						{notice.type === 53 && notice.thumb !== "" && <Link to="#" onClick={this.goPage.bind(this, notice.type, "/common/adReward/"+notice.target_idx+"/"+notice.target_sub_idx)} className="icon_reward_chk"><img alt="" src={notice.thumb}/>{notice.is_new === 1 ? <i className='new'></i> : ""}<div className="alarm_list_txt"><span>{notice.complete_time}</span><p>{notice.contents}</p></div></Link>}


					</li>
					)}
				</ul>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(notice);