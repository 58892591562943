import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import * as fnc from '../../js/fnc.js';

import iconTime from "../../img/main/icon_time.png";
import accum_done from "../../img/main/accum_done.png";

class mainHomeRecommend extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			adult: cookies.get('adult'),

			recos: []
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
	}
	init(){
		const url = this.state.apiurl+"/init/reco_product.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.data.count === 0){
				$(".main_home_recommend_area").hide();
			}
			this.setState({
				recos : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goView = (ccidx, e) => {
		fnc.event_log("home_main", "home_main_click", "list_recommend_store");
		document.location.href="/main/productView/"+ccidx;
	}
		
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	render(){
		const params2 = {
			lazy: true,
			slidesPerView: 'auto',
			spaceBetween: 0,
			grabCursor: true,
			id:'test',
			rebuildOnUpdate: true,
			preventInteractionOnTransition: true
		};
		return (
		<section className="main_cont_wrap main_home_recommend_area">
			<h2 className="main_cont_ttl">추천 상품</h2>
			<div className="main_cont_inner">
				<div className="recom_prod_list">
					<Swiper className="swiper-wrapper" {...params2}>
						{this.state.recos.map(reco =>
						<div className="swiper-slide" key={reco.ccidx}>
							{
							reco.check_adult && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 <br/>불가 상품</p>
							</div>
							}
							<Link to="#" onClick={this.goView.bind(this, reco.ccidx)}>
								{/*<!-- 적립기한임박 -->*/}
								{
								!(reco.check_adult && this.state.adult !== "Y") && reco.near_end &&
								<div className="period_warn">
									<img alt="" src={iconTime}/>
									적립 기한이 <br />곧 종료됩니다.
									<div className="period_warn_bg"></div>
								</div>
								}
								{/*<!-- 적립기한임박 end -->*/}
								{
								reco.limit_type === "day" && reco.remain_trial === 0 &&
								<div className="accum_done_cover">
									<img alt="" src={accum_done} />
									<div className="accum_done_dimm"></div>
								</div>
								}
								{
								reco.limit_type === "season" && reco.remain_trial === 0 &&
								<div className="accum_done_cover">
									<img alt="" src={accum_done} />
									<div className="accum_done_dimm"></div>
								</div>
								}
								<figure><img alt="" src={reco.image}/></figure>
								<strong className="point">{reco.reward}P</strong>
								<p className="name">{reco.name}</p>
								<div className="stat_btns">
									{reco.limit_type === 0 && <span><i>무제한</i></span>}
									{reco.limit_type === "none" && <span><i>무제한</i></span>}
									{reco.limit_type === "day" && <span><i>하루 {reco.daily_limit - reco.remain_trial}/{reco.daily_limit}</i></span>}
									{reco.limit_type === "season" &&<span><i>기간 {reco.seasonal_limit - reco.remain_trial}/{reco.seasonal_limit}</i></span>}
								</div>
							</Link>
						</div>
						)}
					</Swiper>
				</div>
			</div>
			<button name="homeRecommend" id="homeRecommend" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
		</section>
		)
	}
}

export default withCookies(mainHomeRecommend);