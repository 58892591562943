import React, {Component} from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route } from 'react-router-dom';

import gate from './common/gate';
import init from './common/init';
import notice from './common/notice';
import bridge from './common/bridge.js';
import survey from './common/survey.js';
import siteReward from './common/site.js';
import advertiseReward from './common/advertise.js';

import login from './member/login';
import join from './member/join';
import terms from './member/terms';
import joinMemInfo from './member/joinMemInfo';
import findId from './member/findId';
import findPw from './member/findPw';
import recommend from './member/recommend';
import joinEnd from './member/joinEnd';

import main from './main/main';
import mainSearchBlank from './main/mainSearchBlank';
import mainSearch from './main/mainSearch';
import mainProductCategory from './main/mainProductCategory';
import mainProductCategoryView from './main/mainProductCategoryView';
import mainProductView from './main/mainProductView';
import mainOnlineMall from './main/mainOnlineMall';
import mainOnlineMallCategory from './main/mainOnlineMallCategory';
import mainOnlineMallCategoryView from './main/mainOnlineMallCategoryView';
import mainOnlineMallThroughOut from './main/mainOnlineMallThroughOut';
import mainOnlineMallThroughIn from './main/mainOnlineMallThroughIn';

import receiptStore from './receipt/receiptStore';
import receiptGuide from './receipt/receiptGuide';
import receiptChoice from './receipt/receiptChoice';

import point from './point/point';
import pointView from './point/pointView';

import mycow from './mycow/mycow';
import mycowMyinfo from './mycow/mycowMyinfo';
import mycowLeavePw from './mycow/mycowLeavePw';
import mycowMyPwChk from './mycow/mycowMyPwChk';
import mycowMyPw from './mycow/mycowMyPw';
import mycowInvite from './mycow/mycowInvite';
import mycowInviteInput from './mycow/mycowInviteInput';
import mycowDonate from './mycow/mycowDonate';
import mycowPset from './mycow/mycowPset';
import mycowVersion from './mycow/mycowVersion';
import mycowNotice from './mycow/mycowNotice';
import mycowNoticeView from './mycow/mycowNoticeView';
import mycowEvent from './mycow/mycowEvent';
import mycowEventView from './mycow/mycowEventView';
import mycowTerms from './mycow/mycowTerms';
import mycowTermsView from './mycow/mycowTermsView';
import mycowInFaq from './mycow/mycowInFaq';
import mycowInFaqSearch from './mycow/mycowInFaqSearch';
import mycowInquiry from './mycow/mycowInquiry';
import mycowInquiryView from './mycow/mycowInquiryView';
import mycowInquiryWrite from './mycow/mycowInquiryWrite';
import mycowManual from './mycow/mycowManual';
import mycowManualView from './mycow/mycowManualView';

import './index.css';
import './css/reset.css';
import './css/style.css';
import './css/style_main.css';
import './css/style_mem.css';
import './css/style_mycow.css';
import './css/style_receipt.css';

class App extends Component{
	render(){
		return(
		<CookiesProvider>
			<BrowserRouter>
				<Route exact path="/" component={gate} />
				<Route exact path="/gate/:type/:id" component={gate} />
				<Route exact path="/bridge/:type/:id" component={bridge} />
				<Route exact path="/init" component={init} />
				<Route exact path="/main" component={main} />
				<Route exact path="/main/mainSearchBlank/" component={mainSearchBlank} />
				<Route exact path="/main/mainSearch/:word" component={mainSearch} />
				<Route exact path="/main/mainProductCategory" component={mainProductCategory} />
				<Route exact path="/main/mainProductCategoryView/:id" component={mainProductCategoryView} />
				<Route exact path="/main/productView/:id" component={mainProductView} />
				<Route exact path="/main/mainOnlineMallCategory" component={mainOnlineMallCategory} />
				<Route exact path="/main/mainOnlineMallCategoryView/:id" component={mainOnlineMallCategoryView} />
				<Route exact path="/main/onlineMall/:id" component={mainOnlineMall} />
				<Route exact path="/main/onlineMallThroughOut/:id/:pid" component={mainOnlineMallThroughOut} />
				<Route exact path="/main/onlineMallThroughIn/:id/:pid" component={mainOnlineMallThroughIn} />

				<Route exact path="/common/notice" component={notice} />
				<Route exact path="/common/survey/:id/:lid" component={survey} />
				<Route exact path="/common/siteReward/:id/:lid" component={siteReward} />
				<Route exact path="/common/adReward/:id/:lid" component={advertiseReward} />

				<Route exact path="/member/login" component={login} />
				<Route exact path="/member/join" component={join} />
				<Route exact path="/member/terms/:type" component={terms} />
				<Route exact path="/member/joinMemInfo" component={joinMemInfo} />
				<Route exact path="/member/findId" component={findId} />
				<Route exact path="/member/findPw" component={findPw} />
				<Route exact path="/member/recommend" component={recommend} />
				<Route exact path="/member/joinEnd" component={joinEnd} />

				<Route exact path="/receipt/receiptStore" component={receiptStore} />
				<Route exact path="/receipt/receiptGuide/:id" component={receiptGuide} />
				<Route exact path="/receipt/receiptChoice/:id/:date" component={receiptChoice} />

				<Route exact path="/point/point" component={point} />
				<Route exact path="/point/pointView/:id" component={pointView} />

				<Route exact path="/mycow/mycow" component={mycow} />
				<Route exact path="/mycow/mycowMyinfo" component={mycowMyinfo} />
				<Route exact path="/mycow/mycowLeavePw" component={mycowLeavePw} />
				<Route exact path="/mycow/mycowMyPwChk" component={mycowMyPwChk} />
				<Route exact path="/mycow/mycowMyPw" component={mycowMyPw} />
				<Route exact path="/mycow/mycowInvite" component={mycowInvite} />
				<Route exact path="/mycow/mycowInviteInput" component={mycowInviteInput} />
				<Route exact path="/mycow/mycowDonate" component={mycowDonate} />
				<Route exact path="/mycow/mycowPset" component={mycowPset} />
				<Route exact path="/mycow/mycowVersion" component={mycowVersion} />
				<Route exact path="/mycow/mycowNotice" component={mycowNotice} />
				<Route exact path="/mycow/mycowNoticeView/:id" component={mycowNoticeView} />
				<Route exact path="/mycow/mycowEvent" component={mycowEvent} />
				<Route exact path="/mycow/mycowEventView/:id" component={mycowEventView} />
				<Route exact path="/mycow/mycowTerms" component={mycowTerms} />
				<Route exact path="/mycow/mycowTermsView/:id" component={mycowTermsView} />
				<Route exact path="/mycow/mycowInFaq" component={mycowInFaq} />
				<Route exact path="/mycow/mycowInFaqSearch" component={mycowInFaqSearch} />
				<Route exact path="/mycow/mycowInFaqSearch/:word" component={mycowInFaqSearch} />
				<Route exact path="/mycow/mycowInquiry" component={mycowInquiry} />
				<Route exact path="/mycow/mycowInquiryView/:id" component={mycowInquiryView} />
				<Route exact path="/mycow/mycowInquiryWrite" component={mycowInquiryWrite} />
				<Route exact path="/mycow/mycowManual" component={mycowManual} />
				<Route exact path="/mycow/mycowManualView/:id" component={mycowManualView} />

			</BrowserRouter>
		</CookiesProvider>
		)
	}
}

export default App;