import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import * as fnc from '../../js/fnc.js';

import iconTime from "../../img/main/icon_time.png";
import accum_done from "../../img/main/accum_done.png";

class mainProductProductSub extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};
	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),

			adult: cookies.get('adult')
		};
	}
	goProductView = (idx, e) =>{
		fnc.event_log("product_main", "product_main_click", "prod_detail");
		const { cookies } = this.props;
		cookies.set('mainTab', '2', { path: '/' });
		document.location.href = "/main/productView/"+idx;
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	render(){
		return (
			<ul>
				{this.props.list.map((item, i) =>
				<li key={i} className={"itemList itemList"+this.props.index}>
					{
					item.check_adult && this.state.adult !== "Y" &&
					<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
						<div className="age_limit_dimm"></div>
						<p>미성년자 불가 상품</p>
					</div>
					}

					{
					item.limit_type === "day" && item.remain_trial === 0 &&
					<div className="accum_done_cover">
						<img alt="" src={accum_done} />
						<div className="accum_done_dimm"></div>
					</div>
					}
					{
					item.limit_type === "season" && item.remain_trial === 0 &&
					<div className="accum_done_cover">
						<img alt="" src={accum_done} />
						<div className="accum_done_dimm"></div>
					</div>
					}

					<Link to="#" onClick={this.goProductView.bind(this, item.ccidx)}>
						<div className="serv_prod_info">
							<figure><img alt="" src={item.image} /></figure>
							<div className="txt">
								{
								item.reward_method === "F" &&
								<strong>{item.reward}P</strong>
								}
								{
								item.reward_method === "R" &&
								<strong>{item.reward}%</strong>
								}
								<p>{item.name}</p>
							</div>
						</div>
						<div className="serv_prod_tags">
							<ul>
								{item.limit_type === 0 && <li><span>무제한</span></li>}
								{item.limit_type === "none" && <li><span>무제한</span></li>}
								{item.limit_type === "day" && <li><span>하루 {item.daily_limit - item.remain_trial}/{item.daily_limit}</span></li>}
								{item.limit_type === "season" &&<li><span>기간 {item.seasonal_limit - item.remain_trial}/{item.seasonal_limit}</span></li>}
								{item.cond_keyword_1 != "" && <li><span>{(item.cond_keyword_1).substr(0, 5)}</span></li>}
								{item.cond_keyword_2 != "" && <li><span>{(item.cond_keyword_2).substr(0, 5)}</span></li>}
							</ul>
							{
							!(item.check_adult && this.state.adult !== "Y") && item.near_end &&
							<div className="period_warn">
								<img alt="" src={iconTime} />
								적립 기한이 곧 종료됩니다.
								<div className="period_warn_bg"></div>
							</div>
							}
						</div>
					</Link>
				</li>
				)}
			</ul>
		)
	}
}

export default withCookies(mainProductProductSub);