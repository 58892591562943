import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import spinner from '../img/common/spinner.gif';

class pointView extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			id: props.match.params.id,
			pointSubTab: cookies.get('pointSubTab') == undefined ? "1" : cookies.get('pointSubTab'),

			pdtl: [],
			pcount: 0,
			total: 0,
			plists: []
		};
	}
	componentDidMount(){
		this.init();

		if(this.state.pointSubTab === "1")
			fnc.event_log("point_pre_detail", "", "");
		if(this.state.pointSubTab === "2")
			fnc.event_log("point_com_detail", "", "");
		if(this.state.pointSubTab === "3")
			fnc.event_log("point_not_detail", "", "");
		if(this.state.pointSubTab === "4")
			fnc.event_log("point_can_detail", "", "");
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/point', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		var url = this.state.apiurl+"/user/reward_detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ridx", this.state.id);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				pdtl : res.data.data,
				pcount : res.data.data.count
			});
			if(res.data.data.count > 0){
				var totalPoint = 0;
				for(var i=0 ; i < res.data.data.list.length ; i++){
					totalPoint = totalPoint + res.data.data.list[i].reward;
				}
				this.setState({
					plists : res.data.data.list,
					total : totalPoint
				})
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	rewardCancelOpen(){
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".prod_detail_pop").css("top", Math.max(0, (($(window).height() - $(".prod_detail_pop").outerHeight()) / 2) + posY) + "px");
		$(".prod_detail, .prod_detail_pop").fadeIn("fast");
	}
	rewardCancel(){
		var url = this.state.apiurl+"/user/reward_cancel.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ridx", this.state.pdtl.ridx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			$(".prod_detail_pop").fadeOut("fast");
			//$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + this.state.posY) + "px");
			$(".certi_ok_pop").html("완료");
			$(".certi_ok_pop").fadeIn("fast");
			var _this = this;
			setTimeout(function(){
				if(global.variable.osType === 0){
					$(window).scrollTop(_this.state.posY);
					$("#wrap").css("top", 0);
					$("html, body").off('scroll touchmove');
				}
				$('html, body').css("overflow", "visible");

				document.location.href="/point/point";
			}, 2000);
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	popCls(){
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".prod_detail, .prod_detail_pop").fadeOut("fast");
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/point/point";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

		{/*<!-- header 영역 -->*/}
		<header className="mem_header">
			<p className="btn_prev"><Link to="/point/point">앞으로</Link></p>
			{this.state.pdtl.status === "standby" && <h2>적립 예정</h2>}
			{this.state.pdtl.status === "complete" && <h2>적립 완료</h2>}
			{this.state.pdtl.status === "reject" && <h2>적립 불가</h2>}
			{this.state.pdtl.status === "cancel" && <h2>적립 취소</h2>}
			{this.state.pdtl.status === "use" && <h2>사용</h2>}
		</header>
		{/*<!-- header 영역 end -->*/}
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}


			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>
			{/*<!-- 완료 팝업 end -->*/}
			{/*<!-- 상품팝업 - iOS -->*/}
			<div className="layer_dimm prod_detail" style={{display:"none"}}></div>
			<div className="prod_detail_pop" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>신청된 리워드를 취소합니다.</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>아니오</Link>
					<Link className="dgrey" to="#" onClick={this.rewardCancel.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 상품팝업 - iOS end -->*/}
			
			{/*<!-- 내용 영역 -->*/}
			<div className="grey_gap"></div>
			<dl className="alarm_date_info">
				<dt>{this.state.pdtl.rdate}</dt>
				<dd>{this.state.pdtl.randid}</dd>
			</dl>
			<div className="grey_gap"></div>
			<div className="perch_list_wrap">
				<div className="mart_logo_wrap">
					<img alt="" className="logo" src={this.state.pdtl.image} style={{width:"140px"}}/>
				</div>
				{
				this.state.pdtl.store_type === 'brand' &&
				<div className="perch_list_cont accum">
					{this.state.pdtl.status === "reject" && <p className="perch_list_warn">{this.state.pdtl.description}</p>}
					{this.state.pdtl.status === "cancel" && <p className="perch_list_warn">{this.state.pdtl.description}</p>}
					<ul>
						{
						this.state.plists.map((plist, i) =>
						<li key={i}>
							<div className="list_cont_wrap">
								<img alt="" src={plist.thumb} />
								<p className="prod_name">{plist.name} * {plist.qty}</p>
								<p className="prod_point">{plist.reward}P</p>
							</div>
							{
							this.state.pdtl.status === "complete" && plist.reward === 0 && plist.description !== "" &&
							<p className="perch_list_warn mb20">{plist.description}</p>
							}
						</li>
						)}
					</ul>
					<div className="prod_point_sum">
						<div className="inner">
							<p className="count">총 {this.state.pcount}건</p>
							<strong className="result">{fnc.comma(this.state.total)}P</strong>
						</div>
					</div>
				</div>
				}

				{
				this.state.pdtl.store_type === 'online' &&
				<div className="perch_list_cont accum">
					<ul>
						{
						this.state.plists.map((plist, i) =>
						<li key={i}>
							<div className="list_cont_wrap">
								<img alt="" src={plist.thumb} />
								<p className="prod_name">{plist.name} * {plist.qty}</p>
							</div>
							<p className="perch_confirm mb20">구매확정일 : {this.state.pdtl.rdate}</p>
						</li>
						)}
					</ul>
					<div className="prod_point_sum">
						<div className="inner">
							<p className="count">총 적립포인트</p>
							<strong className="result">{fnc.comma(this.state.total)}P</strong>
						</div>
					</div>
					<div className="point_accum_warn_wrap">
						<ul>
							<li>쿠폰, 프로모션 할인 등을 제외한 실제 결제 금액의 5%가 리워드로 적립됩니다.</li>
							<li>구매상품, 옵션 등 상세정보는 해당 온라인몰에서 확인 가능합니다.</li>
							<li>부분취소, 환불 등을 반영한 최종 결제금액에 대해 리워드를 지급합니다.</li>
						</ul>
					</div>
				</div>
				}
			</div>
			<div className="receipt_pic_wrap">
				<img alt="" src={this.state.pdtl.receipt} />
				{this.state.pdtl.status === "standby" && 
				<p className="btn_rew_req_cancel">
					<Link to="#" onClick={this.rewardCancelOpen.bind(this)}>리워드 신청 취소</Link>
				</p>
				}
			</div>

			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(pointView);