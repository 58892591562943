import { withCookies, Cookies } from 'react-cookie';
import $ from 'jquery';
import axios from 'axios';

export function comma(num){
	var len, point, str; 

	num = num + ""; 
	point = num.length % 3 ;
	len = num.length; 

	str = num.substring(0, point); 
	while (point < len) { 
		if (str != "") str += ","; 
		str += num.substring(point, point + 3); 
		point += 3; 
	}

	return str;
}

export function network_error_pop(){
	$(".network_error_pop").css({
		"top" : Math.max(0, (($(window).height() - $(".network_error_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
		"margin-top" : "100px",
		"opacity" : "0",
		"display" : "block"
	});
	$(".network_error_pop").animate({"margin-top":"0px","opacity" : "1"}, 300);
	$(".netword_dimm").fadeIn("fast");
	return;
}

export function server_close_pop(msg){
	$(".server_close_pop").css({
		"top" : Math.max(0, (($(window).height() - $(".network_error_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
		"margin-top" : "100px",
		"opacity" : "0",
		"display" : "block"
	});
	$(".server_close_pop").find("p").html(msg);
	$(".server_close_pop").animate({"margin-top":"0px","opacity" : "1"}, 300);
	$(".netword_dimm").fadeIn("fast");
	return;
}

export function update_pop(msg){
	$(".update_pop").css({
		"top" : Math.max(0, (($(window).height() - $(".network_error_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
		"margin-top" : "100px",
		"opacity" : "0",
		"display" : "block"
	});
	$(".update_pop").find("p").html(msg);
	$(".update_pop").animate({"margin-top":"0px","opacity" : "1"}, 300);
	$(".netword_dimm").fadeIn("fast");
	return;
}

export function push_detail(apiurl, version, cidx, push_idx, pstatus, ptype, pidx, psubidx, purl){
	var userAgent=navigator.userAgent.toLowerCase();
	var osType = userAgent.match(/android/i) ? 1 : 0;

	var url = apiurl+"/report/push_open.json";
	var formData = new FormData();
	formData.append("os", osType);
	formData.append("appver", version);
	formData.append("applocale", "kr");
	formData.append("bypass_emergency", 0);
	formData.append("cidx", cidx);
	formData.append("idx", push_idx);
	formData.append("open_status", pstatus);
	let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
	axios.post(url, formData, global.variable.config).then(res => {
		clearInterval(spinnerInterval);
		if(pstatus === "0"){
			return;
		}
		if(pstatus === "1"){
			if(ptype === "1"){
				document.location.href="/mycow/mycowEventView/"+pidx;
			}
			if(ptype === "2"){
				document.location.href="/mycow/mycowNoticeView/"+pidx;
			}
			if(ptype === "3"){
				document.location.href="/main";
			}
			if(ptype === "4"){
				if(osType === 1){
					window.android.openinurl(purl);
				}
				if(osType === 0){
					window.webkit.messageHandlers.openinurl.postMessage({ url : purl });
				}
			}
			if(ptype === "5"){
				document.location.href="/point/pointView/"+pidx;
			}
			if(ptype === "6"){
				document.location.href="/point/pointView/"+pidx;
			}
			if(ptype === "7"){
				document.location.href="/point/pointView/"+pidx;
			}
			if(ptype === "8"){
				document.location.href="/point/point";
			}
			if(ptype === "9"){
				document.location.href="/point/point";
			}
			if(ptype === "10"){
				document.location.href="/point/point";
			}
			if(ptype === "11"){
			}
			if(ptype === "12"){
				document.location.href="/main/productView/"+pidx;
			}
			if(ptype === "13"){
				document.location.href="/main/onlineMall/"+pidx;
			}
			if(ptype === "14"){
				document.location.href="/mycow/mycowInquiryView/"+pidx;
			}

			//alert(apiurl);
			//alert(version);
			//alert(cidx);
			//alert(push_idx);
			//alert(pstatus);
			//alert(ptype);
			//alert(pidx);
			//alert(psubidx);
			//alert(purl);

			if(ptype === "51"){
				document.location.href="/common/survey/"+pidx+"/"+psubidx;
			}
			if(ptype === "52"){
				document.location.href="/common/siteReward/"+pidx+"/"+psubidx;
			}
			if(ptype === "53"){
				document.location.href="/common/adReward/"+pidx+"/"+psubidx;
			}
		}
	}).catch(res => {
		var msg = res.message;
		if(msg === global.variable.axiosTimeoutMsg){network_error_pop();}
	});
}

export function event_log(ename, param1, param2){
	var userAgent=navigator.userAgent.toLowerCase();
	var osType = userAgent.match(/android/i) ? 1 : 0;

	var jsonArr = null;
	var jArray = new Array();
	var jobj = new Object();

	if(ename === "product_detail"){
		jobj.key = "idx";
		jobj.value = param1;
		jArray.push(jobj);

		jobj.key = "name";
		jobj.value = param2;
		jArray.push(jobj );
		
	}
	if(ename === "online_mall_detail"){
		jobj.key = "idx";
		jobj.value = param1;
		jArray.push(jobj);

		jobj.key = "name";
		jobj.value = param2;
		jArray.push(jobj );
	}
	if(ename === "online_mall_enter"){
		jobj.key = "idx";
		jobj.value = param1;
		jArray.push(jobj);
	}
	if(ename === "online_mall_back"){
		jobj.key = "idx";
		jobj.value = param1;
		jArray.push(jobj);
	}
	if(ename === "keyword_search"){
		jobj.key = "type";
		jobj.value = param1;
		jArray.push(jobj);
	}
	if(ename === "notice_detail"){
		jobj.key = "type";
		jobj.value = param1;
		jArray.push(jobj);

		jobj.key = "name";
		jobj.value = param2;
		jArray.push(jobj);
	}
	if(ename === "event_detail"){
		jobj.key = "type";
		jobj.value = param1;
		jArray.push(jobj);

		jobj.key = "name";
		jobj.value = param2;
		jArray.push(jobj);
	}
	if(ename === "share_kakao" || ename === "share_facebook" || ename === "share_sms" || ename === "share_mail" || ename === "share_copy"){
		jobj.key = "type";
		jobj.value = param1;
		jArray.push(jobj);

		jobj.key = "idx";
		jobj.value = param2;
		jArray.push(jobj);
	}
	if(ename === "home_main"){
		jobj.key = param1;
		jobj.value = param2;
		jArray.push(jobj);
	}
	jsonArr = JSON.stringify(jArray);

	try{
		if(osType === 1){
			window.android.setEventLog(ename, jsonArr);
		}
		if(osType === 0){
			window.webkit.messageHandlers.setEventLog.postMessage({ 
				ename : ename,
				param1 : jsonArr
			});
		}
	}catch(e){
	}
}