import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class receiptGuide extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		var userAgent=navigator.userAgent.toLowerCase();

		// 오늘 날짜
		var nowDate = new Date();
		var nowYear = nowDate.getFullYear();
		var nowMonth = nowDate.getMonth() + 1;
		var nowDay = nowDate.getDate();

		if(nowMonth < 10){ nowMonth = "0" + nowMonth; }
		if(nowDay < 10) { nowDay = "0" + nowDay; }

		var todayDate = nowYear + "-" + nowMonth + "-" + nowDay;

		// 어제 날짜
		var nowDate = new Date();
		var dayb1Date = nowDate.getTime() - (1 * 24 * 60 * 60 * 1000);
		nowDate.setTime(dayb1Date);

		var dayb1Year = nowDate.getFullYear();
		var dayb1Month = nowDate.getMonth() + 1;
		var dayb1Day = nowDate.getDate();

		if(dayb1Month < 10){ dayb1Month = "0" + dayb1Month; }
		if(dayb1Day < 10) { dayb1Day = "0" + dayb1Day; }
		dayb1Date = dayb1Year + "-" + dayb1Month + "-" + dayb1Day;

		// 어제 날짜
		var nowDate = new Date();
		var dayb2Date = nowDate.getTime() - (2 * 24 * 60 * 60 * 1000);
		nowDate.setTime(dayb2Date);

		var dayb2Year = nowDate.getFullYear();
		var dayb2Month = nowDate.getMonth() + 1;
		var dayb2Day = nowDate.getDate();

		if(dayb2Month < 10){ dayb2Month = "0" + dayb2Month; }
		if(dayb2Day < 10) { dayb2Day = "0" + dayb2Day; }
		dayb2Date = dayb2Year + "-" + dayb2Month + "-" + dayb2Day;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			bidx: props.match.params.id,

			bcategorys: [],
			binfo: [],
			day1: dayb2Date,
			day2: dayb1Date,
			day3: todayDate
		};

		window.addEventListener('load', function(){
			$(document).ready(function(){
				$("input[name=date]").prop("checked", false);
			});
		}, true);

	}
	componentDidMount(){
		fnc.event_log("reward_date", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/receipt/receiptStore', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}

		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		if ($(window).height() >= 1468) {
			$('html, body').css({
				'overflow':'hidden'
			});
		} else {
			$('html, body').css({
				'overflow':'visible'
			});
		}

		const url = this.state.apiurl+"/reward/brand_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			for(var i=0 ; i < res.data.data.brand_count ; i++){
				if(~~res.data.data.brand_list[i].bidx === ~~this.state.bidx){
					this.setState({binfo : res.data.data.brand_list[i]});
				}
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	componentDidUpdate(){
	}
	dateSelect(val, e){
		$(".btm_btn").find("a").addClass("on");
	}
	agreeBtn(){
		if(!$(".btm_btn").find("a").hasClass("on")){
			return;
		}
		fnc.event_log("reward_type", "", "");
		$(".sel_layer_dimm").fadeIn("fast");
		$(".receipt_sel_pop").fadeIn("fast");
	}
	selectClose(e){
		e.preventDefault();
		$(".layer_dimm").fadeOut("fast");
		$(".receipt_sel_pop").fadeOut("fast");
	}
	pReceiptCall = (mode, e) => {
		if(mode === false) return;
		try{
			setTimeout(function(){
				$(".white_dimm").show();
				$(".spinner").show();
			}, 500);
			if(global.variable.osType === 1){
				window.android.getCameraImagePath(this.state.cidx, "/receipt/receiptChoice", this.state.bidx, $("input[name=date]:checked").val(), '', '')
			}
			if(global.variable.osType === 0){
				window.webkit.messageHandlers.getCameraImagePath.postMessage({ cidx : this.state.cidx, url : "/receipt/receiptChoice", param1 : this.state.bidx, param2 : $("input[name=date]:checked").val(), param3 : "", param4 : ""} );
			}
			$(".layer_dimm").hide();
			$(".receipt_sel_pop").hide();
		}catch(e){
			document.location.href = "/receipt/receiptChoice/"+this.state.bidx+"/"+$("input[name=date]:checked").val();
		}
	}
	eReceiptCall = (mode, logo, top_area_ratio, side_area_ratio, e) => {
		if(mode === false) return;
		try{
			setTimeout(function(){
				$(".white_dimm").show();
				$(".spinner").show();
			}, 500);
			if(global.variable.osType === 1){
				window.android.getReceiptImagePath(this.state.cidx, "/receipt/receiptChoice", this.state.bidx, $("input[name=date]:checked").val(), '', '', logo, top_area_ratio, side_area_ratio);
			}
			if(global.variable.osType === 0){
				window.webkit.messageHandlers.getReceiptImagePath.postMessage({ cidx : this.state.cidx, url : "/receipt/receiptChoice", param1 : this.state.bidx, param2 : $("input[name=date]:checked").val(), param3 : "", param4 : "", imageurl : logo, topratio : top_area_ratio, sideratio : side_area_ratio} );
			}
			$(".layer_dimm").hide();
			$(".receipt_sel_pop").hide();
		}catch(e){
			alert(e);
			document.location.href = "/receipt/receiptChoice/"+this.state.bidx+"/"+$("input[name=date]:checked").val();
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == 'getCameraImagePath'){
			cookies.set('filetype', 'p', { path: '/' });
			cookies.set('filepath', resObj.filepath, { path: '/' });
			document.location.href = "/receipt/receiptChoice/"+resObj.param1+"/"+resObj.param2;
		}
		if(resObj.key == 'getReceiptImagePath'){
			cookies.set('filetype', 'e', { path: '/' });
			cookies.set('filepath', resObj.filepath, { path: '/' });
			document.location.href = "/receipt/receiptChoice/"+resObj.param1+"/"+resObj.param2;
		}
		if(resObj.key == "pback"){
			document.location.href="/receipt/receiptStore";
		}
		if(resObj.key == "cancel"){
			$(".white_dimm").hide();
			$(".spinner").hide();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/receipt/receiptStore">앞으로</Link></p>
			<h2>적립 신청 안내</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 영수증 선택 팝업 -->*/}
			<div className="layer_dimm sel_layer_dimm" style={{display:"none"}}></div>
			<div className="receipt_sel_pop" style={{display:"none"}}>
				<div className="receipt_sel_pop_cont">
					<div className="receipt_sel_pop_inner">
						<div className="receipt_each elec_receipt">
							<Link to="#" onClick={this.eReceiptCall.bind(this, this.state.binfo.e_receipt, this.state.binfo.thumb_1, this.state.binfo.top_area_ratio, this.state.binfo.side_area_ratio)}>
								<div className="icon_wrap">
								{this.state.binfo.e_receipt === false && <div className="regi_deny_cover"></div>}
								</div>
								<p>전자 영수증</p>
							</Link>
						</div>
						<div className="receipt_each paper_receipt">
							<Link to="#" onClick={this.pReceiptCall.bind(this, this.state.binfo.p_receipt)}>
								<div className="icon_wrap">
								{this.state.binfo.p_receipt === false && <div className="regi_deny_cover"></div>}
								</div>
								<p>종이 영수증</p>
							</Link>
						</div>
					</div>
				</div>
				<Link className="receipt_sel_cancel" to="/main" onClick={this.selectClose.bind(this)}>취소</Link>
			</div>
			{/*<!-- 영수증 선택 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="pt50" id="content">
				{
				this.state.binfo.guide_popup !== "" &&
				<div className="store_receipt_ttl">
					<img alt="" src={this.state.binfo.thumb_1} width="140"/>
					<strong>영수증을 등록해주세요.</strong>
				</div>
				}


				{
				this.state.binfo.guide_popup === "" &&
				<div className="store_receipt_ttl icon">
					<div className="store_receipt_txt">
						<img alt="" src={this.state.binfo.thumb_1} width="200"/>
						<strong>영수증을 등록해주세요.</strong>
					</div>
				</div>
				}


				<div className="receipt_regi_chklist">
					<ul>
						<li><strong>본인</strong>이 매장에서 <strong>상품</strong>을 구매한 영수증만 가능</li>
						<li><strong>영수증 전체</strong>를 정면에서 선명하게 촬영</li>
						<li>영수증 <strong>접음/가림</strong>, 휴대폰/PC<strong>화면 촬영 금지</strong></li>
						<li>영수증 1장으로 <strong>1번만 등록</strong> 가능</li>
						<li>구매일로 부터 <strong>2일 이내</strong>의 영수증만 가능</li>
					</ul>
				</div>
				{/*<!-- 매장별 주의사항 -->*/}
				<div className="each_store_warn">
				{
				this.state.binfo.guide_popup !== "" &&
				<img alt="" src={this.state.binfo.guide_popup} />
				}
				</div>
				{/*<!-- 매장별 주의사항 end -->*/}
				{/*<!-- 구매일 선택 -->*/}
				<div className="purch_date_sel">
					<strong>영수증에 기재된 구매일을 선택해 주세요.</strong>
					<ol>
						<li>
							<input id="date1" name="date" type="radio" value={this.state.day1} onChange={this.dateSelect.bind(this, this.state.day1)}/>
							<label htmlFor="date1">
								<p><strong>그제</strong>{this.state.day1}</p>
							</label>
						</li>
						<li>
							<input id="date2" name="date" type="radio" value={this.state.day2} onChange={this.dateSelect.bind(this, this.state.day2)}/>
							<label htmlFor="date2">
								<p><strong>어제</strong>{this.state.day2}</p>
							</label>
						</li>
						<li>
							<input id="date3" name="date" type="radio" value={this.state.day3} onChange={this.dateSelect.bind(this, this.state.day3)}/>
							<label htmlFor="date3">
								<p><strong>오늘</strong>{this.state.day3}</p>
							</label>
						</li>
					</ol>
					<p>
						영수증에 따라 포인트를 선지급 후 검수할 수 있습니다.<br />
						따라서 위내용과 회원약관을 위반하면, 사전 예고 없이 <br />
						회원활동이 정지 될 수 있습니다. <span>(모든 적립 포인트 취소)</span>
					</p>
				</div>
				{/*<!-- 구매일 선택 end -->*/}
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<p className="btm_btn">
			<Link to="#" onClick={this.agreeBtn.bind(this)}>동의</Link>
		</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(receiptGuide);