import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import MainHomeRankingSub from './mainHomeRankingSub';

import * as fnc from '../../js/fnc.js';

class mainHomeRanking extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			adult: cookies.get('adult'),

			rankings: []
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
	}
	init(){
		const url = this.state.apiurl+"/init/ranking_banner.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("=================================================================");
			//console.log(res.data.data);
			//console.log("=================================================================");
			//for(var i = 0 ; i < res.data.data.count ; i++){
			//	res.data.data.list[i].check_adult = true;
			//}
			this.setState({
				rankings : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	render(){
		const paramsLoop = {
			lazy: true,
			//loop: true,
			loopFillGroupWithBlank: true,
			rebuildOnUpdate: true
		}
		return (
		<section className="main_cont_wrap">
			<h2 className="main_cont_ttl">캐시카우 랭킹</h2>
			<div className="main_cont_inner main_rank_cate">
				<Swiper className="swiper-wrapper" {...paramsLoop}>
					{this.state.rankings.map((ranking, i) =>
					<div className="main_rank_cate_wrap swiper-slide" key={i}>
						{
						ranking.check_adult && this.state.adult !== "Y" &&
						<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
							<div className="age_limit_dimm"></div>
							<p>미성년자 불가 상품</p>
						</div>
						}
						<div className="main_rank_cate_ttl">
							<strong> {ranking.rkname}</strong>
						</div>
						<div className="main_rank_cate_list">
							<MainHomeRankingSub list={ranking.list} rkidx={ranking.rkidx}/>
						</div>
					</div>
					)}
				</Swiper>
			</div>
			<button name="homeRanking" id="homeRanking" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
		</section>
		)
	}
}

export default withCookies(mainHomeRanking);