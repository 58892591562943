import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_use_point_w from '../img/mycow/btn_use_point_w.png';

class mycowInquiry extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			mycowInquiryST: cookies.get('mycowInquiryST') == undefined ? 0 : ~~cookies.get('mycowInquiryST'),

			ilists: [],
			icount: 0
		};
	}
	componentDidMount(){
		const { cookies } = this.props;
		if(this.state.mycowInquiryST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowInquiryST);
				_this.setState({mycowInquiryST : 0});
				cookies.set('mycowInquiryST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			}, 700);
		}
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInquiry', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/guide/inquiry_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				ilists : res.data.data.list,
				icount : res.data.data.count
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	icateChg = (idx, e) => {
		e.preventDefault();
		$(".fcate").removeClass("on");
		$(".fTitle").removeClass("fold");
		$(".fTitle").next().hide();
		$("[data-fidx=\""+idx+"\"]").addClass("on");
		const url = this.state.apiurl+"/guide/faq_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 99);
		formData.append("offset", 1);
		formData.append("categoey", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				flists: res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	handleKeyPress = (e) => {
		if(e.key === "Enter"){
			var stxt = $("#stxt").val();
			document.location.href="/mycow/mycowInquirySearch/"+stxt;
		}
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowInquiryST', 0, { path: '/' });

		document.location.href="/mycow/mycowInFaq";
	}
	goPage = (url, e) => {
		const { cookies } = this.props;
		cookies.set('mycowInquiryST', $(window).height(), { path: '/' });

		document.location.href= url;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
			<h2>문의하기</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			{this.state.icount === 0 &&
			<div className="inquiry_guide_txt">
				<p>문의 사항은 신청 후 영업일 기준 3일 전후로 답변 드립니다.</p>
				<p>답변이 완료되면 푸시 알람을 보내 드립니다.</p>
			</div>
			}
			{this.state.icount !== 0 &&
		<div className="inquiry_guide_txt">
				<p>문의 사항은 접수 후 5일(영업일 기준) 내에 답변 드리고 있습니다.</p>
				<p>답변이 완료되면 푸시 알람을 보내 드립니다.</p>
			</div>
			}
			<div className="grey_gap"></div>
			{/*<!-- 문의 내역 -->*/}
			<div className="inquiry_list_wrap">
				<strong className="inquiry_list_ttl">문의 내역</strong>
				<table>
					<thead>
						<tr>
							<th className="w105">번호</th>
							<th className="w345">문의유형</th>
							<th className="w130">신청일</th>
							<th className="w170">진행상태</th>
						</tr>
					</thead>
					{this.state.icount === 0 &&
					<tbody>
						<tr><td className="no_list" colSpan="5">문의 내역이 없습니다.</td></tr>
					</tbody>
					}
					{this.state.icount !== 0 &&
					<tbody>
						{this.state.ilists.map((ilist, i) => 
						<tr key={i}>
							<td>{this.state.icount - i}</td>
							<td className="tal">
								{ilist.uicname}
								<Link to="#" onClick={this.goPage.bind(this, "/mycow/mycowInquiryView/"+ilist.uiidx)}>{ilist.title}</Link>
							</td>
							<td>{ ((ilist.insert_dt).substr(2, 8)).replace(/-/gi, '/') }</td>
							{ilist.status === 0 && <td><p className="stat wait">답변<br />대기</p></td>}
							{ilist.status === 1 && <td><p className="stat done">답변<br />완료</p></td>}
							{ilist.status === 2 && <td><p className="stat wait">회원<br />취소</p></td>}
						</tr>
						)}
					</tbody>
					}
				</table>
			</div>
			{/*<!-- 문의 내역 end -->*/}
			<div className="grey_gap"></div>
			<div className="faq_btm_btn">
				<p>최대 1년간 문의하신 내역을 <br />확인 가능합니다.</p>
				{/*<Link to="/mycow/mycowInquiryWrite">1:1 문의하기<img alt="" src={btn_use_point_w}/></Link>*/}
				<Link to="#" onClick={this.goPage.bind(this, "/mycow/mycowInquiryWrite")}><i>1:1 문의하기</i></Link>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInquiry);