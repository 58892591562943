import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import btn_emphasis_s from '../img/receipt/btn_emphasis_s.png';
import btn_arrow_s from '../img/receipt/btn_arrow_s.png';

class receiptChoice extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};


	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			adult: cookies.get('adult'),
			filetype: cookies.get('filetype'),
			filepath: cookies.get('filepath'),
			id: props.match.params.id,
			date: props.match.params.date,

			plists: [],
			select_type: "",
			select_ccidx: "",
			select_name: "",
			select_image: "",
			pdlists: [],
			now_ccidx : "",
			now_type : "",
			proc : false
		};
	}
	componentDidMount(){
		fnc.event_log("reward_product_list", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if((this.state.cidx === 0)){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/receipt/receiptChoice', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);

		const url = this.state.apiurl+"/reward/product_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("bidx", this.state.id);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.data.count > 0){
				this.setState({plists : res.data.data.list});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		//$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop").css("top", Math.max(0, (($(window).height() - $(".confirm_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop2").css("top", Math.max(0, (($(window).height() - $(".confirm_pop2").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".purch_num_pop").css("top", Math.max(0, (($(window).height() - $(".purch_num_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".idpw_nomatch_pop").css("top", Math.max(0, (($(window).height() - $(".idpw_nomatch_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(window).scroll(function() { 
		//$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop").css("top", Math.max(0, (($(window).height() - $(".confirm_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop2").css("top", Math.max(0, (($(window).height() - $(".confirm_pop2").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".purch_num_pop").css("top", Math.max(0, (($(window).height() - $(".purch_num_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".idpw_nomatch_pop").css("top", Math.max(0, (($(window).height() - $(".idpw_nomatch_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		});
		$(window).resize(function() { 
		//$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop").css("top", Math.max(0, (($(window).height() - $(".confirm_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".confirm_pop2").css("top", Math.max(0, (($(window).height() - $(".confirm_pop2").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".purch_num_pop").css("top", Math.max(0, (($(window).height() - $(".purch_num_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".idpw_nomatch_pop").css("top", Math.max(0, (($(window).height() - $(".idpw_nomatch_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		});
	}
	componentDidUpdate(){
		var preName = "";
		var standIdx = "";
		$(".accum_prod_receipt_ttl").each(function(idx){
			var nowName = $(this).html();
			if(preName == nowName){
				$(this).remove();
			}else{
				if(idx != 0)
				$(this).parent().css("border-top", "20px solid #f0f0f0");
				standIdx = idx;
			}
			preName = nowName;
		});
	}
	prodSelCancel(){
		var now_type = this.state.now_type;
		var now_ccidx = this.state.now_ccidx;
		var classVal = $(".prod_stat_"+now_ccidx).attr("class");
		if(now_type == "BAR"){
			classVal = classVal.replace(/num/gi, 'scan');
		}else{
			classVal = classVal.replace(/num/gi, '');
		}
		$(".prod_stat_"+now_ccidx).attr("class", classVal);
		$(".prod_stat_"+now_ccidx).html("선택")
		$("#sel_count_"+now_ccidx).val('');

		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		$(".confirm_pop2, .confirm_pop2_dimm").fadeOut();

		var total_price = 0;
		$(".sel_count").each(function(){
			var ccidx = $(this).data("ccidx");
			if($(this).val() != ""){
				var count = $("#sel_count_"+ccidx).val();
				var price = $("#sel_price_"+ccidx).val();
				total_price += count * price;
			}
		});
		if(total_price == 0){
			$(".btm_btn1").find("a").removeClass("on");
		}else{
			if(!$(".btm_btn1").find("a").hasClass("on")){
				$(".btm_btn1").find("a").addClass("on");
			}
		}
		$(".btm_btn1").find("a").html(total_price+"P 적립하기");
		$(".total_price_done").html(total_price+"P");
	}
	amountSel = (type, ccidx, name, image, limit_type, daily_limit, seasonal_limit, remain_trial, min_qty, e) => {

		//alert(type);
		//alert(ccidx);
		//alert(name);
		//alert(image);
		//alert(limit_type);
		//alert(daily_limit);
		//alert(seasonal_limit);
		//alert(remain_trial);
		//alert(min_qty);

		this.setState({
			now_type : type,
			now_ccidx : ccidx
		});

		if($("#sel_count_"+ccidx).val() != ""){
			var posY = $(window).scrollTop();
			this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			$(".confirm_pop2").css("top", Math.max(0, (($(window).height() - $(".confirm_pop").outerHeight()) / 2) + posY) + "px");
			$(".confirm_pop2, .confirm_pop2_dimm").fadeIn()
		}else{
			var max_qty = 0;
			if(limit_type === "none"){
				$(".accum_limit").hide();
				this.setState({
					max_qty : 0
				});
			}else if(limit_type === "day"){
				$(".accum_limit").show();
				$(".accum_limit").html("적립 한도 : 하루 "+ remain_trial +"개 남음");
				this.setState({
					max_qty : remain_trial
				});
				max_qty = remain_trial;
			}else if(limit_type === "season"){
				$(".accum_limit").show();
				$(".accum_limit").html("적립 한도 : 기간 "+ remain_trial +"개 남음");
				this.setState({
					max_qty : remain_trial
				});
				max_qty = remain_trial;
			}
			$(".accum_limit").prev().html(name);

			this.setState({
				min_qty : min_qty,
				select_type : type,
				select_ccidx : ccidx,
				select_name : name,
				select_image : image
			});

			$(".count").html(min_qty);

			$(".minus").find("a").removeClass("on");
			$(".plus").find("a").removeClass("on");
			if(max_qty > 1 || max_qty == 0){
				$(".plus").find("a").addClass("on");
			}

			$(".popImg").attr("src", image);

			$(".purch_num_pop_dimm").fadeIn();

			var posY = $(window).scrollTop();
			this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			$(".purch_num_pop").css("top", Math.max(0, (($(window).height() - $(".purch_num_pop").outerHeight()) / 2) + posY) + "px");
			$(".purch_num_pop").fadeIn();
		}
	}
	minus(){
		var count = ~~( $(".count").html() );
		var min = ~~(this.state.min_qty);
		var ccidx = this.state.select_ccidx;

		if(count == min) return;

		$(".plus").find("a").addClass("on");

		if(count > min){
			count--;
		}
		if(count == min){
			$(".minus").find("a").removeClass("on");
		}
		$(".count").html(count);
	}
	plus(){
		var count = ~~( $(".count").html() );
		var max = ~~(this.state.max_qty);
		var ccidx = this.state.select_ccidx;

		if(count == max) return;

		$(".minus").find("a").addClass("on");

		if(max == 0){
			count++;
		}else if( (count+1) < max){
			count++;
		}else if( (count+1) == max){
			count++;
			$(".plus").find("a").removeClass("on");
		}
		$(".count").html(count);
	}
	countSelect(){
		var ccidx = this.state.select_ccidx;
		var ctype = this.state.select_type;
		var cname = this.state.select_name;
		var cimage = this.state.select_image;
		var count = ~~$(".count").html();
		if(count === 0){
			$(".prod_stat_"+ccidx).removeClass("num");
			if(ctype == "BAR"){
				$(".prod_stat_"+ccidx).html("스캔");
				$(".prod_stat_"+ccidx).addClass("scan");
			}else{
				$(".prod_stat_"+ccidx).html("선택");
			}
			$("#sel_count_"+ccidx).val('');

			if(global.variable.osType === 0){
				$(window).scrollTop(this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");

			$(".purch_num_pop").fadeOut();
			$(".purch_num_pop_dimm").fadeOut();
		}else{
			if(ctype == "BAR"){

				var ccidx = this.state.select_ccidx;
				var url = this.state.apiurl+"/product/detail.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				formData.append("ccidx", ccidx);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					if(global.variable.osType == "1"){
						window.android.getProductBarcode(this.state.cidx, "", ccidx, "", "", "", cimage, cname, res.data.data.barcode);
					}
					if(global.variable.osType == "0"){
						window.webkit.messageHandlers.getProductBarcode.postMessage({ cidx : this.state.cidx, url : "", param1 : ccidx, param2 : "", param3 : "", param4 : "", imageurl : cimage, name : cname, bno : res.data.data.barcode } );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
				return;
			}else{
				if(!$(".prod_stat_"+ccidx).hasClass("num")) $(".prod_stat_"+ccidx).addClass("num");
				$(".prod_stat_"+ccidx).html(count+"개");
				$("#sel_count_"+ccidx).val(count);

				if(global.variable.osType === 0){
					$(window).scrollTop(this.state.posY);
					$("#wrap").css("top", 0);
					$("html, body").off('scroll touchmove');
				}
				$('html, body').css("overflow", "visible");

				$(".purch_num_pop").fadeOut();
				$(".purch_num_pop_dimm").fadeOut();
			}
		}
		var total_price = 0;
		$(".sel_count").each(function(){
			var ccidx = $(this).data("ccidx");
			if($(this).val() != ""){
				var count = $("#sel_count_"+ccidx).val();
				var price = $("#sel_price_"+ccidx).val();
				total_price += count * price;
			}
		});
		if(total_price == 0){
			$(".btm_btn1").find("a").removeClass("on");
		}else{
			if(!$(".btm_btn1").find("a").hasClass("on")){
				$(".btm_btn1").find("a").addClass("on");
			}
		}
		$(".btm_btn1").find("a").html(total_price+"P 적립하기");
		$(".total_price_done").html(total_price+"P");
		if(count !== 0){
			var posY = $(window).scrollTop();
			this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			//$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + posY) + "px");
			$(".certi_ok_pop").html("상품 선택이 완료되었습니다.");
			$(".certi_ok, .certi_ok_pop").fadeIn("fast");
			var _this = this;
			setTimeout(function(){
				if(global.variable.osType === 0){
					$(window).scrollTop(_this.state.posY);
					$("#wrap").css("top", 0);
					$("html, body").off('scroll touchmove');
				}
				$('html, body').css("overflow", "visible");
				$(".certi_ok, .certi_ok_pop").fadeOut("fast");
			}, 2000);
		}
	}
	popClose = (cname, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$("."+cname).fadeOut();
		$(".layer_dimm").fadeOut();
	}
	btmBtnClick1 = (e) => {
		if( !$(".btm_btn1").find("a").hasClass("on") ) return;
		$('html').scrollTop(0);
		$(".full_mem_header").hide();
		$(".full_layer_bg").hide();
		$(".full_layer").hide();

		var sel_item_cnt = 0;
		var sel_kind_cnt = 0;
		var total_price = 0;
		var item_array = new Array;
		var item_list_array = new Array;
		var _this = this;
		$(".sel_count").each(function(idx){
			var ccidx = $(this).data("ccidx");
			if($(this).val() != ""){
				item_array.push(ccidx);
				var count = $("#sel_count_"+ccidx).val();
				var price = $("#sel_price_"+ccidx).val();
				sel_item_cnt = ~~sel_item_cnt + ~~count;
				sel_kind_cnt++;
				total_price += count * price;

				const url = _this.state.apiurl+"/product/detail.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", _this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", _this.state.cidx);
				formData.append("ccidx", ccidx);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, _this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					res.data.data.itemcnt = count;
					item_list_array.push(res.data.data);
					_this.setState({pdlists : item_list_array});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
		});
		$(".hidden_mem_header").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".hidden_content").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".hidden_mem_header").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".hidden_content").animate({"margin-top":"0","opacity" : "1"}, 400);

		$(".store_receipt_txt").find("strong").html(sel_kind_cnt+"개의 상품이 선택되었습니다.");
		$(".btm_btn2").find("a").html("<span class='yellow'><b>"+sel_item_cnt+"개</b></span>&nbsp;&nbsp;<b>"+total_price+"P</b> 적립하기<img alt='' src='"+ btn_arrow_s +"'/>");
	}
	uploadProc = (e) => {
		if(this.state.proc) return;
		this.setState({proc : true});

		$('html').scrollTop(0);
		const _this = this;
		const filepath = _this.state.filepath;
		const bidx = _this.state.id;
		const receipt_date = _this.state.date;
		var filetype = _this.state.filetype;

		if(filetype == 'p'){
			filetype = "P"
		}
		if(filetype == 'e'){
			filetype = "E"
		}

		fnc.event_log("reward_product_confirm", "", "");

		if(global.variable.osType === 1){
			var list = new Array();
			var list_count = 0;
			$(".sel_count").each(function(idx){
				var ccidx = $(this).data("ccidx");
				if($(this).val() != ""){
					var count = $("#sel_count_"+ccidx).val();
					var type = $("#sel_type_"+ccidx).val();
					list_count++;

					var aJson = new Object();
					aJson.ccidx = ccidx;
					aJson.type = type;
					aJson.count = count;
					list.push(aJson);
				}
				if(idx+1 == $(".sel_count").length){
					window.android.getSendReceiptImage(
						_this.state.cidx,
						"",
						"",
						"",
						"",
						"",
						bidx,
						receipt_date,
						list_count,
						JSON.stringify(list),
						filepath,
						filetype
					);
				}
			});
		}else{
			var list = new Array();
			var list_count = 0;
			$(".sel_count").each(function(idx){
				var ccidx = $(this).data("ccidx");
				if($(this).val() != ""){
					var count = $("#sel_count_"+ccidx).val();
					var type = $("#sel_type_"+ccidx).val();
					list_count++;

					var aJson = new Object();
					aJson.ccidx = ccidx;
					aJson.type = type;
					aJson.count = count;
					list.push(aJson);
				}
				if(idx+1 == $(".sel_count").length){
					window.webkit.messageHandlers.getSendReceiptImage.postMessage({
						cidx : _this.state.cidx,
						url : "",
						param1 : "",
						param2 : "",
						param3 : "",
						param4 : "",
						bidx : bidx,
						receipt_date : receipt_date,
						list_count : list_count+"",
						list : JSON.stringify(list),
						filepath : filepath,
						receipt_type : filetype
					});
				}
			});
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);
		//alert(jsonStr);
		if(resObj.key == 'getProductBarcode'){
			var ccidx = this.state.select_ccidx;
			var url = this.state.apiurl+"/product/detail.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", resObj.cidx);
			formData.append("ccidx", ccidx);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var count = ~~$(".count").html();
				if(res.data.data.barcode == resObj.barcode){
					$(".prod_stat_"+ccidx).removeClass("scan")
					if(!$(".prod_stat_"+ccidx).hasClass("num")) $(".prod_stat_"+ccidx).addClass("num");
					$(".prod_stat_"+ccidx).html(count+"개");
					$("#sel_count_"+ccidx).val(count);

					if(global.variable.osType === 0){
						$(window).scrollTop(this.state.posY);
						$("#wrap").css("top", 0);
						$("html, body").off('scroll touchmove');
					}
					$('html, body').css("overflow", "visible");

					$(".purch_num_pop").fadeOut();
					$(".purch_num_pop_dimm").fadeOut();
				}else{
					return;
				}
				var total_price = 0;
				$(".sel_count").each(function(){
					var ccidx = $(this).data("ccidx");
					if($(this).val() != ""){
						var count = $("#sel_count_"+ccidx).val();
						var price = $("#sel_price_"+ccidx).val();
						total_price += count * price;
					}
				});
				if(total_price == 0){
					$(".btm_btn1").find("a").removeClass("on");
				}else{
					if(!$(".btm_btn1").find("a").hasClass("on"))
						$(".btm_btn1").find("a").addClass("on");
				}
				$(".btm_btn1").find("a").html(total_price+"P 적립하기");
				$(".total_price_done").html(total_price+"P");

				if(count !== 0){
					var posY = $(window).scrollTop();
						this.setState({
						posY : posY
					});
					if(global.variable.osType === 0){
						$("#wrap").css("top",-($(window).scrollTop()));
						$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
					}
					$('body').css("overflow", "hidden");
					$(".certi_ok_pop").html("완료");
					$(".certi_ok, .certi_ok_pop").fadeIn("fast");
					var _this = this;
					setTimeout(function(){
						if(global.variable.osType === 0){
							$(window).scrollTop(_this.state.posY);
							$("#wrap").css("top", 0);
							$("html, body").off('scroll touchmove');
						}
						$('html, body').css("overflow", "visible");

						$(".certi_ok, .certi_ok_pop").fadeOut("fast");
					}, 2000);
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){
					fnc.network_error_pop();
					return;
				}else{
					alert( JSON.stringify(res) );
				}
			});
		}
		if(resObj.key == 'getSendReceiptImage'){
			fnc.event_log("reward_complete", "", "");
			var error_code = resObj.result_message.error_code;
			if(error_code == undefined){

				$(".hidden_mem_header").hide();
				$(".hidden_content").hide();

				$(".receipt_upload_graph_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
				$(".receipt_upload_graph_pop").animate({"margin-top":"0","opacity" : "1"}, 400);

				var rate = 0;
				var graphInterval = setInterval(function(){
					rate = rate + 1;
					rate = Math.floor(rate);
					$(".procPct").html(rate + "%");
					$(".receipt_upload_bar").css("width", rate + "%");

					if(rate == 100){
						clearInterval(graphInterval);
						$(".receipt_upload_graph_pop").hide();

						$(".receipt_upload_done_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
						$(".receipt_upload_done_pop").animate({"margin-top":"0","opacity" : "1"}, 400);
					}
				}, 30);
			}else{
				this.alertPop( (resObj.result_message.error).replace(/\n/g, "<br/>") );
			}
		}
		if(resObj.key == "pback"){
			//this.goPreStep(3);
			this.goBackConfirm();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	goBackConfirm(){
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".confirm_pop").css("top", Math.max(0, (($(window).height() - $(".confirm_pop").outerHeight()) / 2) + posY) + "px");
		$(".confirm_pop, .confirm_pop_dimm").fadeIn()
	}
	popCls(){
		var _this = this;

		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		$(".confirm_pop, .confirm_pop_dimm").fadeOut();
		$(".confirm_pop2, .confirm_pop2_dimm").fadeOut();
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop").fadeOut("fast");
	}
	fold = (idx, e) => {
//		if($(".accum_prod_receipt_each"+idx).length == 1){

			if($(".accum_prod_receipt_each"+idx+":eq(0)").find(".prodList"+idx).css("display") === "none"){
				$(".accum_prod_receipt_each"+idx+":eq(0)").find(".prodList"+idx).show();
				$(".accum_prod_receipt_each"+idx).show();
				$(".arrow"+idx).removeClass("on");
			}else{
				$(".accum_prod_receipt_each"+idx+":eq(0)").find(".prodList"+idx).hide();
				$(".accum_prod_receipt_each"+idx+":gt(0)").hide();
				$(".arrow"+idx).addClass("on");
			}

//		}else{
//			if($(".accum_prod_receipt_each"+idx+":gt(0)").css("display") === "none"){
//				$(".accum_prod_receipt_each"+idx+":eq(0)").find(".prodList"+idx).show();
//				$(".accum_prod_receipt_each"+idx).show();
//				$(".arrow"+idx).removeClass("on");
//			}else{
//				$(".accum_prod_receipt_each"+idx+":eq(0)").find(".prodList"+idx).hide();
//				$(".accum_prod_receipt_each"+idx+":gt(0)").hide();
//				$(".arrow"+idx).addClass("on");
//			}
//		}
	}
	goPreStep = (idx, e) => {
		if(idx == 3){
			$(".hidden_mem_header").hide();
			$(".hidden_content").hide();

			$(".full_mem_header").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".full_layer_bg").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".full_layer").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".full_mem_header").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".full_layer_bg").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".full_layer").animate({"margin-top":"0","opacity" : "1"}, 400);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header cate full_mem_header">
			<p className="btn_close"><Link to="#" onClick={this.goBackConfirm.bind(this)}>닫기</Link></p>
			<h2>적립할 상품/영수증 선택</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPop" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>
			{/*<!-- 완료 팝업 end -->*/}

			{/*<!-- 영수증 등록 팝업 -->*/}
			<div className="layer_dimm confirm_pop_dimm" style={{display:"none"}}></div>
			<div className="prod_detail_pop confirm_pop fixed" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>적립신청을 취소하시겠습니까?<br/>(영수증은 저장되지 않습니다.)</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>아니오</Link>
					<Link className="dgrey" to={"/receipt/receiptGuide/"+this.state.id}>네</Link>
				</div>
			</div>
			{/*<!-- 영수증 등록 팝업 end -->*/}

			{/*<!-- 영수증 등록 팝업 -->*/}
			<div className="layer_dimm confirm_pop2_dimm" style={{display:"none"}}></div>
			<div className="prod_detail_pop confirm_pop2 fixed" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>상품 선택을 취소하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>아니오</Link>
					<Link className="dgrey" to="#" onClick={this.prodSelCancel.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 영수증 등록 팝업 end -->*/}

			{/*<!-- 적립할상품/영수증선택 레이어 -->*/}
			<div className="full_layer_bg"></div>
			<div className="full_layer">
				{/*<!-- <header className="mem_header">
					<p className="btn_close"><Link to="#" onClick={this.goBackConfirm.bind(this)}>닫기</Link></p>
					<h2>적립할 상품/영수증 선택</h2>
				</header> -->*/}
				<div className="accum_prod_receipt_list">
					{this.state.plists.map((plist, index) =>
					<div className={"accum_prod_receipt_each accum_prod_receipt_each"+plist.pcidx} key={index}>
						<h3 className="accum_prod_receipt_ttl">{plist.pc_name}<p className="key_prod_fold"><Link to="#" className={"arrow"+plist.pcidx} onClick={this.fold.bind(this, plist.pcidx)}></Link></p></h3>
						<ul className={"prodList"+plist.pcidx}>
							<li>
								{
								plist.limit_type === "day" && plist.remain_trial === 0 &&
								<div className="accum_done_cover">
									<div className="accum_done_dimm"></div>
									<p>적립 완료</p>
								</div>
								}
								{
								plist.limit_type === "season" && plist.remain_trial === 0 &&
								<div className="accum_done_cover">
									<div className="accum_done_dimm"></div>
									<p>적립 완료</p>
								</div>
								}
								{
								plist.check_adult === true && this.state.adult !== "Y" &&
								<div className="age_limit_cover">
									<div className="age_limit_dimm"></div>
									<p>미성년자 불가 상품</p>
								</div>
								}
								<Link to="#">
									<div className="prod_info">
										<input type="hidden" name="sel_ccidx" id={"sel_ccidx_"+plist.ccidx} value={plist.ccidx} readOnly/>
										<input type="hidden" name="sel_type" id={"sel_type_"+plist.ccidx} value={plist.type} readOnly/>
										<input type="hidden" className="sel_count" name="sel_count" id={"sel_count_"+plist.ccidx} data-ccidx={plist.ccidx}/>
										{plist.reward_method === "F" && <input type="hidden" name="sel_price" id={"sel_price_"+plist.ccidx} value={plist.reward} readOnly/>}
										{plist.reward_method === "R" && <input type="hidden" name="sel_price" id={"sel_price_"+plist.ccidx} value={plist.reward} readOnly/>}
										<img alt="" src={plist.image} style={{width:"90px", height:"90px"}}/>
										{plist.reward_method === "F" && <p className="prod_info_txt"><strong>{plist.reward}P</strong><br />{plist.name}</p>}
										{plist.reward_method === "R" && <p className="prod_info_txt"><strong>{plist.reward}%</strong><br />{plist.name}</p>}
										{plist.type === "SEL" && <p className={"prod_stat prod_stat_"+plist.ccidx} onClick={this.amountSel.bind(this, plist.type, plist.ccidx, plist.name, plist.image, plist.limit_type, plist.daily_limit, plist.seasonal_limit, plist.remain_trial, plist.min_qty)}>선택</p>}
										{plist.type === "BAR" && <p className={"prod_stat scan prod_stat_"+plist.ccidx} onClick={this.amountSel.bind(this, plist.type, plist.ccidx, plist.name, plist.image, plist.limit_type, plist.daily_limit, plist.seasonal_limit, plist.remain_trial, plist.min_qty)}>스캔</p>}
										{plist.type === "REC" && <p className={"prod_stat prod_stat_"+plist.ccidx} onClick={this.amountSel.bind(this, plist.type, plist.ccidx, plist.name, plist.image, plist.limit_type, plist.daily_limit, plist.seasonal_limit, plist.remain_trial, plist.min_qty)}>선택</p>}
									</div>
									<div className="prod_tag">
										{plist.limit_type === "none" && <p>무제한</p>}
										{plist.limit_type === "day" && <p>하루 {plist.daily_limit - plist.remain_trial}/{plist.daily_limit}</p>}
										{plist.limit_type === "season" && <p>기간 {plist.seasonal_limit - plist.remain_trial}/{plist.seasonal_limit}</p>}
									</div>
								</Link>
							</li>
						</ul>
					</div>
					)}
				</div>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<p className="btm_btn btm_btn1">
					<Link to="#" onClick={this.btmBtnClick1.bind(this)}>0P 적립하기</Link>
				</p>
			</div>
			{/*<!-- 적립할상품/영수증선택 레이어 end -->*/}

			{/*<!-- 영수증 업로드 팝업 -->*/}
			<div className="receipt_upload_pop receipt_upload_graph_pop" style={{display:"none"}}>
				<div className="receipt_upload_wrap">
					<p>영수증 업로드 중</p>
					<strong className="procPct">0%</strong>
					<div className="receipt_upload_graph">
						<div className="receipt_upload_bar" style={{width:"0%"}}></div>
						<div className="receipt_upload_rail"></div>
					</div>
				</div>
			</div>
			{/*<!-- 영수증 업로드 팝업 end -->*/}

			{/*<!-- 적립신청완료 팝업 -->*/}
			<div className="receipt_upload_pop receipt_upload_done_pop" style={{display:"none"}}>
				<div className="accum_req_done">
					<figure>
						<span className="total_price_done">300P</span>
					</figure>
					<strong>적립 신청이 완료되었습니다.</strong>
					<p>포인트는 영업일 기준 2일 전후로 적립해 드립니다.</p>
				</div>
				<p className="btm_btn">
					<Link className="on" to="/point/point">확인</Link>
				</p>
			</div>
			{/*<!-- 적립신청완료 팝업 end -->*/}

			{/*<!-- 구매수량 팝업 -->*/}
			<div className="layer_dimm purch_num_pop_dimm" style={{display:"none"}}></div>
			<div className="purch_num_pop" style={{display:"none"}}>
				<strong className="purch_num_popttl">
					구매수량은 몇 개 입니까?
					<Link className="btn_close" to="#" onClick={this.popClose.bind(this, 'purch_num_pop')}></Link>
				</strong>
				<div className="purch_num_pop_cont">
					<figure><img alt="" className="popImg" src="" /></figure>
					<strong>통조림</strong>
					<p className="accum_limit">적립 한도 : 하루 5개 남음</p>
				</div>
				<div className="purch_num_count">
					<p className="minus"><Link to="#" onClick={this.minus.bind(this)}>빼기</Link></p>
					<p className="count">1</p>
					<p className="plus"><Link to="#" onClick={this.plus.bind(this)}>더하기</Link></p>
				</div>
				<Link className="purch_count_ok" to="#" onClick={this.countSelect.bind(this)}>확인</Link>
			</div>
			{/*<!-- 구매수량 팝업 end -->*/}


			{/*<!-- <!-- header 영역 -->*/}
			<header className="mem_header hidden_mem_header" style={{display:"none"}}>
				<p className="btn_prev"><Link to="#" onClick={this.goPreStep.bind(this, 3)}>앞으로</Link></p>
				<h2>적립 신청하기</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="pt100 hidden_content" id="content" style={{display:"none"}}>
				<div className="store_receipt_ttl sel_icon">
					<div className="store_receipt_txt">
						<strong>2개의 상품이 선택되었습니다.</strong>
					</div>
					<p className="accum_txt_warn">
						<img alt="" src={btn_emphasis_s}/><span>영수증 1장당 한번만</span> 등록됩니다. <br />모든 상품/영수증 적립을 동시에 신청해 주세요.
					</p>
				</div>
				<div className="grey_gap"></div>
				<div className="accum_prod_receipt_list selected">
					<div className="accum_prod_receipt_each">
						<h3 className="accum_prod_receipt_ttl">선택 상품</h3>
						<ul>
							{this.state.pdlists.map((pdlist, index) =>
							<li key={index}>
								<Link to="#">
									<div className="prod_info">
										<img alt="" src={pdlist.thumb} width="90"/>
										{pdlist.reward_method === "F" && <p className="prod_info_txt"><strong>{pdlist.reward}P</strong><br />{pdlist.name}</p>}
										{pdlist.reward_method === "R" && <p className="prod_info_txt"><strong>{pdlist.reward}%</strong><br />{pdlist.name}</p>}
										<p className="prod_stat num">{pdlist.itemcnt}개</p>
									</div>
									<div className="prod_tag">
										{pdlist.limit_type === 'none' && <p>무제한</p>}
										{pdlist.limit_type === 'day' && <p>하루 {pdlist.daily_limit - pdlist.remain_trial}/{pdlist.daily_limit}</p>}
										{pdlist.limit_type === 'season' && <p>기간 {pdlist.seasonal_limit - pdlist.remain_trial}/{pdlist.seasonal_limit}</p>}
										{pdlist.type === 'REC' && pdlist.e_receipt === '1' && <p>전자영수증</p>}
										{pdlist.type === 'REC' && pdlist.p_receipt === '1' && <p>종이영수증</p>}

									</div>
								</Link>
							</li>
							)}
						</ul>
					</div>
				</div>
				{/*<!--<p className="btm_btn btm_btn2">
					<Link className="on" to="#" onClick={this.uploadProc.bind(this)}><span className="yellow"><b>3개</b></span>&nbsp;&nbsp;<b>300P</b> 적립하기<img alt="" src={btn_arrow_s}/></Link>
				</p>-->*/}
			</div>
			{/*<!-- 내용 영역 end -->*/}


		</div>
		<p className="btm_btn btm_btn2">
			<Link className="on" to="#" onClick={this.uploadProc.bind(this)}><span className="yellow"><b></b></span>&nbsp;&nbsp;<b>0P</b> 적립하기<img alt="" src={btn_arrow_s}/></Link>
		</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(receiptChoice);