import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton } from 'react-share';
import { KaKaoTalkButton  } from "react-social-kr";
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mainOnlineMallThroughOut extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};


	constructor(props) {
		super(props);

		const { cookies } = props;
		const omidx = props.match.params.id;
		const opidx = props.match.params.pid;

		const cidx = cookies.get('cidx') == undefined ? 0 : cookies.get('cidx');
		const apiurl = cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl');

		this.state = {
			odetail: [],
			oimages: [],
			cidx: cidx,
			apiurl: apiurl,
			version: cookies.get('version'),
			email: cookies.get('uemail'),
			omidx: omidx,
			opidx: opidx,
			autoNoLogin: cookies.get('autoNoLogin'),
			reward:"",
			config : global.variable.config
		};
	}
	componentDidMount(){
		fnc.event_log("online_mall_enter", this.state.opidx, "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const osType = global.variable.osType;
		const omidx = this.state.omidx;
		var url = this.state.apiurl+"/online/detail.json";
		var formData = new FormData();
		formData.append("os", osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("omidx", this.state.omidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("online_detail");
			//console.log(res);
			this.setState({
				odetail : res.data.data.info,
				oimages : res.data.data.info.images
			});
			if(res.data.data.product_count > 0){
				for(var i=0 ; i < res.data.data.product_count ; i++){
					if(res.data.data.product_list[i].opidx == this.state.opidx){
						var obj = res.data.data.product_list[i];
					}
				}
				this.setState({
					oproducts : res.data.data.product_list
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	componentDidUpdate(){
	}
	goSite(){
		const osType = global.variable.osType;
		const omidx = this.state.omidx;
		const opidx = this.state.opidx;
		var url = this.state.apiurl+"/online/detail.json";
		var formData = new FormData();
		formData.append("os", osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("omidx", this.state.omidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("online_detail");
			//console.log(res);
			this.setState({
				odetail : res.data.data.info,
				oimages : res.data.data.info.images
			});
			if(res.data.data.product_count > 0){
				for(var i=0 ; i < res.data.data.product_count ; i++){
					if(res.data.data.product_list[i].opidx == this.state.opidx){
						url = this.state.apiurl+"/online/request_online_session.json";
						formData = new FormData();
						formData.append("os", osType);
						formData.append("appver", this.state.version);
						formData.append("applocale", "kr");
						formData.append("bypass_emergency", 0);
						formData.append("cidx", this.state.cidx);
						formData.append("ccidx", res.data.data.product_list[i].ccidx);

						var obj = res.data.data.product_list[i];
						let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
						axios.post(url, formData, this.state.config).then(res2 => {
							if( ~~res2.data.result === -1){
								if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
								if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
							}
							clearInterval(spinnerInterval2);
							url = obj.url;

							if(this.state.cidx !== 0){
								if(url.indexOf("?") != -1) {
									url = url + "&app_id=cashcow&session_key="+res2.data.data.session_key;
								}else{
									url = url + "?app_id=cashcow&session_key="+res2.data.data.session_key;
								}
							}
							if( (url).substr(0, 4) == "http"){
								url = url;
							}else{
								url = "http://"+url;
							}
							if(osType == "1"){
								window.android.openUrl(this.state.cidx, url, "", "", "", "");
							}
							if(osType == "0"){
								window.webkit.messageHandlers.openUrl.postMessage({ cidx : this.state.cidx, url : url, param1 : "", param2 : "", param3 : "", param4 : "" });
							}
							document.location.href = "/main/onlineMallThroughIn/"+omidx+"/"+opidx;
						}).catch(res => {
							var msg = res.message;
							if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
						});
					}
				}
				this.setState({
					oproducts : res.data.data.product_list
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			const omidx = this.state.omidx;
			document.location.href = "/main/onlineMall/"+omidx;
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		const paramsOnline = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="online_via_wrap">
				<img alt="" src={this.state.odetail.logo} />
				<p>{this.state.odetail.name}로 이동합니다.<br /><br />본 화면을 통해 이동 후 구매한 경우에만 <br />포인트가 적립됩니다.<br /><br />상품 구매 후 반드시 다시 캐시카우로 돌아오세요.</p>
			</div>
		</div>
		<p className="btn_login on"><Link to="#" onClick={this.goSite.bind(this)}>확인</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainOnlineMallThroughOut);