import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import * as fnc from '../../js/fnc.js'; 

class mainHomeRankingSub extends React.Component {
	homHotk = (rkidx, e) => {

		fnc.event_log("home_main", "home_main_click", "list_ranking");

		$("#rankclick").val(rkidx);
		$(".swiper-pagination-bullet").eq(1).click();
		setTimeout(function(){
			$(".rankHotk[idx="+rkidx+"]").click();
		}, 500);
	}
	render(){
		return (
			<ol>
				{this.props.list.map((item, i) =>
				<li key={i} >
					<Link to="#" className="homeHotk" data-idx={this.props.rkidx} onClick={this.homHotk.bind(this, this.props.rkidx)}>
						<figure>
							<i className={i == 0 ? "badge win" : "badge"}>{item.ranking}위</i>
							<img alt="" src={item.h_thumb} />
						</figure>
						<p>{item.goods_name}</p>
					</Link>
				</li>
				)}
			</ol>
		)
	}
}

export default mainHomeRankingSub;