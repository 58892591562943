import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import icon_time_limit from '../img/mem/icon_time_limit.png';

class findPw extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			originPage: cookies.get('originPage'),
			userid: "",
			enc_userid: "",
			mode: "",
			maxtime: 180,
			cidx: 0,
			startStat: false,
			wh:0,
			focusChk : ""
		};
		if(global.variable.osType === 1){
			window.android.getDeviceInfo("", "", "", "", "", "");
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "value", param2 : "value", param3 : "value" , param4 : "value" } );
		}
	}
	componentDidMount(){
		fnc.event_log("find_pass", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$(".time_remain").html("");

		var _this = this;
		_this.setState({wh : $(window).height()});
		setTimeout(function(){
			_this.setState({wh : $(window).height()});
		}, 700);
	}
	buttonOnChk = (e) => {
		if( $("input[name=pwFind]:checked").val() === "1" ){
			$(".find_pw_info_input1").show();
			$(".find_pw_info_input2").hide();
			$("#pwFindId1").focus();

			if(global.variable.osType == 0){
				if ($('#pwFindId1').is(':focus')) {
					$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});
				} else {
					$('body').removeClass('notouch').off('scroll touchmove mousewheel');
				}
			}


			if(global.variable.osType == 1){
				window.android.setWebviewFocus();
			}
		}
		if( $("input[name=pwFind]:checked").val() === "2" ){
			$(".find_pw_info_input1").hide();
			$(".find_pw_info_input2").show();
			$("#pwFindId2").focus();

			if(global.variable.osType == 0){
				if ($('#pwFindId2').is(':focus')) {
					$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});
				} else {
					$('body').removeClass('notouch').off('scroll touchmove mousewheel');
				}
			}


			if(global.variable.osType == 1){
				window.android.setWebviewFocus();
			}
		}
		$("#pwFindId1").val("");
		$("#pwFindId1").removeClass("ok");

		$("#pwFindId2").val("");
		$("#pwFindId2").removeClass("ok");

		$("#pwFindPhone2").val("");
		$("#pwFindPhone2").removeClass("ok");

		$(".certi_num_input").hide();
		$("#certNum").val("");
		$("#certNum").removeClass("ok");

		$(".goNext").removeClass("on");

		$(".time_remain").html("");
		$(".certi_time_limit").html("");
		this.setState({
			maxtime : -1,
			mode : ""
		});
	}
	pwEmailChk1 = (e) => {
		var pwFindId1 = $("#pwFindId1").val();
		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		$("#pwFindId1").removeClass("ok");
		$("#pwFindId1").removeClass("wrong");
		if(pwFindId1.match(emailRegExp) == null) {
			$(".goNext").removeClass("on");
		}else{
			$("#pwFindId1").addClass("ok");
			$(".goNext").addClass("on");
		}
	}
	pwEmailChk2 = (e) => {
		var pwFindId2 = $("#pwFindId2").val();
		var emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
		$("#pwFindId2").removeClass("ok");
		$("#pwFindId2").removeClass("wrong");
		if(pwFindId2.match(emailRegExp) == null) {
			$(".goNext").removeClass("on");
		}else{
			$("#pwFindId2").addClass("ok");
		}
		if($("#pwFindId2").hasClass("ok") && $("#pwFindPhone2").hasClass("ok")){
			$(".goNext").addClass("on");
		}else{
			$(".goNext").removeClass("on");
		}
	}
	pwPhoneChk2 = (e) => {
		var pwFindPhone2 = $("#pwFindPhone2").val();
		var hpRegExp = /01(0|1|6|7|8|9)(\d{4}|\d{3})\d{4}$/g;
		$("#pwFindPhone2").removeClass("ok");
		$("#pwFindPhone2").removeClass("wrong");
		if(pwFindPhone2.match(hpRegExp) == null) {
			$(".goNext").removeClass("on");
		}else{
			$("#pwFindPhone2").addClass("ok");
		}
		if($("#pwFindId2").hasClass("ok") && $("#pwFindPhone2").hasClass("ok")){
			$(".goNext").addClass("on");
		}else{
			$(".goNext").removeClass("on");
		}
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	goNext = (e) =>{
		e.preventDefault();
		if(!$(".goNext").hasClass("on")){
			var focusChk = this.state.focusChk;
			if(focusChk !== ""){
				$("#"+focusChk).focus();
			}
			return;
		}

		const { cookies } = this.props;
		var osType = global.variable.osType;
		var userid = this.state.userid;
		var originPage = this.state.originPage;
		if(this.state.mode == "change"){
			if( !$(".goNext").hasClass("on") ) return;
			var password = $("#newpw").val();
			if(osType == 1){
				var url = this.state.apiurl+"/login/reset_password.json";
				var formData = new FormData();
				formData.append("os", osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", $.trim(window.android.encryptBase64(userid)));
				formData.append("password", $.trim(window.android.encryptBase64(password)));
				formData.append("method", "cis");
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".certi_ok_pop").html("완료");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");
						if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
							cookies.set('originPage', '', { path: '/' });
							setTimeout(function(){
								document.location.href=originPage;
							}, 2000);
						}else{
							setTimeout(function(){
								$(".certi_ok, .certi_ok_pop").fadeOut("fast");
								document.location.href="/member/login";
							}, 2000);
						}
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if(osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : password, param1 : "password", param2 : "", param3 : "" , param4 : "" } )
			}
			return;
		}
		if(this.state.mode == "changes"){
			if( !$(".goNext").hasClass("on") ) return;
			var password = $("#newpw").val();
			if(osType == 1){
				var url = this.state.apiurl+"/login/reset_password.json";
				var formData = new FormData();
				formData.append("os", osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", $.trim(window.android.encryptBase64($("#pwFindId2").val())));
				formData.append("password", $.trim(window.android.encryptBase64(password)));
				formData.append("method", "sms");
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".certi_ok_pop").html("완료");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");
						if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
							cookies.set('originPage', '', { path: '/' });
							setTimeout(function(){
								document.location.href=originPage;
							}, 2000);
						}else{
							setTimeout(function(){
								$(".certi_ok, .certi_ok_pop").fadeOut("fast");
								document.location.href="/member/login";
							}, 2000);
						}
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if(osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : password, param1 : "passwords", param2 : "", param3 : "" , param4 : "" } )
			}
			return;
		}
		if(this.state.mode == "smschk"){
			if( !$(".goNext").hasClass("on") ) return;

			const cookie_cidx = (this.state.cidx != undefined ? this.state.cidx : 0);
			var url = this.state.apiurl+"/login/confirm_sms_code.json";
			var formData = new FormData();
			formData.append("os", osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", "0");
			formData.append("type", "reset_password");
			formData.append("code", $("#certNum").val());
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var error_code = res.data.error_code;
				if(error_code == undefined){
					this.setState({
						mode : "changes",
						maxtime : -1
					});
					$(".time_remain").html("");
					//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
					$(".certi_ok_pop").html("인증 완료");
					$(".certi_ok, .certi_ok_pop").fadeIn("fast");
					setTimeout(function(){
						$(".certi_ok, .certi_ok_pop").fadeOut("fast");
						$(".find_idpw_wrap").eq(0).hide();
						$(".find_idpw_wrap").eq(1).show();
						$("#pwFindId1").val("");
						$(".goNext").removeClass("on");
						$(".goNext").html("확인");
						$("#newpw").focus();
//						setTimeout(function(){
//							if(global.variable.osType == 1){
//								window.android.setWebviewFocus();
//							}
//						}, 1000);
					}, 2000);
				}else{
					var error = res.data.error;
					this.alertPop( error.replace(/\n/g, '<br/>') );
					return;
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
			return;
		}

		if($("#pwFind1").prop("checked") + $("#pwFind2").prop("checked") === 0){
			this.alertPop("원하는 방법을 선택해주세요.");
			return;
		}
		if($("#pwFind1").prop("checked")){

			var email = $("#pwFindId1").val();
			if(email === ""){
				this.alertPop("이메일을 입력해주세요.");
				$("#pwFind1").focus();
				return;
			}
			if(osType == 1){
				var url = this.state.apiurl+"/login/check_userid.json";
				var formData = new FormData();
				formData.append("os", osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", $.trim(window.android.encryptBase64(email)));
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						window.android.getIdentity("0", "url", "param1", "param2", "param3", "param4", "findpw", email);
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
						return;
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if(osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : email, param1 : "email", param2 : "", param3 : "" , param4 : "" } )
				return;
			}
		}
		if($("#pwFind2").prop("checked")){
			if($(".goNext").hasClass("on")){
				if(global.variable.osType == 1){
					var enc_email = $.trim(window.android.encryptBase64($("#pwFindId2").val()));
					this.smsSend(enc_email);
				}
				if(global.variable.osType == 0){
					window.webkit.messageHandlers.encryptBase64.postMessage({ str : $("#pwFindId2").val(), param1 : "smsSend", param2 : "", param3 : "" , param4 : "" } )
					return;
				}
			}
		}
	}

	smsSend = (enc_email, e) => {
		var _this = this;

		var url = this.state.apiurl+"/login/request_sms_code.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "reset_password");
		formData.append("email", enc_email);
		formData.append("phone", $("#pwFindPhone2").val());
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;

			if(error_code == undefined){

				$(".time_remain").html("남은시간: 03분 00초");
				$(".certi_time_limit").html("인증번호를 입력해 주세요.<br/>인증 번호 문자가 오지 않는경우 스팸처리를 해제해 주세요.");
				$(".certi_num_input").show();

				var startStat = this.state.startStat;
				_this.setState({
					maxtime : 180
				});
				if(startStat){
					return;
				}
				_this.setState({
					startStat : true
				});

				$(".goNext").removeClass("on");
				var time = setInterval(function(){
					var maxtime = _this.state.maxtime;
					if(maxtime < 0) return;
					maxtime--;
					_this.setState({
						maxtime : maxtime
					});
					if(maxtime == 180){
						$(".certi_time_limit").html("인증번호를 입력해 주세요.<br/>인증 번호 문자가 오지 않는경우 스팸처리를 해제해 주세요.");
					}else if(maxtime == 0){
						$(".time_remain").html("남은시간: 00분 00초");
						$(".certi_time_limit").html("인증번호 유효시간이 경과하였습니다.<br/>인증번호를 다시 발송해주세요.");
						$(".goNext").removeClass("on");
						//clearInterval(time);
					}else if(maxtime < 0){
					}else{
						$(".time_remain").html("남은시간: 0"+Math.floor(maxtime/60)+"분 "+(maxtime%60 < 10 ? "0"+maxtime%60 : maxtime%60)+"초");
					}
				}, 1000);

			}else{
				this.alertPop( (res.data.error).replace(/\n/g, '<br/>') );
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){
				fnc.network_error_pop();
				return;
			}else{
				this.alertPop( JSON.stringify(res) );
			}
		});
	}
	reSend = (e) => {
		if(global.variable.osType == 1){
			var enc_email = $.trim(window.android.encryptBase64($("#pwFindId2").val()));
			this.reSendProc(enc_email);
		}
		if(global.variable.osType == 0){
			this.reSendProc(this.state.enc_userid);
			return;
		}
	}
	reSendProc = (enc_email, e) =>{
		var _this = this;

		var url = this.state.apiurl+"/login/request_sms_code.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", "0");
		formData.append("type", "reset_password");
		formData.append("email", enc_email);
		formData.append("phone", $("#pwFindPhone2").val());
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			$(".goNext").removeClass("on");
			_this.setState({
				maxtime : 180
			});
			$(".time_remain").html("남은시간: 03분 00초");
			$(".certi_time_limit").html("인증번호를 입력해 주세요.<br/>인증 번호 문자가 오지 않는경우 스팸처리를 해제해 주세요.");
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	cerNumChk = (e) => {
		var certNum = $("#certNum").val();
		if(certNum.length == 6){
			if(!$(".goNext").hasClass("on")) $(".goNext").addClass("on");
			this.setState({mode : "smschk"});
		}else{
			$(".goNext").removeClass("on")
			this.setState({mode : ""});
		}
	}
	pwChg = (e) => {
		var newpw = $("#newpw").val();
		var newpwre = $("#newpwre").val();
		var passRegExp = /^[A-Za-z0-9!@#$%^*-_=]{6,12}$/;
		if(newpw == ""){
			$("#newpw").removeClass("ok");
			$("#newpw").removeClass("wrong");
			$(".pw_wran").eq(0).html("");
		}else if(newpw.match(passRegExp) == null) {
			$("#newpw").removeClass("ok");
			if(!$("#newpw").hasClass("wrong")) $("#newpw").addClass("wrong");
			//$(".pw_wran").eq(0).html("6~12자 영문 대/소문자, 숫자, 특수문자를 사용해주세요.");
			$(".pw_wran").eq(0).html("비밀번호는 6자 이상을 입력해주세요.");
		}else{
			if(!$("#newpw").hasClass("ok")) $("#newpw").addClass("ok");
			$("#newpw").removeClass("wrong");
			$(".pw_wran").eq(0).html("");
		}
		if(newpw != newpwre){
			$("#newpwre").removeClass("ok");
			if(!$("#newpwre").hasClass("wrong")) $("#newpwre").addClass("wrong");
			$(".pw_wran").eq(1).html("비밀번호가 일치하지 않습니다.");
		}
		if($("#newpw").hasClass("ok") && $("#newpwre").hasClass("ok")){
			$(".goNext").addClass("on");
		}else{
			$(".goNext").removeClass("on");
		}
	}
	pwReChg = (e) => {
		var newpw = $("#newpw").val();
		var newpwre = $("#newpwre").val();
		if(newpwre == ""){
			$("#newpwre").removeClass("ok");
			$("#newpwre").removeClass("wrong");
			$(".pw_wran").eq(1).html("");
		}else if(newpw != newpwre){
			$("#newpwre").removeClass("ok");
			if(!$("#newpwre").hasClass("wrong")) $("#newpwre").addClass("wrong");
			$(".pw_wran").eq(1).html("비밀번호가 일치하지 않습니다.");
		}else{
			if(!$("#newpwre").hasClass("ok")) $("#newpwre").addClass("ok");
			$("#newpwre").removeClass("wrong");
			$(".pw_wran").eq(1).html("");
		}
		if($("#newpw").hasClass("ok") && $("#newpwre").hasClass("ok")){
			$(".goNext").addClass("on");
		}else{
			$(".goNext").removeClass("on");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;
		var originPage = this.state.originPage;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version
			});
			cookies.set('uuid', resObj.uuid, { path: '/' });
			cookies.set('model', resObj.model, { path: '/' });
			cookies.set('version', resObj.version, { path: '/' });
		}
		if(resObj.key == "getIdentity"){
			var error_code = resObj.result_message.error_code;
			if(error_code == undefined){
				var email = $("#pwFindId1").val();
				if(resObj.result_message.data.userid === email){
					this.setState({
						userid : resObj.result_message.data.userid,
						mode : "change"
					});
					//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
					$(".certi_ok_pop").html("인증 완료");
					$(".certi_ok, .certi_ok_pop").fadeIn("fast");
					setTimeout(function(){
						$(".certi_ok, .certi_ok_pop").fadeOut("fast");
						$(".find_idpw_wrap").eq(0).hide();
						$(".find_idpw_wrap").eq(1).show();
						$("#pwFindId1").val("");
						$(".goNext").removeClass("on");
						$(".goNext").html("확인");
						$("#newpw").focus();
						if(global.variable.osType == 1){
							window.android.setWebviewFocus();
						}
					}, 2000);
				}else{
					this.alertPop( "<img src='"+ icon_time_limit +"' style='padding:10px 10px 0 0;'/>일치하는 회원 정보가 없습니다.<br/>다시 한번 확인해주세요." );
				}
			}else{
				this.alertPop( "<img src='"+ icon_time_limit +"' style='padding:10px 10px 0 0;'/>"+(resObj.result_message.error).replace(/\$/g, '<br/>') );
			}
		}
		if(resObj.key == "encryptBase64"){
			if( resObj.param1 == "email" ){
				this.setState({
					enc_userid : resObj.returnStr
				});
				var url = this.state.apiurl+"/login/check_userid.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", resObj.returnStr);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					var email = $("#pwFindId1").val();
					if(error_code == undefined){
						window.webkit.messageHandlers.getIdentity.postMessage({ cidx : "0", url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : "findpw", userid : email } );
						return;
					}else{
						this.alertPop(res.data.error);
						return;
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if( resObj.param1 == "password"){
				var url = this.state.apiurl+"/login/reset_password.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", this.state.enc_userid);
				formData.append("password", resObj.returnStr);
				formData.append("method", "cis");
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".certi_ok_pop").html("완료");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");
						if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
							cookies.set('originPage', '', { path: '/' });
							setTimeout(function(){
								document.location.href=originPage;
							}, 2000);
						}else{
							setTimeout(function(){
								$(".certi_ok, .certi_ok_pop").fadeOut("fast");
								document.location.href="/member/login";
							}, 2000);
						}
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if( resObj.param1 == "passwords"){
				var url = this.state.apiurl+"/login/reset_password.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", "0");
				formData.append("userid", this.state.enc_userid);
				formData.append("password", resObj.returnStr);
				formData.append("method", "sms");
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);
					var error_code = res.data.error_code;
					if(error_code == undefined){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".certi_ok_pop").html("완료");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");
						if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
							cookies.set('originPage', '', { path: '/' });
							setTimeout(function(){
								document.location.href=originPage;
							}, 2000);
						}else{
							setTimeout(function(){
								$(".certi_ok, .certi_ok_pop").fadeOut("fast");
								document.location.href="/member/login";
							}, 2000);
						}
					}else{
						var error = res.data.error;
						this.alertPop( error.replace(/\n/g, '<br/>') );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}
			if( resObj.param1 == "smsSend"){
				this.setState({
					enc_userid : resObj.returnStr
				});
				this.smsSend(resObj.returnStr);
			}
		}
		if(resObj.key == "pback"){
			this.goPage('/member/login');
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		var originPage = this.state.originPage;

		if( $(".find_idpw_wrap").eq(1).css("display") == "none"){
			if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
				cookies.set('originPage', '', { path: '/' });
				document.location.href=originPage;
			}else{
				document.location.href = page;
			}
		}else{
			$(".confirmPop").css("top", Math.max(0, ((this.state.wh - $(".confirmPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
			$(".confirmPop, .confirmPopDimm").fadeIn();
		}
	}
	goPage2 = (e) => {
		const { cookies } = this.props;
		var originPage = this.state.originPage;

		if(originPage === "/mycow/mycowLeavePw" || originPage === "/mycow/mycowMyPwChk"){
			return;
		}else{
			document.location.href = "/member/findId";
		}
	}
	goCancel = (e) => {
		document.location.reload();
	}
	alertPop = (msg, e) => {
		//$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
		$(".confirmPop, .confirmPopDimm").fadeOut("fast");
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPage.bind(this, '/member/login')}>앞으로</Link></p>
			<h2>아이디(이메일)/비밀번호 찾기</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>
			{/*<!-- 완료 팝업 end -->*/}

			<div className="layer_dimm confirmPopDimm" style={{display:"none"}}></div>
			<div className="common_pop confirmPop"  style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>비밀번호 변경을 취소하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.alertCls.bind(this)}>아니오</Link>
					<Link className="dgrey" to="#" onClick={this.goCancel.bind(this)}>네</Link>
				</div>
			</div>

			{/*<!-- header 영역 -->*/}
			{/*<!-- <header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goPage.bind(this, '/member/login')}>앞으로</Link></p>
				<h2>아이디(이메일)/비밀번호 찾기</h2>
			</header> -->*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="find_idpw_tabs">
				<p><Link className="id" to="#" onClick={this.goPage2.bind(this)}>아이디(이메일) 찾기</Link></p>
				<p><Link className="pw on" to="#">비밀번호 찾기</Link></p>
			</div>
			<div className="find_idpw_wrap pw">
				<p className="find_idpw_ttl">원하는 방법을 선택해 <br/>비밀번호를 찾으세요.</p>
				<fieldset>
					<div className="find_idpw_radio">
						<input id="pwFind1" name="pwFind" type="radio" value="1" onChange={this.buttonOnChk.bind(this)}/>
						<label htmlFor="pwFind1">본인 인증으로 찾기</label>
					</div>
					<div className="find_pw_info_input find_pw_info_input1">
						<input id="pwFindId1" name="pwFindId1" placeholder="아이디(이메일)" type="email" onChange={this.pwEmailChk1.bind(this)} onFocus={this.onFocusHandle.bind(this,'pwFindId1')} onBlur={this.onBlurHandle.bind(this,'pwFindId1')}/>
					</div>
					<div className="find_idpw_radio">
						<input id="pwFind2" name="pwFind" type="radio" value="2" onChange={this.buttonOnChk.bind(this)}/>
						<label htmlFor="pwFind2">sms 인증번호로 찾기</label>
					</div>
					<div className="find_pw_info_input find_pw_info_input2">
						<input id="pwFindId2" name="pwFindId2" placeholder="아이디(이메일)" type="email" onChange={this.pwEmailChk2.bind(this)} onFocus={this.onFocusHandle.bind(this,'pwFindId2')} onBlur={this.onBlurHandle.bind(this,'pwFindId2')}/>
						<input id="pwFindPhone2" name="pwFindPhone2" placeholder="휴대전화번호(‘-’ 제외하고 입력해주세요.)" type="tel" onChange={this.pwPhoneChk2.bind(this)} onFocus={this.onFocusHandle.bind(this,'pwFindPhone2')} onBlur={this.onBlurHandle.bind(this,'pwFindPhone2')}/>
						<div className="certi_num_input">
							<input id="certNum" name="certNum" placeholder="인증번호 6자리" type="tel" onChange={this.cerNumChk.bind(this)} onFocus={this.onFocusHandle.bind(this,'certNum')} onBlur={this.onBlurHandle.bind(this,'certNum')}/>
							<Link className="btn_resend" to="#" onClick={this.reSend.bind(this)}>재전송</Link>
						</div>
					</div>
				</fieldset>
				<p className="time_remain">{/*남은시간: 03분 00초*/}</p>
				<p className="certi_time_limit">{/*인증번호 유효시간 경과하였습니다. <br/>인증번호 문자가 오지 않는 경우 스팸처리를 해제해주세요.*/}</p>
			</div>

			<div className="find_idpw_wrap pw" style={{display:"none"}}>
				<p className="find_idpw_ttl">본인인증이 완료되었습니다. <br/>새로운 비밀번호로 변경해주세요.</p>
				<fieldset>
					<div className="find_pw_input">
						<input id="newpw" name="newpw" type="password" placeholder="비밀번호(6~12자 영문 대/소문자,숫자,특수문자)" onChange={this.pwChg.bind(this)} maxLength="12" onFocus={this.onFocusHandle.bind(this,'newpw')} onBlur={this.onBlurHandle.bind(this,'newpw')}/>
						<p className="pw_wran">{/*비밀번호는 6자 이상을 입력해주세요.*/}</p>
						<input id="newpwre" name="newpwre" type="password" placeholder="비밀번호 확인" onChange={this.pwReChg.bind(this)} maxLength="12" onFocus={this.onFocusHandle.bind(this,'newpwre')} onBlur={this.onBlurHandle.bind(this,'newpwre')}/>
						<p className="pw_wran">{/*비밀번호가 일치하지 않습니다.*/}</p>
					</div>
				</fieldset>
			</div>

			{/*<!-- <p className="btm_btn"><Link className="goNext" to="#" onClick={this.goNext.bind(this)}>다음</Link></p> -->*/}
		</div>
		<p className="btm_btn"><Link className="goNext" to="#" onClick={this.goNext.bind(this)}>다음</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(findPw);