import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import SurveySub1 from './comp/surveySub1';
import SurveySub2 from './comp/surveySub2';
import SurveySub3 from './comp/surveySub3';

import spinner from '../img/common/spinner.gif';

class survey extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			originPage: cookies.get('originPage'),
			adid: props.match.params.id,
			adlogid: props.match.params.lid,

			surveys: [],
			qCnt: 0,
			sInfo: [],
			pageno: 0,
			first: true,
			wh : 0,
			mode : ""
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		var _this = this;
		$(".mem_header, .btm_btn").hide();

		const url = this.state.apiurl+"/ad/survey_detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(error_code === undefined){
				$(".mem_header, .btm_btn").show();
				this.setState({
					wh : $(window).height(),
					qCnt : res.data.data.data.question_count,
					sInfo : res.data.data.data,
					surveys : res.data.data.data.question_list
				});

				if(res.data.data.data.question_count === 1){
					fnc.event_log("push_single", "", "");
				}else{
					fnc.event_log("push_multiple", "", "");
				}
			}else{
				_this.setState({
					wh : $(window).height(),
					mode : "no"
				});
				this.alertPop( res.data.error.replace(/\n/gi, '<br/>') );
				$(".layer_dimm").fadeIn();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

//		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(window).scroll(function() { 
//		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		});
//		$(window).resize(function() { 
//		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".alertPop").css("top", Math.max(0, (($(window).height() - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		});
	}
	componentDidUpdate(){
		if(this.state.first){
			this.setState({first:false});
			$(".wrap:gt(0)").hide();
		}
	}
	goNext = (idx, e) => {
		e.preventDefault();

		var osType = global.variable.osType;
		if(~~idx+1 == this.state.qCnt){
			return;
		}else{
			if($("input[name=type"+idx+"]").val() == "select_1" && $("input[name=answer"+idx+"]").val() == ""){
				return;
			}
			if($("input[name=type"+idx+"]").val() == "select_n" && $("input[name=answer"+idx+"]").val() == ""){
				return;
			}
			if($("input[name=type"+idx+"]").val() == "rank_i" && $("input[name=answer"+idx+"]").val() == ""){
				return;
			}
			$(".wrap").hide();
			$(".wrap").eq(~~idx+1).show();
			
			this.setState({
				pageno : ~~idx+1
			});

			$(".backBtn").removeClass("btn_close");
			if(!$(".backBtn").hasClass("btn_prev")) $(".backBtn").addClass("btn_prev");

			if($("input[name=type"+(~~idx+1)+"]").val() === "text"){
				$("textarea[name=textarea_"+(~~idx+1)+"]").focus()
				if(osType == 1){
					window.android.setWebviewFocus();
				}
				$(".goNextBtn").html("다음");
			}else{
				$(".goNextBtn").html("완료 시 "+ fnc.comma(this.state.sInfo.plus_point) +"P 적립");
			}
		}
	}
	goBack = (e) => {
		e.preventDefault();
		
		var idx = this.state.pageno;
		var osType = global.variable.osType;
		if(~~idx === 0){

			var posY = $(window).scrollTop();
				this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			$(".interrupt").css({
				"top" : Math.max(0, ((this.state.wh - $(".interrupt").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".interrupt").animate({"margin-top":"0px","opacity" : "1"}, 300);
			$(".layer_dimm").fadeIn();
			return;
		}else{
			$(".wrap").hide();
			$(".wrap").eq(~~idx-1).show();

			this.setState({
				pageno : ~~idx-1
			});

			if( (~~idx-1) == 0 ){
				$(".backBtn").removeClass("btn_prev");
				$(".backBtn").addClass("btn_close");
			}

			if($("input[name=type"+(~~idx-1)+"]").val() === "text"){
				$("textarea[name=textarea_"+(~~idx-1)+"]").focus()
				if(osType == 1){
					window.android.setWebviewFocus();
				}
				$(".goNextBtn").html("다음");
			}else{
				$(".goNextBtn").html("완료 시 "+ fnc.comma(this.state.sInfo.plus_point) +"P 적립");
			}
		}
	}
	SetRank = (i, star, e) => {
		$("input[name=answer"+i+"]").val( star );
		$(".star_rate_wrap_"+i).removeClass("star1");
		$(".star_rate_wrap_"+i).removeClass("star2");
		$(".star_rate_wrap_"+i).removeClass("star3");
		$(".star_rate_wrap_"+i).removeClass("star4");
		$(".star_rate_wrap_"+i).removeClass("star5");
		$(".star_rate_wrap_"+i).addClass("star"+star);

		var result = 0;
		for(var j=0 ; j < this.state.qCnt ; j++){
			if($("input[name=answer"+j+"]").val() == ""){
				result++;
			}
		}
		if(result == 0){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}

		$(".btn_next_ques"+i).find("a").addClass("on");
	}
	chkLength = (i, e) => {
		$(".textarea_length_"+i).html( ($("textarea[name=textarea_"+i+"]").val()).length + "/200");
		$("input[name=answer"+i+"]").val( $("textarea[name=textarea_"+i+"]").val() );

		var result = 0;
		for(var j=0 ; j < this.state.qCnt ; j++){
			if($("input[name=answer"+j+"]").val() == ""){
				result++;
			}
		}
		if(result == 0){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}

		if(($("textarea[name=textarea_"+i+"]").val()).length === 0){
		$(".btn_next_ques"+i).find("a").removeClass("on");
		}else{
		$(".btn_next_ques"+i).find("a").addClass("on");
		}
	}
	popCls = (cName, e) => {
		if(cName === "complete"){
			if(global.variable.osType === 0){
				$(window).scrollTop(this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");
			$(".saving, .interrupt").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".saving, .interrupt").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}else{
			const { cookies } = this.props;
			var originPage = this.state.originPage;
			cookies.set('originPage', '', { path: '/' });
			document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? "/main" : originPage);
		}
	}
	goCompleteShow(){
		if($(".goNextBtn").hasClass("on")){

			var posY = $(window).scrollTop();
				this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");

			$(".saving").css({
				//"top" : Math.max(0, ((this.state.wh - $(".saving").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".saving").animate({"margin-top":"0px","opacity" : "1"}, 300);
			$(".layer_dimm").fadeIn();
		}
	}
	goComplete(){
		const { cookies } = this.props;
		const url = this.state.apiurl+"/ad/survey_report.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		formData.append("answer_count", this.state.qCnt);
		var aJsonArray = new Array();
		var aJson = new Object();
		for(var i=0 ; i < this.state.qCnt ; i++){
			aJson = new Object();
			aJson.question_id = $("input[name=id"+i+"]").val();
			aJson.question_type = $("input[name=type"+i+"]").val();
			aJson.answer = $("input[name=answer"+i+"]").val();
			aJsonArray.push(aJson);
		}
		formData.append("answer_list", JSON.stringify(aJsonArray));
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(~~res.data.result === 1){
				$(".saving").animate({"margin-top":"100px","opacity" : "0"}, {
					"duration" : 400,
					"complete" : function(){
						$(".saving").hide();
					}
				});
				$(".certi_ok_pop").css({
					//"top" : Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
					"margin-top" : "100px",
					"opacity" : "0",
					"display" : "block"
				});
				$(".certi_ok_pop").animate({"margin-top":"0px","opacity" : "1"}, {
					"duration" : 300,
					"complete" : function(){
						cookies.set('pointSubTab', '2', { path: '/' });
						setTimeout(function(){
							document.location.href="/point/point";
						}, 2000);
					}
				});
				$(".layer_dimm").fadeIn();
			}else{
				$(".saving").hide();
				this.alertPop( (res.data.error).replace(/\n/g, "<br/>") );
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop").fadeOut("fast");
		$(".layer_dimm").fadeOut("fast");

		var mode = this.state.mode;
		if(mode === "no"){
			this.popCls('interrupt');
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "pback"){
			this.goBack();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

		<header className="mem_header">
			<p className="backBtn btn_close"><Link to="#" onClick={this.goBack.bind(this)}>닫기</Link></p>
			<h2>광고적립</h2>
		</header>

		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm"></div>
			<div className="common_pop saving">
				<div className="common_pop_txt">
					<p>적립하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goComplete.bind(this)}>네</Link>
				</div>
			</div>
			<div className="common_pop interrupt">
				<div className="common_pop_txt">
					<p>광고 적립을 종료합니다.<br/>종료 후 다시 진행하실 수 없습니다.</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.popCls.bind(this, 'interrupt')}>확인</Link>
				</div>
			</div>

			<div className="certi_ok_pop" style={{display:"none"}}>{this.state.sInfo.plus_point}P<br/>적립 완료</div>

			{/*<!-- alert 팝업 -->*/}
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{this.state.surveys.map((list, i) =>
			<div className="wrap" key={i} data-type={list.question_type}>
			<input type="hidden" name={"id"+i} value={list.question_id}/>
			<input type="hidden" name={"type"+i} value={list.question_type}/>
			<input type="hidden" name={"answer"+i}/>
			{/*<!-- header 영역 -->*/}
			{/*<!-- <header className="mem_header">
				<p className="backBtn btn_close"><Link to="#" onClick={this.goBack.bind(this)}>닫기</Link></p>
				<h2>광고적립</h2>
			</header> -->*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			{this.state.qCnt > 1 &&
			<div className="adv_accum_top">
				<div className="survey_ques_ttl">
					<span className="next_survey_txt">{this.state.sInfo.name}</span>
					<h3>{(i+1)<10 ? "QUESTION 0"+(i+1) : "QUESTION "+(i+1)}</h3>
					<div className="ques_pager"><p>{i+1}/{this.state.qCnt}</p></div>
					<strong dangerouslySetInnerHTML={{__html : (list.question).replace(/\n/g, "<br/>")}}></strong>
					<span className="survey_shoose_txt" dangerouslySetInnerHTML={{__html : (list.guide).replace(/\n/g, "<br/>")}}></span>
				</div>
			</div>
			}
			{this.state.qCnt == 1 &&
			<div className="adv_accum_top single">
				<p className="purch_reason">
					<strong dangerouslySetInnerHTML={{__html : (list.question).replace(/\n/g, "<br/>")}}></strong>
					<span dangerouslySetInnerHTML={{__html : (list.guide).replace(/\n/g, "<br/>")}}></span>
				</p>
			</div>
			}
			{list.question_type == "select_1" && this.state.qCnt > 1 &&
			<div className="adv_accum_rate_wrap">
				<div className="cmplx_rate_wrap">
					<ul>
						<SurveySub1 list={list.option_list} i={i} qCnt={this.state.qCnt}/>
					</ul>
				</div>
				{ this.state.qCnt != i+1 &&
					<p className={"btn_next_ques btn_next_ques"+i}><Link to="#" onClick={this.goNext.bind(this, i)}>다음</Link></p>
				}
			</div>
			}
			{list.question_type == "select_n" && this.state.qCnt > 1 &&
			<div className="adv_accum_rate_wrap">
				<div className="adv_accum_rate_list">
					<ul>
						<SurveySub2 list={list.option_list} i={i} qCnt={this.state.qCnt} min={list.min} max={list.max}/>
					</ul>
				</div>
				{ this.state.qCnt != i+1 &&
					<p className={"btn_next_ques btn_next_ques"+i}><Link to="#" onClick={this.goNext.bind(this, i)}>다음</Link></p>
				}
			</div>
			}
			{list.question_type == "rank_i" && this.state.qCnt > 1 &&
			<div className="adv_accum_rate_wrap">
				<div className={"star_rate_wrap star0 star_rate_wrap_"+i}>
					<ol>
						<li className="star_rate01"><Link to="#" onClick={this.SetRank.bind(this, i, 1)}>1점</Link></li>
						<li className="star_rate02"><Link to="#" onClick={this.SetRank.bind(this, i, 2)}>2점</Link></li>
						<li className="star_rate03"><Link to="#" onClick={this.SetRank.bind(this, i, 3)}>3점</Link></li>
						<li className="star_rate04"><Link to="#" onClick={this.SetRank.bind(this, i, 4)}>4점</Link></li>
						<li className="star_rate05"><Link to="#" onClick={this.SetRank.bind(this, i, 5)}>5점</Link></li>
					</ol>
				</div>
				{ this.state.qCnt != i+1 &&
					<p className={"btn_next_ques btn_next_ques"+i}><Link to="#" onClick={this.goNext.bind(this, i)}>다음</Link></p>
				}
			</div>
			}
			{list.question_type == "text" && this.state.qCnt > 1 &&
			<div className="adv_accum_rate_wrap">
				<div className="adv_accum_txt_input">
					<textarea name={"textarea_"+i} placeholder="200자 이내로 입력해 주세요." onChange={this.chkLength.bind(this, i)} maxLength="200"></textarea>
					<span className={"textarea_length_"+i}>0/200</span>
				</div>
				<p className="btm_stick_point">완료 시 {fnc.comma(this.state.sInfo.plus_point)}P 적립</p>
				{ this.state.qCnt != i+1 &&
					<p className={"btn_next_ques btn_next_ques"+i}><Link to="#" onClick={this.goNext.bind(this, i)}>다음</Link></p>
				}
			</div>
			}
			{list.question_type == "select_1" && this.state.qCnt == 1 &&
			<div className="adv_accum_rate_wrap">
				<div className="adv_accum_rate_list">
					<ul>
						<SurveySub3 list={list.option_list} i={i} qCnt={this.state.qCnt}/>
					</ul>
				</div>
			</div>
			}

			{/*<!-- <p className="btm_btn"><Link to="#" onClick={this.goCompleteShow.bind(this)}>완료시 {this.state.sInfo.plus_point}P 적립</Link></p> -->*/}
			{/*<!-- 내용 영역 end -->*/}
			</div>
			)}
		</div>
		<p className="btm_btn"><Link to="#" className="goNextBtn" onClick={this.goCompleteShow.bind(this)}>완료 시 {fnc.comma(this.state.sInfo.plus_point)}P 적립</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(survey);