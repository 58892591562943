import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import * as fnc from '../js/fnc.js';
import btn_arrow_g from "../img/mem/btn_arrow_g.png";


class joinEnd extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
	}

	componentDidMount(){
		fnc.event_log("signup_complete", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
	}

	idChg(e){
		var val = $("#reco").val();
			val = $.trim(val);
		if(val === ""){
			$(".btn_login").find("a").html("건너뛰기");
		}else{
			$(".btn_login").find("a").html("확인");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key === "pback"){
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<h2>가입완료</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="memjoin_done">
				<h3 className="memjoin_done_ttl">캐시카우 회원 가입이 <br />완료되었습니다.</h3>
				<Link className="cashcow_start" to="/main">캐시카우 시작하기<img alt="" src={btn_arrow_g} /></Link>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
			<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(joinEnd);