import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowInquiryWrite extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),

			icates: [],
			icount: 0,
			qTypeIdx: "",
			selectedFile1: null,
			selectedFile2: null,
			selectedFile3: null,
			selectedFile4: null,
			selectedFile5: null,
			selectedName1: null,
			selectedName2: null,
			selectedName3: null,
			selectedName4: null,
			selectedName5: null,
			selectedSize1: null,
			selectedSize2: null,
			selectedSize3: null,
			selectedSize4: null,
			selectedSize5: null,
			proc: false,
			wh: 0,
			img1: "",
			img2: "",
			img3: "",
			img4: "",
			img5: "",
			rltStatus: ""
		};
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInquiryWrite', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const formData = new FormData();
		formData.append('os', global.variable.osType);
		formData.append('appver', this.state.version);
		formData.append('applocale', 'kr');
		formData.append('bypass_emergency', 0);
		formData.append('cidx', this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+'/guide/inquiry_category.json', formData, this.state.config).then( res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log(res);
			this.setState({
				icates : res.data.data.list,
				icount : res.data.data.count
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		this.setState({
			wh : $(window).height()
		});

		//$(window).scroll(function() { 
		//$(".inq_type_pop").css("top", Math.max(0, (($(window).height() - $(".purch_num_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		//});

	}
	componentDidUpdate(){
	}
	typeSel(e){
		e.preventDefault();
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".inq_type_pop").css("top", Math.max(0, ((this.state.wh - $(".inq_type_pop").outerHeight()) / 2) + posY) + "px");
		$(".layer_dimm, .inq_type_pop").fadeIn("fast");
	}
	catePopCls(e){
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".layer_dimm, .inq_type_pop").fadeOut("fast");
	}
	selType = (txt, idx, e) =>{
		$("#qTypeNm").val(txt);
		this.setState({
			qTypeIdx : idx
		});
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".layer_dimm, .inq_type_pop").fadeOut("fast");

		var qTitle = $("#qTitle").val();
		var contents = $("#contents").val();

		if(qTitle.length >= 2 && contents.length >= 2){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}
	}
	fillChk = (e) =>{
		var contents = $("#contents").val();
		$(".contentsLen").html(contents.length + "/500");

		var uicidx = $("#qTypeIdx").val();
		var qTitle = $("#qTitle").val();
		var contents = $("#contents").val();

		if(uicidx != "" && qTitle.length >= 2 && contents.length >= 2){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}
	}
	imgDel = (idx, e) => {
		var img1 = this.state.img1;
		var img2 = this.state.img2;
		var img3 = this.state.img3;
		var img4 = this.state.img4;
		var img5 = this.state.img5;

		if(idx == 1){
			this.setState({
				img1 : img2,
				img2 : img3,
				img3 : img4,
				img4 : img5,
				img5 : ""
			});
		}
		if(idx == 2){
			this.setState({
				img2 : img3,
				img3 : img4,
				img4 : img5,
				img5 : "",
			});
		}
		if(idx == 3){
			this.setState({
				img3 : img4,
				img4 : img5,
				img5 : ""
			});
		}
		if(idx == 4){
			this.setState({
				img4 : img5,
				img5 : "",
			});
		}
		if(idx == 5){
			this.setState({
				img5 : ""
			});
		}

		var del = 5;
		var isChk = true;

		if(isChk){
			if($(".inquiry_img_list").find("li:eq(4) > span").html() != ""){
				del = 4;
				isChk = false;
			}
		}
		if(isChk){
			if($(".inquiry_img_list").find("li:eq(3) > span").html() != ""){
				del = 3;
				isChk = false;
			}
		}
		if(isChk){
			if($(".inquiry_img_list").find("li:eq(2) > span").html() != ""){
				del = 2;
				isChk = false;
			}
		}
		if(isChk){
			if($(".inquiry_img_list").find("li:eq(1) > span").html() != ""){
				del = 1;
				isChk = false;
			}
		}
		if(isChk){
			if($(".inquiry_img_list").find("li:eq(0) > span").html() != ""){
				del = 0;
				isChk = false;
			}
		}
		$(".inquiry_img_list").find("li:eq("+del+")").hide();
		$(".inquiry_img_upload").show();
	}
	submit(){
		if(!$(".btm_btn").find("a").hasClass("on")) return;
		if(this.state.proc === true) return;
		this.setState({
			proc : true
		});
		var uicidx = $("#qTypeIdx").val();
		var qTitle = $("#qTitle").val();
		var contents = $("#contents").val();

		var fileCnt = 0;
		if(this.state.img1 !== ""){fileCnt++;}
		if(this.state.img2 !== ""){fileCnt++;}
		if(this.state.img3 !== ""){fileCnt++;}
		if(this.state.img4 !== ""){fileCnt++;}
		if(this.state.img5 !== ""){fileCnt++;}

		if(global.variable.osType === 1){
			window.android.sendInquiry(
				global.variable.osType,
				this.state.version,
				'kr',
				0,
				this.state.cidx,
				'add',
				uicidx,
				qTitle,
				contents,
				fileCnt,
				this.state.img1,
				this.state.img2,
				this.state.img3,
				this.state.img4,
				this.state.img5
			);
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.sendInquiry.postMessage({
				os : global.variable.osType,
				appver : this.state.version,
				applocale : 'kr',
				bypass_emergency : 0,
				cidx : this.state.cidx,
				action : 'add',
				uicidx : uicidx,
				title : qTitle,
				inquiry : contents,
				file_count : fileCnt,
				img1 : this.state.img1,
				img2 : this.state.img2,
				img3 : this.state.img3,
				img4 : this.state.img4,
				img5 : this.state.img5
			});
		}
	}
	alertPop = (msg, e) => {
		//$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
		if(this.state.proc){
			if(this.state.rltStatus === ""){
				document.location.href="/mycow/mycowInquiry";
			}else{
				this.setState({proc: false});
			}
		}
	}
	setImage(e){
		let cnt = 0;
		if(this.state.img1 !== "") cnt++;
		if(this.state.img2 !== "") cnt++;
		if(this.state.img3 !== "") cnt++;
		if(this.state.img4 !== "") cnt++;
		if(this.state.img5 !== "") cnt++;

		if(global.variable.osType === 1){window.android.setImage(cnt, this.state.img1, this.state.img2, this.state.img3, this.state.img4, this.state.img5);}
		if(global.variable.osType === 0){window.webkit.messageHandlers.setImage.postMessage({ selCnt : cnt,img1 : this.state.img1,img2 : this.state.img2,img3 : this.state.img3,img4 : this.state.img4,img5 :  this.state.img5 } );}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycowInquiry";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "img_selected"){
			var size1 = resObj.size1;
			var size2 = resObj.size2;
			var size3 = resObj.size3;
			var size4 = resObj.size4;
			var size5 = resObj.size5;

			$(".inquiry_img_list").find("li").show();
			$(".inquiry_img_list").find("li").eq(0).hide();
			$(".inquiry_img_list").find("li").eq(1).hide();
			$(".inquiry_img_list").find("li").eq(2).hide();
			$(".inquiry_img_list").find("li").eq(3).hide();
			$(".inquiry_img_list").find("li").eq(4).hide();

			if(~~size1 > 10485760){
				$(".barcode_dimm").fadeIn("fast");
				setTimeout(function(){$(".barcode_dimm").fadeOut("fast");}, 2000);
				this.setState({
					img1 : "",
					img2 : "",
					img3 : "",
					img4 : "",
					img5 : ""
				});
				return;
			}else if(~~size1 + ~~size2 > 10485760){
				$(".barcode_dimm").fadeIn("fast");
				setTimeout(function(){$(".barcode_dimm").fadeOut("fast");}, 2000);
				this.setState({
					img1 : resObj.img1,
					img2 : "",
					img3 : "",
					img4 : "",
					img5 : ""
				});
				if(resObj.img1 !== ""){$(".inquiry_img_list").find("li").eq(0).show();}
				return;
			}else if(~~size1 + ~~size2 + ~~size3 > 10485760){
				$(".barcode_dimm").fadeIn("fast");
				setTimeout(function(){$(".barcode_dimm").fadeOut("fast");}, 2000);
				this.setState({
					img1 : resObj.img1,
					img2 : resObj.img2,
					img3 : "",
					img4 : "",
					img5 : ""
				});
				if(resObj.img1 !== ""){$(".inquiry_img_list").find("li").eq(0).show();}
				if(resObj.img2 !== ""){$(".inquiry_img_list").find("li").eq(1).show();}
				return;
			}else if(~~size1 + ~~size2 + ~~size3 + ~~size4 > 10485760){
				$(".barcode_dimm").fadeIn("fast");
				setTimeout(function(){$(".barcode_dimm").fadeOut("fast");}, 2000);
				this.setState({
					img1 : resObj.img1,
					img2 : resObj.img2,
					img3 : resObj.img3,
					img4 : "",
					img5 : ""
				});
				if(resObj.img1 !== ""){$(".inquiry_img_list").find("li").eq(0).show();}
				if(resObj.img2 !== ""){$(".inquiry_img_list").find("li").eq(1).show();}
				if(resObj.img3 !== ""){$(".inquiry_img_list").find("li").eq(2).show();}
				return;
			}else if(~~size1 + ~~size2 + ~~size3 + ~~size4 + ~~size5 > 10485760){
				$(".barcode_dimm").fadeIn("fast");
				setTimeout(function(){$(".barcode_dimm").fadeOut("fast");}, 2000);
				this.setState({
					img1 : resObj.img1,
					img2 : resObj.img2,
					img3 : resObj.img3,
					img4 : resObj.img4,
					img5 : ""
				});
				if(resObj.img1 !== ""){$(".inquiry_img_list").find("li").eq(0).show();}
				if(resObj.img2 !== ""){$(".inquiry_img_list").find("li").eq(1).show();}
				if(resObj.img3 !== ""){$(".inquiry_img_list").find("li").eq(2).show();}
				if(resObj.img4 !== ""){$(".inquiry_img_list").find("li").eq(3).show();}
				return;
			}else{
				this.setState({
					img1 : resObj.img1,
					img2 : resObj.img2,
					img3 : resObj.img3,
					img4 : resObj.img4,
					img5 : resObj.img5
				});
				if(resObj.img1 != ""){
					$(".inquiry_img_list").find("li").eq(0).show();
					$(".inquiry_img_upload").show();
				}
				if(resObj.img2 != ""){
					$(".inquiry_img_list").find("li").eq(1).show();
					$(".inquiry_img_upload").show();
				}
				if(resObj.img3 != ""){
					$(".inquiry_img_list").find("li").eq(2).show();
					$(".inquiry_img_upload").show();
				}
				if(resObj.img4 != ""){
					$(".inquiry_img_list").find("li").eq(3).show();
					$(".inquiry_img_upload").show();
				}
				if(resObj.img5 != ""){
					$(".inquiry_img_list").find("li").eq(4).show();
					$(".inquiry_img_upload").hide();
				}
			}
		}
		if(resObj.key == "sendResult"){
			var result = resObj.result;

			if( ~~result === -1){
				var type = resObj.type;
				var msg = resObj.msg;
				if(type === "check_server"){fnc.server_close_pop(msg);return;}
				if(type === "update"){fnc.update_pop(msg);return;}
			}
			if( ~~result === 1){
				var posY = $(window).scrollTop();
					this.setState({
					posY : posY
				});
				if(global.variable.osType === 0){
					$("#wrap").css("top",-($(window).scrollTop()));
					$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				}
				$('body').css("overflow", "hidden");

				$(".alertPop").find("p").html("1:1문의 접수가 완료되었습니다.<br/>답변은 영업일 기준 3일 전후로 완료됩니다.");
				$(".alertPop, .alertPopDimm").fadeIn();
				this.setState({rltStatus:""});
			}
			if( ~~result === 0){
				var posY = $(window).scrollTop();
					this.setState({
					posY : posY
				});
				if(global.variable.osType === 0){
					$("#wrap").css("top",-($(window).scrollTop()));
					$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				}
				$('body').css("overflow", "hidden");

				$(".alertPop").find("p").html(resObj.error);
				$(".alertPop, .alertPopDimm").fadeIn();
				this.setState({rltStatus:"error"});
			}
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/mycow/mycowInquiry">앞으로</Link></p>
			<h2>1:1 문의 하기</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			<div className="layer_dimm" style={{display:"none"}}></div>
			{/*<!-- 완료 팝업 -->*/}
			<div className="barcode_dimm done" style={{display:"none"}}>
				<div className="barcode_dimm_bg"></div>
				<p className="mb_limit">총 용량 10mb를<br />초과하였습니다.</p>
			</div>
			{/*<!-- 완료 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop inquiry" style={{display:"none", position:"fixed"}}>
				<p>1:1문의 접수가 완료되었습니다.<br/>답변은 영업일 기준 3일 전후로 완료됩니다.</p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 문의유형 선택 팝업 -->*/}
			<div className="inq_type_pop" style={{display:"none"}}>
				<div className="inq_type_pop_ttl">
					<strong>문의유형을 선택 해주세요.</strong>
					<Link to="#" onClick={this.catePopCls.bind(this)}>닫기</Link>
				</div>
				<ul>
					{
					this.state.icates.map((icate, i) =>
					<li key={i}><Link to="#" onClick={this.selType.bind(this, icate.name, icate.uicidx)}>{icate.name}</Link></li>
					)}
				</ul>
			</div>
			{/*<!-- 문의유형 선택 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="inquiry_guide_txt pers">
				<p>문의내용에 욕설, 성희롱 등의 내용이 포함된 경우 회원활동이 <br />제한될 수 있습니다.</p>
			</div>
			<div className="grey_gap"></div>
			{/*<!-- 문의 내역 -->*/}
			<div className="inquiry_input_field">
				<fieldset>
					<dl>
						<dt>문의 유형</dt>
						<dd>
							<input className="w466" name="qTypeNm" id="qTypeNm" placeholder="문의 유형을 선택해주세요." readOnly="readOnly" type="text" onClick={this.typeSel.bind(this)} onChange={this.fillChk.bind(this)}/>
							<input name="qTypeIdx" id="qTypeIdx" placeholder="문의 유형을 선택해주세요." type="hidden" value={this.state.qTypeIdx} onChange={this.fillChk.bind(this)}/>
							<Link className="btn_sel" to="#" onClick={this.typeSel.bind(this)}>선택</Link>
						</dd>
					</dl>
					<dl>
						<dt>제목</dt>
						<dd>
							<input name="qTitle" id="qTitle" placeholder="제목을 입력해주세요." type="text" onChange={this.fillChk.bind(this)}/>
						</dd>
					</dl>
					<div className="txtarea">
						<textarea name="contents" id="contents" maxLength="500" placeholder="500자 이내로 문의해주세요." onChange={this.fillChk.bind(this)}></textarea>
						<span className="contentsLen">0/500</span>
					</div>
					{/*<!-- 문의하기 이미지 목록 -->*/}
					<div className="inquiry_img_list">
						<ul>
							<li style={{display:"none"}}><span>{this.state.img1}</span><Link to="#" onClick={this.imgDel.bind(this, 1)}>삭제</Link></li>
							<li style={{display:"none"}}><span>{this.state.img2}</span><Link to="#" onClick={this.imgDel.bind(this, 2)}>삭제</Link></li>
							<li style={{display:"none"}}><span>{this.state.img3}</span><Link to="#" onClick={this.imgDel.bind(this, 3)}>삭제</Link></li>
							<li style={{display:"none"}}><span>{this.state.img4}</span><Link to="#" onClick={this.imgDel.bind(this, 4)}>삭제</Link></li>
							<li style={{display:"none"}}><span>{this.state.img5}</span><Link to="#" onClick={this.imgDel.bind(this, 5)}>삭제</Link></li>
						</ul>
					</div>
					{/*<!-- 문의하기 이미지 목록 end -->*/}
					<div className="inquiry_img_upload">
						<input id="fileName" name="" placeholder="사진 첨부" readOnly="readOnly" type="text"/>
						<p className="btn_upload">
							{/*<input id="hiddenFileInput" name="" type="file" onChange={e => this.handleFileInput(e)} accept="image/gif, image/jpeg, image/png"/>*/}
							<input id="" name="" type="button" onClick={this.setImage.bind(this)}/>
						</p>
					</div>
					<p className="img_limit">* 최대 5장, 총 10 mb이하 사진 파일만 등록 가능합니다.</p>
				</fieldset>
			</div>
			{/*<!-- 문의 내역 end -->*/}
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<p className="btm_btn">
			<Link to="#" onClick={this.submit.bind(this)}>확인</Link>
		</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowInquiryWrite);