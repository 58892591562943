import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import spinner from '../img/common/spinner.gif';
import icon_use01 from '../img/mycow/icon_use01.png';
import icon_use02 from '../img/mycow/icon_use02.png';
import icon_use03 from '../img/mycow/icon_use03.png';
import icon_use04 from '../img/mycow/icon_use04.png';
import icon_use05 from '../img/mycow/icon_use05.png';

class point extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),
			pointTab: cookies.get('pointTab') == undefined ? 0 : cookies.get('pointTab'),
			pointSubTab: cookies.get('pointSubTab') == undefined ? "1" : cookies.get('pointSubTab'),
			pointST: cookies.get('pointST') == undefined ? 0 : ~~cookies.get('pointST'),

			pinfo: [],
			plists1: [],
			plists2: [],
			plists3: [],
			pageNo: 1,
			mode: 'standby',
			wh:0
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/point/point");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/point/point"});}
		}catch(e){}
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/point/point");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/point/point"});}
		}catch(e){}
		const { cookies } = this.props;
		if(this.state.pointST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.pointST);
				_this.setState({pointST : 0});
				cookies.set('pointST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			}, 700);
		}
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		let _this = this;
		setTimeout(function(){
			_this.setState({
				wh:$(window).height()
			});
		}, 1000);

		$(".sns_share").hide();
		$(".pop_sns_share").hide();
	}
	pointList = (mode, e) => {
		if(mode === "standby") fnc.event_log("point_save", "", "");
		if(mode === "standby2") fnc.event_log("point_pre", "", "");
		if(mode === "complete") fnc.event_log("point_complete", "", "");
		if(mode === "reject") fnc.event_log("point_not", "", "");
		if(mode === "cancel") fnc.event_log("point_cancel", "", "");
		if(mode === "use") fnc.event_log("point_use", "", "");
		if(mode === "all") fnc.event_log("point_total", "", "");

		const { cookies } = this.props;
		switch (mode){
			case 'standby' : 
				if(!$(".use01").hasClass("on")){
					this.setState({pageNo : 1});
					$(".use01").addClass("on");
					$(".use02").removeClass("on");
					$(".use03").removeClass("on");

					$(".stat01").removeClass("on").addClass("on");
					$(".stat02").removeClass("on");
					$(".stat03").removeClass("on");
					$(".stat04").removeClass("on");
					//this.pointListCommon(mode);
					($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(0).click()
				}
				break;
			case 'standby2' : 
				if($(".stat01").hasClass("on")) return;
				this.setState({pageNo : 1});
				$(".stat01").removeClass("on").addClass("on");
				$(".stat02").removeClass("on");
				$(".stat03").removeClass("on");
				$(".stat04").removeClass("on");
				cookies.set('pointSubTab', '1', { path: '/' });
				this.pointListCommon("standby");
				break;
			case 'complete' : 
				if($(".stat02").hasClass("on")) return;
				this.setState({pageNo : 1});
				$(".stat01").removeClass("on");
				$(".stat02").removeClass("on").addClass("on");
				$(".stat03").removeClass("on");
				$(".stat04").removeClass("on");
				cookies.set('pointSubTab', '2', { path: '/' });
				this.pointListCommon(mode);
				break;
			case 'reject' : 
				if($(".stat03").hasClass("on")) return;
				this.setState({pageNo : 1});
				$(".stat01").removeClass("on");
				$(".stat02").removeClass("on");
				$(".stat03").removeClass("on").addClass("on");
				$(".stat04").removeClass("on");
				cookies.set('pointSubTab', '3', { path: '/' });
				this.pointListCommon(mode);
				break;
			case 'cancel' : 
				if($(".stat04").hasClass("on")) return;
				this.setState({pageNo : 1});
				$(".stat01").removeClass("on");
				$(".stat02").removeClass("on");
				$(".stat03").removeClass("on");
				$(".stat04").removeClass("on").addClass("on");
				cookies.set('pointSubTab', '4', { path: '/' });
				this.pointListCommon(mode);
				break;
			case 'use' : 
				if(!$(".use02").hasClass("on")){
					this.setState({pageNo : 1});
					$(".use02").addClass("on");
					$(".use01").removeClass("on");
					$(".use03").removeClass("on");
					//this.pointListCommon(mode);
					($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(1).click()
				}
				break;
			case 'all' : 
				if(!$(".use03").hasClass("on")){
					this.setState({pageNo : 1});
					$(".use03").addClass("on");
					$(".use01").removeClass("on");
					$(".use02").removeClass("on");
					//this.pointListCommon(mode);
					($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(2).click()
				}
				break;
		}
	}
	pointListCommon = (mode, e) => {
		this.setState({
			mode : mode
		});

		var url = "";
		this.setState({pageNo : 1});

		url = this.state.apiurl+"/user/point_history.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", mode);
		formData.append("block", 10);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			if(res.data.data.total_count < res.data.data.block){
				$(".btn_login").hide();
			}else{
				$(".btn_login").show().addClass("on");
			}
			if(res.data.data.count === 0){
				if(mode == "standby"){
					$(".no_list").eq(0).html('적립 예정 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
				if(mode == "complete"){
					$(".no_list").eq(0).html('적립 완료 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
				if(mode == "reject"){
					$(".no_list").eq(0).html('적립 불가 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
				if(mode == "cancel"){
					$(".no_list").eq(0).html('적립 취소 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
				if(mode == "use"){
					$(".no_list").eq(1).html('사용 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
				if(mode == "all"){
					$(".no_list").eq(2).html('리워드 내역이 없습니다. <br />캐시카우 사용하고 포인트 적립 받으세요!').show();
				}
			}else{
				$(".no_list").eq(0).html('').hide();
				$(".no_list").eq(1).html('').hide();
				$(".no_list").eq(2).html('').hide();
			}
			if(mode == "standby" || mode == "complete" || mode == "reject" || mode == "cancel"){
				this.setState({
					pinfo : res.data.data,
					plists1 : res.data.data.list
				});
			}
			if(mode == "use"){
				this.setState({
					pinfo : res.data.data,
					plists2 : res.data.data.list
				});
			}
			if(mode == "all"){
				this.setState({
					pinfo : res.data.data,
					plists3 : res.data.data.list
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	pointListMore = (e) => {
		if(!$(".btn_login").hasClass("on")) return;

		var pageNo = this.state.pageNo;
		pageNo++;
		this.setState({
			pageNo : pageNo
		});

		const mode = this.state.mode;
		var _this = this;
		var url = "";
		switch (mode){
			case 'standby' : 
			case 'standby2' : 
			case 'complete' : 
			case 'reject' : 
			case 'cancel' : 
				url = this.state.apiurl+"/user/point_history.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				if(mode === "standby2"){
				formData.append("type", "standby");
				}else{
				formData.append("type", mode);
				}
				formData.append("block", 10);
				formData.append("offset", pageNo);
				let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval);

					if(res.data.data.count === 0){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");

						var posY = $(window).scrollTop();
						if(global.variable.osType === 0){
							$("#wrap").css("top",-($(window).scrollTop()));
							$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
						}
						$('body').css("overflow", "hidden");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");

						$(".btn_login").hide();
						setTimeout(function(){

							if(global.variable.osType === 0){
								$(window).scrollTop( $("#posy").val() );
								$("#wrap").css("top", 0);
								$("html, body").off('scroll touchmove');
							}
							$('html, body').css("overflow", "visible");

							$(".certi_ok, .certi_ok_pop").fadeOut("fast");
						}, 2000);
						return;
					}

					if(res.data.data.count < res.data.data.block){
						$(".btn_login").hide();
					}else{
						$(".btn_login").show().addClass("on");
					}
					var plist = _this.state.plists1;
					for (var i in res.data.data.list) {
						plist.push(res.data.data.list[i]);
					}
					_this.setState({
						plists1 : plist
					});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
				break;
			case 'use' : 
				url = this.state.apiurl+"/user/point_history.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				formData.append("type", mode);
				formData.append("block", 10);
				formData.append("offset", pageNo);
				let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval2);

					if(res.data.data.count === 0){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");

						var posY = $(window).scrollTop();
						if(global.variable.osType === 0){
							$("#wrap").css("top",-($(window).scrollTop()));
							$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
						}
						$('body').css("overflow", "hidden");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");

						$(".btn_login").hide();
						setTimeout(function(){

							if(global.variable.osType === 0){
								$(window).scrollTop( $("#posy").val() );
								$("#wrap").css("top", 0);
								$("html, body").off('scroll touchmove');
							}
							$('html, body').css("overflow", "visible");

							$(".certi_ok, .certi_ok_pop").fadeOut("fast");
						}, 2000);
						return;
					}

					if(res.data.data.count < res.data.data.block){
						$(".btn_login").hide();
					}else{
						$(".btn_login").show().addClass("on");
					}
					var plist = _this.state.plists2;
					for (var i in res.data.data.list) {
						plist.push(res.data.data.list[i]);
					}
					_this.setState({
						plists2 : plist
					});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
				break;
			case 'all' : 
				url = this.state.apiurl+"/user/point_history.json";
				var formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				formData.append("type", mode);
				formData.append("block", 10);
				formData.append("offset", pageNo);
				let spinnerInterval3 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res => {
					if( ~~res.data.result === -1){
						if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
						if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
					}
					clearInterval(spinnerInterval3);

					if(res.data.data.count === 0){
						//$(".certi_ok_pop").css("top", Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");

						var posY = $(window).scrollTop();
						if(global.variable.osType === 0){
							$("#wrap").css("top",-($(window).scrollTop()));
							$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
						}
						$('body').css("overflow", "hidden");
						$(".certi_ok, .certi_ok_pop").fadeIn("fast");

						$(".btn_login").hide();
						setTimeout(function(){

							if(global.variable.osType === 0){
								$(window).scrollTop( $("#posy").val() );
								$("#wrap").css("top", 0);
								$("html, body").off('scroll touchmove');
							}
							$('html, body').css("overflow", "visible");

							$(".certi_ok, .certi_ok_pop").fadeOut("fast");
						}, 2000);
						return;
					}

					if(res.data.data.count < res.data.data.block){
						$(".btn_login").hide();
					}else{
						$(".btn_login").show().addClass("on");
					}
					var plist = _this.state.plists3;
					for (var i in res.data.data.list) {
						plist.push(res.data.data.list[i]);
					}
					_this.setState({
						plists3 : plist
					});
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
				break;
		}	
	}
	goPage = (idx, cnt, e) => {
		if(cnt == 0) return;
		const { cookies } = this.props;
		cookies.set('pointST', $(window).scrollTop(), { path: '/' });

		document.location.href="/point/pointView/"+idx;
	}
	goPrev(){
		const { cookies } = this.props;
		var originPage = this.state.originPage;

		cookies.set('pointTab', 0, { path: '/' });
		cookies.set('pointSubTab', "1", { path: '/' });
		cookies.set('pointST', 0, { path: '/' });

		if(originPage != "" && originPage != null && originPage != undefined){
			cookies.set('originPage', '', { path: '/' });
			document.location.href= originPage;
		}else{
			document.location.href= "/main";
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		var _this = this;
		const paramsMain = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			autoHeight: true,
			shouldSwiperUpdate: true,
			touchRatio: 0.3,
			pagination: {
				el: '.top-gnb',
				clickable: true
			},
			initialSlide: this.state.pointTab,
			on: {
				init: function() {

					$('.swiper-wrapper').addClass('important');

					var _thisRealIdx = this.realIndex;
					var _thisHeight = this.height;

					if(_thisRealIdx == 0){
						_this.setState({pageNo : 1});
						$(".use01").addClass("on");
						$(".use02").removeClass("on");
						$(".use03").removeClass("on");

						//$(".stat01").removeClass("on").addClass("on");
						//$(".stat02").removeClass("on");
						//$(".stat03").removeClass("on");
						//$(".stat04").removeClass("on");
						//_this.pointListCommon("standby");

						var pointSubTab = _this.state.pointSubTab;
						_this.setState({pointSubTab : "1"});

						$(".stat01").removeClass("on");
						$(".stat02").removeClass("on");
						$(".stat03").removeClass("on");
						$(".stat04").removeClass("on");
						$(".stat0"+pointSubTab).addClass("on");

						if(pointSubTab == "1"){
							_this.pointListCommon("standby");
						}else if(pointSubTab == "2"){
							_this.pointListCommon("complete");
						}else if(pointSubTab == "3"){
							_this.pointListCommon("reject");
						}else if(pointSubTab == "4"){
							_this.pointListCommon("cancel");
						}
					}
					if(_thisRealIdx == 1){
						_this.setState({pageNo : 1});
						$(".use02").addClass("on");
						$(".use01").removeClass("on");
						$(".use03").removeClass("on");
						_this.pointListCommon("use");
					}
					if(_thisRealIdx == 2){
						_this.setState({pageNo : 1});
						$(".use03").addClass("on");
						$(".use01").removeClass("on");
						$(".use02").removeClass("on");
						_this.pointListCommon("all");
					}


					setTimeout(function(){
						if ($('.swiper-wrapper').hasClass('important')) {
							$('.swiper-wrapper').removeClass('important');
						}
					}, 700);

				},
				slideChangeTransitionStart: function(){
					$('html').scrollTop(0);

					var _thisRealIdx = this.realIndex;
					var _thisHeight = this.height;

					if(_thisRealIdx == 0){
						_this.setState({pageNo : 1});
						$(".use01").addClass("on");
						$(".use02").removeClass("on");
						$(".use03").removeClass("on");

						$(".stat01").removeClass("on").addClass("on");
						$(".stat02").removeClass("on");
						$(".stat03").removeClass("on");
						$(".stat04").removeClass("on");
						_this.pointListCommon("standby");
					}
					if(_thisRealIdx == 1){
						_this.setState({pageNo : 1});
						$(".use02").addClass("on");
						$(".use01").removeClass("on");
						$(".use03").removeClass("on");
						_this.pointListCommon("use");
					}
					if(_thisRealIdx == 2){
						_this.setState({pageNo : 1});
						$(".use03").addClass("on");
						$(".use01").removeClass("on");
						$(".use02").removeClass("on");
						_this.pointListCommon("all");
					}
				},
				slideChangeTransitionEnd: function(){
					const { cookies } = _this.props;
					cookies.set('pointTab', this.realIndex, { path: '/' });
					
					var realIndex = this.realIndex;
					_this.setState({
						pointTab : realIndex
					});
				}
			}
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 완료 팝업 -->*/}
			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>더 이상 내역이 없습니다</div>
			{/*<!-- 완료 팝업 end -->*/}


			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
				<h2>포인트 내역</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="mycow_point_ybg full">
				<div className="mycow_point_txt">
					<p>나의 포인트 <span>({this.state.uemail})</span></p>
					<strong>{fnc.comma(this.state.pinfo.reward_total - this.state.pinfo.usage_total - this.state.pinfo.expire_total)}P</strong>
				</div>
				<div className="expt_point_wrap">
					<dl>
						<dt>적립예정</dt>
						<dd>{fnc.comma(this.state.pinfo.reward_tobe)}P</dd>
					</dl>
					<dl>
						<dt>소멸예정</dt>
						<dd>{fnc.comma(this.state.pinfo.expire_tobe)}P</dd>
					</dl>
					<p>(이달 말일 소멸)</p>
				</div>
			</div>
			<div className="mycow_point_use_wrap">
				<div className="mycow_point_use_tabs">
					<ul>
						<li onClick={this.pointList.bind(this, 'standby')}><Link className="use01 on" to="#"><i>적립</i></Link></li>
						<li onClick={this.pointList.bind(this, 'use')}><Link className="use02" to="#"><i>사용</i></Link></li>
						<li onClick={this.pointList.bind(this, 'all')}><Link className="use03" to="#"><i>전체</i></Link></li>
					</ul>
				</div>

				<Swiper {...paramsMain} class="swiper-wrapper">
					<div class="swiper-slide">

						{/*<!-- 적립내용 -->*/}
						<div className="use01_cont">
							<div className="use01_tabs">
								<ul>
									<li onClick={this.pointList.bind(this, 'standby2')}><Link className="stat01" to="#"><i>예정</i></Link></li>
									<li onClick={this.pointList.bind(this, 'complete')}><Link className="stat02" to="#"><i>완료</i></Link></li>
									<li onClick={this.pointList.bind(this, 'reject')}><Link className="stat03" to="#"><i>불가</i></Link></li>
									<li onClick={this.pointList.bind(this, 'cancel')}><Link className="stat04" to="#"><i>취소</i></Link></li>
								</ul>
							</div>
							<div className="expt_accum_list">
								<ul>
									{this.state.plists1.map((plist, i) =>
									<li key={i}>
										<Link to="#" onClick={this.goPage.bind(this, plist.idx, plist.product_count)}>
											{
											plist.thumb !== "" &&
											<figure><img alt={plist.name} src={plist.thumb} style={{width:"140px"}}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "쿠폰" &&
											<figure><img alt={plist.name} src={icon_use01}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "캐시" &&
											<figure><img alt={plist.name} src={icon_use02}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "기부" &&
											<figure><img alt={plist.name} src={icon_use03}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "환불" &&
											<figure><img alt={plist.name} src={icon_use04}/></figure>
											}
											{
											plist.thumb === "" && plist.name === "무상지급" &&
											<figure><img alt={plist.name} src={icon_use05}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,4) === "추가지급" &&
											<figure><img alt={plist.name} src={icon_use05}/></figure>
											}
											<div className="expt_accum_txt">
												{plist.product_count >= 1 && <p>{plist.name} <strong>{plist.product_count}건</strong></p>}
												{plist.product_count < 1 && <p>{plist.name}({plist.description})</p>}
												<span>{plist.timestamp}</span>
											</div>
											<div className="expt_accum_point">
												<strong>{fnc.comma(plist.reward)}P</strong>
												{plist.type === 'standby' && <p>예정</p>}
												{plist.type === 'complete' && <p>완료</p>}
												{plist.type === 'reject' && <p className="red">불가</p>}
												{plist.type === 'cancel' && <p>취소</p>}
											</div>
										</Link>
									</li>
									)}
								</ul>
							</div>
							<div className="expt_accum_list">
								<p className="no_list"></p>
							</div>
						</div>
						{/*<!-- 적립내용 end -->*/}
					</div>
					<div class="swiper-slide">
						{/*<!-- 사용내용 -->*/}
						<div className="use02_cont">
							{/*<!-- 총 적립 포인트 -->*/}
							<div className="accum_point_sum">
								<div className="accum_point_inner">
									<p>총 사용 포인트</p>
									<strong>{fnc.comma(this.state.pinfo.usage_total)}P</strong>
								</div>
								<div className="expt_accum_list">
									<ul>
										{this.state.plists2.map((plist, i) =>
										<li key={i}>
											<Link to="#" onClick={this.goPage.bind(this, plist.idx, plist.product_count)}>
												{
												plist.thumb !== "" &&
												<figure><img alt={plist.name} src={plist.thumb} style={{width:"140px"}}/></figure>
												}
												{
												plist.thumb === "" && (plist.name).substr(0,2) === "쿠폰" &&
												<figure><img alt={plist.name} src={icon_use01}/></figure>
												}
												{
												plist.thumb === "" && (plist.name).substr(0,2) === "캐시" &&
												<figure><img alt={plist.name} src={icon_use02}/></figure>
												}
												{
												plist.thumb === "" && (plist.name).substr(0,2) === "기부" &&
												<figure><img alt={plist.name} src={icon_use03}/></figure>
												}
												{
												plist.thumb === "" && (plist.name).substr(0,2) === "환불" &&
												<figure><img alt={plist.name} src={icon_use04}/></figure>
												}
												{
												plist.thumb === "" && plist.name === "무상지급" &&
												<figure><img alt={plist.name} src={icon_use05}/></figure>
												}
												{
												plist.thumb === "" && (plist.name).substr(0,4) === "추가지급" &&
												<figure><img alt={plist.name} src={icon_use05}/></figure>
												}
												<div className="accum_use_txt">
													<p>{plist.name}</p>
													<p>{plist.description}</p>
													<span>{plist.timestamp}</span>
												</div>
												<div className="expt_accum_point">
													<strong>{fnc.comma(plist.reward)}P</strong>
												</div>
											</Link>
										</li>
										)}
									</ul>
								</div>
							</div>
							{/*<!-- 총 적립 포인트 end -->*/}
							<div className="expt_accum_list">
								<p className="no_list"></p>
							</div>
						</div>
						{/*<!-- 사용내용 end -->*/}
					</div>
					<div class="swiper-slide">
						{/*<!-- 전체내용 -->*/}
						<div className="use03_cont">
							{/*<!-- 총 적립 포인트 -->*/}
							<div className="accum_point_sum">
								<div className="accum_point_inner">
									<p>총 적립 포인트</p>
									<strong>{fnc.comma(this.state.pinfo.reward_total)}P</strong>
								</div>
								<div className="expt_accum_list">
									<ul>
									{this.state.plists3.map((plist, i) =>
									<li key={i}>
										<Link to="#" onClick={this.goPage.bind(this, plist.idx, plist.product_count)}>
											{
											plist.thumb !== "" &&
											<figure><img alt={plist.name} src={plist.thumb} style={{width:"140px"}}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "쿠폰" &&
											<figure><img alt={plist.name} src={icon_use01}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "캐시" &&
											<figure><img alt={plist.name} src={icon_use02}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "기부" &&
											<figure><img alt={plist.name} src={icon_use03}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,2) === "환불" &&
											<figure><img alt={plist.name} src={icon_use04}/></figure>
											}
											{
											plist.thumb === "" && plist.name === "무상지급" &&
											<figure><img alt={plist.name} src={icon_use05}/></figure>
											}
											{
											plist.thumb === "" && (plist.name).substr(0,4) === "추가지급" &&
											<figure><img alt={plist.name} src={icon_use05}/></figure>
											}
											<div className="expt_accum_txt">
												{plist.product_count >= 1 && <p>{plist.name} <strong>{plist.product_count}건</strong></p>}
												{plist.product_count < 1 && <p>{plist.name}({plist.description})</p>}
												{plist.type === 'use' && <p>{plist.description}</p>}
												<span>{plist.timestamp}</span>
											</div>
											<div className="expt_accum_point">
												<strong>{fnc.comma(plist.reward)}P</strong>
												{plist.type === 'standby' && <p>예정</p>}
												{plist.type === 'complete' && <p>완료</p>}
												{plist.type === 'reject' && <p className="red">불가</p>}
												{plist.type === 'cancel' && <p>취소</p>}
												{plist.type === 'use' && <p>포인트 사용</p>}
											</div>
										</Link>
									</li>
									)}
									</ul>
								</div>
							</div>
							{/*<!-- 총 적립 포인트 end -->*/}
							<div className="expt_accum_list">
								<p className="no_list"></p>
							</div>
						</div>
						{/*<!-- 전체내용 end -->*/}
					</div>
				</Swiper>
			</div>
			<p className="btn_login on"><Link to="#" onClick={this.pointListMore.bind(this)}>더보기</Link></p>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(point);