import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import * as fnc from '../js/fnc.js';
class mainSearchBlank extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			stxt: props.match.params.word,
			mainTab: cookies.get('mainTab') == undefined ? 0 : cookies.get('mainTab'),
			focusChk : ""
		};
	}

	componentDidMount(){
		if(this.state.mainTab === "2"){
			fnc.event_log("keyword_search", "product", "");
		}
		if(this.state.mainTab === "3"){
			fnc.event_log("keyword_search", "online", "");
		}
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$("body").addClass("gbg");

		$("#stxt").focus();
		if(global.variable.osType == 0){
			if ($('#stxt').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}
		if(global.variable.osType === 1){
			window.android.setWebviewFocus();
		}
	}
	handleKeyPress = (e) => {
		var stxt = $("#stxt").val();
		if(stxt.length>=2){
			if(!$(".btm_btn").find("a").hasClass("on")) $(".btm_btn").find("a").addClass("on");
			if(e.key === "Enter"){
				document.location.href="/main/mainSearch/"+stxt;
			}
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	goSearch = (e) =>{
		e.preventDefault();
		if(!($(".btm_btn").find("a")).hasClass("on")){
			var focusChk = this.state.focusChk;
			if(focusChk !== ""){
				$("#"+focusChk).focus();
			}
			return;
		}

		if($(".btm_btn").find("a").hasClass("on")){
			var stxt = $("#stxt").val();
			document.location.href="/main/mainSearch/"+stxt;
		}
	}
	cancelClick = (e) => {
		$("#stxt").val('');
		$("#stxt").blur();
		$(".btm_btn").find("a").removeClass("on");
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key === "pback"){
			document.location.href = "/main";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/main">앞으로</Link></p>
			<h2>통합 키워드 검색</h2>
		</header>
		<div id="wrap">

			{/*<!-- header 영역 -->*/}
			{/*<header className="mem_header">
				<p className="btn_prev"><Link to="/">앞으로</Link></p>
				<h2>통합 키워드 검색</h2>
			</header>*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="total_search_top">
				<div className="total_search_input">
					<input id="stxt" name="stxt" placeholder="검색어" type="text" onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handleKeyPress.bind(this)} onFocus={this.onFocusHandle.bind(this,'stxt')} onBlur={this.onBlurHandle.bind(this,'stxt')}/>
					<Link className="btn_input_del" to="#" onClick={this.cancelClick.bind(this)}>삭제</Link>
				</div>
				<Link className="btn_cancel" to="#" onClick={this.cancelClick.bind(this)}>취소</Link>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<p className="btm_btn">
			<Link to="#" onClick={this.goSearch.bind(this)}>확인</Link>
		</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainSearchBlank);