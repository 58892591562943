import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class joinEnd extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),

			wh:0
		};
	}

	componentDidMount(){
		fnc.event_log("signup_recommend", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}

		var _this = this;
		var wh = $(window).height();
		_this.setState({wh : wh});
		$(".common_pop").css("top", Math.max(0, ((wh - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").css("top", Math.max(0, ((wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");

		$("#reco").focus();
		if(global.variable.osType == 1){
			try{window.android.setWebviewFocus();}catch(e){console.log("on pc");}
		}
	}
	idChg(e){
		var val = $("#reco").val();
			val = $.trim(val);
		if(val == ""){
			$(".btn_login").find("a").html("건너뛰기");
		}else{
			$(".btn_login").find("a").html("확인");
		}
	}
	proc(e){
		var mode = $(".btn_login").find("a").html();
		if(mode === "건너뛰기"){
			var date = new Date();
			$(".today").html(date.getFullYear()+"."+ (date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) +"."+ (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()));
			$(".certi_ok, .common_pop").fadeIn();
		}else{
			var recomId = $("#reco").val();
			if(global.variable.osType == 1){
				recomId = $.trim(window.android.encryptBase64(recomId));
				this.inputProc(recomId);
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : recomId, param1 : "", param2 : "", param3 : "" , param4 : "" } )
				return;
			}
		}
	}
	alertPop = (msg, e) => {
		//$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "encryptBase64"){
			var recomId = resObj.returnStr;
			this.inputProc(recomId);
		}
	}
	inputProc = (recomId, e) => {
		var url = this.state.apiurl+"/signup/input_recommended.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "check");
		formData.append("email", $.trim(recomId));
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			var error_code = res.data.error_code;
			if(error_code == undefined){
				url = this.state.apiurl+"/signup/input_recommended.json";
				formData = new FormData();
				formData.append("os", global.variable.osType);
				formData.append("appver", this.state.version);
				formData.append("applocale", "kr");
				formData.append("bypass_emergency", 0);
				formData.append("cidx", this.state.cidx);
				formData.append("type", "input");
				formData.append("email", $.trim(recomId));
				let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
				axios.post(url, formData, this.state.config).then(res2 => {
					if( ~~res2.data.result === -1){
						if(res2.data.type === "check_server"){fnc.network_error_pop(res2.data.msg);return;}
						if(res2.data.type === "update"){fnc.update_pop(res2.data.msg);return;}
					}
					clearInterval(spinnerInterval2);
					var error_code2 = res2.data.error_code;
					if(error_code2 == undefined){
						$(".certi_ok_pop, .certi_ok").fadeIn();
						setTimeout(function(){
							var date = new Date();
							$(".today").html(date.getFullYear()+"."+ (date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) +"."+ (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()));
							$(".common_pop").fadeIn();
						}, 2000);
					}else{
						this.alertPop( (res.data.error).replace(/\n/g, "<br/>") );
					}
				}).catch(res => {
					var msg = res.message;
					if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
				});
			}else{
				this.alertPop( (res.data.error).replace(/\n/g, "<br/>") );
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm certi_ok" style={{display:"none"}}></div>
			<div className="certi_ok_pop" style={{display:"none"}}>완료</div>

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 처리팝업 -->*/}
			<div className="common_pop" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						<span className="today">2019.11.11</span><br />
						캐시카우에서 보내는<br />
						광고/이벤트/공지사항 수신 여부가<br />
						<strong className="marketing_push">“동의”</strong> 처리되었습니다.<br />
						<span>광고성 정보 수신동의 설정 : 마이카우 > 알림설정</span>
					</p>
				</div>
				<Link className="pop_ok" to="/member/joinEnd">확인</Link>
			</div>
			{/*<!-- 처리팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<h2>추천인 입력</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div id="content">
				<div className="memjoin_id_input">
					<h3 className="memjoin_input_ttl">캐시카우를 추천해 주신 분이 <br />있으신가요?</h3>
					{
					global.variable.osType == "1" &&
					<p className="memjoin_input_subttl">추천인의 아이디(이메일)을 입력하면 <br />나도! 추천인도! <strong>300P 적립</strong>해드립니다.</p>
					}
					<fieldset>
						<input className="recomm_input" id="reco" name="reco" placeholder="추천인 아이디(이메일)을 입력해주세요." type="email" onChange={this.idChg.bind(this)}/>
						<p className="certi_time_limit mt140">추천인 입력은 가입 후 “마이카우 > 친구초대”에서도 가능합니다.</p>
					</fieldset>
				</div>
				{/*<!-- 내용 영역 end -->*/}
				<p className="btn_login on"><Link to="#" onClick={this.proc.bind(this)}>건너뛰기</Link></p>
			</div>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(joinEnd);