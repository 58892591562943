import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class surveySub1 extends React.Component {
	answerSel(id, i, e){
		$("input[name=answer"+i+"]").val(id)
		$(".option_order_"+i).removeClass("on");
		$(".option_"+id).addClass("on");

		var result = 0;
		for(var j=0 ; j < this.props.qCnt ; j++){
			if($("input[name=answer"+j+"]").val() == ""){
				result++;
			}
		}
		if(result == 0){
			$(".btm_btn").find("a").addClass("on");
		}else{
			$(".btm_btn").find("a").removeClass("on");
		}

		$(".btn_next_ques"+i).find("a").addClass("on");
	}
	render(){
		return (
			<ul>
				{this.props.list.map((item, i) =>
				<li key={i} className={item.option_icon}><Link to="#" className={"option_order_"+this.props.i+" option_"+item.option_id} onClick={this.answerSel.bind(this, item.option_id, this.props.i)}><span>{item.option_text}</span></Link></li>
				)}
			</ul>
		)
	}
}

export default surveySub1;