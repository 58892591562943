import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton } from 'react-share';
import { KaKaoTalkButton  } from "react-social-kr";
import MetaTags from 'react-meta-tags';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import dc_arrow_s from '../img/main/dc_arrow_s.png';
import icon_sns01 from "../img/common/icon_sns01.png";
import icon_sns02 from "../img/common/icon_sns02.png";
import icon_sns03 from "../img/common/icon_sns03.png";
import icon_sns04 from "../img/common/icon_sns04.png";
import icon_sns05 from "../img/common/icon_sns05.png";

class mainOnlineMallThroughIn extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;
		const omidx = props.match.params.id;
		const opidx = props.match.params.pid;

		const cidx = cookies.get('cidx') == undefined ? 0 : cookies.get('cidx');
		const apiurl = cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl');

		this.state = {
			odetail: [],
			oimages: [],
			cidx: cidx,
			apiurl: apiurl,
			version: cookies.get('version'),
			email: cookies.get('uemail'),
			omidx: omidx,
			opidx: opidx,
			autoNoLogin: cookies.get('autoNoLogin'),
			reward:"",
			config : global.variable.config
		};
	}
	componentDidMount(){
		fnc.event_log("online_mall_back", this.state.opidx, "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/online/detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("omidx", this.state.omidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				odetail : res.data.data.info,
				oimages : res.data.data.info.images
			});
			if(res.data.data.product_count > 0){
				this.setState({
					oproducts : res.data.data.product_list
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	componentDidUpdate(){
	}
	backCash(){
		const omidx = this.state.omidx;
		document.location.href = "/main/onlineMall/"+omidx;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			const omidx = this.state.omidx;
			document.location.href = "/main/onlineMall/"+omidx;
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		const paramsOnline = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="online_via_wrap">
				<img alt="" src={this.state.odetail.logo} />
				<p>{this.state.odetail.name}를 이용해주셔서 감사합니다.<br /><br />적립 대상 상품을 구매하셨으면 자동으로 <br />적립 신청됩니다.<br />신청 내역은 당일~1일 후 포인트 내역에서 <br />확인하실 수 있습니다.<br />포인트 적립은 배송 및 구매 확정 후 완료됩니다.<br />(최대45일 소요)</p>
			</div>
		</div>
		<p className="btn_login on"><Link to="#" onClick={this.backCash.bind(this)}>확인</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainOnlineMallThroughIn);