import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import event_default from '../img/mycow/event_default.png'

class mycowEvent extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			uemail: cookies.get('uemail'),
			mycowEventST: cookies.get('mycowEventST') == undefined ? 0 : ~~cookies.get('mycowEventST'),

			elists: [],
			pageNo: 1,
			moreProc: false
		};
	}
	componentDidMount(){
		const { cookies } = this.props;
		if(this.state.mycowEventST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowEventST);
				_this.setState({mycowEventST : 0});
				cookies.set('mycowEventST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			});
		}
		this.init();
		window.addEventListener('scroll', this.showMore.bind(this), true);
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowEvent', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/guide/event_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 10);
		formData.append("offset", 1);
		this.setState({
			pageNo : 1
		});
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({elists : res.data.data.list});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	showMore(){
		if(this.state.moreProc) return;

		let scrollHeight = Math.floor(document.documentElement.scrollHeight, document.body.scrollHeight);
		let scrollTop = Math.ceil(document.documentElement.scrollTop, document.body.scrollTop);
		let clientHeight = Math.ceil(document.documentElement.clientHeight);
		var pageNo = this.state.pageNo;
		if(scrollTop + clientHeight + 150 >= scrollHeight){
			pageNo = pageNo+1;
			this.setState({
				pageNo : pageNo,
				moreProc : true
			});

			const url = this.state.apiurl+"/guide/event_list.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("block", 10);
			formData.append("offset", pageNo);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var elists  = this.state.elists ;
				for (var i in res.data.data.list) {
					elists.push(res.data.data.list[i]);
				}
				this.setState({
					elists  : elists,
					moreProc:false
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowEventST', 0, { path: '/' });
		document.location.href="/mycow/mycow";
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		cookies.set('originPage', '/mycow/mycowEvent', { path: '/' });
		cookies.set('mycowEventST', $(window).scrollTop(), { path: '/' });
		document.location.href=page;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
			<h2>이벤트</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="evt_list_wrap">
				<ul>
					{this.state.elists.map((elist, i) =>
					<li key={i}>
						<Link to="#" onClick={this.goPage.bind(this, "/mycow/mycowEventView/"+elist.idx)}>
							<div className="evt_ttl_wrap">
								{elist.ing === true && elist.is_new === true && <strong className="now"><span className="stat on">진행중</span>{elist.title}</strong>}
								{elist.ing === true && elist.is_new === false && <strong><span className="stat on">진행중</span>{elist.title}</strong>}
								{elist.ing === false && elist.is_new === true && <strong className="now"><span className="stat">마감</span>{elist.title}</strong>}
								{elist.ing === false && elist.is_new === false && <strong><span className="stat">마감</span>{elist.title}</strong>}
								<span className="date">{elist.regist_date}</span>
							</div>
							<figure><img src={elist.banner} onError={(e)=>{e.target.onerror = null; e.target.src=event_default}}/></figure>
						</Link>
					</li>
					)}
				</ul>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<p class="evt_list_band">* 캐시카우의 최근 12개월 간 이벤트 현황을 확인하실 수 있습니다.</p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowEvent);