import React from 'react';
import { Link } from 'react-router-dom';

class mainOnlineMallCategorySub extends React.Component {
	render(){
		return (
			<ul>
				{this.props.list.map((item, i) =>
				<li key={i}>{item.title}</li>
				)}
			</ul>
		)
	}
}

export default mainOnlineMallCategorySub;