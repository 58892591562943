import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import * as fnc from '../../js/fnc.js';

class mainBanner extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			type: this.props.dataFromParent,

			banners: []
		};
	}
	async componentDidMount (){
		var url = this.state.apiurl+"/init/banner.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", this.state.type);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("type : " + this.state.type);
			//console.log(res);
			//console.log(res.data.data.list);
			this.setState({
				banners : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	componentDidUpdate(){
	}
	goPage = (idx, type, eidx, midx, url, e) => {
		e.preventDefault();
		const { cookies } = this.props;

		if(this.state.type === "home"){
			fnc.event_log("home_main", "home_main_click", "list_ad_banner");
		}
		if(this.state.type === "ranking"){
			fnc.event_log("ranking_main", "rangking_main_click", "ranking_ad_banner");
		}
		if(this.state.type === "product"){
			fnc.event_log("product_main", "product_main_click", "product_ad_banner");
		}
		if(this.state.type === "online"){
			fnc.event_log("online_main", "online_main_click", "online_ad_banner");
		}

		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "banner");
		formData.append("idx", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+"/report/visit.json", formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			cookies.set('originPage', '/main', { path: '/' });
			if(type === 0){
				document.location.href="/mycow/mycowEventView/"+eidx;
			}else if(type === 1){
				if(global.variable.osType == "1"){
					window.android.openinurl(url);
				}
				if(global.variable.osType == "0"){
					window.webkit.messageHandlers.openinurl.postMessage({ url : url });
				}
				//document.location.href=url;
			}else if(type === 2){
				document.location.href="/main/onlineMall/"+midx;
			}else if(type === 3){
			}else if(type === 4){
				document.location.href="/mycow/mycowNoticeView/"+eidx;
			}else if(type === 5){
				document.location.href="/main/productView/"+midx;
			}else if(type === 6){
				var nowTab = 0;
				$(".common_nav").find(".swiper-slide > a").each(function(idx){
					if($(this).hasClass("on")){
						nowTab = idx;
					}
				});
				if(nowTab != 1){
					($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(1).click()
				}
				$(".rankHotk[idx="+midx+"]").click();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	render(){
		const paramsLoop = {
			lazy: true,
			//loop: true,
			//loopFillGroupWithBlank: true,
			autoplay: {
				delay: 3000,
			},
			shouldSwiperUpdate: true,
			//touchRatio: 0.9
		}
		return (
		<section className="main_mid_bnr">
			<Swiper className="swiper-wrapper" {...paramsLoop}>
				{this.state.banners.map(banner =>
				<div className="swiper-slide" key={banner.idx}>
				<div onClick={this.goPage.bind(this, banner.idx, banner.jumptype, banner.eidx, banner.midx, banner.url)}><img alt={banner.title} src={banner.image}/></div>
				</div>
				)}
			</Swiper>
			<div className="swiper-pagination"></div>
		</section>
		)
	}
}

export default withCookies(mainBanner);