import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { KaKaoTalkButton  } from "react-social-kr";
import { Helmet } from "react-helmet";
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import { Player, ControlBar, VolumeMenuButton, BigPlayButton, LoadingSpinner } from 'video-react';
import "../css/video-react.css";

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import ReactHelmet from '../common/helmet';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import iconTime from "../img/main/icon_time.png";
import iconBarcode from "../img/main/icon_barcode.png";
import icon_sns01 from "../img/common/icon_sns01.png";
import icon_sns02 from "../img/common/icon_sns02.png";
import icon_sns03 from "../img/common/icon_sns03.png";
import icon_sns04 from "../img/common/icon_sns04.png";
import icon_sns05 from "../img/common/icon_sns05.png";

function customSend(tempId, title, contents, thumb, id){
	try{
		window.Kakao.Link.sendCustom({
			templateId: tempId,
			templateArgs: {
				'title': title,
				'description': contents,
				'THU': thumb,
				'path': 'bridge/productView/'+id
			}
		});
	}catch(e){
		alert("error");
	}
}

class mainProductView extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			email: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),
			ccidx: props.match.params.id,

			reward:"",
			pdetail: [],
			pdetailConditions: [],
			pdetailImgs: [],
			pdetailImgCnt: 0,
			pdetailStores: [],
			pdetailStoreCnt: 0
		};
	}
	componentDidMount(){
		this.init();
		fnc.event_log("product_detail", this.state.ccidx, this.state.pdetail.name);
	}
	componentDidUpdate(){
		$(".prod_detail_tags").find("li:gt(4)").hide();
		$(".simpleCond:eq(0)").hide();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/product/detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ccidx", this.state.ccidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				pdetail : res.data.data,
				pdetailConditions : res.data.data.condition_list,
				pdetailImgs : res.data.data.images,
				pdetailImgCnt : res.data.data.image_count,
				pdetailStores : res.data.data.available_stores,
				pdetailStoreCnt : res.data.data.available_stores.length
			});
			if(res.data.data.reward_method === "F"){
				this.setState({
					reward : res.data.data.reward +"포인트"
				});
			}
			if(res.data.data.reward_method === "R"){
				this.setState({
					reward : res.data.data.reward +"퍼센트"
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		//$(window).scroll(function() { 
		//$(".pop_sns_share").css("top", Math.max(0, (($(window).height() - $(".pop_sns_share").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		//});

		//this.player.subscribeToStateChange(this.handleStateChange.bind(this));
	}
	share(e){
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$('html, body').css("overflow", "hidden");
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}else if(global.variable.osType === 1){
			$('body').css("overflow", "hidden");
		}
		$(".pop_sns_share").css({
			"top" : Math.max(0, (($(window).height() - $(".pop_sns_share").outerHeight()) / 2) + posY) + "px",
			"margin-top" : "100px",
			"opacity" : "0",
			"display" : "block"
		});
		$(".pop_sns_share").animate({"margin-top":"0px","opacity" : "1"}, 300);
		$(".sns_share, .pop_sns_share").fadeIn("fast");
		$(".sns_share").fadeIn();
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			if(global.variable.osType === 0) $("#wrap").css("top", 0);
			$('html, body').css("overflow", "visible");
			$("html, body").off('scroll touchmove');
		}else if(global.variable.osType === 1){
			$('html, body').css("overflow", "visible");
		}
		$(".pop_sns_share").animate({"margin-top":"100px","opacity" : "0"}, 300);
		$(".layer_dimm").fadeOut();

		if(cName === "sns_share"){
			$(".pop_sns_share").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 300,
				"complete" : function(){
					$(".pop_sns_share").hide();
				}
			});
		}
		if(cName === "sns_share2"){
			$(".pop_sns_share").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 300,
				"complete" : function(){
					$(".pop_sns_share").hide();
				}
			});
		}
	}
	kakaotalkShare(){
		fnc.event_log("share_kakao", "product", this.state.ccidx);
		if(this.state.cidx === 0){
			customSend(24042, "습관이캐시가되다-캐시카우", "\""+this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~나도 포인트 받고 싶다면", this.state.pdetail.thumb, this.state.ccidx);
		}else{
			customSend(24042, "습관이캐시가되다-캐시카우\n[추천인 : "+ this.state.email+"]", "\""+this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~나도 포인트 받고 싶다면", this.state.pdetail.thumb, this.state.ccidx);
		}
	}
	facebookShare = (url, e) => {
		fnc.event_log("share_facebook", "product", this.state.ccidx);
		if(global.variable.osType === 1){
			window.android.openUrl(this.state.cidx, "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", "", "", "", "");
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.openUrl.postMessage({ cidx : this.state.cidx, url : "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", param1 : "", param2 : "", param3 : "", param4 : "" });
			return;
		}
	}
	smsShare = (e) => {
		fnc.event_log("share_sms", "product", this.state.ccidx);
		var contents = "body=" + encodeURIComponent("습관이 캐시가 되다-캐시카우\n"+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]\n" )+"\""+ this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~\n\n나도 포인트 받고 싶다면 >\nhttps://devdown.cash-cow.co.kr/campaign_product?ccidx=" + this.state.ccidx + "&type=B&name=" + this.state.pdetail.name);
		if(global.variable.osType === 1){
			contents = "smsto:?" + contents;
			window.android.sendSms(contents, global.variable.osType);
		}
		if(global.variable.osType === 0){
			contents = "sms://&" + contents;
			window.webkit.messageHandlers.sendSms.postMessage({ "msg" : contents, "os" : global.variable.osType});
			return;
		}
	}
	mailShare = (e) => {
		fnc.event_log("share_mail", "product", this.state.ccidx);
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		var originPage = this.state.originPage;
		cookies.set('originPage', '', { path: '/' });
		document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? page : originPage);
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$('html, body').css("overflow", "hidden");
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}else if(global.variable.osType === 1){
			$('body').css("overflow", "hidden");
		}
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			if(global.variable.osType === 0) $("#wrap").css("top", 0);
			$('html, body').css("overflow", "visible");
			$("html, body").off('scroll touchmove');
		}else if(global.variable.osType === 1){
			$('html, body').css("overflow", "visible");
		}
		$(".alertPop").fadeOut("fast");
	}
	playerObj(){
		try{
			this.player.pause();
		}catch(e){
		}
	}
	copy(){
		fnc.event_log("share_copy", "product", this.state.ccidx);
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$('html, body').css("overflow", "hidden");
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}else if(global.variable.osType === 1){
			$('body').css("overflow", "hidden");
		}
		$(".certi_ok_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".certi_ok_pop").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".certi_ok").fadeIn();

		var _this = this;
		setTimeout(function(){
			$(".certi_ok").fadeOut();
			$(".certi_ok_pop").animate({"margin-top":"100","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					if(global.variable.osType === 0){
						$(window).scrollTop(posY);
						if(global.variable.osType === 0) $("#wrap").css("top", 0);
						$('html, body').css("overflow", "visible");
						$("html, body").off('scroll touchmove');
					}else if(global.variable.osType === 1){
						$('html, body').css("overflow", "visible");
					}
					$(".certi_ok_pop").hide();
				}
			});
		}, 2000);
	}
	handleStateChange(state, prevState) {
		if(state.hasStarted){
			alert("play");
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPage('/main');
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		var _this = this;
		const paramsProduct1 = {
			slidesPerView: 1,
			spaceBetween: 2,
			grabCursor: true,
			//loop: true,
			loopFillGroupWithBlank: true,
			autoplay: {
				delay: 3000,
			},
			//autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true,
			touchRatio: 0.3,
			on: {
				init: function() {
				},
				slideChangeTransitionStart: function(idx){
					_this.playerObj();
				},
				slideChangeTransitionEnd: function(){
				}
			}
		};
		const paramsProduct2 = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			//autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true,
			touchRatio: 0.3
		};
		var kakao = {
			pathname: "http://cashcow.mgine.co.kr/main/mainProductView/"+this.state.ccidx,
			message: "습관이캐시가되다-캐시카우\\n추천인 : "+ this.state.uemail,
			jsKey:"c25914003c6cff033ea4508f09d896a6",
//			media:"http://cashcow.mgine.co.kr/static/media/buddy_invi_top.a33ffc39.jpg",
			id:"kakaobtn"
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<ReactHelmet 
				keywords="cashcow" 
				description={this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~"} 
				title={this.state.pdetail.name} 
				favicon={this.state.pdetail.thumb}
			/>

			{/*<!-- 인증완료 팝업 -->*/}
			<div className="layer_dimm certi_ok"style={{display:"none"}}></div>
			<div className="certi_ok_pop"style={{display:"none"}}>복사되었습니다</div>
			{/*<!-- 인증완료 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPop" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 공유하기 팝업 -->*/}
			<div className="layer_dimm sns_share" style={{display:"none"}}></div>
			<div className="pop_sns_share" style={{display:"none"}}>
				<strong className="pop_sns_share_ttl">공유하기</strong>
				<div className="pop_sns_share_btns">
					<ul>
						<KaKaoTalkButton {...kakao} style={{display:"none"}}><li><Link to="#"><img alt="" src={icon_sns01} /></Link></li></KaKaoTalkButton>
						<li><Link to="#" onClick={this.kakaotalkShare.bind(this)}><img alt="" src={icon_sns01} /></Link></li>
						<li><Link to="#" onClick={this.facebookShare.bind(this, encodeURIComponent("https://devdown.cash-cow.co.kr/campaign_product?ccidx=" + this.state.ccidx + "&type=B&name=" + this.state.pdetail.name))}><img alt="" src={icon_sns02}/></Link></li>

						{global.variable.osType === 1 &&
						<li><a onClick={this.smsShare.bind(this)} href={"smsto:?body=습관이 캐시가 되다-캐시카우%0D%0A"+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]%0D%0A" )+"\""+ this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~%0D%0A%0D%0A나도 포인트 받고 싶다면 >%0D%0A"+encodeURIComponent("https://devdown.cash-cow.co.kr/campaign_product?ccidx=" + this.state.ccidx + "%26type=B%26name=" + this.state.pdetail.name)}><img alt="" src={icon_sns03} /></a></li>
						}
						{global.variable.osType === 0 &&
						<li><Link to="#" onClick={this.smsShare.bind(this)}><img alt="" src={icon_sns03}/></Link></li>
						}

						<li><a onClick={this.mailShare.bind(this)} href={"mailto:?subject=습관이 캐시가 되다-캐시카우&body="+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]%0D%0A" )+"\""+ this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~%0D%0A%0D%0A나도 포인트 받고 싶다면 >%0D%0A"+encodeURIComponent("https://devdown.cash-cow.co.kr/campaign_product?ccidx=" + this.state.ccidx + "&type=B&name=" + this.state.pdetail.name)}><img alt="" src={icon_sns04} /></a></li>
						<CopyToClipboard text={"습관이 캐시가 되다-캐시카우\n\r"+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]\n\r" )+"\""+ this.state.pdetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~\n\r\n\r나도 포인트 받고 싶다면 >\n\rhttps://devdown.cash-cow.co.kr/campaign_product?ccidx=" + this.state.ccidx + "&type=B&name=" + this.state.pdetail.name} onCopy={() => {this.popCls('sns_share2');this.copy();} }>
						<li><Link to="#"><img alt="" src={icon_sns05} /></Link></li>
						</CopyToClipboard>
					</ul>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'sns_share')}>확인</Link>
			</div>
			{/*<!-- 공유하기 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goPage.bind(this, '/main')}>앞으로</Link></p>
				<h2>{this.state.pdetail.name}</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="prod_detail_slide">
				<div className="prod_detail_slide_inner">

					{
					this.state.pdetailImgCnt == 1 &&
					<Swiper {...paramsProduct2} className="swiper-wrapper">
						{this.state.pdetailImgs.map((pdetailImg, i) =>
						<div className="swiper-slide" key={i}>
							{
							pdetailImg.ctype === "P" &&
							<Link to="#"><img src={pdetailImg.image}/></Link>
							}
							{
							pdetailImg.ctype === "V" &&
							<Player
								playsInline
								autoPlay={false}
								fluid={false}
								width={690}
								//poster={pdetailImg.image}
								src={pdetailImg.image}
								ref={(player) => { this.player = player }}
							>
								<BigPlayButton position="center" />
								<LoadingSpinner />
								<ControlBar autoHide={true} disableDefaultControls>
									<VolumeMenuButton/>
								</ControlBar>
							</Player>
							}
						</div>
						)}
					</Swiper >
					}
					{
					this.state.pdetailImgCnt > 1 &&
					<Swiper {...paramsProduct1} className="swiper-wrapper">
						{this.state.pdetailImgs.map((pdetailImg, i) =>
						<div className="swiper-slide" key={i}>
							{
							pdetailImg.ctype === "P" &&
							<Link to="#"><img src={pdetailImg.image}/></Link>
							}
							{
							pdetailImg.ctype === "V" &&
							<Player
								playsInline
								autoPlay={false}
								fluid={false}
								width={690}
								//poster={pdetailImg.image}
								src={pdetailImg.image}
								ref={(player) => { this.player = player }}
							>
								<BigPlayButton position="center" />
								<LoadingSpinner />
								<ControlBar autoHide={true} disableDefaultControls>
									<VolumeMenuButton/>
								</ControlBar>
							</Player>
							}
						</div>
						)}
					</Swiper >
					}
					<div className="swiper-pagination"></div>
				</div>
				<div className="prod_detail_tags">
					<ul>
						{this.state.pdetail.limit_type === 'none' && <li><span>무제한</span></li>}
						{this.state.pdetail.limit_type === 'day' && <li><span>하루 {this.state.pdetail.daily_limit - this.state.pdetail.remain_trial}/{this.state.pdetail.daily_limit}</span></li>}
						{this.state.pdetail.limit_type === 'season' && <li><span>기간 {this.state.pdetail.seasonal_limit - this.state.pdetail.remain_trial}/{this.state.pdetail.seasonal_limit}</span></li>}
						{this.state.pdetail.type === 'REC' && this.state.pdetail.e_receipt === '1' && this.state.pdetailStoreCnt === 1 && <li><span>전자영수증</span></li>}
						{this.state.pdetail.type === 'REC' && this.state.pdetail.p_receipt === '1' && this.state.pdetailStoreCnt === 1 && <li><span>종이영수증</span></li>}
						{this.state.pdetailConditions.map((pdetailCondition, index) =><li key={index} className="simpleCond"><span>{(pdetailCondition.title).substr(0, 5)}</span></li>)}
					</ul>
					{
					this.state.pdetail.near_end &&
					<div className="period_warn">
						<img alt="" src={iconTime}/>
						적립 기한이 곧 종료됩니다.
						<div className="period_warn_bg"></div>
					</div>
					}
				</div>
			</div>
			<div className="grey_gap"></div>
			<div className="prod_detail_info">
				{
				this.state.pdetail.reward_method === "F" &&
				<strong className="prod_detail_info_ttl">
					{this.state.pdetail.reward}P
					<Link className="share" to="#" onClick={this.share.bind(this)}>공유하기</Link>
				</strong>
				}
				{
				this.state.pdetail.reward_method === "R" &&
				<strong className="prod_detail_info_ttl">
					{this.state.pdetail.reward}%
					<Link className="share" to="#" onClick={this.share.bind(this)}>공유하기</Link>
				</strong>
				}
				<div className="prod_detail_info_cont">
					<strong className="prod_name">{this.state.pdetail.name}</strong>
					<p className="prod_brand">[{this.state.pdetail.company}]</p>
					<ul>
						{
						this.state.pdetail.barcode != "" &&
						<li>상품 바코드 번호 : <img alt="" src={iconBarcode}/> {this.state.pdetail.barcode}</li>
						}
						{
						this.state.pdetail.notice != "" &&
						<li>{this.state.pdetail.notice}</li>
						}
						<li>적립 신청 기한 :  {this.state.pdetail.end_date}까지</li>
					</ul>
				</div>
			</div>
			<div className="grey_gap"></div>
			<div className="prod_detail_more">
				<strong className="prod_detail_more_ttl">적립 상세 정보</strong>
				<div className="prod_detail_more_cont">
					{this.state.pdetailConditions.map((pdetailCondition, index) =>
					<dl key={index}>
						<dt><span>{(pdetailCondition.title).substr(0, 5)}</span></dt>
						<dd>{pdetailCondition.desc}</dd>
					</dl>
					)}
				</div>
			</div>
			<div className="grey_gap"></div>
			<div className="prod_detail_more">
				<strong className="prod_detail_more_ttl">리워드 가능 매장</strong>
				<div className="prod_detail_more_cont">
					<div className="reward_store_slide">
						<ul>
							{this.state.pdetailStores.map((pdetailStore, index) =>
							<li key={index}><img alt="" src={pdetailStore.image}/></li>
							)}
						</ul>
					</div>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainProductView);