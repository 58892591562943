import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { post } from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

// img import
import spinner from '../img/common/spinner.gif';
import arrow_small_g from '../img/common/arrow_small_g.png';
import icon_time_limit from '../img/mem/icon_time_limit.png';

class login extends Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			loginAfterPath: cookies.get('loginAfterPath'),
			autoNoLogin: cookies.get('autoNoLogin'),
			nowPath: window.location.href,

			authType: "",
			uuid: "",
			token: "",
			model: "",
			version: "",
			osversion: "",
			iosEnCnt: 0,
			iosid: "",
			iospw: "",
			tabCnt: 0,
			wh: 0,
			focusChk : ""
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/member/login");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/member/login"});}
		}catch(e){}
	}
	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/member/login");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/member/login"});}
		}catch(e){}
		const { cookies } = this.props;
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		try{
			if(global.variable.osType === 1){
				window.android.getDeviceInfo("", "", "", "", "", "");
			}
			if(global.variable.osType === 0){
				window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "value", param2 : "value", param3 : "value" , param4 : "value" } );
			}
		}catch(e){
			if(global.variable.osType == 1){
				cookies.set('autoNoLogin', 'Y', { path: '/' });
				cookies.set('cidx', "-5oBda2A2x8LEWWyrqdEcw,,", { path: '/' });
				cookies.set('uemail', "v7test@cash-cow.co.kr", { path: '/' });
				cookies.set('version', "7.0.0", { path: '/' });
				document.location.href="/main";
			}
//			if(global.variable.osType == 0){
//				cookies.set('autoNoLogin', 'Y', { path: '/' });
//				cookies.set('cidx', "-5oBda2A2x8LEWWyrqdEcw,,", { path: '/' });
//				cookies.set('uemail', "v7test@cash-cow.co.kr", { path: '/' });
//				cookies.set('version', "7.0.0", { path: '/' });
//				document.location.href="/main";
//			}
		}
		$("#uid").focus();

		if(global.variable.osType == 0){
			if ($('#uid, #upw').is(':focus')) {
				$('body').addClass('notouch').on('scroll touchmove mousewheel', function(e){
					e.preventDefault();
				});
			} else {
				$('body').removeClass('notouch').off('scroll touchmove mousewheel');
			}
		}

		if(global.variable.osType === 1){
			window.android.setWebviewFocus();
		}
		this.setState({focusChk : "uid"});

		cookies.set('autoNoLogin', 'Y', { path: '/' });

		$(".goLogin, .chgSite").css("-webkit-tap-highlight-color", "transparent");

		this.setState({
			wh : $(window).height()
		});
	}
	onFocusHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:name});
		}, 10);
	}
	onBlurHandle = (name, e) => {
		var _this = this;
		setTimeout(function(){
		_this.setState({focusChk:""});
		}, 10);
	}
	popCls(){
		$(".other_id_limit").fadeOut("fast");
		$(".other_id_limit_dimm").fadeOut("fast");
		$(".login_limit").fadeOut("fast");
		$(".login_limit_dimm").fadeOut("fast");
		$(".other_id_limit_1005").fadeOut("fast");
		$(".other_id_limit_1005_dimm").fadeOut("fast");
		$(".rest_id").fadeOut("fast");
		$(".rest_id_dimm").fadeOut("fast");
	}
	alertPop = (msg, e) => {
		$(".alertPop").find("p").html(msg);
		$(".alertPop, .alertPopDimm").fadeIn();
	}
	alertCls = (e) => {
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	loginCls(){
		var returnPage = this.state.loginAfterPath == undefined ? "/main" : this.state.loginAfterPath;
		document.location.href=returnPage;
	}
	goAuth = (mode, e) => {
		this.setState({auth : mode});
		if(global.variable.osType == 1){window.android.getIdentity(0, "url", "param1", "param2", "param3", "param4", mode, $("#uid").val());}
		if(global.variable.osType == 0){window.webkit.messageHandlers.getIdentity.postMessage({ cidx : 0, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : mode, userid : $("#uid").val() } );}
	}
	goLogin = (e) => {
		e.preventDefault();
		if(!$(".btn_real_login").hasClass("on")){
			var focusChk = this.state.focusChk;
			if(focusChk !== ""){
				$("#"+focusChk).focus();
			}
			return;
		}

		const { cookies } = this.props;
		var uid = $("#uid").val();
		var upw = $("#upw").val();
		var uuid = this.state.uuid;
		var token = this.state.token;
		var model = this.state.model;
		var version = this.state.version;
		var returnPage = this.state.loginAfterPath;

		if(uid == ""){
			this.alertPop("아이디를 입력해주세요.");
			$("#uid").focus();
			return;
		}
		if(upw == ""){
			this.alertPop("비밀번호를 입력해주세요.");
			$("#upw").focus();
			return;
		}

		try{
			if(global.variable.osType == 1){
				var enc_uid = window.android.encryptBase64(uid);
				var enc_upw = window.android.encryptBase64(upw);

				enc_uid = $.trim(enc_uid);
				enc_upw = $.trim(enc_upw);

				this.loginProc(uid, upw, enc_uid, enc_upw);
				return;
			}
			if(global.variable.osType == 0){
				//$("#iosid").val("");
				//$("#iospw").val("");
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : uid, param1 : "id", param2 : "", param3 : "" , param4 : "" } )
				window.webkit.messageHandlers.encryptBase64.postMessage({ str : upw, param1 : "pw", param2 : uid, param3 : upw, param4 : "" } )
				return;
			}
		}catch(e){
			if(global.variable.osType == 1){
			}
			if(global.variable.osType == 0){
				uid = "";
				upw = "";
				uuid = "";
			}
		}
	}
	loginProc = (uid, upw, enc_uid, enc_upw, e) =>{
		var _this = this;

		const { cookies } = this.props;
		var uuid = _this.state.uuid;
		var token = _this.state.token;
		var model = _this.state.model;
		var version = _this.state.version;
		var osversion = _this.state.osversion;
		var osType = global.variable.osType;
		var returnPage = _this.state.loginAfterPath;

		var url = _this.state.apiurl+"/login/sign_in.json";
		var formData = new FormData();
		formData.append("os", osType);
		formData.append("appver", version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", "0");
		formData.append("cidx", "0");
		formData.append("userid", enc_uid);
		formData.append("password", enc_upw);
		formData.append("uuid", uuid);
		formData.append("osver", osversion);
		formData.append("device_model", encodeURI(model));
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, _this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			try{
				var error_code = res.data.error_code;
				if(error_code == undefined){
					var cidx = res.data.data.cidx;
					_this.setState({
						cidx : cidx
					});
					url = _this.state.apiurl+"/report/token.json";
					formData = new FormData();
					formData.append("os", osType);
					formData.append("appver", version);
					formData.append("applocale", "kr");
					formData.append("bypass_emergency", 0);
					formData.append("cidx", cidx);
					formData.append("token", token);
					let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
					axios.post(url, formData, _this.state.config).then(res => {
						if( ~~res.data.result === -1){
							if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
							if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
						}
						clearInterval(spinnerInterval2);
						var lng = "";
						var lat = "";
						try{
							navigator.geolocation.getCurrentPosition(function(position) {
								lng = position.coords.longitude;
								lat = position.coords.latitude;
								if(osType == 1){
									lng = $.trim(window.android.encryptBase64(lng));
									lat = $.trim(window.android.encryptBase64(lat));
									_this.callLocation(lng, lat, osType, version, cidx, uid, upw, uuid, model);
								}
								if(osType == 0){
									window.webkit.messageHandlers.encryptBase64.postMessage({ str : lng, param1 : "lng", param2 : "", param3 : "" , param4 : "" } )
									window.webkit.messageHandlers.encryptBase64.postMessage({ str : lat, param1 : "lat", param2 : uid, param3 : upw, param4 : "" } )
									return;
								}
							});
						}catch(e){
							lng = "131.86743";
							lat = "37.24074";
							if(osType == 1){
								lng = $.trim(window.android.encryptBase64(lng));
								lat = $.trim(window.android.encryptBase64(lat));
								_this.callLocation(lng, lat, osType, version, cidx, uid, upw, uuid, model);
							}
							if(osType == 0){
								window.webkit.messageHandlers.encryptBase64.postMessage({ str : lng, param1 : "lng", param2 : "", param3 : "" , param4 : "" } )
								window.webkit.messageHandlers.encryptBase64.postMessage({ str : lat, param1 : "lat", param2 : uid, param3 : upw, param4 : "" } )
								return;
							}
						}
					}).catch(res => {
						var msg = res.message;
						if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
					});
				}else{
					fnc.event_log("login_fail", "", "");
					if(res.data.error_code == 1003){ // ok
						$(".other_id_limit").find("strong").html("["+ $("#uid").val() +"]");
						$(".other_id_limit").css("top", Math.max(0, ((this.state.wh - $(".other_id_limit").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".other_id_limit, .other_id_limit_dimm").fadeIn("fast");
					}else if(res.data.error_code == 1004){ // no
						$(".login_limit").css("top", Math.max(0, ((this.state.wh - $(".login_limit").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".login_limit, .login_limit_dimm").fadeIn("fast");
					}else if(res.data.error_code == 1005){ // ok
						$(".other_id_limit_1005").find("strong").html("["+ $("#uid").val() +"]");
						$(".other_id_limit_1005").css("top", Math.max(0, ((this.state.wh - $(".other_id_limit_1005").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".other_id_limit_1005, .other_id_limit_1005_dimm").fadeIn("fast");
					}else if(res.data.error_code == 1006){
						$(".rest_id").css("top", Math.max(0, ((this.state.wh - $(".rest_id").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".rest_id, .rest_id_dimm").fadeIn("fast");
					}else{
						$(".alertPop").find("p").html( "<img src='"+ icon_time_limit +"' style='padding:10px 10px 0 0;'/>"+(res.data.error).replace(/\n/g, "<br/>") );
						$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
						$(".alertPop, .alertPopDimm").fadeIn();
					}
				}
			}catch(e){
				//console.log(e);
				//console.log(res);
				//alert(res.data.error_code);
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	callLocation = (lng, lat, osType, version, cidx, userid, password, uuid, model, e) => {
		var url = this.state.apiurl+"/report/location.json";
		var formData = new FormData();
		formData.append("os", osType);
		formData.append("appver", version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", cidx);
		formData.append("latitude", lat);
		formData.append("longitude", lng);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.callUserInfo(version, cidx, userid, password, uuid, model);
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	callUserInfo = (version, cidx, userid, password, uuid, model, e) => {
		const { cookies } = this.props;
		var returnPage = this.state.loginAfterPath;
			returnPage = returnPage == undefined ? "/main" : returnPage;

		if(global.variable.osType == 1){
			window.android.login(cidx, userid, global.variable.osType, password);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.login.postMessage({ cidx : cidx , userid : userid, os : global.variable.osType, password : password } );
		}

		var url = this.state.apiurl+"/user/info.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			cookies.set('cidx', cidx, { path: '/' });
			cookies.set('uemail', userid, { path: '/' });
			cookies.set('adult', res.data.data.adult, { path: '/' });
			fnc.event_log("login_success", "", "");
			document.location.href=returnPage;
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	idpwChk(){
		var uid = $("#uid").val();
		var upw = $("#upw").val();

		if(uid != "" && upw != ""){
			if(!$(".btn_real_login").hasClass("on")) $(".btn_real_login").addClass("on");
		}else{
			$(".btn_real_login").removeClass("on");
		}
	}
	chgSite(){
		var tabCnt = this.state.tabCnt;
		tabCnt++;
		if(tabCnt == 13){
			$(".hiddenDiv").fadeIn("fast");
		}else if(tabCnt > 13){
			$(".hiddenDiv").fadeOut("fast");
			tabCnt = 0;
		}
		this.setState({
			tabCnt : tabCnt
		});
	}
	goSite(){
		if(window.confirm("변경하시겠습니까?")){
			var apiurl = $("#apiurl").val();

			const { cookies } = this.props;
			cookies.set('apiurl', apiurl, { path: '/' });

			document.location.reload();
		}
	}
	goSite2(){
		if(window.confirm("이동하시겠습니까?")){
			var nowPath = $("#nowPath").val();
			document.location.href=nowPath;
		}
	}
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "init"){
			document.location.href="/init";
		}
		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version,
				osversion : resObj.os_version
			});
			//this.alertPop(resObj.uuid);

			//let d = new Date();
			//d.setTime(d.getTime() + (60*60*1000));
			//cookies.set('uuid', resObj.uuid, { path: '/', expires: d });
			//cookies.set('model', resObj.model, { path: '/', expires: d });
			//cookies.set('version', resObj.version, { path: '/', expires: d });
			cookies.set('uuid', resObj.uuid, { path: '/' });
			cookies.set('model', resObj.model, { path: '/' });
			cookies.set('version', resObj.version, { path: '/' });
		}
		if(resObj.key == "encryptBase64"){
			var mode = resObj.param1;
			if(mode === "id"){
				this.setState({
					iosid : resObj.returnStr
				});
			}
			if(mode === "pw"){
				this.setState({
					iospw : resObj.returnStr
				});

				var userid		= resObj.param2;
				var password	= resObj.param3;

				var enc_userid = this.state.iosid;
				var enc_password = resObj.returnStr;

				this.loginProc(userid, password, enc_userid, enc_password);
			}
			if(mode === "lng"){
				this.setState({
					ioslng : resObj.returnStr
				});
			}
			if(mode === "lat"){
				this.setState({
					ioslat : resObj.returnStr
				});

				var enc_lng = this.state.ioslng;
				var enc_lat = resObj.returnStr;

				this.callLocation(enc_lng, enc_lat, global.variable.osType, this.state.version, this.state.cidx, resObj.param2, resObj.param3, this.state.uuid, this.state.model);
			}

		}
		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;
			if(error == undefined){
				this.popCls();
				if(this.state.auth == "restore"){
					$(".alertPop").find("p").html( "<img src='"+ icon_time_limit +"' style='padding:10px 10px 0 0;'/>휴면 계정에서 복원되었습니다.<br/>로그인 화면으로 이동합니다." );
					$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
					$(".alertPop, .alertPopDimm").fadeIn();
				}
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					this.alertPop( (resObj.result_message.error).replace(/\$\$/g, '\n') );
				}
			}
		}
		if(resObj.key == "pback"){
			this.loginCls();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "input_focus"){
			$("#uid").focus();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 본인인증 로그인 제한 팝업 1003 -->*/}
			<div className="layer_dimm other_id_limit_dimm" style={{display:"none"}}></div>
			<div className="common_pop h723 other_id_limit" style={{display:"none"}}>
				<Link className="close_pop" to="#" onClick={this.popCls.bind(this)}>닫기</Link>
				<div className="common_pop_txt h623">
					<p className="login_limit_warn">
						<strong>[cashcow@cash-cow.co.kr]</strong>이 <br/>
						본인의 아이디(이메일)가 맞는 경우 <br/>
						본인인증 후 서비스 이용바랍니다. <br/>
						본인인증 후에는 <br/>
						다른 아이디로의 로그인이 제한됩니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>취소</Link>
					<Link className="dgrey goAuth" to="#" onClick={this.goAuth.bind(this, 'loginblock')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 로그인 제한 팝업 end -->*/}

			{/*<!-- 로그인 제한 팝업 1004 -->*/}
			<div className="layer_dimm login_limit_dimm" style={{display:"none"}}></div>
			<div className="common_pop h723 login_limit" style={{display:"none"}}>
				<Link className="close_pop" to="#" onClick={this.popCls.bind(this)}>닫기</Link>
				<div className="common_pop_txt h623">
					<div className="login_penalty">
						<div className="terms">
							규제 : 로그인 제한<br/>사유 : 타인 계정 사용
						</div>
						서비스 이용약관에 근거하여 <br/>
						타인의 아이디사용 시 서비스 이용이 <br/>
						제한됨을 알려드립니다. <br/>
						기타 자세한 문의는 <br/>
						help@cash-cow.co.kr로 해주세요.
						<a href="mailto:help@cash-cow.co.kr" className="btn_pop_inquiry mt20">문의하기<img alt="" src={arrow_small_g}/></a>
					</div>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- 로그인 제한 팝업 end -->*/}

			{/*<!-- 기존 사용 아이디 팝업 1005 -->*/}
			<div className="layer_dimm other_id_limit_1005_dimm" style={{display:"none"}}></div>
			<div className="common_pop h723 other_id_limit_1005" style={{display:"none"}}>
				<Link className="close_pop" to="#" onClick={this.popCls.bind(this)}>닫기</Link>
				<div className="common_pop_txt h623">
					<p className="login_limit_warn">
						<strong>[cashcow@cash-cow.co.kr]</strong>이 <br/>
						본인의 아이디(이메일)가 맞는 경우 <br/>
						본인인증 후 서비스 이용바랍니다. <br/>
						본인인증 후에는 <br/>
						다른 아이디로의 로그인이 제한됩니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>취소</Link>
					<Link className="dgrey goAuth" to="#" onClick={this.goAuth.bind(this, 'recert')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 기존 사용 아이디 팝업 end -->*/}

			{/*<!-- 휴면 계정 복원 팝업 1006 -->*/}
			<div className="layer_dimm rest_id_dimm" style={{display:"none"}}></div>
			<div className="common_pop rest_id" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						회원님의 계정이 휴면 상태입니다.<br/>
						휴면 계정에서 일반 계정으로<br/>
						복원하시겠습니까?
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this)}>아니요</Link>
					<Link className="dgrey goAuth" to="#" onClick={this.goAuth.bind(this, 'restore')}>네</Link>
				</div>
			</div>
			{/*<!-- 휴면 계정 복원 팝업 end -->*/}

			<header className="mem_header">
				<p className="btn_close"><Link to="#" className="loginCls" onClick={this.loginCls.bind(this)}>닫기</Link></p>
				<h2>로그인</h2>
				<p className="chgSite" style={{width:"100px", height:"100px", float:"right"}} onClick={this.chgSite.bind(this)}></p>
			</header>
			<div className="login_cont">
				<h3 className="login_logo">CASHCOW</h3>
				<fieldset>
					{/*
					<input id="uid" name="uid" placeholder="아이디" type="email" value="nGyY9zQK-Ocv7EohVdOhrwrtlKN19U3jSuXkCJMr3rE,"/>
					<input id="upw" name="upw" placeholder="비밀번호" type="password" value="p60eedYQKkR9RJ-H7fCOpQ,,"/>
					*/}
					<input type="email" id="uid" name="uid" placeholder="아이디" value={this.state.value} onChange={this.idpwChk.bind(this)} onFocus={this.onFocusHandle.bind(this,'uid')} onBlur={this.onBlurHandle.bind(this,'uid')}/>
					<input type="password" id="upw" name="upw" placeholder="비밀번호" value={this.state.value} onChange={this.idpwChk.bind(this)} onFocus={this.onFocusHandle.bind(this,'upw')} onBlur={this.onBlurHandle.bind(this,'upw')}/>

					<input type="hidden" id="iosid" name="iosid" value={this.state.iosid}/>
					<input type="hidden" id="iospw" name="iospw" value={this.state.iospw}/>

				</fieldset>
				<div className="login_mem_btns">
					<p><Link to="/member/join" className="joinCls">회원가입</Link></p>
					<p><Link to="/member/findId">아이디 찾기</Link></p>
					<p><Link to="/member/findPw">비밀번호 찾기</Link></p>
				</div>
				<div className="login_mem_btns hiddenDiv" style={{display:"none"}}>
					<input type="text" id="apiurl" name="apiurl" value={this.state.apiurl} onChange={this.handleChange}/>
					<p className="btn_login on" style={{margin:"0 0 0 0px", padding:"0 0 0 0px"}}><Link to="#" onClick={this.goSite.bind(this)} style={{color:"white"}}>변경</Link></p>

					<input type="text" id="nowPath" name="nowPath" value={this.state.nowPath} onChange={this.handleChange}/>
					<p className="btn_login on" style={{margin:"0 0 0 0px"}}><Link to="#" onClick={this.goSite2.bind(this)} style={{color:"white"}}>이동</Link></p>
				</div>
			</div>
			{/*<p className="btn_login btn_real_login"><Link to="#" className="goLogin" onClick={this.goLogin.bind(this)}>로그인</Link></p>*/}
		</div>
		<p className="btn_login btn_real_login"><Link to="#" className="goLogin" onClick={this.goLogin.bind(this)}>로그인</Link></p>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(login);