import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import * as fnc from '../../js/fnc.js';

import dc_arrow_s from '../../img/main/dc_arrow_s.png';

class mainOnlineMall extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			adult: cookies.get('adult'),

			onlinemalls: [],
			pageNo: 1,
			moreProc: false,
			randNum : Math.floor(Math.random()* 9)+1
		};
	}
	componentDidMount (){
		this.init();
		window.addEventListener('scroll', this.showMore.bind(this), true);
	}
	init(){
		const url = this.state.apiurl+"/online/home_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		this.setState({pageNo : 1});
		formData.append("rand_key", this.state.randNum);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				onlinemalls : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	showMore(){
		const { cookies } = this.props;
		let mainTab = cookies.get('mainTab');
		if(mainTab !== "3") return;

		if(this.state.moreProc) return;

		let scrollHeight = Math.floor(document.documentElement.scrollHeight, document.body.scrollHeight);
		let scrollTop = Math.ceil(document.documentElement.scrollTop, document.body.scrollTop);
		let clientHeight = Math.ceil(document.documentElement.clientHeight);
		var pageNo = this.state.pageNo;
		if(scrollTop + clientHeight + 150 >= scrollHeight){
			pageNo = pageNo+1;
			this.setState({
				pageNo : pageNo,
				moreProc : true
			});

			const url = this.state.apiurl+"/online/home_list.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			formData.append("offset", pageNo);
			formData.append("rand_key", this.state.randNum);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var onlinemalls  = this.state.onlinemalls ;
				for (var i in res.data.data.list) {
					//console.log(res.data.data.list[i]);
					onlinemalls.push(res.data.data.list[i]);
				}
				this.setState({
					onlinemalls  : onlinemalls
				});
				$('nav.common_nav div p:eq(3) a').trigger('click');
				this.setState({
					moreProc:false
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	componentDidUpdate(){
		$(document).ready(function(){
			$('.online_mall_top').parent('.mall_list').css({
				'margin':'0 0 20px 0'
			});
			$('.online_mall_list_each').parent('.mall_list').css({
				'margin':'0 10px 20px 0'
			});
			$('.online_mall_list_each').parent('.mall_list:odd').css({
				'margin':'0 0 20px 0'
			});
		});
		$(".online_mall_list").find(".detail_tags").each(function(idx){
			if($(this).find("span").length > 3){
				$(this).find("span:gt(2)").remove();
			}
		});
	}
	goOnlineView = (idx, mode, e) =>{
		const { cookies } = this.props;
		cookies.set('mainTab', '3', { path: '/' });

		if(mode === "A")
			fnc.event_log("online_main", "online_main_click", "online_pr_banner");
		if(mode === "G")
			fnc.event_log("online_main", "online_main_click", "online_exhibition");
		if(mode === "S")
			fnc.event_log("online_main", "online_main_click", "online_prod_banner");
		if(mode === "B")
			fnc.event_log("online_main", "online_main_click", "online_blind_banner");

		document.location.href = "/main/onlineMall/"+idx;
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		$("#posy").val(posY);
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			$('html, body').css("overflow", "hidden");
		}else{
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	render(){
		return (
				<div className="online_mall_list">
					{this.state.onlinemalls.map((onlinemall, i) =>
					<div className="mall_list" key={i}>
						{
						onlinemall.type === "A" &&
						<div className="online_mall_top">
							{
							onlinemall.check_adult === 1 && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to="#" onClick={this.goOnlineView.bind(this, onlinemall.omidx, onlinemall.type)}>
								<figure><img alt="" src={onlinemall.omlogo} /></figure>
								<div className="online_mall_top_txt">
									<strong>{onlinemall.opname}</strong>
									{
									onlinemall.reward_method === "F" &&
									<p className="accum_info">전상품 +{onlinemall.reward}P적립</p>
									}
									{
									onlinemall.reward_method === "R" &&
									<p className="accum_info">전상품 +{onlinemall.reward}%적립</p>
									}
									<div className="cate_tags">
										<span className="on"><i>{(onlinemall.ocname).substr(0, 5)}</i></span>
									</div>
									<div className="detail_tags">
										{onlinemall.guide_01 !== "" && <span><i>{(onlinemall.guide_01).substr(0, 5)}</i></span>}
										{onlinemall.guide_02 !== "" && <span><i>{(onlinemall.guide_02).substr(0, 5)}</i></span>}
										{onlinemall.guide_03 !== "" && <span><i>{(onlinemall.guide_03).substr(0, 5)}</i></span>}
										{/*onlinemall.guide_04 !== "" && <span>{(onlinemall.guide_04).substr(0, 5)}</span>*/}
									</div>
								</div>
							</Link>
						</div>
						}

						{
						onlinemall.type === "G" &&
						<div className="online_mall_top">
							{
							onlinemall.check_adult === 1 && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to="#" onClick={this.goOnlineView.bind(this, onlinemall.omidx, onlinemall.type)}>
								<figure><img alt="" src={onlinemall.omlogo} /></figure>
								<div className="online_mall_top_txt">
									<strong>{onlinemall.opname}</strong>
									{
									onlinemall.reward_method === "F" &&
									<p className="accum_info">기획전 +{onlinemall.reward}P적립</p>
									}
									{
									onlinemall.reward_method === "R" &&
									<p className="accum_info">기획전 +{onlinemall.reward}%적립</p>
									}
									<div className="cate_tags">
										<span className="on"><i>{(onlinemall.ocname).substr(0, 5)}</i></span>
									</div>
									<div className="detail_tags">
										{onlinemall.guide_01 !== "" && <span><i>{(onlinemall.guide_01).substr(0, 5)}</i></span>}
										{onlinemall.guide_02 !== "" && <span><i>{(onlinemall.guide_02).substr(0, 5)}</i></span>}
										{onlinemall.guide_03 !== "" && <span><i>{(onlinemall.guide_03).substr(0, 5)}</i></span>}
										{/*onlinemall.guide_04 !== "" && <span>{(onlinemall.guide_04).substr(0, 5)}</span>*/}
									</div>
								</div>
							</Link>
						</div>
						}

						{
						onlinemall.type === "S" &&
						<div className="online_mall_list_each">
							{
							onlinemall.check_adult === 1 && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to="#" onClick={this.goOnlineView.bind(this, onlinemall.omidx, onlinemall.type)}>
								<figure><img alt="" src={onlinemall.opimage}/></figure>
								<div className="prod_name">
									<strong>{onlinemall.opname}</strong>
								</div>
								<p className="store_name">{onlinemall.omname}</p>
								<p className="prod_price"><strike>{onlinemall.org_price}원</strike><img alt="" className="dc_arrow_s" src={dc_arrow_s} />{onlinemall.dc_price}원</p>
								{
								onlinemall.reward_method === "F" && onlinemall.dc_ratio > 0 &&
								<p className="dc_result">-{onlinemall.dc_ratio}%할인 +{onlinemall.reward}P적립</p>
								}
								{
								onlinemall.reward_method === "F" && onlinemall.dc_ratio === 0 &&
								<p className="dc_result">{onlinemall.reward}P적립</p>
								}
								{
								onlinemall.reward_method === "R" && onlinemall.dc_ratio > 0 &&
								<p className="dc_result">-{onlinemall.dc_ratio}%할인 +{onlinemall.reward}%적립</p>
								}
								{
								onlinemall.reward_method === "R" && onlinemall.dc_ratio === 0 &&
								<p className="dc_result">{onlinemall.reward}%적립</p>
								}
							</Link>
						</div>
						}

						{
						onlinemall.type === "B" &&
						<div className="online_mall_list_each">
							{
							onlinemall.check_adult === 1 && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to="#" onClick={this.goOnlineView.bind(this, onlinemall.omidx, onlinemall.type)}>
								<figure><img alt="" src={onlinemall.opimage}/></figure>
								<div className="prod_name">
									<strong>{onlinemall.opname}</strong>
								</div>
								<p className="store_name">{onlinemall.omname}</p>
								<p className="prod_price"><strike>미공개</strike><img alt="" className="dc_arrow_s" src={dc_arrow_s} />미공개</p>
								<p className="dc_result">-?%할인 +?%적립</p>
							</Link>
						</div>
						}
					</div>
					)}
					<button name="onlineOnline" id="onlineOnline" style={{display:"none"}} onClick={this.init.bind(this)}>test</button>
				</div>

		)
	}
}

export default withCookies(mainOnlineMall);