module.exports = global.variable = {
	osType : (navigator.userAgent.toLowerCase()).match(/android/i) ? 1 : 0,
	config : {
		headers: {
			'Content-Type':'application/x-www-form-urlencoded',
			'Origin': 'https://cashcow.mgine.co.kr'
		},
		withCredentials: true,
		timeout: 9000
	},
	axiosTimeoutMsg : "timeout of 9000ms exceeded",
	config2 : {
		headers: {
			'Content-Type':'application/x-www-form-urlencoded',
			'Origin': 'https://cashcow.mgine.co.kr'
		},
		withCredentials: true,
		timeout: 15000
	},
	axiosTimeoutMsg2 : "timeout of 15000ms exceeded",
	apiurl : "https://devapi.v7.cash-cow.co.kr/v7.0",
	networkDisconnected : "네트워크 연결에 실패하였습니다.<br/>네트워크 연결 상태를 확인해 주세요.",
	cowmallDomain : "https://devmall.v7.cash-cow.co.kr",

	playStoreUrl : "https://play.google.com/store/apps/details?id=kr.co.cashcow.cashcow",
	appStoreUrl : "https://apps.apple.com/kr/app/%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0/id945613961"
};