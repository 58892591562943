import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class mycowPset extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),

			push: "",
			event_push: ""
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_notify_set", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowPset', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const url = this.state.apiurl+"/user/info.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				push : res.data.data.push,
				event_push : res.data.data.event_push
			});

			if(res.data.data.push){
				$("input[name=push]").prop("checked", true);
			}
			if(res.data.data.event_push){
				$("input[name=event_push]").prop("checked", true);
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	componentDidUpdate(){
	}
	setPush(type, e){
		var pushVal = $("input[name=push]").prop("checked");
		var eventPushVal = $("input[name=event_push]").prop("checked");
		if(type === "event_push"){
			if(eventPushVal){
				$(".marketing_push").html("“동의”");
			}else{
				$(".marketing_push").html("“수신거부”");
			}
			var posY = $(window).scrollTop();
			this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");
			$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
			var date = new Date();
			$(".today").html(date.getFullYear()+"."+ (date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) +"."+ (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()));
			$(".layer_dimm, .common_pop").fadeIn();
		}

		pushVal = (pushVal === true ? "Y" : "N");
		eventPushVal = (eventPushVal === true ? "Y" : "N");

		const url = this.state.apiurl+"/user/push_control.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("push", pushVal);
		formData.append("event_push", eventPushVal);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	layerCls(){
		$(".layer_dimm, .common_pop").fadeOut();
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycow";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
			<h2>알림 설정</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm" style={{display:"none"}}></div>
			{/*<!-- 처리팝업 -->*/}
			<div className="common_pop" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						<span className="today">2019.11.11</span><br />
						캐시카우에서 보내는<br />
						광고/이벤트/공지사항 수신 여부가<br />
						<strong className="marketing_push">“동의”</strong> 처리되었습니다.<br />
						<span>광고성 정보 수신동의 설정 : 마이카우 > 알림설정</span>
					</p>
				</div>
				<Link className="pop_ok" to="#" onClick={this.layerCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- 처리팝업 end -->*/}
			{/*<!-- 알림 설정 팝업 -->*/}
			<div className="prod_detail_pop" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>
						적립/포인트 알림이 켜져있어야<br />
						적립 신청이 가능합니다.<br />
						알림 설정 페이지로 이동하시겠습니까?
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#">아니오</Link>
					<Link className="dgrey" to="#">네</Link>
				</div>
			</div>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			{/*<!-- <header className="mem_header">
				<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
				<h2>알림 설정</h2>
			</header> -->*/}
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="mycow_alarm_wrap">
				<ul>
					<li>
						<div className="txt">
							<strong>적립/포인트 알림</strong>
							<ul>
								<li>캐시카우 포인트 적립 현황을 알려드립니다.</li>
								<li>알림이 꺼져있을 경우 적립 신청이 불가합니다.</li>
							</ul>
						</div>
						<label className="alarm_chk">
							<input id="push" name="push" type="checkbox" onChange={this.setPush.bind(this, 'push')}/>
							<span className="btn_alarm_chk"></span>
						</label>
					</li>
					<li>
						<div className="txt">
							<strong>마케팅 정보 수신 동의 및 설정</strong>
							<ul>
								<li>캐시카우에서 제공하는 광고/이벤트/공지사항 등 <br />다양한 혜택들 알려드립니다.</li>
							</ul>
						</div>
						<label className="alarm_chk">
							<input id="event_push" name="event_push" type="checkbox" onChange={this.setPush.bind(this, 'event_push')}/>
							<span className="btn_alarm_chk"></span>
						</label>
					</li>
				</ul>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowPset);