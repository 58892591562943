import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';

class init extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			inits: [],
			initCnt: []
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/common/init");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/common/init"});}
		}catch(e){}
	}

	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/common/init");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/common/init"});}
		}catch(e){}
		fnc.event_log("first_splash", "", "");
		const { cookies } = this.props;
		try{
			if(global.variable.osType === 1){
				window.android.getDeviceInfo("", "", "", "", "", "");
				window.android.setPullOnOff("off");
			}
			if(global.variable.osType === 0){
				window.webkit.messageHandlers.getDeviceInfo.postMessage({ cidx : "value" , url : "value", param1 : "value", param2 : "value", param3 : "value" , param4 : "value" } );
				window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );
			}
		}catch(e){
			if(global.variable.osType === 1){
				cookies.set('autoNoLogin', 'Y', { path: '/' });
				cookies.set('cidx', "-5oBda2A2x8LEWWyrqdEcw,,", { path: '/' });
				cookies.set('uemail', "v7test@cash-cow.co.kr", { path: '/' });
				cookies.set('version', "7.0.0", { path: '/' });
				//document.location.href="/";
			}
			if(global.variable.osType === 0){
//				cookies.set('autoNoLogin', 'Y', { path: '/' });
//				cookies.set('cidx', "DFZzyUHMaSeBN67uilLPaA,,", { path: '/' });
//				cookies.set('uemail', "v7test2@cash-cow.co.kr", { path: '/' });
				cookies.set('version', "7.0.0", { path: '/' });
//				document.location.href="/";
			}
			const url = this.state.apiurl+"/init/tutorial.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", "7.0.0");
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", "0");
			formData.append("resolution", "xhdpi");
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				try{
					console.log(res.data.data);
					this.setState({
						inits : res.data.data.list,
						initCnt :  res.data.data.count
					});
				}catch(e){
					alert( res );
				}
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		/*
		if(resObj.key == "pulldown"){
			document.location.reload();
		}
		*/
		if(resObj.key == "getDeviceInfo"){
			this.setState({
				uuid : resObj.uuid,
				token : resObj.token,
				model : resObj.model,
				version : resObj.version
			});
			//let d = new Date();
			//d.setTime(d.getTime() + (60*60*1000));
			//cookies.set('uuid', resObj.uuid, { path: '/', expires: d });
			//cookies.set('model', resObj.model, { path: '/', expires: d });
			//cookies.set('version', resObj.version, { path: '/', expires: d });
			cookies.set('uuid', resObj.uuid, { path: '/' });
			cookies.set('model', resObj.model, { path: '/' });
			cookies.set('version', resObj.version, { path: '/' });

			const url = this.state.apiurl+"/init/tutorial.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", resObj.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", "0");
			if(resObj.resolution_width =="720" || resObj.resolution_width =="1080" || resObj.resolution_width =="800"){
			formData.append("resolution", "xhdpi");
			}else if(resObj.resolution_width =="1440" || resObj.resolution_width =="1200" || resObj.resolution_width =="1600"){
			formData.append("resolution", "xxhdpi");
			}else{
			formData.append("resolution", "xhdpi");
			}
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				this.setState({
					inits : res.data.data.list,
					initCnt :  res.data.data.count
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){
					fnc.network_error_pop();
					return;
				}else{
					alert(res);
				}
			});
		}
	}
	goMain(){
		fnc.event_log("first_tutorial_end", "", "");
		document.location.href="/main";
	}
	render(){
		const paramsInit = {
			slidesPerView: 1,
			loop: false,
			loopFillGroupWithBlank: true,
			rebuildOnUpdate: true,
			touchRatio: 0.3,
			pagination: {
				el: '.init',
				clickable: true
			},
			on: {
				slideChangeTransitionEnd: function(){
					fnc.event_log("first_tutorial_"+( ~~this.realIndex + 1 ), "", "");
				}
			}
		}
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{
			this.state.initCnt === 1 &&
			this.state.inits.map((init, i) =>
			<div className="tutorial_slide">
				<div className="swiper-slide" key={i}>
					<img src={init.image}/><Link to="/main">시작하기</Link>
				</div>
			</div>
			)
			}
			{
			this.state.initCnt > 1 &&
			<div className="tutorial_slide">
				<Swiper {...paramsInit} className="swiper-wrapper init">
					{this.state.inits.map((init, i) =>
					<div className="swiper-slide" key={i}>
						<img src={init.image}/>
						{
						i+1 == this.state.initCnt &&
							<p onClick={this.goMain.bind(this)}>시작하기</p>
						}
					</div>
					)}
				</Swiper>
				<p className="btn_tuto_skip" onClick={this.goMain.bind(this)}>SKIP</p>
			</div>
			}
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(init);