import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { KaKaoTalkButton  } from "react-social-kr";
import { Helmet } from "react-helmet";
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import { Player, ControlBar, VolumeMenuButton, BigPlayButton, LoadingSpinner } from 'video-react';
import "../css/video-react.css";

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import ReactHelmet from '../common/helmet';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import dc_arrow_s from '../img/main/dc_arrow_s.png';
import icon_sns01 from "../img/common/icon_sns01.png";
import icon_sns02 from "../img/common/icon_sns02.png";
import icon_sns03 from "../img/common/icon_sns03.png";
import icon_sns04 from "../img/common/icon_sns04.png";
import icon_sns05 from "../img/common/icon_sns05.png";

function customSend(tempId, title, contents, thumb, id){
	try{
		window.Kakao.Link.sendCustom({
			templateId: tempId,
			templateArgs: {
				'title': title,
				'description': contents,
				'THU': thumb,
				'path': 'bridge/onlineMall/'+id
			}
		});
	}catch(e){
		alert("error");
	}
}

class mainOnlineMall extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;
		const omidx = props.match.params.id;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			email: cookies.get('uemail'),
			adult: cookies.get('adult'),
			autoNoLogin: cookies.get('autoNoLogin'),
			originPage: cookies.get('originPage'),

			odetail: [],
			oimages: [],
			oimagesCnt: 0,
			oproductCnt: 0,
			oproducts: [],
			omidx: omidx,
			reward:""
		};
	}
	componentDidMount(){
		this.init();
		fnc.event_log("online_mall_detail", this.state.omidx, this.state.odetail.name);
	}
	componentDidUpdate(){
		$(document).ready(function(){
			$('.online_mall_top').parent('.mall_list').css({
				'margin':'0 0 20px 0'
			});
			$('.online_mall_list_each').parent('.mall_list').css({
				'margin':'0 10px 20px 0'
			});
			$('.online_mall_list_each').parent('.mall_list:odd').css({
				'margin':'0 0 20px 0'
			});
		});
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").addClass("gbg");

		const url = this.state.apiurl+"/online/detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("omidx", this.state.omidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			console.log(res);
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				odetail : res.data.data.info,
				oimages : res.data.data.info.images,
				oimagesCnt : res.data.data.info.images.length
			});
			this.setState({
				oproductCnt : res.data.data.product_count
			});
			if(res.data.data.product_count > 0){
				this.setState({
					oproducts : res.data.data.product_list
				});
			}
			if(res.data.data.reward_method === "F"){
				this.setState({
					reward : res.data.data.reward +"P"
				});
			}
			if(res.data.data.reward_method === "R"){
				this.setState({
					reward : res.data.data.reward +"%"
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	share(e){
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".pop_sns_share").css({
			"top" : Math.max(0, (($(window).height() - $(".pop_sns_share").outerHeight()) / 2) + $(window).scrollTop()) + "px",
			"margin-top" : "100px",
			"opacity" : "0",
			"display" : "block"
		});
		$(".pop_sns_share").animate({"margin-top":"0px","opacity" : "1"}, 300);
		$(".sns_share, .pop_sns_share").fadeIn("fast");
		$(".sns_share").fadeIn();
	}
	kakaotalkShare(){
		fnc.event_log("share_kakao", "online", this.state.omidx);
		if(this.state.cidx === 0){
			customSend(24042, "습관이캐시가되다-캐시카우", "\""+this.state.odetail.name +"\"사면 "+ this.state.reward +"포인트를 돌려준데요~", this.state.odetail.logo, this.state.omidx);
		}else{
			customSend(24042, "습관이캐시가되다-캐시카우\n[추천인 : "+ this.state.email+"]", "\""+this.state.odetail.name +"\"사면 "+ this.state.reward +"포인트를 돌려준데요~", this.state.odetail.logo, this.state.omidx);
		}
	}
	facebookShare = (url, e) => {
		fnc.event_log("share_facebook", "online", this.state.omidx);
		if(global.variable.osType === 1){
			window.android.openUrl(this.state.cidx, "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", "", "", "", "");
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.openUrl.postMessage({ cidx : this.state.cidx, url : "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", param1 : "", param2 : "", param3 : "", param4 : "" });
			return;
		}
	}
	smsShare = (e) => {
		fnc.event_log("share_sms", "online", this.state.omidx);
		var contents = "body=" + encodeURIComponent("습관이 캐시가 되다-캐시카우\n"+ ( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]\n" )+"\""+ this.state.odetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~\n\n나도 포인트 받고 싶다면 >\nhttps://devdown.cash-cow.co.kr/online_mall?omidx="+this.state.omidx);
		if(global.variable.osType === 1){
			contents = "smsto:?" + contents;
			window.android.sendSms(contents, global.variable.osType);
		}
		if(global.variable.osType === 0){
			contents = "sms://&" + contents;
			window.webkit.messageHandlers.sendSms.postMessage({ "msg" : contents, "os" : global.variable.osType});
			return;
		}
	}
	mailShare = (e) => {
		fnc.event_log("share_mail", "online", this.state.omidx);
	}
	goPage = (page, e) => {
		const { cookies } = this.props;
		var originPage = this.state.originPage;
		cookies.set('originPage', '', { path: '/' });
		document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? page : originPage);
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop( $("#posy").val() );
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		if(cName === "login_need"){
			$(".common_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".common_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "age_limit"){
			$(".age_limit, .age_limit_dimm").fadeOut()
		}
		if(cName === "cert_need"){
			$(".cert_need, .cert_need_dimm").fadeOut()
		}
		if(cName === "recert_need"){
			$(".recert_need, .recert_need_dimm").fadeOut()
		}
		if(cName === "sns_share"){
			$(".pop_sns_share").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 300,
				"complete" : function(){
					$(".pop_sns_share").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "sns_share2"){
			$(".pop_sns_share").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 300,
				"complete" : function(){
					$(".pop_sns_share").hide();
				}
			});
			$(".sns_share").fadeOut();
		}
	}
	goLogin(){
		const { cookies } = this.props;
		cookies.set('loginAfterPath', '/main/onlineMall/'+this.state.omidx, { path: '/' });
		document.location.href="/member/login";
	}
	goAuth = (mode, e) => {
		if(global.variable.osType === 1){
			window.android.getIdentity(this.state.cidx, "url", "param1", "param2", "param3", "param4", mode, this.state.uemail);
		}
		if(global.variable.osType === 0){
			window.webkit.messageHandlers.getIdentity.postMessage({ cidx : this.state.cidx, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : mode, userid : this.state.uemail } );
			return;
		}
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop").fadeOut("fast");
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;
			if(error == undefined){
				cookies.set('adult', "Y", { path: '/' });
				document.location.href="/main/onlineMall/"+this.state.omidx;
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					this.alertPop( (resObj.result_message.error).replace(/\n/g, '<br/>') );
				}
			}
		}
		if(resObj.key == "pback"){
			this.goPage('/main');
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key === "pulldown"){
			this.init();
		}
	}
	playerObj(){
		try{
			this.player.pause();
		}catch(e){
		}
	}
	copy(){
		fnc.event_log("share_copy", "online", this.state.omidx);
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		$(".certi_ok_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".certi_ok_pop").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".certi_ok").fadeIn();

		var _this = this;
		setTimeout(function(){
			$(".certi_ok").fadeOut();
			$(".certi_ok_pop").animate({"margin-top":"100","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					if(global.variable.osType === 0){
						$(window).scrollTop(_this.state.posY);
						$("#wrap").css("top", 0);
						$("html, body").off('scroll touchmove');
					}
					$('html, body').css("overflow", "visible");
					$(".certi_ok_pop").hide();
				}
			});
		}, 2000);
	}
	render(){
		var _this = this;
		const paramsOnline1 = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			//loop: true,
			loopFillGroupWithBlank: true,
			autoplay: {
				delay: 3000,
			},
			//autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true,
			touchRatio: 0.3,
			on: {
				init: function() {
				},
				slideChangeTransitionStart: function(idx){
					_this.playerObj();
				},
				slideChangeTransitionEnd: function(){
				}
			}
		};
		const paramsOnline2 = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			rebuildOnUpdate: true,
			touchRatio: 0.3
		};

		var kakao = {
			pathname: "http://cashcow.mgine.co.kr/main/onlineMall/"+this.state.omidx,
			message: "습관이캐시가되다-캐시카우\\n추천인 : "+ this.state.uemail,
			jsKey:"c25914003c6cff033ea4508f09d896a6",
//			media:"http://cashcow.mgine.co.kr/static/media/buddy_invi_top.a33ffc39.jpg",
			id:"kakaobtn"
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<ReactHelmet 
				keywords="cashcow" 
				description={this.state.odetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~"} 
				title={this.state.odetail.name}
				favicon={this.state.odetail.logo}
			/>

			{/*<!-- 인증완료 팝업 -->*/}
			<div className="layer_dimm certi_ok"style={{display:"none"}}></div>
			<div className="certi_ok_pop"style={{display:"none"}}>복사되었습니다</div>
			{/*<!-- 인증완료 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPop" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 로그인 필요 팝업 -->*/}
			<div className="layer_dimm login_need_dimm"></div>
			<div className="common_pop login_need">
				<div className="common_pop_txt">
					<p>로그인이 필요한 서비스입니다.<br/>로그인 페이지로 이동하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'login_need')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goLogin.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 로그인 필요 팝업 end -->*/}

			{/*<!-- 미성년자 사용 불가 팝업 -->*/}
			<div className="layer_dimm age_limit" style={{display:"none"}}></div>
			<div className="common_pop age_limit" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p className="emphasis">미성년자 사용 불가 서비스 입니다.</p>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'age_limit')}>확인</Link>
			</div>
			{/*<!-- 미성년자 사용 불가 팝업 end -->*/}

			{/*<!-- 본인인증 필요 팝업 -->*/}
			<div className="layer_dimm cert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop cert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						본인(성인) 인증이 필요한 서비스입니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'cert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'adult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 필요 팝업 end -->*/}

			{/*<!-- 본인인증 재인증 필요 팝업 -->*/}
			<div className="layer_dimm recert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop recert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						성인 인증 후 1년이 경과되었습니다.<br/>
						관련 법에 따라 재인증이 필요합니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'recert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'readult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 재인증 필요 팝업 end -->*/}

			{/*<!-- 공유하기 팝업 -->*/}
			<div className="layer_dimm sns_share" style={{display:"none"}}></div>
			<div className="pop_sns_share" style={{display:"none"}}>
				<strong className="pop_sns_share_ttl">공유하기</strong>
				<div className="pop_sns_share_btns">
					<ul>
						<KaKaoTalkButton {...kakao} style={{display:"none"}}><li><Link to="#"><img alt="" src={icon_sns01} /></Link></li></KaKaoTalkButton>
						<li><Link to="#" onClick={this.kakaotalkShare.bind(this)}><img alt="" src={icon_sns01} /></Link></li>
						<li><Link to="#" onClick={this.facebookShare.bind(this, "https://devdown.cash-cow.co.kr/online_mall?omidx="+this.state.omidx)}><img alt="" src={icon_sns02}/></Link></li>

						{global.variable.osType === 1 &&
						<li><a onClick={this.smsShare.bind(this)} href={"smsto:?body="+encodeURIComponent("습관이 캐시가 되다-캐시카우\n"+ ( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]\n" )+"\""+ this.state.odetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~\n\n나도 포인트 받고 싶다면 >\nhttps://devdown.cash-cow.co.kr/online_mall?omidx="+this.state.omidx)}><img alt="" src={icon_sns03} /></a></li>
						}
						{global.variable.osType === 0 &&
						<li><Link to="#" onClick={this.smsShare.bind(this)}><img alt="" src={icon_sns03}/></Link></li>
						}

						<li><a onClick={this.mailShare.bind(this)} href={"mailto:?subject=습관이 캐시가 되다-캐시카우&body="+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]%0D%0A" )+"\""+ this.state.odetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~%0D%0A%0D%0A나도 포인트 받고 싶다면 >%0D%0A"+encodeURIComponent("https://devdown.cash-cow.co.kr/online_mall?omidx="+this.state.omidx)}><img alt="" src={icon_sns04} /></a></li>
						<CopyToClipboard text={"습관이 캐시가 되다-캐시카우\n\r"+( this.state.cidx === 0 ? "" : "[추천인 : "+ this.state.email +"]\n\r" )+"\""+ this.state.odetail.name +"\"사면 "+ this.state.reward +"를 돌려준데요~\n\r\n\r나도 포인트 받고 싶다면 >\n\rhttps://devdown.cash-cow.co.kr/online_mall?omidx=" + this.state.omidx} onCopy={() => {this.popCls('sns_share2');this.copy();} }>
						<li><Link to="#"><img alt="" src={icon_sns05} /></Link></li>
						</CopyToClipboard>


					</ul>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'sns_share')}>확인</Link>
			</div>
			{/*<!-- 공유하기 팝업 end -->*/}
			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="#" onClick={this.goPage.bind(this, '/main')}>앞으로</Link></p>
				<h2>{this.state.odetail.name}</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="online_detail_slide">
				<div className="online_detail_slide_inner">
					{
					this.state.oimagesCnt == 1 &&
					<Swiper {...paramsOnline2} className="swiper-wrapper">
						{this.state.oimages.map((oimage, i) =>
							<div className="swiper-slide" key={i}>
							{
							oimage.type === "P" &&
							<Link to="#"><img src={oimage.image}/></Link>
							}
							{
							oimage.type === "V" &&
							<Player
								playsInline
								autoPlay={false}
								fluid={false}
								width={750}
								src={oimage.image}
								ref={(player) => { this.player = player }}
							>
								<BigPlayButton position="center" />
								<LoadingSpinner />
								<ControlBar autoHide={true} disableDefaultControls>
									<VolumeMenuButton/>
								</ControlBar>
							</Player>
							}
							</div>
						)}
					</Swiper >
					}
					{
					this.state.oimagesCnt > 1 &&
					<Swiper {...paramsOnline1} className="swiper-wrapper">
						{this.state.oimages.map((oimage, i) =>
							<div className="swiper-slide" key={i}>
							{
							oimage.type === "P" &&
							<Link to="#"><img src={oimage.image}/></Link>
							}
							{
							oimage.type === "V" &&
							<Player
								playsInline
								autoPlay={false}
								fluid={false}
								width={750}
								src={oimage.image}
								ref={(player) => { this.player = player }}
							>
								<BigPlayButton position="center" />
								<LoadingSpinner />
								<ControlBar autoHide={true} disableDefaultControls>
									<VolumeMenuButton/>
								</ControlBar>
							</Player>
							}
							</div>
						)}
					</Swiper >
					}
					<div className="swiper-pagination"></div>
				</div>
				<div className="online_detail_tags">
					{this.state.odetail.guide_01 !== "" && <p className="tag"><span>{this.state.odetail.guide_01}</span></p>}
					{this.state.odetail.guide_02 !== "" && <p className="tag"><span>{this.state.odetail.guide_02}</span></p>}
					{this.state.odetail.guide_03 !== "" && <p className="tag"><span>{this.state.odetail.guide_03}</span></p>}
					{this.state.odetail.guide_04 !== "" && <p className="tag"><span>{this.state.odetail.guide_04}</span></p>}
					{this.state.odetail.guide_05 !== "" && <p className="tag"><span>{this.state.odetail.guide_05}</span></p>}
					<Link className="share" to="#" onClick={this.share.bind(this)}>공유하기</Link>
				</div>
			</div>
			<div className="online_detail_cont">
				<div className="online_mall_list">

					{this.state.oproducts.map((oproduct, i) =>
					<div className="mall_list" key={i}>
						{
						oproduct.type === "A" &&
						<div className="online_mall_top">
							{
							oproduct.check_adult === "1" && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to={"/main/onlineMallThroughOut/"+oproduct.omidx+"/"+oproduct.opidx}>
								<figure><img alt="" src={oproduct.omlogo} /></figure>
								<div className="online_mall_top_txt">
									<strong>{oproduct.opname}</strong>
									{
									oproduct.reward_method === "F" &&
									<p className="accum_info">전상품 +{oproduct.reward}P적립</p>
									}
									{
									oproduct.reward_method === "R" &&
									<p className="accum_info">전상품 +{oproduct.reward}%적립</p>
									}
									<div className="cate_tags">
										<span className="on"><i>{(oproduct.ocname).substr(0, 5)}</i></span>
									</div>
									<div className="detail_tags">
										{oproduct.guide_01 !== "" && <span><i>{(oproduct.guide_01).substr(0, 5)}</i></span>}
										{oproduct.guide_02 !== "" && <span><i>{(oproduct.guide_02).substr(0, 5)}</i></span>}
										{oproduct.guide_03 !== "" && <span><i>{(oproduct.guide_03).substr(0, 5)}</i></span>}
										{/*oproduct.guide_04 !== "" && <span><i>{(oproduct.guide_04).substr(0, 5)}</i></span>*/}
										{/*oproduct.guide_05 !== "" && <span><i>{(oproduct.guide_05).substr(0, 5)}</i></span>*/}
									</div>
								</div>
							</Link>
						</div>
						}

						{
						oproduct.type === "G" &&
						<div className="online_mall_top">
							{
							oproduct.check_adult === "1" && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to={"/main/onlineMallThroughOut/"+oproduct.omidx+"/"+oproduct.opidx}>
								<figure><img alt="" src={oproduct.omlogo} /></figure>
								<div className="online_mall_top_txt">
									<strong>{oproduct.opname}</strong>
									{
									oproduct.reward_method === "F" &&
									<p className="accum_info">기획전 +{oproduct.reward}P적립</p>
									}
									{
									oproduct.reward_method === "R" &&
									<p className="accum_info">기획전 +{oproduct.reward}%적립</p>
									}
									<div className="cate_tags">
										<span className="on"><i>{(oproduct.ocname).substr(0, 5)}</i></span>
									</div>
									<div className="detail_tags">
										{oproduct.guide_01 !== "" && <span><i>{(oproduct.guide_01).substr(0, 5)}</i></span>}
										{oproduct.guide_02 !== "" && <span><i>{(oproduct.guide_02).substr(0, 5)}</i></span>}
										{oproduct.guide_03 !== "" && <span><i>{(oproduct.guide_03).substr(0, 5)}</i></span>}
										{/*oproduct.guide_04 !== "" && <span><i>{(oproduct.guide_04).substr(0, 5)}</i></span>*/}
										{/*oproduct.guide_05 !== "" && <span><i>{(oproduct.guide_05).substr(0, 5)}</i></span>*/}

									</div>
								</div>
							</Link>
						</div>
						}

						{
						oproduct.type === "S" &&
						<div className="online_mall_list_each">
							{
							oproduct.check_adult === "1" && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to={"/main/onlineMallThroughOut/"+oproduct.omidx+"/"+oproduct.opidx}>
								<figure><img alt="" src={oproduct.opimage}/></figure>
								<div className="prod_name">
									<strong>{oproduct.opname}</strong>
								</div>
								<p className="store_name">{oproduct.omname}</p>
								<p className="prod_price"><strike>{oproduct.org_price}원</strike><img alt="" className="dc_arrow_s" src={dc_arrow_s} />{oproduct.dc_price}원</p>
								{
								oproduct.reward_method === "F" && oproduct.dc_ratio > 0 &&
								<p className="dc_result">-{oproduct.dc_ratio}%할인 +{oproduct.reward}P적립</p>
								}
								{
								oproduct.reward_method === "F" && oproduct.dc_ratio === 0 &&
								<p className="dc_result">{oproduct.reward}P적립</p>
								}
								{
								oproduct.reward_method === "R" && oproduct.dc_ratio > 0 &&
								<p className="dc_result">-{oproduct.dc_ratio}%할인 +{oproduct.reward}%적립</p>
								}
								{
								oproduct.reward_method === "R" && oproduct.dc_ratio === 0 &&
								<p className="dc_result">{oproduct.reward}%적립</p>
								}
							</Link>
						</div>
						}

						{
						oproduct.type === "B" &&
						<div className="online_mall_list_each">
							{
							oproduct.check_adult === "1" && this.state.adult !== "Y" &&
							<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
								<div className="age_limit_dimm"></div>
								<p>미성년자 불가 상품</p>
							</div>
							}
							<Link to={"/main/onlineMallThroughOut/"+oproduct.omidx+"/"+oproduct.opidx}>
								<figure><img alt="" src={oproduct.opimage}/></figure>
								<div className="prod_name">
									<strong>{oproduct.opname}</strong>
								</div>
								<p className="store_name">{oproduct.omname}</p>
								<p className="prod_price"><strike>미공개</strike><img alt="" className="dc_arrow_s" src={dc_arrow_s} />미공개</p>
								<p className="dc_result">-?%할인 +?%적립</p>
							</Link>
						</div>
						}
					</div>
					)}
					{this.state.oproductCnt === 0 &&
					<div className="online_detail_cont">
						<p className="no_list">“적립 캠페인 준비 중입니다”</p>
					</div>
					}
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<div className="online_mall_guide">
			<ul>
				<li>캐시카우 배너를 통해 온라인몰에 접속해 구매 시에만 적립금이 지급됩니다.</li>
				<li>반품 기한 경과 후 적립금이 지급됩니다.(구매일로 부터 최대 45일 전후)</li>
				<li>캐시카우는 광고플랫폼입니다. 따라서 상기 온라인몰의 상품정보/결제/거래에 대해 책임을 지지 않습니다.</li>
			</ul>
			{/*<!-- <span>캐시카우 배너를 통해 온라인몰에 접속해 구매 시에만 적립금이 지급됩니다.</span>
			<span>반품 기한 경과 후 적립금이 지급됩니다.(구매일로 부터 최대 45일 전후)</span>
			<span>캐시카우는 광고플랫폼입니다. 따라서 상기 온라인몰의 상품정보/결제/거래에 대해 책임을 지지 않습니다.</span> -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mainOnlineMall);