import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import spinner from '../img/common/spinner.gif';

class mycowDonate extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			autoNoLogin: cookies.get('autoNoLogin'),
			uemail: cookies.get('uemail'),
			mycowDonateST: cookies.get('mycowDonateST') == undefined ? 0 : ~~cookies.get('mycowDonateST'),

			dinfo: [],
			dRecents: [],
			dlists: [],
			dcount: 0
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_donation", "", "");
		const { cookies } = this.props;
		if(this.state.mycowDonateST !== 0){
			$(".white_dimm").show();
			$(".spinner").show();
			var _this = this;
			setTimeout(function(){
				$(window).scrollTop(_this.state.mycowDonateST);
				_this.setState({mycowDonateST : 0});
				cookies.set('mycowDonateST', 0, { path: '/' });
				$(".white_dimm").hide();
				$(".spinner").hide();
			});
		}
		this.init();
	}
	componentDidUpdate(){
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowDonate', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		var url = this.state.apiurl+"/user/donate_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("block", 10);
		formData.append("offset", 1);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				dinfo : res.data.data,
				dRecents : res.data.data.amount_recent,
				dcount : res.data.data.count
			});
			if(res.data.data.count > 0){
				this.setState({
					dlists : res.data.data.list
				});
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goPrev(){
		const { cookies } = this.props;
		cookies.set('mycowDonateST', 0, { path: '/' });

		document.location.href="/mycow/mycow";
	}
	goPage = (e) => {
		const { cookies } = this.props;
		cookies.set('mycowDonateST', $(window).scrollTop(), { path: '/' });

		document.location.href = global.variable.cowmallDomain+"/donation?cidx="+this.state.cidx+"&appver="+this.state.version;
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			this.goPrev();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<header className="mem_header">
			<p className="btn_prev"><Link to="#" onClick={this.goPrev.bind(this)}>앞으로</Link></p>
			<h2>기부내역</h2>
		</header>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 내용 영역 -->*/}
			<div className="dona_list_wrap">
				<div className="dona_list_top">
					<div className="dona_sum">
						<p>총 기부 금액</p>
						<strong>{fnc.comma(this.state.dinfo.amount_total == null ? 0 : this.state.dinfo.amount_total)}P</strong>
					</div>
					<div className="dona_history">
						{this.state.dRecents.map((donateRecent,i) =>
						<p k={i}>{donateRecent.year}년<strong>{fnc.comma(donateRecent.sum == null ? 0 : donateRecent.sum)}P</strong></p>
						)}
					</div>
					<div className="dona_thx_bg">따뜻한 마음 감사합니다.</div>
				</div>
				<div className="grey_gap"></div>
				<div className="dona_list_cont">
					<ul>
						{this.state.dlists.map((dlist,i) =>
						<li key={i}>
							<Link to="#">
								<p>{dlist.name}</p>
								<span>{(dlist.insert_ts).substr(0, 10)}</span>
								<strong>{fnc.comma(dlist.donate_point)}P</strong>
							</Link>
						</li>
						)}
					</ul>
				</div>
				{
				this.state.dinfo.count == 0 &&
				<div className="no_dona_list">
					<div className="no_dona_list_cont">
						<p className="txt">아직 기부 내역이 없습니다.<br />따뜻한 마음 나누러 가볼까요?</p>
						<p className="link"><Link to="#" onClick={this.goPage.bind(this)}>기부하러가기</Link></p>
					</div>
				</div>
				}
				{
				this.state.dinfo.count != 0 &&
				<div className="no_dona_list_cont link">
					<p className="link"><Link to="#" onClick={this.goPage.bind(this)}>기부하러가기</Link></p>
				</div>
				}
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(mycowDonate);