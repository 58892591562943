import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { KaKaoTalkButton } from "react-social-kr";
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import buddy_invi_top from '../img/mycow/buddy_invi_top.jpg'
import icon_sns01 from '../img/mycow/icon_sns01.png'
import icon_sns02 from '../img/mycow/icon_sns02.png'
import icon_sns03 from '../img/mycow/icon_sns03.png'
import icon_sns04 from '../img/mycow/icon_sns04.png'
import icon_sns05 from '../img/mycow/icon_sns05.png'
import btn_use_point from '../img/mycow/btn_use_point.png'

function customSend(tempId, title, contents, url){
	window.Kakao.Link.sendCustom({
		templateId: tempId,
		templateArgs: {
			'title': title,
			'description': contents,
			'path': url
		}
	});
}

class mycowInvite extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			inviteUrl: cookies.get('inviteUrl'),
			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),

			rinfo: [],
			uinfo: [],
			test: []
		};
	}
	componentDidMount(){
		fnc.event_log("mycow_friend", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		if(this.state.cidx === 0){
			const { cookies } = this.props;
			cookies.set('loginAfterPath', '/mycow/mycowInvite', { path: '/' });
			if(this.state.autoNoLogin != "Y"){
				document.location.href="/member/login";
			}
		}
		$('html').scrollTop(0);
		$("body").removeClass("gbg");

		const formData = new FormData();
		formData.append('os', global.variable.osType);
		formData.append('appver', this.state.version);
		formData.append('applocale', 'kr');
		formData.append('bypass_emergency', 0);
		formData.append('cidx', this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+'/user/request_recommend_url.json', formData, this.state.config).then( res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("point_info");
			//console.log(res.data.data);
			this.setState({
				rinfo:res.data.data
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+'/user/info.json', formData, this.state.config).then( res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			//console.log("point_info");
			//console.log(res.data.data);
			this.setState({
				uinfo:res.data.data
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		//$(window).scroll(function() { 
		//	$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		//});
	}
	componentDidUpdate(){
	}
	kakaotalkShare(){
		customSend(24039, "[캐시카우] 캐시카우 함께 해요!", "캐시카우를 함께하면 특별한 혜택이!\n추천인 : "+ this.state.uemail, this.state.rinfo.shorturl);
	}
	facebookShare = (url, e) => {
		if(global.variable.osType == "1"){
			window.android.openUrl(this.state.cidx, "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", "", "", "", "");
		}
		if(global.variable.osType == "0"){
			window.webkit.messageHandlers.openUrl.postMessage({ cidx : this.state.cidx, url : "https://www.facebook.com/sharer/sharer.php?u="+url+"&hashtag=%23%EC%BA%90%EC%8B%9C%EC%B9%B4%EC%9A%B0", param1 : "", param2 : "", param3 : "", param4 : "" });
			return;
		}
	}
	smsShare = (e) => {

		var contents = "body=" + encodeURIComponent("캐시카우와 함께하면\n친구도 나도 300포인트 혜택~\n추천인 : "+ this.state.uemail +"\n"+ this.state.rinfo.shorturl);
		if(global.variable.osType == "1"){
			contents = "smsto:?" + contents;
			window.android.sendSms(contents, global.variable.osType);
		}
		if(global.variable.osType == "0"){
			contents = "sms://&" + contents;
			window.webkit.messageHandlers.sendSms.postMessage({ "msg" : contents, "os" : global.variable.osType});
			return;
		}
	}
	copy(){
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".certi_ok_pop").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
		$(".certi_ok_pop").animate({"margin-top":"0","opacity" : "1"}, 400);
		$(".certi_ok").fadeIn();
		var _this = this;
		setTimeout(function(){
			if(global.variable.osType === 0){
				$(window).scrollTop(_this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");
			$(".certi_ok").fadeOut();
			$(".certi_ok_pop").animate({"margin-top":"100","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".certi_ok_pop").hide();
				}
			});
		}, 2000);
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "pback"){
			document.location.href="/mycow/mycow";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		var kakao = {
			pathname: this.state.inviteUrl,
			message: "[캐시카우] 캐시카우 함께 해요!",
			description: "캐시카우와 함께하면 친구도 나도 300포인트 혜택~\\n추천인 : "+ this.state.uemail,
			title: "test",
			jsKey:"c25914003c6cff033ea4508f09d896a6",
			media:"http://cashcow.mgine.co.kr/static/media/buddy_invi_top.a33ffc39.jpg",
			id:"kakaobtn"
		};

		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}
			{/*<!-- 인증완료 팝업 -->*/}
			<div className="layer_dimm certi_ok"style={{display:"none"}}></div>
			<div className="certi_ok_pop"style={{display:"none"}}>복사되었습니다</div>
			{/*<!-- 인증완료 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_prev"><Link to="/mycow/mycow">앞으로</Link></p>
				<h2>친구 초대</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="buddy_invi_wrap">
				<div className="buddy_invi_top">
					<img alt="" src={buddy_invi_top}/>
				</div>
				<div className="grey_gap"></div>
				<div className="buddy_invi_cont">
					<strong className="buddy_invi_ttl">친구 초대</strong>
					<ul className="buddy_invi_sns">
						<KaKaoTalkButton {...kakao} style={{display:"none"}}>
						<li><Link to="#"><img alt="" src={icon_sns01} /></Link></li>
						</KaKaoTalkButton>
						<li><Link to="#" onClick={this.kakaotalkShare.bind(this)}><img alt="" src={icon_sns01} /></Link></li>
						<li><Link to="#" onClick={this.facebookShare.bind(this, this.state.rinfo.shorturl)}><img alt="" src={icon_sns02}/></Link></li>
						{global.variable.osType == "1" &&
						<li><a href={"smsto:?body=캐시카우와 함께하면%0D%0A친구도 나도 300포인트 혜택~%0D%0A추천인 : "+ this.state.uemail +"%0D%0A"+ this.state.rinfo.shorturl}><img alt="" src={icon_sns03} /></a></li>
						}
						{global.variable.osType == "0" &&
						<li><Link to="#" onClick={this.smsShare.bind(this)}><img alt="" src={icon_sns03}/></Link></li>
						}
						<li><a href={"mailto:?subject=습관이 캐시가 되다-캐시카우&body=캐시카우와 함께하면%0D%0A친구도 나도 300포인트 혜택~%0D%0A추천인 : "+ this.state.uemail +"%0D%0A"+ this.state.rinfo.shorturl}><img alt="" src={icon_sns04} /></a></li>
						<CopyToClipboard text={this.state.rinfo.shorturl} onCopy={this.copy.bind(this)}>
						<li><Link to="#"><img alt="" src={icon_sns05} /></Link></li>
						</CopyToClipboard>
					</ul>
					<div className="recom_input_btn">
						{this.state.uinfo.over_recin === false && <Link to="/mycow/mycowInviteInput">추천인 입력하러 가기<img alt="" src={btn_use_point}/></Link>}
						{this.state.uinfo.over_recin === true && <Link to="#" className="done">추천인 입력 완료</Link>}
					</div>
					<p className="recom_input_limit">다른 회원이 나를 추천인으로 입력하면, 총 20회까지 친구 추천포인트가 적립됩니다.</p>
					<p className="recom_input_count">{this.state.uemail} 님은<br/>현재까지 총 <strong>{this.state.rinfo.recer_count}회</strong> 친구추천 적립 되었습니다.</p>
				</div>
			</div>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}
export default withCookies(mycowInvite);