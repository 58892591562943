import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import { Player, ControlBar, VolumeMenuButton, BigPlayButton, LoadingSpinner } from 'video-react';
import "../css/video-react.css";
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js'; 

import spinner from '../img/common/spinner.gif';

class advertise extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),
			originPage: cookies.get('originPage'),
			adid: props.match.params.id,
			adlogid: props.match.params.lid,

			site: [],
			status: "first",
			wh : 0,
			mode : ""
		};
		this.pause = this.pause.bind(this);
	}
	componentDidMount(){
		fnc.event_log("push_mul_video", "", "");
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("off");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "off" } );}
		}catch(e){
			console.log("on pc");
		}
		var _this = this;
		_this.setState({wh : $(window).height()});
		setTimeout(function(){
			_this.setState({wh : $(window).height()});
		}, 700);
		
		$(".mem_header, .adv_accum_bnr, .grey_gap, .adv_accum_prod, .btm_btn").hide();

		const url = this.state.apiurl+"/ad/visit_detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log(res.data.data);
			var error_code = res.data.error_code;
			if(error_code === undefined){
				_this.setState({
					wh : $(window).height()
				});
				$(".mem_header, .adv_accum_bnr, .grey_gap, .adv_accum_prod, .btm_btn").show();
				this.setState({
					qCnt : res.data.data.data.question_count,
					site : res.data.data.data,
				});
			}else{
				_this.setState({
					wh : $(window).height(),
					mode : "no"
				});
				this.alertPop( res.data.error.replace(/\n/gi, '<br/>') );
				$(".layer_dimm").fadeIn();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

//		$(window).scroll(function() { 
//		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".prod_detail_pop").css("top", Math.max(0, (($(window).height() - $(".prod_detail_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		$(".certi_ok_pop").css("top", Math.max(0, (($(window).height() - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
//		});

		this.player.subscribeToStateChange(this.handleStateChange.bind(this));
	}
	pause(state, prevState) {
		console.log(state.currentTime);
	}
	handleStateChange(state, prevState) {
		const { cookies } = this.props;

		if(state.ended && this.state.status !== "ended" && state.currentTime === state.duration){
			this.setState({status : "ended"})
			$(".btm_btn").find("a").addClass("on");
		}else if(state.paused && state.currentTime !== state.duration && this.state.status !== "pause" && this.state.status !== "first"){
			this.setState({status : "pause"})
			console.log("pause");
		}else if(state.hasStarted && this.state.status === "first"){
			this.setState({status : "play"})
			console.log("play");
			$(".btm_btn").find("a").removeClass("on");
		}
	}
	popCls = (cName, e) => {
		if(cName === "complete"){
			if(global.variable.osType === 0){
				$(window).scrollTop(this.state.posY);
				$("#wrap").css("top", 0);
				$("html, body").off('scroll touchmove');
			}
			$('html, body').css("overflow", "visible");
			$(".saving, .interrupt, .prod_detail_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".saving, .interrupt, .prod_detail_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "interrupt"){
			const { cookies } = this.props;
			var originPage = this.state.originPage;
			cookies.set('originPage', '', { path: '/' });
			document.location.href= ( originPage == "" || originPage == null || originPage == undefined ? "/main" : originPage);
		}
	}
	goCompleteShow(){
		if(this.state.status === "ended"){
			var posY = $(window).scrollTop();
				this.setState({
				posY : posY
			});
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");
			$(".saving").css({
				"top" : Math.max(0, ((this.state.wh - $(".saving").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".saving").animate({"margin-top":"0px","opacity" : "1"}, 300);
			$(".layer_dimm").fadeIn();
		}else{
			this.player.play();
		}
	}
	goComplete(){
		const { cookies } = this.props;
		const url = this.state.apiurl+"/ad/visit_report.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("ad_id", this.state.adid);
		formData.append("ad_log_id", this.state.adlogid);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			$(".saving").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".saving").hide();
				}
			});
			$(".certi_ok_pop").css({
				//"top" : Math.max(0, ((this.state.wh - $(".certi_ok_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".certi_ok_pop").animate({"margin-top":"0px","opacity" : "1"}, {
				"duration" : 300,
				"complete" : function(){
					cookies.set('pointSubTab', '2', { path: '/' });
					setTimeout(function(){
						document.location.href="/point/point";
					}, 2000);
				}
			});
			$(".layer_dimm").fadeIn();
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	videoChk(){

		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");

		this.player.pause();
		var play_info = this.player.getState();
		if(play_info.player.currentTime !== play_info.player.duration){
			$(".prod_detail_pop").css({
				"top" : Math.max(0, ((this.state.wh - $(".prod_detail_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".prod_detail_pop").animate({"margin-top":"0px","opacity" : "1"}, 300);
			$(".layer_dimm").fadeIn();
		}else{
			$(".interrupt").css({
				"top" : Math.max(0, ((this.state.wh - $(".interrupt").outerHeight()) / 2) + $(window).scrollTop()) + "px",
				"margin-top" : "100px",
				"opacity" : "0",
				"display" : "block"
			});
			$(".interrupt").animate({"margin-top":"0px","opacity" : "1"}, 300);
			$(".layer_dimm").fadeIn();
		}
	}
	alertPop = (msg, e) => {
		var posY = $(window).scrollTop();
			this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}
		$('body').css("overflow", "hidden");
		$(".alertPop").css("top", Math.max(0, ((this.state.wh - $(".alertPop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		$(".alertPop").find("p").html(msg);
		$(".alertPop").fadeIn();
	}
	alertCls = (e) => {

		if(global.variable.osType === 0){
			$(window).scrollTop(this.state.posY);
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		$(".alertPop").fadeOut("fast");
		$(".layer_dimm").fadeOut("fast");

		var mode = this.state.mode;
		if(mode === "no"){
			this.popCls('interrupt');
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		jsonStr = jsonStr.replace(/(\n|\r\n)/g, '$$$');

		var resObj = JSON.parse(jsonStr);
		if(resObj.key == "pback"){
			this.videoChk();
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>

		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			<div className="layer_dimm"></div>
			<div className="common_pop saving">
				<div className="common_pop_txt">
					<p>적립하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goComplete.bind(this)}>네</Link>
				</div>
			</div>
			<div className="common_pop interrupt">
				<div className="common_pop_txt">
					<p>광고 적립을 종료합니다.<br/>종료 후 다시 진행하실 수 없습니다.</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'complete')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.popCls.bind(this, 'interrupt')}>확인</Link>
				</div>
			</div>
			<div className="prod_detail_pop" style={{display:"none"}}>
				<div className="prod_detail_pop_cont ios">
					<p>동영상을 끝까지 시청하지 않으시면 <br />광고적립이 되지 않습니다. <br />동영상 시청을 중지하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'complete')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.popCls.bind(this, 'interrupt')}>확인</Link>
				</div>
			</div>
			
			<div className="certi_ok_pop" style={{display:"none"}}>{this.state.site.plus_point}P<br/>적립 완료</div>

			{/*<!-- alert 팝업 -->*/}
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- header 영역 -->*/}
			<header className="mem_header">
				<p className="btn_close"><Link to="#" onClick={this.videoChk.bind(this)}>닫기</Link></p>
				<h2>광고적립</h2>
			</header>
			{/*<!-- header 영역 end -->*/}
			{/*<!-- 내용 영역 -->*/}
			<div className="adv_accum_bnr">
				<Player
					playsInline
					autoPlay={false}
					fluid={false}
					width={750}
					src={"https://devimage.cash-cow.co.kr"+this.state.site.video}
					ref={(player) => { this.player = player }}
				>
					<BigPlayButton position="center" />
					<LoadingSpinner />
					<ControlBar autoHide={true} disableDefaultControls>
						<VolumeMenuButton/>
					</ControlBar>
				</Player>

			</div>
			<div className="grey_gap"></div>
			<div className="adv_accum_prod">
				<strong>{this.state.site.name}</strong>
				<div className="adv_accum_prod_cont">
					<p className="adv_accum_prod_txt">{this.state.site.desc}</p>
					<p className="adv_charge_guide">광고 사이트 이동 시 3G/4G/LTE일 경우 통화료가 발생할 수 있습니다.</p>
				</div>
			</div>
			<p className="btm_btn"><Link className="on" to="#" onClick={this.goCompleteShow.bind(this)}>{fnc.comma(this.state.site.plus_point)}P 적립하기</Link></p>
			{/*<!-- 내용 영역 end -->*/}
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(advertise);