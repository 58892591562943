import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'
import axios from 'axios';

import MainOnlineMallCategorySub from './comp/mainOnlineMallCategorySub';
import NetworkDisconnected from '../common/networkDisconnected';
import * as fnc from '../js/fnc.js';

import spinner from '../img/common/spinner.gif';
import dc_arrow from '../img/main/dc_arrow.png';

class mainOnlineMallCategoryView extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const { cookies } = props;
		const oid = props.match.params.id;

		const cidx = cookies.get('cidx') == undefined ? 0 : cookies.get('cidx');
		const apiurl = cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl');

		this.state = {
			oproducts: [],
			oproductCnt: 0,
			oid: oid,
			cidx: cidx,
			apiurl: apiurl,
			version: cookies.get('version'),
			adult: cookies.get('adult'),
			config : global.variable.config
		};
	}
	componentDidMount (){
		this.init();
	}
	componentDidUpdate(){
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$('html').scrollTop(0);
		$("body").addClass("gbg");

		const url = this.state.apiurl+"/online/product_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("category", this.state.oid);
		formData.append("sort", "recent_registeration");
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log("rank_detail");
			//console.log(res.data.data.count);
			//console.log(res.data.data.list);
			this.setState({
				oproducts : res.data.data.list,
				oproductCnt : res.data.data.count
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	orderChg = (sort, e) => {
		const url = this.state.apiurl+"/online/product_list.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("category", this.state.oid);
		formData.append("sort", sort);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				oproducts : res.data.data.list,
				oproductCnt : res.data.data.count
			});

			$(".oCateOrderBtn").find("a").removeClass("on");
			if(sort === 'recent_registeration') $(".oCateOrderBtn").find("a").eq(0).addClass("on");
			if(sort === 'most_purchased') $(".oCateOrderBtn").find("a").eq(1).addClass("on");
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goOnlineView = (idx, e) =>{
		const { cookies } = this.props;
		cookies.set('mainTab', '3', { path: '/' });
		cookies.set('originPage', '/main/mainOnlineMallCategoryView/'+this.state.oid, { path: '/' });
		document.location.href = "/main/onlineMall/"+idx;
	}
	adultChk(){
		const adult = this.state.adult;
		var posY = $(window).scrollTop();
		this.setState({
			posY : posY
		});
		if(global.variable.osType === 0){
			$("#wrap").css("top",-($(window).scrollTop()));
			$('html, body').css("overflow", "hidden");
			$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
		}else if(global.variable.osType === 1){
			$('body').css("overflow", "hidden");
		}
		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + posY) + "px");
		if(this.state.cidx == 0){
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}
		if(this.state.cidx != 0 && (adult == "N" || adult == "X")){
			$(".age_limit").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "U"){
			$(".cert_need, .cert_need_dimm").fadeIn();
		}
		if(this.state.cidx != 0 && adult == "R"){
			$(".recert_need, .recert_need_dimm").fadeIn();
		}
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop( $("#posy").val() );
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		if(cName === "login_need"){
			$(".common_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".common_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "age_limit"){
			$(".age_limit, .age_limit_dimm").fadeOut()
		}
		if(cName === "cert_need"){
			$(".cert_need, .cert_need_dimm").fadeOut()
		}
		if(cName === "recert_need"){
			$(".recert_need, .recert_need_dimm").fadeOut()
		}
	}
	goLogin(){
		const { cookies } = this.props;
		cookies.set('loginAfterPath', "/main/mainOnlineMallCategoryView/"+this.state.oid, { path: '/' });
		document.location.href="/member/login";
	}
	goAuth = (mode, e) => {
		if(global.variable.osType == 1){
			window.android.getIdentity(this.state.cidx, "url", "param1", "param2", "param3", "param4", mode, this.state.uemail);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.getIdentity.postMessage({ cidx : this.state.cidx, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : mode, userid : this.state.uemail } );
			return;
		}
	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;
			if(error == undefined){
				cookies.set('adult', "Y", { path: '/' });
				document.location.href="/main/mainOnlineMallCategoryView/"+this.state.oid;
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					this.alertPop( (resObj.result_message.error).replace(/\$\$/g, '\n') );
				}
			}
		}
		if(resObj.key == "pback"){
			document.location.href="/main/mainOnlineMallCategory";
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.ptidx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = "";
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}

			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl);
		}
		if(resObj.key == "pulldown"){
			this.init();
		}
	}
	render(){
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionEnter={false}
		transitionLeave={false}
		transitionAppearTimeout={500}
		transitionEnterTimeout={500}
		transitionLeaveTimeout={500}>
		{/*<!-- header 영역 -->*/}
		<header className="mem_header cate">
			<p className="btn_prev btn_prev2"><Link to="/main/mainOnlineMallCategory">앞으로</Link></p>
			<h2>온라인몰 카테고리</h2>
		</header>
		{/*<!-- header 영역 end -->*/}
		<div id="wrap">

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 로그인 필요 팝업 -->*/}
			<div className="layer_dimm login_need_dimm"></div>
			<div className="common_pop login_need">
				<div className="common_pop_txt">
					<p>로그인이 필요한 서비스입니다.<br/>로그인 페이지로 이동하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'login_need')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goLogin.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 로그인 필요 팝업 end -->*/}

			{/*<!-- 미성년자 사용 불가 팝업 -->*/}
			<div className="layer_dimm age_limit" style={{display:"none"}}></div>
			<div className="common_pop age_limit" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p className="emphasis">미성년자 사용 불가 서비스 입니다.</p>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'age_limit')}>확인</Link>
			</div>
			{/*<!-- 미성년자 사용 불가 팝업 end -->*/}

			{/*<!-- 본인인증 필요 팝업 -->*/}
			<div className="layer_dimm cert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop cert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						본인(성인) 인증이 필요한 서비스입니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'cert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'adult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 필요 팝업 end -->*/}

			{/*<!-- 본인인증 재인증 필요 팝업 -->*/}
			<div className="layer_dimm recert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop recert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						성인 인증 후 1년이 경과되었습니다.<br/>
						관련 법에 따라 재인증이 필요합니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'recert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'readult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 재인증 필요 팝업 end -->*/}

			<div className="prod_result_pop_wrap prod_result_pop_wrap2">
				<div className="prod_result_pop_bg"></div>
				{/*<!-- 내용 영역 -->*/}
				<div className="prod_cate_search_result mt116">
					<div className="prod_cate_search_top">
						<p className="num">온라인몰 <span>{this.state.oproductCnt}</span>건</p>
						<div className="btns cate_page oCateOrderBtn">
							<Link className="on" to="#" onClick={this.orderChg.bind(this, 'recent_registeration')}>최근 등록순</Link>
							<Link to="#" onClick={this.orderChg.bind(this, 'most_purchased')}>최다 구매순</Link>
						</div>
					</div>
					<div className="main_cont_inner">
						<div className="lp_prod_list">
							<ul>
							{this.state.oproducts.map((oproduct, index) =>
								<li key={index}>
									{
									oproduct.check_adult === 1 && this.state.adult !== "Y" &&
									<div className="age_limit_cover" onClick={this.adultChk.bind(this)}>
										<div className="age_limit_dimm"></div>
										<p>미성년자 불가 상품</p>
									</div>
									}
									{
									oproduct.type === "A" &&
									<Link to="#" onClick={this.goOnlineView.bind(this, oproduct.omidx)}>
										<figure><img alt="" src={oproduct.omlogo}/></figure>
										<div className="txt_wrap">
											<strong className="prod_name">{oproduct.opname}</strong>
											{
											oproduct.reward_method === "F" &&
											<span className="prod_info">전품목 {oproduct.reward}P적립</span>
											}
											{
											oproduct.reward_method === "R" &&
											<span className="prod_info">전품목 {oproduct.reward}%적립</span>
											}
											<div className="lp_cate_icon">
												<p className="yellow"><span>{(oproduct.ocname).substr(0, 5)}</span></p>
												{oproduct.guide_01 !== "" && <p><span>{(oproduct.guide_01).substr(0, 5)}</span></p>}
												{oproduct.guide_02 !== "" && <p><span>{(oproduct.guide_02).substr(0, 5)}</span></p>}
												{oproduct.guide_03 !== "" && <p><span>{(oproduct.guide_03).substr(0, 5)}</span></p>}
												{/*oproduct.guide_04 !== "" && <p>{(oproduct.guide_04).substr(0, 5)}</p>*/}
											</div>
										</div>
									</Link>
									}
									{
									oproduct.type === "G" &&
									<Link to="#" onClick={this.goOnlineView.bind(this, oproduct.omidx)}>
										<figure><img alt="" src={oproduct.omlogo}/></figure>
										<div className="txt_wrap">
											<strong className="prod_name">{oproduct.opname}</strong>
											{
											oproduct.reward_method === "F" &&
											<span className="prod_info">전품목 {oproduct.reward}P적립</span>
											}
											{
											oproduct.reward_method === "R" &&
											<span className="prod_info">전품목 {oproduct.reward}%적립</span>
											}
											<div className="lp_cate_icon">
												<p className="yellow"><span>{(oproduct.ocname).substr(0, 5)}</span></p>
												{oproduct.guide_01 !== "" && <p><span>{(oproduct.guide_01).substr(0, 5)}</span></p>}
												{oproduct.guide_02 !== "" && <p><span>{(oproduct.guide_02).substr(0, 5)}</span></p>}
												{oproduct.guide_03 !== "" && <p><span>{(oproduct.guide_03).substr(0, 5)}</span></p>}
												{/*oproduct.guide_04 !== "" && <p>{(oproduct.guide_04).substr(0, 5)}</p>*/}
											</div>
										</div>
									</Link>
									}
									{
									oproduct.type === "S" &&
									<Link to="#" onClick={this.goOnlineView.bind(this, oproduct.omidx)}>
										<figure><img alt="" src={oproduct.opimage} style={{width:"140px", height:"140px" }}/></figure>
										<div className="txt_wrap dc">
											<strong className="prod_name">{oproduct.opname}</strong>
											<span className="prod_comp">{oproduct.omname}</span>
											{
											oproduct.reward_method === "F" && oproduct.dc_ratio > 0 &&
											<span className="prod_info">할인-{oproduct.dc_ratio}% + {oproduct.reward}P적립</span>
											}
											{
											oproduct.reward_method === "F" && oproduct.dc_ratio === 0 &&
											<span className="prod_info">{oproduct.reward}P적립</span>
											}
											{
											oproduct.reward_method === "R" && oproduct.dc_ratio > 0 &&
											<span className="prod_info">할인-{oproduct.dc_ratio}% + {oproduct.reward}%적립</span>
											}
											{
											oproduct.reward_method === "R" && oproduct.dc_ratio === 0 &&
											<span className="prod_info">{oproduct.reward}%적립</span>
											}
											<div className="dc_price">
												<p className="origin">{oproduct.org_price}원</p>
												<img alt="" src={dc_arrow}/>
												<p>{oproduct.dc_price}원</p>
											</div>
										</div>
									</Link>
									}
									{
									oproduct.type === "B" &&
									<Link to="#" onClick={this.goOnlineView.bind(this, oproduct.omidx)}>
										<figure><img alt="" src={oproduct.opimage} style={{width:"140px", height:"140px" }}/></figure>
										<div className="txt_wrap dc">
											<strong className="prod_name">{oproduct.opname}</strong>
											<span className="prod_comp">{oproduct.omname}</span>
											<span className="prod_info">할인-?% + ?%적립</span>
											<div className="dc_price">
												<p className="origin">미공개</p>
												<img alt="" src={dc_arrow}/>
												<p>미공개</p>
											</div>
										</div>
									</Link>
									}
								</li>
							)}
							</ul>
						</div>
					</div>
				</div>
				{/*<!-- 내용 영역 end -->*/}
			</div>
		</div>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		)
	}
}

export default withCookies(mainOnlineMallCategoryView);