import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'
import '../css/transition.css'

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import * as fnc from '../js/fnc.js';

// common comp
import Header from '../common/header';
import Gnb from '../common/gnb';
import PushLayer from '../common/pushLayer';
import NetworkDisconnected from '../common/networkDisconnected';
import MainBanner from '../main/comp/mainBanner';

// home comp
import MainHomeRanking from '../main/comp/mainHomeRanking';
import MainHomeRecommend from '../main/comp/mainHomeRecommend';
import MainHomeLowPrice from '../main/comp/mainHomeLowPrice';

// rank comp
import MainRankList from '../main/comp/mainRankList';

// product comp
import MainProductRecommend from '../main/comp/mainProductRecommend';
import MainProductProduct from '../main/comp/mainProductProduct';

// onlinemall comp
import MainOnlineMall from '../main/comp/mainOnlineMall';

import spinner from '../img/common/spinner.gif';

class main extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			uemail: cookies.get('uemail'),
			autoNoLogin: cookies.get('autoNoLogin'),
			version: cookies.get('version'),
			mainTab: cookies.get('mainTab') == undefined ? 0 : ~~cookies.get('mainTab'),
			mainST: cookies.get('mainST') == undefined ? 0 : ~~cookies.get('mainST'),

			homes: [],
			banners: [],
			hotks: [],
			rankinfo : [],
			rankdetails : [],
			clsbanner : [],
			wh : 0
		};
		try{
			if(global.variable.osType === 1){window.android.startViewLog("/main/main");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.startViewLog.postMessage({path : "/main/main"});}
		}catch(e){}
	}

	UNSAFE_componentWillMount (){
	}

	componentDidMount(){
		try{
			if(global.variable.osType === 1){window.android.endViewLog("/main/main");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.endViewLog.postMessage({path : "/main/main"});}
		}catch(e){}
		//alert("global : " + global.variable.osType);
		this.init();
	}
	init(){
		try{
			if(global.variable.osType === 1){window.android.setPullOnOff("on");}
			if(global.variable.osType === 0){window.webkit.messageHandlers.setPullOnOff.postMessage({ status  : "on" } );}
		}catch(e){
			console.log("on pc");
		}
		$("body").addClass("gbg");
		$('body').removeClass('notouch').off('scroll touchmove mousewheel');

		const { cookies } = this.props;

		$(".top-gnb").hide();

		var _this = this;
		_this.setState({wh : $(window).height()});
		setTimeout(function(){
			_this.setState({wh : $(window).height()});
		}, 700);
		$(window).one('scroll touchmove touchend', function(){
			setTimeout(function(){
				$('#gnb ul li a.gnb01').trigger('click');
			}, 700);
		});

		$('.common_nav .swiper-wrapper').off("click").on('click', 'a', function(){
			var targetIdx = $(this).parent().index();
			($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(targetIdx).click()
			const { cookies } = _this.props;
			cookies.set('mainTab', targetIdx, { path: '/' });
			if(targetIdx === 0) fnc.event_log("home_main", "home_main_click", "tab_home");
			if(targetIdx === 1) fnc.event_log("home_main", "home_main_click", "tab_ranking");
			if(targetIdx === 2) fnc.event_log("home_main", "home_main_click", "tab_product");
			if(targetIdx === 3) fnc.event_log("home_main", "home_main_click", "tab_online");
		});

		$(".prod_cate_pop_wrap").hide();
		$(".prod_result_pop_wrap").hide();

		url = this.state.apiurl+"/init/banner.json";
		formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "close");
		let spinnerInterval2 = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval2);
			this.setState({
				clsbanner : res.data.data.list
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});

		if(this.state.mainTab === 0){
			$("#homeRanking").click();
			$("#homeRecommend").click();
			$("#homeLowPrice").click();

			var url = this.state.apiurl+"/ranking/hot_keywords.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				this.setState({
					hotks : res.data.data.list
				});
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});

		}
		if(this.state.mainTab === 1){
			var url = this.state.apiurl+"/ranking/hot_keywords.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", this.state.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", this.state.cidx);
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				var rkidx = null;
				this.setState({
					hotks : res.data.data.list
				});
				if($("#rankclick").val() !== ""){
					rkidx = $("#rankclick").val();
					$("#rankclick").val('');
				}else{
					var list_order = Math.floor(Math.random()* res.data.data.count);
					rkidx = res.data.data.list[list_order].rkidx;
				}
				this.keywordChange(rkidx, "random");
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
		if(this.state.mainTab === 2){
			$("#productRecommend").click();
			$("#productProduct").click();
		}
		if(this.state.mainTab === 3){
			$("#onlineOnline").click();
		}
	}
	handleKeyPress = (txt, e) => {
		e.preventDefault();
		if(txt === "txt1")
			fnc.event_log("product_main", "product_main_click", "prod_total_search");
		if(txt === "txt2")
			fnc.event_log("online_main", "online_main_click", "online_total_search");

		document.location.href="/main/mainSearchBlank";
		return;
		if(e.key === "Enter"){
			var stxt = "";
			if(txt == "txt1"){
				stxt = $("#stxt1").val();
			}else{
				stxt = $("#stxt2").val();
			}
			document.location.href="/main/mainSearch/"+stxt;
		}
	}
	popCls = (cName, e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop( $("#posy").val() );
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");

		if(cName === "login_need"){
			$(".common_pop").animate({"margin-top":"100px","opacity" : "0"}, {
				"duration" : 400,
				"complete" : function(){
					$(".common_pop").hide();
				}
			});
			$(".layer_dimm").fadeOut();
		}
		if(cName === "age_limit"){
			$(".age_limit, .age_limit_dimm").fadeOut()
		}
		if(cName === "cert_need"){
			$(".cert_need, .cert_need_dimm").fadeOut()
		}
		if(cName === "recert_need"){
			$(".recert_need, .recert_need_dimm").fadeOut()
		}
	}
	goLogin(){
		const { cookies } = this.props;
		cookies.set('loginAfterPath', '/main', { path: '/' });
		document.location.href="/member/login";
	}
	goAuth = (mode, e) => {
		if(global.variable.osType == 1){
			window.android.getIdentity(this.state.cidx, "url", "param1", "param2", "param3", "param4", mode, this.state.uemail);
		}
		if(global.variable.osType == 0){
			window.webkit.messageHandlers.getIdentity.postMessage({ cidx : this.state.cidx, url : "", param1 : "", param2 : "", param3 : "", param4 : "", mode : mode, userid : this.state.uemail } );
			return;
		}
	}
	alertCls = (e) => {
		if(global.variable.osType === 0){
			$(window).scrollTop( $("#posy").val() );
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".alertPop, .alertPopDimm").fadeOut("fast");
	}
	goHelp = (mode, e) => {
		if(mode === "ranking") fnc.event_log("ranking_main", "ranking_main_click", "btn_help");
		if(mode === "product") fnc.event_log("product_main", "product_main_click", "prod_help");
		if(mode === "online") fnc.event_log("online_main", "online_main_click", "online_help");

		var url = this.state.apiurl+"/service/settings.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			//console.log(res.data);
			document.location.href=res.data.data.url.online_help+"?section="+mode;
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	keywordChange = (idx, mode, e) => {
		if(mode === "click"){
			fnc.event_log("ranking_main", "ranking_main_click", "ranking_tag_"+idx);
		}
		$('html, body').animate({scrollTop: '0'}, 300);
		var url = this.state.apiurl+"/ranking/detail.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("offset", 1);
		formData.append("block", 99);
		formData.append("rkidx", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				rankinfo : res.data.data.ranking_info,
				rankdetails : res.data.data.ranking_details
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goPage = (idx, type, eidx, midx, url, e) => {
		e.preventDefault();
		const { cookies } = this.props;

		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		formData.append("type", "banner");
		formData.append("idx", idx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(this.state.apiurl+"/report/visit.json", formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			cookies.set('originPage', '/main', { path: '/' });
			if(type === 0){
				document.location.href="/mycow/mycowEventView/"+eidx;
			}else if(type === 1){
				if(global.variable.osType == "1"){
					window.android.openinurl(url);
				}
				if(global.variable.osType == "0"){
					window.webkit.messageHandlers.openinurl.postMessage({ url : url });
				}
				//document.location.href=url;
			}else if(type === 2){
				document.location.href="/main/onlineMall/"+midx;
			}else if(type === 3){
			}else if(type === 4){
				document.location.href="/mycow/mycowNoticeView/"+eidx;
			}else if(type === 5){
				document.location.href="/main/productView/"+midx;
			}else if(type === 6){
				var nowTab = 0;
				$(".common_nav").find(".swiper-slide > a").each(function(idx){
					if($(this).hasClass("on")){
						nowTab = idx;
					}
				});
				if(nowTab != 1){
					($(".swiper-pagination-bullets").find(".swiper-pagination-bullet")).eq(1).click()
				}
				$(".rankHotk[idx="+midx+"]").click();
			}
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	noQuit(){
		if(global.variable.osType === 0){
			$(window).scrollTop($("#posy").val());
			$("#wrap").css("top", 0);
			$("html, body").off('scroll touchmove');
		}
		$('html, body').css("overflow", "visible");
		$(".prod_detail_end, .prod_detail_pop_end").fadeOut("fast");
	}
	goQuit(){
		var osType = global.variable.osType;
		if(osType === 1){
			window.android.quit(osType);
		}
		if(osType === 0){
			window.webkit.messageHandlers.quit.postMessage({ os : osType } );
		}
	}
	goCatePage = (mode, e) => {
		if(mode === "product"){
			fnc.event_log("product_main", "product_main_click", "prod_category");
			document.location.href="/main/mainProductCategory";
		}
		if(mode === "online"){
			fnc.event_log("online_main", "online_main_click", "online_category");
			document.location.href="/main/mainOnlineMallCategory";
		}

	}
	hiddenClickHandle = (e) => {
		e.preventDefault();

		const { cookies } = this.props;

		var jsonStr = $("#callbackButton").val();
		var resObj = JSON.parse(jsonStr);

		if(resObj.key == "init"){
			document.location.href="/init";
		}
		if(resObj.key == "autoLogin"){
			var url = this.state.apiurl+"/user/info.json";
			var formData = new FormData();
			formData.append("os", global.variable.osType);
			formData.append("appver", resObj.version);
			formData.append("applocale", "kr");
			formData.append("bypass_emergency", 0);
			formData.append("cidx", resObj.cidx);
			var returnPage = this.state.loginAfterPath;
			returnPage = returnPage == undefined ? "/main" : returnPage;

			if(global.variable.osType == 1){
				window.android.login(resObj.cidx, resObj.userid, global.variable.osType);
			}
			if(global.variable.osType == 0){
				window.webkit.messageHandlers.login.postMessage({ cidx : resObj.cidx , userid : resObj.userid, os : global.variable.osType } );
			}
			//alert("main auto");
			let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
			axios.post(url, formData, this.state.config).then(res => {
				if( ~~res.data.result === -1){
					if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
					if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
				}
				clearInterval(spinnerInterval);
				cookies.set('cidx', resObj.cidx, { path: '/' });
				cookies.set('uemail', resObj.userid, { path: '/' });
				cookies.set('adult', res.data.data.adult, { path: '/' });
				cookies.set('uuid', resObj.uuid, { path: '/' });
				cookies.set('model', resObj.model, { path: '/' });
				cookies.set('version', resObj.version, { path: '/' });

				document.location.href=returnPage;
			}).catch(res => {
				var msg = res.message;
				if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
			});
		}
		if(resObj.key == "getIdentity"){
			var error = resObj.result_message.error;
			if(error == undefined){
				cookies.set('adult', "Y", { path: '/' });
				document.location.href="/main";
			}else{
				var error = resObj.result_message.error;
				if(error == ""){
					this.alertPop( "오류가 발생되었습니다." );
				}else{
					this.alertPop( (resObj.result_message.error).replace(/\$\$/g, '\n') );
				}
			}
		}
		if(resObj.key == "pback"){
			var posY = $(window).scrollTop();
			$("#posy").val(posY);
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
			}
			$('body').css("overflow", "hidden");
			$(".prod_detail_pop_end").css("top", Math.max(0, ((this.state.wh - $(".prod_detail_pop_end").outerHeight()) / 2) + posY) + "px");
			$(".prod_detail_end, .prod_detail_pop_end").fadeIn("fast");
		}
		if(resObj.key == "receivePush"){
			var push_idx = resObj.push_idx;
			var pstatus = resObj.status;
			var ptype = resObj.type;
			var pidx = resObj.idx;
			var psubidx = resObj.subidx;
			var purl = resObj.url;

			if(~~ptype === 51 || ~~ptype === 52 || ~~ptype === 53){
				psubidx = resObj.ad_log_id;
			}
			fnc.push_detail(this.state.apiurl, this.state.version, this.state.cidx, push_idx, pstatus, ptype, pidx, psubidx, purl)
		}
		if(resObj.key == "pulldown"){
			this.init();
		}

	}
	render(){
		const paramsHomeGnb = {
			slidesPerView: 4,
			spaceBetween: 30,
			grabCursor: true,
			autoHeight: true,
			shouldSwiperUpdate: true,
			touchRatio: 0.3,
			initialSlide: this.state.mainTab
		};
		var _this = this;
		const paramsMain = {
			slidesPerView: 1,
			spaceBetween: 0,
			grabCursor: true,
			autoHeight: true,
			shouldSwiperUpdate: true,
			touchRatio: 0.3,
			initialSlide: this.state.mainTab,
			pagination: {
				el: '.top-gnb',
				clickable: true
			},
			on: {
				init: function() {
					$('.main_cont_slide .swiper-wrapper').addClass('important');

					var _thisRealIdx = this.realIndex;
					var _thisHeight = this.height;

					setTimeout(function(){
						if(_this.state.mainST !== 0){
							$(window).scrollTop(_this.state.mainST);
							_this.setState({mainST : 0});
							const { cookies } = _this.props;
							cookies.set('mainST', 0, { path: '/' });
						}
					}, 700);

					setTimeout(function(){
						if ($('.main_cont_slide .swiper-wrapper').hasClass('important')) {
							$('.main_cont_slide .swiper-wrapper').removeClass('important');
						}
						$('nav.common_nav div p:eq(' + _thisRealIdx + ') a').trigger('click');
					}, 700);
				},
				slideChangeTransitionStart: function(){
					_this.setState({mainTab : this.realIndex});
					_this.init();

					$('html').scrollTop(0);
					$('.main_cont_slide .swiper-wrapper').removeClass('important');
					$(".itemListReco:gt(1)").stop().slideUp("fast");
					$(".btn_prod_more").find("a").first().show();
					$('.common_nav div div p a').removeClass('on');
					$('.common_nav div div p a').eq(this.realIndex).addClass('on');

					var _thisIdx = this.realIndex;
					if (_thisIdx == 1) {
						$('.main_hot_keyword').css({
							'bottom':'104px',
							'opacity':'1'
						});
						$('#content').css({
							'padding':'97px 0 319px'
						});
					} else {
						$('.main_hot_keyword').css({
							'bottom':'0',
							'opacity':'0'
						});
						$('#content').css({
							'padding':'97px 0 129px'
						});
					}
				},
				slideChangeTransitionEnd: function(){
					const { cookies } = _this.props;
					cookies.set('mainTab', this.realIndex, { path: '/' });
					_this.setState({mainTab : this.realIndex});
				}
			}
		};
		return (
		<CSSTransitionGroup
		transitionName="upTransition"
		transitionAppear={true}
		transitionAppearTimeout={500}
		transitionEnter={false}
		transitionLeave={false}>
		
		<div id="wrap">

			<div className="layer_dimm prod_detail prod_detail_end" style={{display:"none"}}></div>
			<div className="prod_detail_pop prod_detail_pop_end" style={{display:"none"}}>
				<div className="prod_detail_pop_cont">
					{
					this.state.clsbanner.map((banner, i) =>
					<img alt="" src={banner.image} onClick={this.goPage.bind(this, banner.idx, banner.jumptype, banner.eidx, banner.midx, banner.url)}/>
					)}
					<p>앱을 종료하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.noQuit.bind(this)}>아니오</Link>
					<Link className="dgrey" to="#" onClick={this.goQuit.bind(this)}>네</Link>
				</div>
			</div>

			<div className="white_dimm" style={{display:"none"}}></div>
			<div className="spinner" style={{display:"none"}}><img src={spinner}/></div>
			<div className="layer_dimm netword_dimm" style={{display:"none"}}></div>
			{/*<!-- 알림 설정 팝업 -->*/}
			<NetworkDisconnected/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- alert 팝업 -->*/}
			<div className="layer_dimm alertPopDimm" style={{display:"none"}}></div>
			<div className="idpw_nomatch_pop alertPop" style={{display:"none"}}>
				<p></p>
				<Link to="#" onClick={this.alertCls.bind(this)}>확인</Link>
			</div>
			{/*<!-- alert 팝업 end -->*/}

			{/*<!-- 알림 설정 팝업 -->*/}
			<div className="layer_dimm push_on_plz_dimm" style={{display:"none"}}></div>
			<PushLayer/>
			{/*<!-- 알림 설정 팝업 end -->*/}

			{/*<!-- 로그인 필요 팝업 -->*/}
			<div className="layer_dimm login_need_dimm"></div>
			<div className="common_pop login_need">
				<div className="common_pop_txt">
					<p>로그인이 필요한 서비스입니다.<br/>로그인 페이지로 이동하시겠습니까?</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white" to="#" onClick={this.popCls.bind(this, 'login_need')}>아니요</Link>
					<Link className="dgrey" to="#" onClick={this.goLogin.bind(this)}>네</Link>
				</div>
			</div>
			{/*<!-- 로그인 필요 팝업 end -->*/}

			{/*<!-- 미성년자 사용 불가 팝업 -->*/}
			<div className="layer_dimm age_limit" style={{display:"none"}}></div>
			<div className="common_pop age_limit" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p className="emphasis">미성년자 사용 불가 서비스 입니다.</p>
				</div>
				<Link className="pop_ok" to="#" onClick={this.popCls.bind(this, 'age_limit')}>확인</Link>
			</div>
			{/*<!-- 미성년자 사용 불가 팝업 end -->*/}

			{/*<!-- 본인인증 필요 팝업 -->*/}
			<div className="layer_dimm cert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop cert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						본인(성인) 인증이 필요한 서비스입니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'cert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'adult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 필요 팝업 end -->*/}

			{/*<!-- 본인인증 재인증 필요 팝업 -->*/}
			<div className="layer_dimm recert_need_dimm" style={{display:"none"}}></div>
			<div className="common_pop recert_need" style={{display:"none"}}>
				<div className="common_pop_txt">
					<p>
						성인 인증 후 1년이 경과되었습니다.<br/>
						관련 법에 따라 재인증이 필요합니다.<br/>
						본인인증 페이지로 이동합니다.
					</p>
				</div>
				<div className="btm_confirm_btns">
					<Link className="white cancel" to="#" onClick={this.popCls.bind(this, 'recert_need')}>취소</Link>
					<Link className="dgrey" to="#" onClick={this.goAuth.bind(this, 'readult')}>본인인증하기</Link>
				</div>
			</div>
			{/*<!-- 본인인증 재인증 필요 팝업 end -->*/}

			<Header/>
			{/*<!-- 내용 영역 -->*/}
			<div id="content">
				<nav className="common_nav">
					<Swiper {...paramsHomeGnb} className="swiper-wrapper top-gnb">
						<p className="swiper-slide" key='1'><Link to="#" className="on">홈</Link></p>
						<p className="swiper-slide" key='2'><Link to="#">랭킹</Link></p>
						<p className="swiper-slide" key='3'><Link to="#">상품</Link></p>
						<p className="swiper-slide" key='4'><Link to="#">온라인몰</Link></p>
					</Swiper>
				</nav>
				<div className="main_cont_slide">
					<Swiper {...paramsMain} className="swiper-wrapper">
						{/*<!-- 홈내용 -->*/}
						<div className="swiper-slide" key='1'>
							{/*<!-- 캐시카우 랭킹 -->*/}<MainHomeRanking/>{/*<!-- 캐시카우 랭킹 end -->*/}
							{/*<!-- 배너 -->*/}<MainBanner dataFromParent="home"/>{/*<!-- 배너 end -->*/}
							{/*<!-- 추천 상품 -->*/}<MainHomeRecommend/>{/*<!-- 추천 상품 end -->*/}
							{/*<!-- 온라인 최저가 -->*/}<MainHomeLowPrice/>{/*<!-- 온라인 최저가 end -->*/}
						</div>
						{/*<!-- 홈내용 end -->*/}

						{/*<!-- 랭킹내용 -->*/}
						<div className="swiper-slide" key='2'>
							{/*<!-- 캐시카우 랭킹 -->*/}
							<section className="main_cont_wrap">
								<h2 className="main_cont_ttl">캐시카우 랭킹<Link className="about_ranking" to="#" onClick={this.goHelp.bind(this, 'ranking')}>캐시카우 랭킹이란?</Link></h2>
							</section>
							{/*<!-- 캐시카우 랭킹 end -->*/}
							{/*<!-- 배너 -->*/}<MainBanner dataFromParent="ranking"/>{/*<!-- 배너 end -->*/}
							{/*<!-- 주류/음료 -->*/}<MainRankList rinfo={this.state.rankinfo} list={this.state.rankdetails}/>{/*<!-- 주류/음료 end -->*/}
						</div>
						{/*<!-- 랭킹내용 end -->*/}
						{/*<!-- 상품내용 -->*/}
						<div className="swiper-slide product_slide" key='3'>
							{/*<!-- 상품검색 -->*/}
							<section className="prod_search_wrap">
								<Link className="btn_prod_cate" to="/main/mainProductCategory" onClick={this.goCatePage.bind(this, "product")}>상품카테고리로 이동</Link>
								<div className="prod_search_input">
									<input id="stxt1" name="stxt1" placeholder="검색어" type="text" readOnly="readOnly" onFocus={this.handleKeyPress.bind(this, 'txt1')} onKeyPress={this.handleKeyPress.bind(this, 'txt1')}/>
								</div>
								<Link className="about_ranking prod" to="#" onClick={this.goHelp.bind(this, 'product')}>상품 도움말</Link>
							</section>
							{/*<!-- 상품검색 end -->*/}
							{/*<!-- 배너 -->*/}<MainBanner dataFromParent="product"/>{/*<!-- 배너 end -->*/}
							{/*<!-- 추천상품 -->*/}<MainProductRecommend/>{/*<!-- 추천상품 end -->*/}
							{/*<!-- 영수증 -->*/}<MainProductProduct/>{/*<!-- 영수증 end -->*/}
						</div>
						{/*<!-- 상품내용 end -->*/}
						{/*<!-- 온라인몰내용 -->*/}
						<div className="swiper-slide" key='4'>
							{/*<!-- 상품검색 -->*/}
							<section className="prod_search_wrap">
								<Link className="btn_prod_cate" to="/main/mainOnlineMallCategory" onClick={this.goCatePage.bind(this, "online")}>상품카테고리로 이동</Link>
								<div className="prod_search_input">
									<input id="stxt2" name="stxt2" placeholder="검색어" type="text" readOnly="readOnly" onFocus={this.handleKeyPress.bind(this, 'txt2')} onKeyPress={this.handleKeyPress.bind(this, 'txt2')}/>
								</div>
								<Link className="about_ranking prod" to="#" onClick={this.goHelp.bind(this, 'online')}>상품 도움말</Link>
							</section>
							{/*<!-- 상품검색 end -->*/}
							{/*<!-- 배너 -->*/}<MainBanner dataFromParent="online"/>{/*<!-- 배너 end -->*/}
							{/*<!-- 온라인몰 -->*/}
							<section className="main_cont_wrap">
								<h2 className="main_cont_ttl">온라인몰</h2>
								<div className="main_cont_inner">
									<MainOnlineMall/>
								</div>
							</section>
							{/*<!-- 온라인몰 end -->*/}
						</div>
						{/*<!-- 온라인몰내용 end -->*/}
					</Swiper>
				</div>

				{/*<!-- 핫키워드 -->*/}
				<div className="main_hot_keyword">
					<strong>HOT KEYWORD</strong>
					<ul>
						{this.state.hotks.map((hotk, i) =>
						<li key={i} className="rankHotk" idx={hotk.rkidx} onClick={this.keywordChange.bind(this, hotk.rkidx, "click")}><Link to="#">#{hotk.keyword}</Link></li>
						)}
					</ul>
				</div>
				{/*<!-- 핫키워드 end -->*/}

			</div>
			{/*<!-- 내용 영역 end -->*/}
			{/*<!-- footer 메뉴 -->*/}
			<Gnb/>
			{/*<!-- footer 메뉴 end -->*/}
		</div>
		<button name="posy" id="posy" style={{display:"none"}}></button>
		<button name="rankclick" id="rankclick" style={{display:"none"}}></button>
		<button name="callbackButton" id="callbackButton" style={{display:"none"}} onClick={this.hiddenClickHandle.bind(this)}>test</button>
		</CSSTransitionGroup>
		);
	}
}

export default withCookies(main);