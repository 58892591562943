import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';

import * as fnc from '../js/fnc.js';

class header extends React.Component {

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		const { cookies } = props;

		this.state = {
			cidx: cookies.get('cidx') == undefined || cookies.get('cidx') == "" ? 0 : cookies.get('cidx'),
			apiurl: cookies.get('apiurl') == undefined ? global.variable.apiurl : cookies.get('apiurl'),
			config : global.variable.config,

			version: cookies.get('version'),

			newPush: false
		};
	}


	componentDidMount = () => {
		const { cookies } = this.props;
		const url = this.state.apiurl+"/user/info.json";
		var formData = new FormData();
		formData.append("os", global.variable.osType);
		formData.append("appver", this.state.version);
		formData.append("applocale", "kr");
		formData.append("bypass_emergency", 0);
		formData.append("cidx", this.state.cidx);
		let spinnerInterval = setInterval(function(){$(".white_dimm").show();$(".spinner").show();},3000);
		axios.post(url, formData, this.state.config).then(res => {
			if( ~~res.data.result === -1){
				if(res.data.type === "check_server"){fnc.server_close_pop(res.data.msg);return;}
				if(res.data.type === "update"){fnc.update_pop(res.data.msg);return;}
			}
			clearInterval(spinnerInterval);
			this.setState({
				newPush : res.data.data.new_push
			});
		}).catch(res => {
			var msg = res.message;
			if(msg === global.variable.axiosTimeoutMsg){fnc.network_error_pop();}
		});
	}
	goNotice(e){
		const { cookies } = this.props;

		$(".common_pop").css("top", Math.max(0, (($(window).height() - $(".common_pop").outerHeight()) / 2) + $(window).scrollTop()) + "px");
		if(this.state.cidx === 0){
			var posY = $(window).scrollTop();
			$("#posy").val(posY);
			if(global.variable.osType === 0){
				$("#wrap").css("top",-($(window).scrollTop()));
				$("html, body").on('scroll touchmove', function(e){e.preventDefault();});
				$('html, body').css("overflow", "hidden");
			}else{
				$('body').css("overflow", "hidden");
			}
			$(".login_need").css({"display" : "block","margin-top" : "100px","opacity" : "0"});
			$(".login_need").animate({"margin-top":"0","opacity" : "1"}, 400);
			$(".login_need_dimm").fadeIn("fast");
		}else{
			fnc.event_log("home_main", "home_main_click", "btn_notify");
			document.location.href="/common/notice";
		}
	}

	render(){
		return (
		<header id="header">
			<h1><Link to="/main">CASHCOW</Link></h1>
			<p className="header_alarm"><Link to="#" onClick={this.goNotice.bind(this)}>알람</Link>{this.state.newPush === true && <i class="new_alarm"></i>}</p>
		</header>
		)
	}
}

export default withCookies(header);